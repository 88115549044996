import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { MyDocsStateResponse, MyDocsActionTypes } from './types'
import {
  getMyDocsStatusSuccess,
  getMyDocsStatusFailure,
} from './actions'

import { EXTERNAL_URL_MAP } from '../../routes/ExternalUrls'
import { get } from '../../utils/ajax'

const getMyDocsStatus = async (): Promise<MyDocsStateResponse> => {
  const res = await get<MyDocsStateResponse>(EXTERNAL_URL_MAP.MYDOCS)
  return res.data
}


function* handleGetMyDocsStatus(): Generator {
  try {
    const res: MyDocsStateResponse = (yield call(getMyDocsStatus)) as MyDocsStateResponse
    yield put(getMyDocsStatusSuccess(res))
  } catch (err) {
    yield put(getMyDocsStatusFailure())
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchGetMyDocsStatusRequest() {
  yield takeEvery(MyDocsActionTypes.GetMyDocsStatus, handleGetMyDocsStatus)
}

// Export our root saga.
// We can also use `fork()` here to split our saga into multiple watchers.
export function* myDocsSaga() {
  yield all([
    fork(watchGetMyDocsStatusRequest),
  ])
}
