import { cloneDeep } from 'lodash';
import {
  AssistiveTechnology,
  AssistiveTechnologySelection,
  ITEquipment,
  ShippingAddressApplicationState,
  ShippingAddressAction,
  ShippingAddressActionTypes,
  SubmissionStatus,
  SuggestedAddress,
  UpdateEquipmentPreferenceAction,
} from './types';

import {
  defaultKeyboardLayout,
  EquipmentStatus,
  equipmentPreferenceStatusCode,
  freezeEquipmentPreferences,
  setIsDirty,
} from '../../utils/shippingAddressUtils';

export const createInitialShippingAddressState = (): ITEquipment => {
  return {
    availableEquipment: [],
    availableAssistiveTechnologies: [],
    assistiveTechnologyPreferences: [],
    availableKeyboardLayouts: [],
    buildingSecurityCode: '',
    canKeepHardware: false,
    canGetItKit: false,
    city: '',
    country: '',
    county: '',
    deliveryInstructions: '',
    equipmentDetailsSubmitted: false,
    equipmentPreference: '',
    equipmentStatus: EquipmentStatus.UNAVAILABLE,
    fullName: '',
    isAssistiveTechnologyActive: true,
    keyboardPreference: '',
    lockDate: '',
    locked: false,
    line1: '',
    line2: '',
    line3: '',
    overrideAddressValidation: false,
    phoneNumber: '',
    phoneNumberInfo: {
      isoCode: '',
      countryCode: '',
      phoneNumber: '',
    },
    suggestionId: '',
    state: '',
    willGetItKit: false,
    willKeepHardware: false,
    zip: '',
  };
};

const initialCurrentShippingAddress: ITEquipment = createInitialShippingAddressState();
const initialDraftShippingAddress: ITEquipment = createInitialShippingAddressState();

export const initialState: ShippingAddressApplicationState = {
  attemptedSubmit: false,
  currentlyEditing: false,
  currentShippingAddress: initialCurrentShippingAddress, // mutable state
  draftShippingAddress: initialDraftShippingAddress, // immutable state (cannot change e.g. canKeepHardware, canGetItKit)
  isDirty: false,
  submissionStatus: SubmissionStatus.Unsubmitted,
  touched: {},
  needsValidation: false,
  addressValidationResult: {},
};

const addressValidationFields: SuggestedAddress = {
  line1: '',
  line2: '',
  line3: '',
  city: '',
  state: '',
  zip: '',
  county: '',
  country: '',
};

const isAddressValidationField = (fields: { [key: string]: string }) =>
  Object.keys(fields).findIndex((field) => field in addressValidationFields) >= 0;

export const shippingAddressReducer = (state = initialState, action: ShippingAddressAction) => {
  let payload;
  switch (action.type) {
    case ShippingAddressActionTypes.LoadShippingAddressSuccess: {
      const payload = action.payload;
      const { equipmentDetailsSubmitted, lockDate } = payload;
      payload.locked = freezeEquipmentPreferences(equipmentDetailsSubmitted)(lockDate);
      if (!payload.keyboardPreference) {
        payload.keyboardPreference = defaultKeyboardLayout(payload.availableKeyboardLayouts);
      }

      const draftShippingAddress = {
        ...cloneDeep(payload),
        isAssistiveTechnologyActive: shouldKeepAssistiveTechnologySectionOpen(payload),
      };

      return {
        ...state,
        currentlyEditing: !equipmentDetailsSubmitted,
        currentShippingAddress: payload,
        draftShippingAddress,
        isDirty: false,
        submissionStatus: equipmentDetailsSubmitted ? SubmissionStatus.SubmissionSuccess : SubmissionStatus.Unsubmitted,
      };
    }
    case ShippingAddressActionTypes.UpdateShippingAddress: {
      return {
        ...state,
        submissionStatus: SubmissionStatus.Submitting,
      };
    }
    case ShippingAddressActionTypes.UpdateShippingAddressSuccess: {
      const { equipmentStatus = EquipmentStatus.DUE_NOW } = state.currentShippingAddress;
      payload = action.payload;
      if (equipmentStatus === EquipmentStatus.OVERDUE) {
        payload.locked = true;
      }
      payload.isAssistiveTechnologyActive = state.draftShippingAddress.isAssistiveTechnologyActive;
      return {
        ...state,
        currentlyEditing: false,
        isDirty: false,
        currentShippingAddress: payload,
        draftShippingAddress: cloneDeep(payload),
        submissionStatus: SubmissionStatus.SubmissionSuccess,
      };
    }
    case ShippingAddressActionTypes.UpdateShippingAddressSuggestions: {
      payload = action.payload;
      return {
        ...state,
        addressValidationResult: payload,
        submissionStatus: SubmissionStatus.SubmissionHasSuggestions,
      };
    }
    case ShippingAddressActionTypes.UpdateShippingAddressFailure: {
      return {
        ...state,
        submissionStatus: SubmissionStatus.SubmissionFailure,
      };
    }
    case ShippingAddressActionTypes.EditShippingAddress:
      return {
        ...state,
        currentlyEditing: true,
        submissionStatus: SubmissionStatus.Unsubmitted,
      };
    case ShippingAddressActionTypes.CancelEditShippingAddress:
      const { currentShippingAddress } = state;
      const draftShippingAddress = {
        ...cloneDeep(currentShippingAddress),
        isAssistiveTechnologyActive: state.draftShippingAddress.isAssistiveTechnologyActive,
      };
      return {
        ...state,
        currentlyEditing: false,
        draftShippingAddress,
        isDirty: false,
        isAssistiveTechnologyActive: state.draftShippingAddress.isAssistiveTechnologyActive,
        submissionStatus: SubmissionStatus.Unsubmitted,
        touched: {},
      };
    case ShippingAddressActionTypes.ClearShippingAddress: {
      return clearShippingAddress(state);
    }
    case ShippingAddressActionTypes.UpdateEquipmentPreference: {
      return updateEquipmentPreference(state, action);
    }
    case ShippingAddressActionTypes.CalculateEquipmentPreferenceStatus: {
      const { currentShippingAddress } = state;
      const { equipmentDetailsSubmitted, lockDate } = currentShippingAddress;
      const formStatus = equipmentPreferenceStatusCode(equipmentDetailsSubmitted)(lockDate);
      const newShippingAddressState = { ...currentShippingAddress, equipmentStatus: formStatus };

      return {
        ...state,
        currentShippingAddress: newShippingAddressState,
      };
    }
    case ShippingAddressActionTypes.ChangeShippingAddress: {
      const address: { [key: string]: string } = action.payload;
      const { draftShippingAddress, isDirty, touched } = state;
      const addressTouchMap = Object.fromEntries(Object.entries(address).map((entry) => [entry[0], true]));
      const newDraftShippingAddress = Object.assign({}, draftShippingAddress, address);
      return {
        ...state,
        attemptedSubmit: false,
        draftShippingAddress: { ...newDraftShippingAddress, overrideAddressValidation: false, suggestionId: '' },
        needsValidation: isAddressValidationField(address) || state.needsValidation,
        isDirty: setIsDirty(isDirty, true),
        touched: {
          ...touched,
          ...addressTouchMap,
        },
      };
    }
    case ShippingAddressActionTypes.ChangeShippingAddressCountry: {
      const country: string = action.payload;
      const { draftShippingAddress, touched } = state;
      return {
        ...state,
        attemptedSubmit: false,
        isDirty: false,
        draftShippingAddress: Object.assign({}, draftShippingAddress, { country }),
        touched: {
          equipmentPreference: !!touched.equipmentPreference,
          country: true,
        },
      };
    }
    case ShippingAddressActionTypes.AddMessageBannerRef: {
      const messageBannerRef = action.payload;
      return {
        ...state,
        messageBannerRef,
      };
    }
    case ShippingAddressActionTypes.AttemptEquipmentPreferenceSubmission: {
      return {
        ...state,
        attemptedSubmit: true,
      };
    }
    case ShippingAddressActionTypes.UpdateKeyboardLayoutSelection: {
      const { payload } = action;
      const { draftShippingAddress } = state;
      return {
        ...state,
        draftShippingAddress: {
          ...draftShippingAddress,
          keyboardPreference: payload,
        },
      };
    }

    case ShippingAddressActionTypes.RetainExistingHardware: {
      return retainExistingEquipment(state);
    }

    case ShippingAddressActionTypes.SelectITKit: {
      const { draftShippingAddress } = state;
      return {
        ...state,
        draftShippingAddress: {
          ...draftShippingAddress,
          willGetItKit: !draftShippingAddress.willGetItKit,
        },
      };
    }

    case ShippingAddressActionTypes.ValidateAndGetAddressSuggestions: {
      return {
        ...state,
        submissionStatus: SubmissionStatus.Validating,
        needsValidation: false,
      };
    }

    case ShippingAddressActionTypes.OverrideAddressValidation: {
      const { draftShippingAddress } = state;
      return {
        ...state,
        draftShippingAddress: {
          ...draftShippingAddress,
          overrideAddressValidation: true,
        },
        needsValidation: false,
      };
    }

    case ShippingAddressActionTypes.ChangeShippingAddressFromSuggestion: {
      const address = action.payload;
      const { draftShippingAddress } = state;
      // const newDraftShippingAddress = Object.assign({}, draftShippingAddress, address)

      return {
        ...state,
        attemptedSubmit: false,
        draftShippingAddress: {
          ...draftShippingAddress,
          ...address,
          overrideAddressValidation: false,
        },
        needsValidation: false,
      };
    }

    case ShippingAddressActionTypes.SelectAssistiveTechnology: {
      const assistiveTech = action.payload;
      const { draftShippingAddress } = state;

      return {
        ...state,
        draftShippingAddress: {
          ...draftShippingAddress,
          ...selectAT(draftShippingAddress, assistiveTech),
        },
      };
    }

    case ShippingAddressActionTypes.UnSelectAssistiveTechnology: {
      const assistiveTech = action.payload;
      const { draftShippingAddress } = state;
      return {
        ...state,
        draftShippingAddress: {
          ...draftShippingAddress,
          ...unselectAT(draftShippingAddress, assistiveTech),
        },
      };
    }

    case ShippingAddressActionTypes.OpenAssistiveTechnologyExpander: {
      const { draftShippingAddress } = state;
      return {
        ...state,
        draftShippingAddress: {
          ...draftShippingAddress,
          ...toggleAT(draftShippingAddress),
        },
      };
    }

    default:
      return state;
  }
};

// Business logic on how to clear the form.
// This is triggered when a shipping address country is changed on the IT form.
export const clearShippingAddress = (state: ShippingAddressApplicationState): ShippingAddressApplicationState => {
  const { country, equipmentPreference, keyboardPreference } = state.draftShippingAddress;
  const initialShippingAddressState = createInitialShippingAddressState();

  return {
    ...state,
    currentlyEditing: true,
    draftShippingAddress: {
      ...initialShippingAddressState,
      availableAssistiveTechnologies: state.draftShippingAddress.availableAssistiveTechnologies,
      assistiveTechnologyPreferences: state.draftShippingAddress.assistiveTechnologyPreferences,
      country,
      equipmentPreference,
      isAssistiveTechnologyActive: state.draftShippingAddress.isAssistiveTechnologyActive,
      keyboardPreference,
    },
  };
};

export const updateEquipmentPreference = (
  state: ShippingAddressApplicationState,
  action: UpdateEquipmentPreferenceAction
): ShippingAddressApplicationState => {
  const newEquipmentPreference = action.payload;
  const currentShippingAddress = state.currentShippingAddress;
  const oldDraftShippingAddress = state.draftShippingAddress;
  const touched = state.touched;
  const newDraftShippingAddress = {
    ...oldDraftShippingAddress,
    equipmentPreference: newEquipmentPreference,
    willKeepHardware: false,
  };
  return {
    ...state,
    attemptedSubmit: false,
    draftShippingAddress: newDraftShippingAddress,
    isDirty: setIsDirty(state.isDirty, currentShippingAddress.equipmentPreference !== newEquipmentPreference),
    touched: {
      ...touched,
      equipmentPreference: true,
    },
  };
};

export const retainExistingEquipment = (state: ShippingAddressApplicationState) => {
  const { currentShippingAddress, draftShippingAddress } = state;
  const didNotPreviouslyRetainEquipment = !currentShippingAddress.willKeepHardware;
  const result = {
    ...state,
    isDirty: didNotPreviouslyRetainEquipment,
    draftShippingAddress: {
      ...draftShippingAddress,
      willKeepHardware: true,
      willGetItKit: false,
      equipmentPreference: undefined,
    },
  };
  return result;
};

export const selectAT = (
  state: AssistiveTechnologySelection,
  selection: AssistiveTechnology
): AssistiveTechnologySelection => {
  const { assistiveTechnologyPreferences = [] } = state;
  return {
    ...state,
    assistiveTechnologyPreferences: [...assistiveTechnologyPreferences, selection],
  };
};

export const unselectAT = (
  state: AssistiveTechnologySelection,
  selection: AssistiveTechnology
): AssistiveTechnologySelection => {
  const { assistiveTechnologyPreferences = [] } = state;
  return {
    ...state,
    assistiveTechnologyPreferences: assistiveTechnologyPreferences.filter(
      (preferance) => preferance.description !== selection.description
    ),
  };
};

export const toggleAT = (state: AssistiveTechnologySelection): AssistiveTechnologySelection => {
  const { isAssistiveTechnologyActive } = state;
  return {
    ...state,
    isAssistiveTechnologyActive: !isAssistiveTechnologyActive,
  };
};

export const shouldKeepAssistiveTechnologySectionOpen = (
  assistiveTechnologySelection: AssistiveTechnologySelection
): boolean => {
  return assistiveTechnologySelection?.assistiveTechnologyPreferences?.length > 0;
};
