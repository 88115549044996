import { Action } from 'redux';

export interface Task {
  title: string;
  // description: string
  // linkText: string
  url: string;
  status: number;
  type: string;
  slug: string;
}

export interface DashboardTaskState {
  loading: boolean;
  tasks: Task[];
}

export interface DashboardApplicationState {
  taskState: DashboardTaskState;
}

export enum DashboardEvent {
  LoadTasks = 'NEWHIRE_DASHBOARD_LOAD_TASKS',
  LoadTasksSuccess = 'NEWHIRE_DASHBOARD_LOAD_TASKS_SUCCESS',
  LoadTasksFailure = 'NEWHIRE_DASHBOARD_LOAD_TASKS_FAILURE',
  LoadTaskContent = 'NEWHIRE_DASHBOARD_LOAD_TASK_CONTENT',
  GoBackToDashboard = 'NEWHIRE_DASHBOARD_GO_BACK_TO_DASHBOARD',
}

interface LoadTasksAction extends Action {
  type: DashboardEvent.LoadTasks
}

interface LoadTasksSuccessAction extends Action {
  type: DashboardEvent.LoadTasksSuccess
  // payload: { tasks : Task[]}
  payload: Task[]
}

interface LoadTasksFailureAction extends Action {
  type: DashboardEvent.LoadTasksFailure
}

interface LoadTaskContentAction extends Action {
  type: DashboardEvent.LoadTaskContent
}

interface GoBackToDashboardAction extends Action {
  type: DashboardEvent.GoBackToDashboard
}

export type TaskAction = LoadTasksAction
  | LoadTasksSuccessAction
  | LoadTasksFailureAction
  | LoadTaskContentAction
  | GoBackToDashboardAction
