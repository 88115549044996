import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects'
import { WorkAuthStateResponse, WorkAuthStateRequest, UpdateWorkAuthStatusAction, WorkAuthActionTypes } from './types'
import {
  getWorkAuthStatusSuccess,
  getWorkAuthStatusFailure,
  updateWorkAuthStatusSuccess,
  updateWorkAuthStatusFailure
} from './actions'

import { NavActionTypes } from '../nav/types'

import { EXTERNAL_URL_MAP } from '../../routes/ExternalUrls'
import { get, post } from '../../utils/ajax'
import postUtil from '../../metrics/postUtil';

const getWorkAuthStatus = async (): Promise<WorkAuthStateResponse> => {
  const res = await get<WorkAuthStateResponse>(EXTERNAL_URL_MAP.WORKAUTH)
  return res.data
}

const updateWorkAuthStatus = async (workAuthStateRequest: WorkAuthStateRequest): Promise<WorkAuthStateResponse> => {
  const res = await post<WorkAuthStateResponse>(EXTERNAL_URL_MAP.WORKAUTH, workAuthStateRequest)
  return res.data
}

function* handleGetWorkAuthStatus(): Generator {
  try {
    const res: WorkAuthStateResponse = (yield call(getWorkAuthStatus)) as WorkAuthStateResponse
    yield put(getWorkAuthStatusSuccess(res))
  } catch (err) {
    yield put(getWorkAuthStatusFailure())
  }
}

function* handleUpdateWorkAuthStatus(action: UpdateWorkAuthStatusAction): Generator {
  try {
    const res: any = (yield call(updateWorkAuthStatus, action.payload)) as any
    postUtil('workAuth.status.success')({
      timeStamp: (new Date()).toISOString()
    })

    yield all([
      put(updateWorkAuthStatusSuccess(res)),
      put({ type: NavActionTypes.FetchNav, meta: { refetch: true } })
    ])
  } catch (err) {
    postUtil('workAuth.status.failure')({
      timeStamp: (new Date()).toISOString()
    })
    yield put(updateWorkAuthStatusFailure())
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchGetWorkAuthStatusRequest() {
  yield takeEvery(WorkAuthActionTypes.GetWorkAuthStatus, handleGetWorkAuthStatus)
}

function* watchUpdateWorkAuthStatusRequest() {
  yield takeEvery(WorkAuthActionTypes.UpdateWorkAuthStatus, handleUpdateWorkAuthStatus)
}

// Export our root saga.
// We can also use `fork()` here to split our saga into multiple watchers.
export function* workAuthSaga() {
  yield all([
    fork(watchGetWorkAuthStatusRequest),
    fork(watchUpdateWorkAuthStatusRequest),
  ])
}
