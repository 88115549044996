import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'
import { SubFooterEvent, SubFooterItem } from './types'
import { get } from '../../utils/ajax'

import { EXTERNAL_URL_MAP } from '../../routes/ExternalUrls'

import {  loadSubFooterItemsSuccessAction, loadSubFooterItemsFailureAction } from './actions'

import { ApplicationState } from '../types'

import {
  prefixSegmentToPath
} from '../../context/baseSegmentContext'

const loadSubFooterItems = async (prefixSegment: string) : Promise<SubFooterItem[]> => {
  const subFooterUrl = prefixSegmentToPath({
    path: EXTERNAL_URL_MAP.SUB_FOOTER_ITEMS,
    prefixSegment 
  })
  const response = await get<SubFooterItem[]>(subFooterUrl)
  return response.data
}

function* handleLoadSubFooterItemsRequest(): Generator {
  try {
    const prefixSegment = (yield select((state: ApplicationState) => state.environment.baseSegmentPrefix)) as string
    const res: SubFooterItem[] = (yield(call(loadSubFooterItems, prefixSegment))) as SubFooterItem[]
    yield(put(loadSubFooterItemsSuccessAction(res)))
  } catch(err) {
    yield(put(loadSubFooterItemsFailureAction()))
  }
}

function* watchLoadSubFooterItemsRequests() {
  yield takeEvery(
    SubFooterEvent.LoadSubFooterItems,
    handleLoadSubFooterItemsRequest
  )
}

export function* subFooterSaga() {
  yield all([    
    fork(watchLoadSubFooterItemsRequests)
  ])
}
