import { Action } from 'redux'

export interface MyDocsStateResponse {
    status: number,
    dueDate: string,
    myDocsPortalSsoUrl: string,
}

export interface MyDocsStateRequest {
    status: number,
    dueDate: string,
    myDocsPortalSsoUrl: string,
}

export interface MyDocsApplicationState {
    status?: number,
    dueDate?: string,
    myDocsPortalSsoUrl?: string,
}

export enum MyDocsActionTypes {
    GetMyDocsStatus = 'GET_MYDOCS_STATUS',
    GetMyDocsStatusSuccess = 'GET_MYDOCS_STATUS_SUCCESS',
    GetMyDocsStatusFailure = 'GET_MYDOCS_STATUS_FAILURE',
}

export interface GetMyDocsStatusAction extends Action {
    type: MyDocsActionTypes.GetMyDocsStatus
}

export interface GetMyDocsStatusSuccessAction extends Action {
    type: MyDocsActionTypes.GetMyDocsStatusSuccess
    payload: MyDocsStateResponse
}

export interface GetMyDocsStatusFailureAction extends Action {
    type: MyDocsActionTypes.GetMyDocsStatusFailure
}

export type MyDocsAction =
    GetMyDocsStatusAction
    | GetMyDocsStatusSuccessAction
    | GetMyDocsStatusFailureAction
