import { configureStore } from './store';
import { DEFAULT_LANGUAGE_TAG } from './i18n-constants';
export const store = configureStore();
const state = store.getState();
const { languages } = state;
export const languageTag: string = languages?.languageOfPreference?.tag ?? DEFAULT_LANGUAGE_TAG;

const normalizeToLDML = (locale: string) => locale.replace(/\_/, '-');
export const normalizedLocale = normalizeToLDML(languageTag);

export const config = {
  normalizedLocale,
  defaultLocale: 'en-US',
};
