import { VideoState, VideoActionTypes, VideoActions } from '../types'
import { Maybe, Just } from 'monet';
import { curry } from 'lodash/fp';

const initialVideoState: Maybe<VideoState> = Just({
  playing: null,
  meta: {}
});

const playVideo = (action: VideoActions, state: VideoState): VideoState => {
  return {
    ...state,
    playing: action.payload.id
  };
};

const currentlyPlayingVideo = (action: VideoActions, state: VideoState): VideoState => {
  const meta = { ...state.meta };
  meta[action.payload.id] = {
    currentTime: action.payload.currentTime
  };

  return {
    ...state,
    meta
  };
}

export const videoReducer = (state: Maybe<VideoState> = initialVideoState, action: VideoActions): Maybe<VideoState> => {
  switch(action.type){
    case VideoActionTypes.Play:
      const playFn = curry(playVideo)(action);
      return state.map(playFn);
    case VideoActionTypes.CurrentlyPlaying:
      const currentlyPlayingFn = curry(currentlyPlayingVideo)(action);
      return state.map(currentlyPlayingFn);
    default:
      return state;
  }
};
