import { Action } from 'redux'

export enum FaqActionTypes {
    FetchFaq = 'FAQ_REQUEST',
    FetchFaqFulfilled = 'FAQ_RESPONSE_COMPLETED',
    FetchFaqFailed = 'FAQ_REQUEST_FAILED'
}

interface FaqItem {
  title: string,
  content: string
}

export interface FaqResponse {
  items: FaqItem[]
}

export interface FetchFaqAction extends Action {
    type: FaqActionTypes.FetchFaq
}

export interface FetchFaqFulfilledAction extends Action {
    type: FaqActionTypes.FetchFaqFulfilled
    payload: FaqResponse
}

export interface FetchFaqFailedAction extends Action {
    type: FaqActionTypes.FetchFaqFailed
}

export type FaqActions = FetchFaqAction | FetchFaqFulfilledAction | FetchFaqFailedAction;
