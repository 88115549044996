import {
  EnvActionType,
  EnvEvent
} from './types'

import { createStandardAction } from 'typesafe-actions'

import { HostAppContext } from '../../types/hostApp'

export const loadEnvAction = createStandardAction(EnvEvent.LoadEnv)()
export const loadEnvSuccessAction = createStandardAction(EnvEvent.LoadEnvSuccess)()
export const loadEnvFailureAction = createStandardAction(EnvEvent.LoadEnvFailure)()

export const persistHostApp = createStandardAction(EnvActionType.PersistHostApp)<HostAppContext>()
