import React, { useEffect } from 'react';
import postUtil from '../../metrics/postUtil';

export default function TestError() {
  useEffect(() => {
    postUtil('app.error')({
      timeStamp: new Date().toUTCString(),
    });
  }, []);

  return <div>Testing Client-Side Error Handling.</div>;
}
