import { debounce } from 'lodash'

import { onLCP, Metric } from 'web-vitals'
import postMetric from '../metrics/postUtil.js'

function setupLcpTracking(metric: Metric){
  const postLcp = postMetric('performance.lcp')
  postLcp({
    metric,
    pageName: window.location.pathname
  })
}

export function trackPerformanceMetrics(){
  onLCP(debounce(setupLcpTracking, 2000), { reportAllChanges: true })
}
