import { RefObject } from 'react'
import { createStandardAction } from 'typesafe-actions'
import { AddressValidationResult, AssistiveTechnology, ShippingAddressActionTypes, ShippingAddress } from './types'

export const calculateEquipmentPreferenceStatus = createStandardAction(ShippingAddressActionTypes.CalculateEquipmentPreferenceStatus)<any>()
export const loadShippingAddress = createStandardAction(ShippingAddressActionTypes.LoadShippingAddress)()
export const loadShippingAddressSuccess = createStandardAction(ShippingAddressActionTypes.LoadShippingAddressSuccess)<ShippingAddress>()
export const loadShippingAddressFailure = createStandardAction(ShippingAddressActionTypes.LoadShippingAddressFailure)()

export const editShippingAddress = createStandardAction(ShippingAddressActionTypes.EditShippingAddress)()
export const cancelEditShippingAddress = createStandardAction(ShippingAddressActionTypes.CancelEditShippingAddress)()
export const clearShippingAddress = createStandardAction(ShippingAddressActionTypes.ClearShippingAddress)()

export const updateShippingAddress = createStandardAction(ShippingAddressActionTypes.UpdateShippingAddress)<ShippingAddress>()
export const updateShippingAddressSuccess = createStandardAction(ShippingAddressActionTypes.UpdateShippingAddressSuccess)<ShippingAddress>()
export const updateShippingAddressFailure = createStandardAction(ShippingAddressActionTypes.UpdateShippingAddressFailure)()
export const updateShippingAddressSuggestions = createStandardAction(ShippingAddressActionTypes.UpdateShippingAddressSuggestions)<AddressValidationResult>()

export const updateEquipmentSelection = createStandardAction(ShippingAddressActionTypes.UpdateEquipmentPreference)<string>()

export const updateKeyboardLayoutSelection = createStandardAction(ShippingAddressActionTypes.UpdateKeyboardLayoutSelection)<string>()

export const changeShippingAddress = createStandardAction(
  ShippingAddressActionTypes.ChangeShippingAddress
)<{[key: string]: string}>()
export const changeShippingAddressCountry = createStandardAction(ShippingAddressActionTypes.ChangeShippingAddressCountry)<string>()

export const addMessageBanner = createStandardAction(ShippingAddressActionTypes.AddMessageBannerRef)<RefObject<HTMLDivElement>>()
export const scrollToMessageBanner = createStandardAction(ShippingAddressActionTypes.ScrollToMessageBanner)()

export const attemptFormSubmission = createStandardAction(ShippingAddressActionTypes.AttemptEquipmentPreferenceSubmission)();

export const retainExistingHardware = createStandardAction(ShippingAddressActionTypes.RetainExistingHardware)();

export const selectITKit = createStandardAction(ShippingAddressActionTypes.SelectITKit)();

export const validateAndGetAddressSuggestions = createStandardAction(ShippingAddressActionTypes.ValidateAndGetAddressSuggestions)<ShippingAddress>();
export const overrideAddressValidation = createStandardAction(ShippingAddressActionTypes.OverrideAddressValidation)();
export const changeShippingAddressFromSuggestion = createStandardAction(ShippingAddressActionTypes.ChangeShippingAddressFromSuggestion)<ShippingAddress>();

export const selectAssistiveTech = createStandardAction(ShippingAddressActionTypes.SelectAssistiveTechnology)<AssistiveTechnology>()

export const unselectAssistiveTech = createStandardAction(ShippingAddressActionTypes.UnSelectAssistiveTechnology)<AssistiveTechnology>()

export const toggleAssistiveTech = createStandardAction(ShippingAddressActionTypes.OpenAssistiveTechnologyExpander)<boolean>()
