import {
  HostApp
} from '../types/hostApp'

export function apiUserLoginRule(hostApp: HostApp): boolean{
  switch(hostApp){
    case HostApp.Fallback:
      return true
    default:
      return false
  }
}
