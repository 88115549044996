import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { IdVerificationStateResponse, IdVerificationStateRequest, IdVerificationActionTypes } from './types'
import {
  getIdVerificationStatusSuccess,
  getIdVerificationStatusFailure,
} from './actions'

import { EXTERNAL_URL_MAP } from '../../routes/ExternalUrls'
import { get } from '../../utils/ajax'

const getIdVerificationStatus = async (): Promise<IdVerificationStateResponse> => {
  const res = await get<IdVerificationStateResponse>(EXTERNAL_URL_MAP.ID_VERIFICATION)
  return res.data
}


function* handleGetIdVerificationStatus(): Generator {
  try {
    const res: IdVerificationStateResponse = (yield call(getIdVerificationStatus)) as IdVerificationStateResponse
    yield put(getIdVerificationStatusSuccess(res))
  } catch (err) {
    yield put(getIdVerificationStatusFailure())
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchGetIdVerificationStatusRequest() {
  yield takeEvery(IdVerificationActionTypes.GetIdVerificationStatus, handleGetIdVerificationStatus)
}

// Export our root saga.
// We can also use `fork()` here to split our saga into multiple watchers.
export function* idVerificationSaga() {
  yield all([
    fork(watchGetIdVerificationStatusRequest),
  ])
}
