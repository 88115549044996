import { Action } from 'redux';

export enum ContentStringId {
  NOT_YET_SUBMITTED = 'notYetSubmitted',
  IN_PROGRESS = 'inProgress',
  UNDER_REVIEW = 'underReview',
  UNDER_REVIEW_PROVIDE_DOCUMENTS = 'underReviewProvideDocuments',
  UNDER_REVIEW_NEXT_STEPS = 'underReviewNextSteps',
  COMPLETE_NO_FURTHER_ACTION = 'completeNoFurtherAction',
  COMPLETE_NEXT_STEPS = 'completeNextSteps',
  COMPLETE_AMAZON_WILL_CONTACT = 'completeAmazonWillContact',
  UNDER_REVIEW_IF_ADDITIONAL_INFO = 'underReviewIfAdditionalInfo',
  UNDER_REVIEW_CHECK_EMAIL = 'underReviewCheckEmail',
  CANCELLED = 'cancelled',
}

type PillStatus = number;
export interface BgcUserData {
  contentStringId?: ContentStringId;
  result?: string;
  vendorName?: string;
  vendorEmail?: string;
  vendorUrl?: string;
  updatedDateTime?: string;
  status?: PillStatus;
}

export interface BgcMetadata {
  isLoadingInitialState: boolean;
}

export type BgcApplicationState = BgcUserData & BgcMetadata;

export interface BgcCallToAction {
  toShow: boolean;
  isEnabled: boolean;
}

//These are the statuses sent from Periscope
//These should always match with https://code.amazon.com/packages/EmbarkLaunchpadService/blobs/534dc3ebe33169025fa42918b0b4bdeebd56ae24/--/src/service/BackgroundCheckService.ts
export enum BackgroundCheckStatus {
  NEW = 'NEW',
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  NOT_REQUIRED = 'NOT_REQUIRED',
}

// These are the results sent from Periscope
// These should always match with https://code.amazon.com/packages/EmbarkLaunchpadService/blobs/534dc3ebe33169025fa42918b0b4bdeebd56ae24/--/src/service/BackgroundCheckService.ts
export enum BackgroundCheckResult {
  NONE = 'NONE',
  NEEDS_REVIEW = 'NEEDS_REVIEW',
  PASS_PENDING = 'PASS_PENDING',
  PRE_DNM = 'PRE_DNM',
  PRE_DD = 'PRE_DD',
  MEETS_REQUIREMENTS = 'MEETS_REQUIREMENTS',
  DOES_NOT_MEET_REQUIREMENTS = 'DOES_NOT_MEET_REQUIREMENTS',
  DELAYED_DECISION = 'DELAYED_DECISION',
  NOT_ADJUDICATED = 'NOT_ADJUDICATED',
  NOT_ADJUDICATED_NO_LETTERS = 'NOT_ADJUDICATED_NO_LETTERS',
  NOT_ADJUDICATED_PRE_ADVERSE = 'NOT_ADJUDICATED_PRE_ADVERSE',
  NEEDS_REVIEW_NO_NOTICE = 'NEEDS_REVIEW_NO_NOTICE',
}

export enum BgcActionType {
  InitialLoadRequest = 'BGC/INITIAL_LOAD_REQUEST',
  InitialLoadRequestSuccess = 'BGC/INITIAL_LOAD_REQUEST_SUCCESS',
  InitialLoadRequestFailure = 'BGC/INITIAL_LOAD_REQUEST_FAILURE',
}

export interface BgcInitialLoadRequestAction extends Action {
  type: BgcActionType.InitialLoadRequest;
}

export interface BgcInitialLoadRequestActionSuccess extends Action {
  type: BgcActionType.InitialLoadRequestSuccess;
  payload: BgcUserData;
}

export interface BgcInitialLoadRequestActionFailure extends Action {
  type: BgcActionType.InitialLoadRequestFailure;
}

export type BgcAction =
  | BgcInitialLoadRequestAction
  | BgcInitialLoadRequestActionSuccess
  | BgcInitialLoadRequestActionFailure;

export interface HeroBoxContent {
  bold: boolean | null;
}
