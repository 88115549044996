import { compareAsc } from 'date-fns';
import { get, partialRight } from 'lodash';
import { parseInput } from './humanizeDate';
import postUtil from '../metrics/postUtil';

// This partially applies compareAsc with the current Date
// compareAscFromNow :: Date -> {-1, 0 , 1}
// Returns 1 if the input date is in the future
// Returns -1 if the input date is in the past
// Returns 0 if the date is today
const compareAscFromNow = partialRight(compareAsc, new Date());

export const EquipmentStatus = {
  UNAVAILABLE: -1,
  DUE_NOW: 0,
  SUBMITTED: 1,
  OVERDUE: 2,
};

const shouldLock = (equipmentDetailsSubmitted) => (lockDate) =>
  equipmentDetailsSubmitted && compareAscFromNow(lockDate) === -1;

export const freezeEquipmentPreferences = (equipmentDetailsSubmitted) => (lockDate) => {
  const lockDateObj = parseInput(lockDate);
  return shouldLock(equipmentDetailsSubmitted)(lockDateObj);
};

// { Due now, Complete, Overdue }
// Form is not locked for due now and overdue
// if equipmentDetailsSubmitted is true, return submitted.
// if equipmentDetailsSubmitted is false and lockDate is in the past, then show overdue
// if equipmentDetailsSubmitted is false and lockDate is in present or future, show due now
export const equipmentPreferenceStatusCode = (equipmentDetailsSubmitted) => (lockDate) => {
  if (lockDate === '') return EquipmentStatus.UNAVAILABLE;

  if (equipmentDetailsSubmitted) {
    return EquipmentStatus.SUBMITTED;
  } else if (lockDate && compareAscFromNow(parseInput(lockDate)) === -1) {
    return EquipmentStatus.OVERDUE;
  } else {
    return EquipmentStatus.DUE_NOW;
  }
};

// Lookup for country name, given a country locale
// Returns undefined if nothing is found
// countryCodeForName :: [{ name, schema }] -> string -> string
export const countryNameForLocale = (countryList) => (locale) =>
  get(
    countryList.find((country) => country.schema === locale),
    'name'
  );

// Lookup for country locale, given a country name
// Returns undefined if nothing is found
// countryCodeForName :: [{ name, schema }] -> string -> string
export const countryCodeForName = (countryList) => (countryValue) =>
  get(
    countryList.find((country) => country.value === countryValue),
    'schema'
  );

export const countryIsoCodeForValue = (countryList) => (countryValue) =>
  get(
    countryList.find((country) => country.value === countryValue),
    'isoCode'
  );

// Lookup for validateAddress, given a country name
// Returns undefined if nothing is found
// validateAddressForName :: [{ name, schema }] -> string -> boolean
export const validateAddressForName = (countryList) => (countryValue) =>
  get(
    countryList.find((country) => country.value === countryValue),
    'validateAddress',
    false
  );

// Update `shippingAddressApplicationState.isDirty` flag
// And make appropriate metrics api calls to record this.
export const setIsDirty = (previousIsDirty, isDirty) => {
  if (!previousIsDirty && isDirty) {
    postUtil('equipmentPreference.edit.start')({
      timeStamp: new Date().toISOString(),
    });
  }
  return isDirty;
};

// Make metric calls for clicking on Submit/edit button
export const trackSubmission = (isFirstSubmission) => () => {
  const type = isFirstSubmission ? 'equipmentPreference.save.submit' : 'equipmentPreference.save.edit';
  const timeStamp = new Date().toISOString();
  postUtil(type)({
    timeStamp,
  });
};

// Given a set of availableKeyboardLayouts, find the default layout
export const defaultKeyboardLayout = (availableKeyboardLayouts) => availableKeyboardLayouts[0] || '';
