import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import { RumConfigSettings } from './types';

let awsRumClient: AwsRum;

const getAwsRumClient = () => awsRumClient || null;

const APPLICATION_VERSION = '1.0.0';
const APPLICATION_REGION = 'us-west-2';

const getConfig = (LaunchpadRUM: RumConfigSettings): AwsRumConfig => {
  const config: AwsRumConfig = {
    allowCookies: true,
    endpoint: new URL(`https://dataplane.rum.${APPLICATION_REGION}.amazonaws.com`).toString(),
    guestRoleArn: LaunchpadRUM?.RUMConfig?.guestRoleArn,
    identityPoolId: LaunchpadRUM?.RUMConfig?.identityPoolId,
    sessionSampleRate: 1,
    enableXRay: LaunchpadRUM?.RUMConfig?.enableXRay,
    telemetries: [
      [
        'errors',
        {
          stackTraceLength: 5000,
        },
      ],
      'performance',
      'http',
    ],
  };

  return config;
};

const createRUMClient = (LaunchpadRUM: RumConfigSettings) => {
  try {
    const APPLICATION_ID: string = LaunchpadRUM?.RUMAppMonitorId || '';
    const config = getConfig(LaunchpadRUM);

    awsRumClient = new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config);

    return awsRumClient; // added this to help with unit testing
  } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
  }
};

const initializeRUM = () => {
  const LaunchpadRUM = {
    RUMAppMonitorId: process.env.REACT_APP_RUM_APP_MONITOR_ID,
    RUMConfig: {
      guestRoleArn: process.env.REACT_APP_RUM_GUEST_ROLE_ARN,
      identityPoolId: process.env.REACT_APP_RUM_IDENTITY_POOL,
      enableXRay: process.env.REACT_APP_RUM_ENABLE_XRAY === 'true',
    }
  }

  if (LaunchpadRUM?.RUMAppMonitorId) {
    createRUMClient(LaunchpadRUM);
  }

  return awsRumClient;
};

export { getConfig, createRUMClient, initializeRUM, getAwsRumClient };
