import { SubFooterEvent, SubFooterState, SubFooterAction } from './types'

const initialSubFooterState: SubFooterState = {
  loading: false,  
  subFooterItems: []
}


const taskReducer = (state: SubFooterState  = initialSubFooterState, action: SubFooterAction): SubFooterState => {
  switch(action.type){    
    case SubFooterEvent.LoadSubFooterItems: {
      return {
        ...state,
        loading: true
      }
    }
    case SubFooterEvent.LoadSubFooterItemsSuccess: {
      const { payload } = action;
      return {
        ...state,
        loading: false,
        subFooterItems: payload
      }
    }
    case SubFooterEvent.LoadSubFooterItemsFailure: {
      return {
        ...state,
        loading: false
      }
    }
    default:
      return state
  }
};

export default taskReducer;
