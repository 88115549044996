import { Action } from 'redux';
import { FetchUserFulfilledAction, UserProfileApiResponse } from '../user/types'
// import { Language } from '../user/types'

// purely a lookuptable in case we haven't loaded info from content service yet
export const potentialLanguages: {[key: string]:Language} = {
  'en_US': {
      tag: 'en_US',
      name: 'EN - English',
      shortName: 'En',
      mobileName: 'English',
  },
  'cs_CZ': {
      tag: 'cs_CZ',
      name: 'CS - Čeština',
      shortName: 'Cs',
      mobileName: 'Čeština',
  },
  'de_DE': {
      tag: 'de_DE',
      name: 'DE - Deutsch',
      shortName: 'De',
      mobileName: 'Deutsch',
  },
  'es_ES': {
      tag: 'es_ES',
      name: 'ES - Español',
      shortName: 'Es',
      mobileName: 'Español',
  },
  'fr_FR': {
      tag: 'fr_FR',
      name: 'FR - Français',
      shortName: 'Fr',
      mobileName: 'Français',
  },
  // Just in case we find this language in the launchpadusers table
  'fr_CA': {
    tag: 'fr_FR',
    name: 'FR - Français',
    shortName: 'Fr',
    mobileName: 'Français',
  },
  'id_ID': {
      tag: 'id_ID',
      name: 'ID - Bahasa Indonesia',
      shortName: 'Id',
      mobileName: 'Bahasa Indonesia',
  },
  'it_IT': {
      tag: 'it_IT',
      name: 'IT - Italiano',
      shortName: 'It',
      mobileName: 'Italiano',
  },
  'ja_JP': {
      tag: 'ja_JP',
      name: 'JA - 日本語',
      shortName: 'Ja',
      mobileName: '日本語',
  },
  'pl_PL': {
      tag: 'pl_PL',
      name: 'PL - Polski',
      shortName: 'Pl',
      mobileName: 'Polski',
  },
  'pt_BR': {
      tag: 'pt_BR',
      name: 'PT - Português',
      shortName: 'Pt',
      mobileName: 'Português',
  },
  'ro_RO': {
      tag: 'ro_RO',
      name: 'RO - Română',
      shortName: 'Ro',
      mobileName: 'Română',
  },
  'sk_SK': {
      tag: 'sk_SK',
      name: 'SK - Slovenčina',
      shortName: 'Sk',
      mobileName: 'Slovenčina',
  },
  'tr_TR': {
      tag: 'tr_TR',
      name: 'TR - Türkçe',
      shortName: 'Tr',
      mobileName: 'Türkçe',
  },
  'zh_CN': {
      tag: 'zh_CN',
      name: 'ZH - 中文 (简体)',
      shortName: 'Zh',
      mobileName: '中文 (简体)',
  },
}

export interface Language {
  tag: string
  name: string
  shortName: string
  mobileName: string
}
export const DEFAULT_LANG:Language = {
  tag: "en_US",
  name: "EN - English",
  shortName: "En",
  mobileName: "English",
}

export enum LangActionTypes {
  FetchLang = 'LANG_REQUEST',
  FetchLangFulfilled = 'LANG_RESPONSE_COMPLETED',
  FetchLangFailed = 'LANG_REQUEST_FAILED'
}

export interface LanguagesPayload {
  languages: Language[]
}

export interface LanguagesState extends LanguagesPayload {
  loading: boolean
  languageOfPreference: Language
  setting: boolean
}

export enum LanguagesEvent {
  LoadItems = 'LANG_LOAD_ITEMS',
  LoadItemsSuccess = 'LANG_LOAD_ITEMS_SUCCESS',
  LoadItemsFailure = 'LANG_LOAD_ITEMS_FAILURE',
  SetUserLanguage = 'SET_USER_LANGUAGE',
  SetUserLanguageSuccess = 'SET_USER_LANGUAGE_SUCCESS',
  SetUserLanguageFailure = 'SET_USER_LANGUAGE_FAILURE',
  UpdatedWorkAuthCopySuccess = 'UPDATED_WORK_AUTH_COPY_SUCCESS',
	UpdatedWorkAuthCopyFailure = 'UPDATED_WORK_AUTH_COPY_FAILURE'
}

export interface SetUserLanguageAction extends Action {
  type: LanguagesEvent.SetUserLanguage
  payload: Language
}

export interface SetUserLanguageSuccessAction extends Action {
  type: LanguagesEvent.SetUserLanguageSuccess
  result: UserProfileApiResponse
}

interface SetUserLanguageFailureAction extends Action {
  type: LanguagesEvent.SetUserLanguageFailure
}
interface LoadItemsAction extends Action {
  type: LanguagesEvent.LoadItems
}

interface LoadItemsSuccessAction extends Action {
  type: LanguagesEvent.LoadItemsSuccess
  payload: LanguagesPayload
}

interface LoadItemsFailureAction extends Action {
  type: LanguagesEvent.LoadItemsFailure
}

interface UpdatedWorkAuthCopySuccess extends Action {
  type: LanguagesEvent.UpdatedWorkAuthCopySuccess
}
interface UpdatedWorkAuthCopyFailure extends Action {
  type: LanguagesEvent.UpdatedWorkAuthCopyFailure
}

export type ItemAction = LoadItemsAction
  | LoadItemsSuccessAction
  | LoadItemsFailureAction
  | SetUserLanguageAction
  | SetUserLanguageSuccessAction
  | SetUserLanguageFailureAction
  | FetchUserFulfilledAction
  | UpdatedWorkAuthCopySuccess
	| UpdatedWorkAuthCopyFailure
