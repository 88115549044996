export function getHostLanguage(elem: HTMLElement | null): string {
  if(elem === null) return ''

  const locale = elem.dataset.locale

  const localeTester = /^[a-z]{2}\-[A-Z]{2}$/

  if(locale && localeTester.test(locale)){
    return locale.replace(/\-/, '_')
  }

  return ''
}
