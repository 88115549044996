// Panther is working on abstracting away the need to import this file
// See: https://w.amazon.com/bin/view/I18N-Eng/Products/AmazonResourceBundle/JS_UserGuide/
import arbManifest from '../../public/translations/arbManifest';
import { LocalizationContextBuilder } from '@amzn/arb-tools';

export const mbmOptions = {
  arbManifest,
  defaultLocalizationContext: new LocalizationContextBuilder().withLocale('en-US').build(),
  urlPrefix: '/translations',
  resolveBundleUrl: (filename?: string) => (filename ? `${process.env.PUBLIC_URL}/translations/${filename}` : undefined),
};
