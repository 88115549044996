import { all, fork } from 'redux-saga/effects'
import { userSaga } from './user/sagas'
import { copySaga } from './copy/sagas';
import { faqSaga } from './faq/sagas';
import { pendingBadgePhotoSaga } from './pending_badge_photo/sagas';
import { badgePhotoReviewStatusSaga } from './badge_status/sagas';
import { badgePhotoUploadCheckSaga } from './badge_upload_check/sagas';
import { shippingAddressSaga } from './shipping_address/sagas';
import { loginSelectionSaga } from './login_selection/sagas';
import { dashboardSaga } from './dashboard/sagas';
import { subFooterSaga } from './sub_footer/sagas';
import { navSaga } from './nav/sagas';
import { langSaga } from './languages/sagas';
import { envSaga } from './environment/sagas';
import { heartBeatSaga } from './heartbeat/sagas';
import { workAuthSaga } from './work_auth/sagas';
import { myDocsSaga } from './mydocs/sagas';
import { pageScrollSaga } from './page_scroll/sagas';
import { bgcSaga } from './Bgc/sagas';
import { idVerificationSaga } from './id_verification/sagas';

export function* rootSaga() {
  yield all([
    fork(userSaga),
    fork(copySaga),
    fork(faqSaga),
    fork(pendingBadgePhotoSaga),
    fork(badgePhotoReviewStatusSaga),
    fork(badgePhotoUploadCheckSaga),
    fork(shippingAddressSaga),
    fork(loginSelectionSaga),
    fork(workAuthSaga),
    fork(dashboardSaga),
    fork(subFooterSaga),
    fork(navSaga),
    fork(langSaga),
    fork(envSaga),
    fork(heartBeatSaga),
    fork(myDocsSaga),
    fork(pageScrollSaga),
    fork(bgcSaga),
    fork(idVerificationSaga)
  ])
}
