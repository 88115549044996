import { Action } from 'redux'

export enum UserActionTypes {
    FetchUser = 'FETCH_USER',
    FetchUserFulfilled = 'FETCH_USER_FULFILLED',
    FetchUserFailed = 'FETCH_USER_FAILED',
}

export interface UserProfileApiResponse {
    isInternalTransfer: boolean
    isMovingFromCountryToCountry: boolean
    isC2CInternalTransfer: boolean
    isCandidateInStudentPrograms: boolean,
    givenName?: string
    joiningDate?: string
    recruiterEmail?: string
    recruiterName?: string
    hiringManagerEmail?: string
    hiringManagerName?: string
    icimsId?: string
    fullName?: string
    languageOfPreference?: string
    workAuthContentGroup?: string
}

export type UserActions = FetchUserAction | FetchUserFulfilledAction | FetchUserFailedAction

export interface FetchUserAction extends Action {
    type: UserActionTypes.FetchUser
}

export interface FetchUserFulfilledAction extends Action {
    type: UserActionTypes.FetchUserFulfilled
    payload: UserProfileApiResponse
}

export interface FetchUserFailedAction extends Action {
    type: UserActionTypes.FetchUserFailed
}

export interface UserState {
    isInternalUser: boolean
    isCountryToCountryTransfer: boolean
    isAuthenticated: boolean
    authenticationFailed: boolean
    isLoading: boolean
    authenticationCheckComplete: boolean
    profile?: UserProfileApiResponse
}
