import { combineReducers } from 'redux';
import { ApplicationState } from './types';
import { usersReducer } from './user/reducer';
import { videoReducer } from './video/reducer';
import { pageScrollReducer } from './page_scroll/reducer';
import { copyReducer } from './copy/reducer';
import navReducer from './nav/reducer';
import langReducer from './languages/reducer';
import { faqReducer } from './faq/reducer';
import impressionReducer from './metrics/impressions/reducer';
import videoMetricsReducer from './metrics/video/reducer';
import { pendingBadgePhotoReducer } from './pending_badge_photo/reducer';
import { badgePhotoReviewStatusReducer } from './badge_status/reducer';
import { badgePhotoUploadCheckReducer } from './badge_upload_check/reducer';
import { shippingAddressReducer } from './shipping_address/reducer';
import { loginSuggestionsReducer } from './login_selection/reducer';
import { dashboardReducer } from './dashboard/reducer';
import { dashboardNavigationReducer } from './dashboardNavigationReducer';
import envReducer from './environment/reducer';
import { workAuthReducer } from './work_auth/reducer';
import { myDocsReducer } from './mydocs/reducer';
import { bgcReducer } from './Bgc/reducer';
import { idVerificationReducer } from './id_verification/reducer'
import { subFooterReducer } from './sub_footer/reducer';

export const rootReducer = combineReducers<ApplicationState>({
  user: usersReducer,
  video: videoReducer,
  pageScroll: pageScrollReducer,
  copy: copyReducer,
  faqs: faqReducer,
  impressions: impressionReducer,
  videoMetrics: videoMetricsReducer,
  pendingBadgePhoto: pendingBadgePhotoReducer,
  badgePhotoReviewStatus: badgePhotoReviewStatusReducer,
  badgePhotoUploadCheck: badgePhotoUploadCheckReducer,
  shippingAddressApplicationState: shippingAddressReducer,
  loginSelections: loginSuggestionsReducer,
  workAuth: workAuthReducer,
  dashboard: dashboardReducer,
  subFooter: subFooterReducer,
  cameFromDashboard: dashboardNavigationReducer,
  navigation: navReducer,
  environment: envReducer,
  languages: langReducer,
  myDocs: myDocsReducer,
  bgc: bgcReducer,
  idVerification: idVerificationReducer,
});
