import {
  put,
  select
} from 'redux-saga/effects';

import {
  get,
  head
} from 'lodash'

import {
  saveWorkAuthCopyInjections
} from '../work_auth/actions'

/*
 * Responsiblity - The worker saga for managing copy specific to workAuth.
 *
 * This is triggered after we are finished with generic copy management
 * (i.e. querying the copy api, saving it's response to the store, and
 * managing errors if the request is unsuccessful)
 * 
 * This might need to be revisited if the copy api workflow is changed in
 * the frontend.
*/
export function* manageWorkAuthCopy(copy){
  const userProfile = yield select(getUserProfile)
  if(!userProfile) return  /* WARNING - REMOVING THIS WILL TRIGGER A LOOPBACK ON THE FRONTEND - No userProfile in landing page !! */

  const {
    languageOfPreference = 'en_US',
    workAuthContentGroup
  } = userProfile
  const workAuthCopy = getWorkAuthCopy(copy)
  const workAuthInjections = dynamicWorkAuthInjections({
    ...workAuthCopy,
    languageOfPreference,
    workAuthContentGroup,
  })

  if(workAuthInjections?.length > 0){
    yield put(saveWorkAuthCopyInjections(workAuthInjections))
  }
}

export function* manageWorkAuthCopyAfterPreferredLanguageChange(copy, userProfile){
  if(!userProfile) return  /* WARNING - REMOVING THIS WILL TRIGGER A LOOPBACK ON THE FRONTEND - No userProfile in landing page !! */

  const {
    languageOfPreference = 'en_US',
    workAuthContentGroup
  } = userProfile
  const workAuthCopy = getWorkAuthCopy(copy)
  const workAuthInjections = dynamicWorkAuthInjections({
    ...workAuthCopy,
    languageOfPreference,
    workAuthContentGroup,
  })

  if(workAuthInjections?.length > 0){
    yield put(saveWorkAuthCopyInjections(workAuthInjections))
  }
}

const getCopy = state => state.copy

/* Responsiblity - The worker saga for updating copy specific to workAuth.
 *
 * This is called from the language sagas after user makes a language selection change 
 * and allows for injected content to be updated when a new language is selected
 * 
 * There is a delay between the other content in the page updating and the injected 
 * content updating in the new language - not sure how this might be remedied
 */
export function* updateWorkAuthCopyAfterPreferredLanguageChange(userProfile) {
  if(!userProfile) return  /* WARNING - REMOVING THIS WILL TRIGGER A LOOPBACK ON THE FRONTEND - No userProfile in landing page !! */
  
  const {
    languageOfPreference = 'en_US',
    workAuthContentGroup = 'USA-corp'
  } = userProfile

  const state = yield select()
  const copy = yield getCopy(state)
  const workAuthCopy = getWorkAuthCopy(copy)
  const workAuthInjections = dynamicWorkAuthInjections({
    ...workAuthCopy,
    languageOfPreference,
    workAuthContentGroup,
  })

  if(workAuthInjections?.length > 0){
    yield put(saveWorkAuthCopyInjections(workAuthInjections))
  }
}

/*
 * Responsibility - Given a collection of injections, stringResources,
 * configuration specifying dynamic injections in the default layout,
 * a language of preference for the user, and the user's
 * workAuthContentGroup, return a collection of fully specified injections.
 *
 * The result will include both the fully specified static injections (as
 * found in the content package) and fully specified dynamic injections
 * (which cannot be fully specified for a given instance of user pre-runtime
 * because it depends on the user's workAuthContentGroup as well as her
 * languageOfPreference).
 *
 * Once we have this, we have everything to generate a dynamized, localized
 * UI which can specify various types of components (strings, links and
 * email links at the time of writing this documentation). The next step
 * is to persist it in the application store, from where the UI picks it up
 * and generates the dynamic content via content injection: This is
 * implemented in contentInjection.js file within the workAuth component (at
 * the time of writing this documentation).
*/
export const dynamicWorkAuthInjections = ({
    injections = [],
    stringResources = {},
    defaultLayoutConfig = {},
    languageOfPreference,
    workAuthContentGroup,
}) => {
  const dynamicInjections = defaultLayoutConfig[workAuthContentGroup]
  if(!dynamicInjections) return injections
  
  const defaultLayoutCopy = getDefaultLayoutCopy({
    languageOfPreference,
    stringResources
  })

  return injections.map(dynamizeInjection({
    defaultLayoutCopy,
    dynamicInjections,
    workAuthContentGroup
  }))
}

/*
 * Responsibility - This is a mapper for a collection of injections (directly
 *  from the config file).
 *
 * Given an injection, a workAuthContentGroup, map for defaultLayout
 * copy, and a map for dynamic injections, this returns an injection object
 * complete with everthing needed to render a react component in the UI.
 *
 * When there is no dynamic injection specified for a given injection
 * object, this simply returns it transparently.
 *
 * Since it's only the injection object which changes, the rest of the
 * parameters are curried.
*/
const dynamizeInjection = ({
    defaultLayoutCopy = {},
    dynamicInjections = {},
    workAuthContentGroup
}) => injectionObj => {
  const injectionKey = head(Object.keys(injectionObj))

  const dynamicInjectionValue = dynamicInjections[injectionKey]
  if(!dynamicInjectionValue) return injectionObj

  const injection = injectionObj[injectionKey]
  const text = getDefaultLayoutCopyForWorkAuthContentGroup({
    defaultLayoutCopy,
    workAuthContentGroup
  })[injectionKey]

  return buildDynamicInjection({
    dynamicInjectionValue,
    injection,
    injectionKey,
    text
  })
}

/*
 * Responsibility - Given a copy object, extract out the section specific
 * to work auth.
*/
const getWorkAuthCopy = copy =>
  copy.items?.find(item => item.type.toLowerCase() === "workauth")

/*
 * Responsibility - Given the application state, extract the userProfile
 * object for that user
*/
const getUserProfile = state =>
  state.user?.profile

/*
 * Responsibility - Given a languageOfPreference and stringResources for
 * workauth, extract the defaultLayout copy object out of it.
 *
 * The final object will be a collection of all possible
 * workAuthContentGroup, which are localized to a languageOfPreference
*/
const getDefaultLayoutCopy = ({
  languageOfPreference,
  stringResources
}) => get(
  stringResources,
  [languageOfPreference,'defaultLayout'],
  {}
)

/*
 * Responsibility - Given everything we need to specify a dynamic injection
 * (i.e. the static part, the dyanmic part which depends on
 * `workAuthContentGroup`, and also the copy part which depends on both
 * `workAuthContentGroup` as well as `languageOfPreference`, which is the
 * localized part), build an injection object correponding to it.
*/
const buildDynamicInjection =({
  dynamicInjectionValue,
  injection,
  injectionKey,
  text
}) => ({
  [injectionKey]: Object.assign(
    {},
    injection, // The static injection part - found in injections
    dynamicInjectionValue, // The dynamic part - found in defaultLayoutConfig
    { text } // The copy part - This is localized within stringResources
  )
})

/*
 * Responsibility - Get the copy object for the default layout, and extract
 * the bit relevant to the user's workAuthContentGroup. If nothing is found,
 * return an empty object as fallback.
*/
export const getDefaultLayoutCopyForWorkAuthContentGroup  = ({
  defaultLayoutCopy,
  workAuthContentGroup
}) => get(
  defaultLayoutCopy,
  workAuthContentGroup,
  {}
)
