import postUtil from './postUtil';



const type = 'view.page';

const loadTrackingDenialPathList = [
  '/landing'
]

export function denyPageLoadTracking(path: string){
  return (loadTrackingDenialPathList.indexOf(path) !== -1)
}

function usePageLoadTracking(pageName: string, prefixSegment?: string){
  if(denyPageLoadTracking(pageName)) return // escape hatch

  const timestamp = (new Date()).toISOString();
  const context = { pageName, timestamp };
  postUtil(type, prefixSegment)(context);
}

export default usePageLoadTracking;
