import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { EnvEvent } from './types';
import { fetchAllEnvs } from '../../utils/releaseToggle';

function* manageEnvReq() {
  const payload = yield call(fetchAllEnvs);
  yield put({ type: EnvEvent.LoadEnvSuccess, payload });
}

function* watchEnvRequest() {
  yield takeLatest(EnvEvent.LoadEnv, manageEnvReq);
}

export function* envSaga() {
  yield all([fork(watchEnvRequest)]);
}
