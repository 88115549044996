export const isPostDayOne = (user) => {
  // Adding one day post day one
  // Business logic - One day after dayone, users are expected to transition to embark
  // Therefore to check, we create the joining date, increment the date and then compare
  // the current time (using new Date()) with it to decide whether 'now' is post day one.
  const { profile : { joiningDate } } = user
  const dayTwo = new Date(joiningDate);
  dayTwo.setDate(dayTwo.getDate() + 1);
  const postDayOne = dayTwo <= new Date();
  return postDayOne
}



