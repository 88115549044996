import { Action } from 'redux';
import {
  InitialStateSuccessResponse,
  LoginSuggestionsSuccessResponse,
  LoginAvailabilitySuccessResponse,
  DeleteUserLoginSuccessResponse,
  ReserveUserLoginSuccessResponse,
  PimaLoginSuccessResponse
} from './types';

export enum LoginSelectionActionTypes {
  GetInitialState = 'GET_INITIAL_STATE',
  GetInitialStateSuccess = 'GET_INITIAL_STATE_SUCCESS',
  GetInitialStateError = 'GET_INITIAL_STATE_FAIL',
  GetLoginSuggestions = 'GET_LOGIN_SUGGESTIONS',
  LoginSuggestionsSuccess = 'GET_LOGIN_SELECTIONS_SUCCESS',
  LoginSuggestionsError = 'GET_LOGIN_SELECTIONS_ERROR',
  GetLoginAvailability = 'GET_LOGIN_AVAILABILITY',
  LoginAvailabilitySuccess = 'GET_LOGIN_AVAILABILITY_SUCCESS',
  LoginAvailabilityError = 'GET_LOGIN_AVAILABILITY_ERROR',
  GetUserReservedLogin = 'GET_USER_RESERVED_LOGIN',
  GetUserReservedLoginSuccess = 'GET_USER_RESERVED_LOGIN_SUCCESS',
  GetUserReservedLoginError = 'GET_USER_RESERVED_LOGIN_ERROR',
  ReserveUserLogin = 'RESERVE_USER_LOGIN',
  ReserveUserLoginSuccess = 'RESERVE_USER_LOGIN_SUCCESS',
  ReserveUserLoginError = 'RESERVE_USER_LOGIN_ERROR',
  DeleteUserLogin = 'DELETE_USER_LOGIN',
  DeleteUserLoginSuccess = 'DELETE_USER_LOGIN_SUCCESS',
  DeleteUserLoginError = 'DELETE_USER_LOGIN_ERROR',
  GetPimaUserLogin = 'GET_PIMA_USER_LOGIN',
  GetPimaUserLoginSuccess = 'GET_PIMA_USER_LOGIN_SUCCESS',
  SelectLoginOption = 'LOGIN_OPTION_SELECTED',
  ChangedSelfSelect = 'CHANGED_SELF_SELECT',
}

/* Initial state actions */
export interface GetInitialState extends Action {
  type: LoginSelectionActionTypes.GetInitialState,
}
export interface GetInitialStateSuccess extends Action {
  type: LoginSelectionActionTypes.GetInitialStateSuccess,
  payload: InitialStateSuccessResponse
}

export interface GetInitialStateError extends Action {
  type: LoginSelectionActionTypes.GetInitialStateError
}
/** Initial state actions ends here */

/* Login suggestions actions  */
export interface LoginSuggestionsAction extends Action {
  type: LoginSelectionActionTypes.GetLoginSuggestions
}

export interface LoginSuggestionsSuccess extends Action {
  type: LoginSelectionActionTypes.LoginSuggestionsSuccess
  payload: LoginSuggestionsSuccessResponse
}

export interface LoginSuggestionsError extends Action {
  type: LoginSelectionActionTypes.LoginSuggestionsError
}

export interface SelectLoginOption extends Action {
  type: LoginSelectionActionTypes.SelectLoginOption,
  payload: string
}

export interface ChangedSelfSelect extends Action {
  type: LoginSelectionActionTypes.ChangedSelfSelect,
  payload: boolean
}
/* Login suggestions actions ends here  */

/* Login availability actions */
export interface LoginAvailabilityAction extends Action {
  type: LoginSelectionActionTypes.GetLoginAvailability,
  payload: {
    login: string
  }
}

export interface LoginAvailabilitySuccess extends Action {
  type: LoginSelectionActionTypes.LoginAvailabilitySuccess
  payload: LoginAvailabilitySuccessResponse
}

export interface LoginAvailabilityError extends Action {
  type: LoginSelectionActionTypes.LoginAvailabilityError
}
/* Login availability actions ends here  */

/*  Reserve user login actions */
export interface ReserveUserLoginAction extends Action {
  type: LoginSelectionActionTypes.ReserveUserLogin,
  payload: {
    login: string
  }
}

export interface ReserveUserLoginSuccess extends Action {
  type: LoginSelectionActionTypes.ReserveUserLoginSuccess,
  payload: ReserveUserLoginSuccessResponse
}

export interface ReserveUserLoginError extends Action {
  type: LoginSelectionActionTypes.ReserveUserLoginError,
  payload: {
    errorMessage: string
  }
}
/* Reserve user login actions  ends here */

/* Delete user login actions */
export interface DeleteUserLoginAction extends Action {
  type: LoginSelectionActionTypes.DeleteUserLogin
}

export interface DeleteUserLoginSuccess extends Action {
  type: LoginSelectionActionTypes.DeleteUserLoginSuccess
  payload: DeleteUserLoginSuccessResponse
}

export interface DeleteUserLoginError extends Action {
  type: LoginSelectionActionTypes.DeleteUserLoginError
}
/* Delete user login actions ends here */

/* Get user pima login */
export interface GetPimaUserLogin extends Action {
  type: LoginSelectionActionTypes.GetPimaUserLogin
}

export interface GetPimaUserLoginSuccess extends Action {
  type: LoginSelectionActionTypes.GetPimaUserLoginSuccess
  payload: PimaLoginSuccessResponse
}
/* Delete user login actions ends here */

export type LoginSelectionActions = 
| GetInitialState
| GetInitialStateSuccess
| GetInitialStateError
| LoginSuggestionsAction
| LoginSuggestionsSuccess
| LoginSuggestionsError
| SelectLoginOption
| ChangedSelfSelect
| LoginAvailabilityAction
| LoginAvailabilitySuccess
| LoginAvailabilityError
| ReserveUserLoginAction
| ReserveUserLoginSuccess
| ReserveUserLoginError
| DeleteUserLoginAction
| DeleteUserLoginSuccess
| DeleteUserLoginError
| GetPimaUserLogin
| GetPimaUserLoginSuccess;