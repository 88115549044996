/*
 * Named color codes for Stencil and Non-stencil colors used within the application.
 * Project convention is to use whatever is in here.
 * New colors can be added, if there's a design justification for it.
 */
export const ColorPalette = {
  Primary: '#1768c9',
  PrimaryLight: '#008296',
  PrimaryDark: '#006170',
  PrimaryTint: '#EEF5f6',
  SecondaryOne: '#C34789',
  SecondaryTwo: '#4862C8',
  AccentOne: '#232f3e',
  AccentTwo: '#5c7274',
  AccentThree: '#799294',
  AccentFour: '#bdd3d5',
  AccentFive: '#d4e4e6',
  AccentSix: '#eef5f6',
  Positive: '#0b8753',
  PositiveLight: '#3b9f75',
  PositiveDark: '#086c42',
  PositiveTint: '#e6f3ed',
  Negative: '#cd3e3e',
  NegativeLight: '#d35555',
  NegativeDark: '#a43131',
  NegativeTint: '#fbefef',
  Warning: '#CA8700',
  WarningLight: '#d69433',
  WarningDark: '#9e6209',
  WarningTint: '#fdf4e6',

  AccentSeven: `#F8A49E`, // Pink
  AccentEight: `#26303D`, // Dark blue

  Orange: '#ff9900',

  White: '#ffffff',
  Black: '#000000',

  AccentOne65: '#6f7781',
  AccentSix90: '#d5dcdc',
  AccentSix95: '#e1e8e9',
  White95: '#f2f2f2',
  White90: '#e5e5e5',

  // Non-stencil colors follow
  InfoPopoverUnderline: '#00b628',

  // --- grays ---
  Gray100: '#000000',
  Gray90: '#000000e6',
  Gray80: '#000000cc',
  Gray70: '#000000b3',
  Gray60: '#00000099',
  Gray50: '#00000080',
  Gray40: '#00000066',
  Gray30: '#0000004d',
  Gray20: '#00000033',
  Gray10: '#0000001a',

  // badging
  GuidelinesShouldBG: '#F2FBF8',
  GuidelinesShouldNotBG: '#FFF5F5',

  // pills
  PillYellow: '#F7B500',
  PillGreen: '#008296',
  PillRed: '#DA3733',
  PillBlue: '#0066CC',
  PillDefault: '#ECF4FB',

  // Shipping Address Important Info
  AccentSpray: '#82D8E3',
  AccentNordic: '#002F36',
  AccentNavy: '#0055A9',
  AccentGray: '#FAFAF8',

  FooterBG: '#333E48',
  FooterBGDark: '#17202B',
  FooterBorder: '#00C9FF',
  HRBlue: '#0066CC',
  DashBoardHeroGray: '#F1F4F6',
  ToolTipDate: '#1768C9',
  TaskLinkBlue: '#1768C9',
  TaskCardBorder: '#B9C0C8',
  CultureAction: '#1768C9',

  Neutral20: '#D1D5DA',
};

export const DefaultPillText = {
  due: 'Due Now',
  overdue: 'Overdue',
  complete: 'Complete',
  inReview: 'In Progress'
}

/*
 * Typography sizes as per stencil spec - See Stencil > Fundamentals > General Style > Typography
 * Useful for font sizes and lineHeights
 */
export const StencilTypography = {
  xxl: {
    fontSize: 4.75,
    lineHeight: 1.1316
  },
  xl: {
    fontSize: 3,
    lineHeight: 1.2917
  },
  l: {
    fontSize: 1.875,
    lineHeight: 1.3333
  },
  m: {
    fontSize: 1.5,
    lineHeight: 1.5,
  },
  s: {
    fontSize: 1.25,
    lineHeight: 1.5
  },
  xs: {
    fontSize: 1.125,
    lineHeight: 1.5
  },
  xxs: {
    fontSize: 1,
    lineHeight: 1.5
  },
  tiny: {
    fontSize: 0.875,
    lineHeight: 1.5
  },
  t100: {
    fontSize: 0.75,
    lineHeight: 1.66667
  }
}

export enum TextSizes {
  xxl = 'xxl',
  xl = 'xl',
  l = 'l',
  m = 'm',
  s = 's',
  xs = 'xs',
  xxs = 'xxs',
  tiny = 'tiny',
  t100 = 't100'
}

/*
 * All app wide configuration parameters here related to the view
 */
export const ViewConfiguration = {
  AppMaxWidth: '1440px', // stencil constraint - see Stencil > Fundamentals > General Style > Screen sizes,
  AboveDesktop: `1281px`,
  Desktop: `1280px`,
  AboveTablet: `971px`,
  Tablet: `970px`,
  Mobile: `600px`,
  HorizontalMargin: `110px`,
  VerticalMargin: `30px`,
  LeftNavLargeBP: `812px`,
  AboveDesktopUx3: `1081px`,
  DesktopUx3: `1080px`,
  AboveDesktopUx3Value: 1081,
  DesktopUx3Value: 1080,
};
