import { Action } from 'redux'

export enum PageScrollActionTypes {
  SetProps = 'PAGE_SCROLL_SET_PROPS',
  ScrollToCard = 'PAGE_SCROLL_TO_CARD',
  Lock = 'PAGE_SCROLL_LOCK',
  Unlock = 'PAGE_SCROLL_UNLOCK',
  ScrollToHash = 'SCROLL_TO_HASH'
}

export type PageScrollActions =
 SetPropsPageScrollAction | PageScrollToCardAction | PageScrollLockAction | PageScrollUnllockAction | PageScrollToHashAction

export interface SetPropsPageScroll {
  scrollTop?: number
  scrollToCard?: number
  visibleCard?: number
  visibleCardSlug?: string
}

export interface SetPropsPageScrollAction extends Action {
  type: PageScrollActionTypes.SetProps
  payload: SetPropsPageScroll
}

export interface PageScrollToCardAction extends Action {
  type: PageScrollActionTypes.ScrollToCard
  payload?: number
}

export interface PageScrollLockAction extends Action {
  type: PageScrollActionTypes.Lock
}

export interface PageScrollUnllockAction extends Action {
  type: PageScrollActionTypes.Unlock
}
export interface PageScrollToHashAction extends Action {
  type: PageScrollActionTypes.ScrollToHash
}

export interface PageScrollState {
  scrollTop?: number
  scrollLocked: boolean
  scrollToCard?: number
  visibleCard?: number
  visibleCardSlug?: string
}
