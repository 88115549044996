import { Action } from 'redux'

export interface BadgePhotoUploadCheckApiResponse {
    icimsId?: string
    reviewStatusUpdatedAt?: string
    status?: string
    statusMessageCode?: string
    statusMessageStrings?: string
}

export enum BadgePhotoUploadCheckActionTypes {
    BeginPollingBadgeUpload = 'BEGIN_POLLING_BADGE_PHOTO_UPLOAD_STATUS',
    FetchUploadCheckFulfilled = 'FETCH_BADGE_PHOTO_UPLOAD_CHECK_FULFILLED',
    FetchUploadCheckFailed = 'FETCH_BADGE_PHOTO_UPLOAD_CHECK_FAILED',
    StopPollingBadgeUpload = 'STOP_POLLING_BADGE_PHOTO_UPLOAD_STATUS',
    ClearUploadStatus = 'CLEAR_UPLOAD_STATUS'
    
}

export type BadgePhotoUploadCheckActions = BeginPollingBadgeUploadAction |
    FetchUploadCheckFulfilledAction |
    FetchUploadCheckFailedAction |
    StopPollingBadgeUploadAction |
    ClearUploadStatusAction

export interface BeginPollingBadgeUploadAction extends Action {
    type: BadgePhotoUploadCheckActionTypes.BeginPollingBadgeUpload
}

export interface FetchUploadCheckFulfilledAction extends Action {
    type: BadgePhotoUploadCheckActionTypes.FetchUploadCheckFulfilled
    payload: BadgePhotoUploadCheckApiResponse
}

export interface FetchUploadCheckFailedAction extends Action {
    type: BadgePhotoUploadCheckActionTypes.FetchUploadCheckFailed
}

export interface StopPollingBadgeUploadAction extends Action {
    type: BadgePhotoUploadCheckActionTypes.StopPollingBadgeUpload
}

export interface ClearUploadStatusAction extends Action {
    type: BadgePhotoUploadCheckActionTypes.ClearUploadStatus
}

export interface BadgePhotoUploadCheckState {
    icimsId?: string
    reviewStatusUpdatedAt?: string
    state?: string
    statusMessageCode?: string
    statusMessageStrings?: string
}