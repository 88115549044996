import { PillStatus } from '../../components/LaunchpadPill';
import {
    MyDocsApplicationState,
    MyDocsAction,
    MyDocsActionTypes,
} from './types'

const initialState: MyDocsApplicationState = {
    status: -2, // PillStatus.UNFETCHED,
    dueDate: '',
    myDocsPortalSsoUrl: '',
};

export const myDocsReducer = (state = initialState, action: MyDocsAction) => {
    switch (action.type) {
        case MyDocsActionTypes.GetMyDocsStatusSuccess: {
            const payload = action.payload
            const { status = PillStatus.UNAVAILABLE, dueDate, myDocsPortalSsoUrl } = payload

            return {
                ...state,
                status,
                dueDate,
                myDocsPortalSsoUrl,
            }
        }
        default:
            return state;
    }
}
