import { all, call, fork, put, takeEvery } from 'redux-saga/effects'

import {
  BgcActionType,
  BgcUserData
} from './types'

import {
  bindBgcPageLoadDataToState
} from './actions'

import { get } from '../../utils/ajax'
import { EXTERNAL_URL_MAP } from '../../routes/ExternalUrls'

export function requestServerForBgcUserData(): Promise<BgcUserData> | null{
  if(window.navigator.onLine){
    return get(EXTERNAL_URL_MAP.BGC)
  }
  return null
}

export function* loadBgcUserDataFromServer(
  invocationFn: () => Promise<BgcUserData> | null // Injecting requestServerForBgcUserData as a dependency in the watcher saga
){
  yield put(bindBgcPageLoadDataToState(
    //@ts-ignore
    (yield call(invocationFn)).data as BgcUserData
  ))
}

export function* watchForBgcPageLoadSaga(){
  yield takeEvery(
    BgcActionType.InitialLoadRequest,
    loadBgcUserDataFromServer,
    requestServerForBgcUserData
  )
}

// Export our root saga.
// We can also use `fork()` here to split our saga into multiple watchers.
export function* bgcSaga() {
  yield all([
    fork(watchForBgcPageLoadSaga)
  ])
}
