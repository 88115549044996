import { CopyActionTypes } from './types';

const initialCopy = null;

export function copyReducer(state = initialCopy, action) {
  switch (action.type) {
    case CopyActionTypes.FetchCopyFulfilled:
      return {... action.payload, loadingCopy: false };
    case CopyActionTypes.FetchCopyFailed:
      return {... action.payload, loadingCopy: false };
    case CopyActionTypes.FetchCopy:
      return {... state, loadingCopy: true };
    default:
      return state;
  }
}
