import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  UserReservedLoginSuccessResponse,
  LoginSuggestionsSuccessResponse,
  LoginAvailabilitySuccessResponse,
  STATUS_CODES
} from './types';
import {
  LoginSelectionActionTypes,
  ReserveUserLoginAction,
  LoginAvailabilityAction
} from './actionTypes';
import {
  initialPageStateSuccess,
  initialPageStateError,
  loginSuggestionsSuccess,
  loginSuggestionsError,
  loginAvailabilitySuccess,
  loginAvailabilityError,
  reserveUserLoginSuccess,
  reserveUserLoginError,
  deleteUserLoginSuccess,
  deleteUserLoginError,
  getPimaLoginSuccess,
} from './actions';
import { NavActionTypes } from '../nav/types'
import { EXTERNAL_URL_MAP } from '../../routes/ExternalUrls';
import { get, post } from '../../utils/ajax';

const getLoginSuggestions = async (): Promise<any> => {
  const res = await get<any>(EXTERNAL_URL_MAP.LOGIN_SUGGESTIONS);
  return {
    data: res.data.body,
    status: res.data.statusCode,
  };
};

function* handleGetLoginSuggestions(action: any) {
  try {
    //@ts-ignore
    const res = yield call(getLoginSuggestions);
    const { status, data }: { status: any, data: LoginSuggestionsSuccessResponse } = res;
    const { suggestedLogins } = data;
    if(status === STATUS_CODES.SUCCESS && suggestedLogins) {
      yield put(loginSuggestionsSuccess({'suggestedLogins': suggestedLogins.splice(0, 6) as []}));
    } else {
      yield put(loginSuggestionsError())    
    }
  } catch (err) {
    yield put(loginSuggestionsError())
  }
}

const getUserReservedLogin = async (): Promise<any> => {
  try {
    const res = await get<any>(EXTERNAL_URL_MAP.GET_USER_RESERVED_LOGIN);
    return {
      data: res.data.body,
      status: res.data.statusCode
    }
  } catch (err) {
    return {
      status: '500'
    }
  }
};

const getUserLoginEligibility = async(): Promise<any> => {
  try {
    const res = await get(EXTERNAL_URL_MAP.LOGIN_ELIGIBILITY);
    return {
      data: res.data,
      status: res.status
    };
  } catch (err) {
    return {
      status: '500'
    }
  }
};

const getLoginAvailability = async (action: LoginAvailabilityAction): Promise<any> => {
  const res = await get<any>(EXTERNAL_URL_MAP.LOGIN_AVAILABILITY, {
    params: {
      login: action.payload
    }
  });
  return {
    data: res.data.body,
    status: res.data.statusCode
  };
};

function* handleGetLoginAvailability(action: LoginAvailabilityAction) {
  try {
    //@ts-ignore
    const res = yield call(getLoginAvailability, action)
    const { status, data} : {status: any, data: LoginAvailabilitySuccessResponse} = res;
    if(status === STATUS_CODES.SUCCESS) {
      yield put(loginAvailabilitySuccess(data))
    } else {
      yield put(loginAvailabilityError())  
    }
  } catch (err) {
    yield put(loginAvailabilityError())
  }
}

function* handleReserveUserLogin(action: ReserveUserLoginAction) {
  try {
    //@ts-ignore
    const res: any = yield post<any>(EXTERNAL_URL_MAP.LOGIN_RESERVATION, {
      login: action.payload
    });
    if(res.data.statusCode === STATUS_CODES.SUCCESS) {
      yield all([
        put(reserveUserLoginSuccess(res.data.body)),
        put({ type: NavActionTypes.FetchNav, meta: { refetch: true } })
      ])
    } else {
      const responseBody = res.data.body;
      const errorMessage = responseBody?.message;
      yield put(reserveUserLoginError({
        errorMessage
      }))
    }
  } catch (err) {
    yield put(reserveUserLoginError({
      errorMessage: err.message
    }))
  }
}

function* handleDeleteUserLogin() {
  try {
    //@ts-ignore
    const res: any = yield get<any>(EXTERNAL_URL_MAP.DELETE_USER_RESERVED_LOGIN);
    if(res.data.statusCode === STATUS_CODES.DELETE_SUCCESS) {
      yield put(deleteUserLoginSuccess(res.data.body));
    } else {
      yield put(deleteUserLoginError())  
    }
  } catch (err) {
    yield put(deleteUserLoginError())
  }
}

function* handleInitialPageState() {
  try {
    //@ts-ignore
    const loginEligibilityResponse = yield call(getUserLoginEligibility);
    const { status: loginEligibityResponseStatus, data: loginEligibilityData = {} }: {status: string, data: any} = loginEligibilityResponse;
    const { status: userLoginReservationStatus = '', login: eligibilityTableLogin = '' } = loginEligibilityData;

    /** If there is a record in the eligibility table and the user has login selected previously */
    if(parseInt(loginEligibityResponseStatus) === STATUS_CODES.SUCCESS && loginEligibilityData.login) { 
      yield put(initialPageStateSuccess({
          login: loginEligibilityData.login,
          suggestedLogins: [],
          eligibilityData: {
            userLoginReservationStatus,
            eligibilityTableLogin
          }
        })
      );
    } else if (parseInt(loginEligibityResponseStatus) === STATUS_CODES.SUCCESS) {
      /** There is an entry in the table for the user, however, user doesnt have a previous login. */
      //@ts-ignore
        const loginSuggestionsResponse = yield call(getLoginSuggestions);
        const { status: loginSuggestionsResponseStatus, data: loginSuggestionsData }: {status: any, data: LoginSuggestionsSuccessResponse} = loginSuggestionsResponse;
        if(loginSuggestionsResponseStatus === STATUS_CODES.SUCCESS) {
          const { suggestedLogins = [] } = loginSuggestionsData || {};
          yield put(initialPageStateSuccess({
            login: '',
            suggestedLogins: suggestedLogins.splice(0, 6) as [],
            eligibilityData: {
              userLoginReservationStatus,
              eligibilityTableLogin
            }
          }));
        } else {
          yield put(initialPageStateError());
        }
      } else {
          /** There is an error OR there is no record in the table OR there is an error while fetching the data */
          // TODO: We need to confirm if 'no record in the table' is actually a scenario that needs to be handled on the UI?
        //@ts-ignore
          const userReservedLoginResponse = yield call(getUserReservedLogin);
          const { status: userReservationResponseStatus, data: reservedLoginData }: {status: any, data: UserReservedLoginSuccessResponse[]} = userReservedLoginResponse;
          if(userReservationResponseStatus === STATUS_CODES.SUCCESS) {  
            const { login = ''  } = reservedLoginData[0] || [];
            yield put(initialPageStateSuccess({
              login,
              suggestedLogins: [],
              eligibilityData: {
                userLoginReservationStatus,
                eligibilityTableLogin
              }
            }));
          } else if(userReservationResponseStatus === STATUS_CODES.NOT_AVAILABLE && userReservedLoginResponse.data.message.indexOf('Invalid personId') === -1) {
            //@ts-ignore
            const loginSuggestionsResponse = yield call(getLoginSuggestions);
            const { status: loginSuggestionsResponseStatus, data: loginSuggestionsData }: {status: any, data: LoginSuggestionsSuccessResponse} = loginSuggestionsResponse;
            if(loginSuggestionsResponseStatus === STATUS_CODES.SUCCESS) {
              const { suggestedLogins = [] } = loginSuggestionsData || {};
              yield put(initialPageStateSuccess({
                login: '',
                suggestedLogins: suggestedLogins.splice(0, 6) as [],
                eligibilityData: {
                  userLoginReservationStatus,
                  eligibilityTableLogin
                }
              }));
            } else {
              yield put(initialPageStateError());
            }
          } else {
            yield put(initialPageStateError());
          }
      }
    } catch (err) {
    yield put(initialPageStateError());
  }
}

function* handleGetPimaUserLogin() {
  try {
    //@ts-ignore
    const res: any = yield get<any>(EXTERNAL_URL_MAP.GET_PIMA_USER_LOGIN);
    if(res.status === STATUS_CODES.SUCCESS) {
      yield put(getPimaLoginSuccess(res.data));
    }
  } catch (err) {
    // console.log(err);
  }
}

function* watchGetLoginSelectionRequest() {
  yield takeEvery(LoginSelectionActionTypes.GetLoginSuggestions, handleGetLoginSuggestions);
}

function* watchReserveUserLoginSelectionRequest() {
  yield takeEvery(LoginSelectionActionTypes.ReserveUserLogin, handleReserveUserLogin);
}

function* watchGetLoginAvailabilityRequest() {
  yield takeEvery(LoginSelectionActionTypes.GetLoginAvailability, handleGetLoginAvailability);
}

function* watchDeleteUserLoginRequest() {
  yield takeEvery(LoginSelectionActionTypes.DeleteUserLogin, handleDeleteUserLogin);
}

function* watchInitialPageStateRequest() {
  yield takeEvery(LoginSelectionActionTypes.GetInitialState, handleInitialPageState);
}

function* watchGetPimaUserLoginRequest() {
  yield takeEvery(LoginSelectionActionTypes.GetPimaUserLogin, handleGetPimaUserLogin);
}

// Export our root saga.
// We can also use `fork()` here to split our saga into multiple watchers.
export function* loginSelectionSaga() {
  yield all([
    fork(watchGetLoginSelectionRequest),
    fork(watchGetLoginAvailabilityRequest),
    fork(watchReserveUserLoginSelectionRequest),
    fork(watchDeleteUserLoginRequest),
    fork(watchInitialPageStateRequest),
    fork(watchGetPimaUserLoginRequest)
  ]);
}
