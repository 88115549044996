import { PillStatus } from '../../components/LaunchpadPill';
import {
    IdVerificationApplicationState,
    IdVerificationAction,
    IdVerificationActionTypes,
    IdVerificationBannerCode,
} from './types'

const initialState: IdVerificationApplicationState = {
    status: -2, // PillStatus.UNFETCHED,
    banner: IdVerificationBannerCode.NOT_AVAILABLE,
    dueDate: '',
    wizardUrl: '',
};

export const idVerificationReducer = (state = initialState, action: IdVerificationAction) => {
    switch (action.type) {
        case IdVerificationActionTypes.GetIdVerificationStatusSuccess: {
            const payload = action.payload
            const { status = PillStatus.UNAVAILABLE, banner, dueDate, wizardUrl, isAutomatedRivEnabled } = payload

            return {
                ...state,
                status,
                banner,
                dueDate,
                wizardUrl,
                isAutomatedRivEnabled
            }
        }
        default:
            return state;
    }
}
