import { RefObject } from 'react'
import { Action } from 'redux'

export const SubmissionStatus = {
  Unsubmitted: 0,
  Submitting: 1,
  SubmissionSuccess: 2,
  SubmissionFailure: 3,
  Validating: 4,
  SubmissionHasSuggestions: 5
};

export interface Equipment {
  hdd: string
  id: string
  os: string
  ram: string
  screensize: string
}

export enum AddressValidationResultCode {
  VALID = 'VALID', // The address is valid as submitted.
  PO_BOX = 'PO_BOX', // The address suggestions had po box.
  VALID_WITH_CHANGES = 'VALID_WITH_CHANGES', // The address is invalid, but can be made valid by accepting suggested changes.
  INVALID = 'INVALID', // The address is invalid because parts of the address were wrong.
  UNSUPPORTED_LOCALE = 'UNSUPPORTED_LOCALE', // The specified country is not supported by any of the available validators.
  SERVICE_UNAVAILABLE = 'SERVICE_UNAVAILABLE', // The Address Validation service is unavailable.
  NOT_VALIDATED = 'NOT_VALIDATED', // The Address Validation service is unavailable.
}

export interface SuggestedAddress {
  line1?: string
  line2?: string
  line3?: string
  city?: string
  state?: string
  county?: string
  country?: string
  zip?: string
  countryCode?: string
  phoneNumber?: string
  phoneNumberInfo?: {
    isoCode?: string
    countryCode?: string
    phoneNumber?: string
  }
}
export interface AddressSuggestion {
  address?: {
    fields?: SuggestedAddress
  }
  suggestionId?: string
  segmentedAddress?: any
  addressAttributes?: any
  confidence?: any
  validationStrategy?: any
  changes?: any
}
export interface AddressValidationResult {
  resultCode?: AddressValidationResultCode  // One of the AddressValidationResultCode enumeration values indicating the success or failure of the validation request.
  blockOriginalAddress?: boolean,  // Set this element to 'true' when original address is NOT acceptable and CANNOT be created.
  confidence?: any,  // Denotes the accuracy or perfectness of the given address
  errorCodes?: any[],
  addressSuggestions?: AddressSuggestion[],
  address?: ITEquipment
}

export interface ShippingAddress {
  availableEquipment: Equipment[]
  availableKeyboardLayouts?: string[]
  buildingSecurityCode?: string
  canKeepHardware?: boolean
  canGetItKit?: boolean
  city?: string
  country?: string
  county?: string
  deliveryInstructions?: string
  equipmentDetailsSubmitted: boolean
  equipmentPreference?: string
  equipmentStatus: number
  fullName?: string
  keyboardPreference: string
  lockDate: string
  locked: boolean
  line1?: string
  line2?: string
  line3?: string
  overrideAddressValidation?: boolean
  phoneNumber?: string
  phoneNumberInfo?: {
    isoCode?: string
    countryCode?: string
    phoneNumber?: string
  }
  state?: string
  suggestionId?: string
  updatedAt?: string
  willGetItKit?: boolean
  willKeepHardware?: boolean
  zip?: string
}

export interface ShippingAddressApplicationState {
  attemptedSubmit: boolean
  currentlyEditing: boolean
  currentShippingAddress: ITEquipment
  draftShippingAddress: ITEquipment
  isDirty: boolean
  messageBannerRef?: RefObject<HTMLDivElement>
  submissionStatus: number
  touched: {[key: string]: boolean}
  needsValidation: boolean,
  addressValidationResult: AddressValidationResult
}

export interface AssistiveTechnology {
  description: string
}

export interface AssistiveTechnologySelection {
  isAssistiveTechnologyActive: boolean
  availableAssistiveTechnologies: AssistiveTechnology[]
  assistiveTechnologyPreferences: AssistiveTechnology[]
}

export type ITEquipment = ShippingAddress & AssistiveTechnologySelection

export enum ShippingAddressActionTypes {
  AttemptEquipmentPreferenceSubmission = 'ATTEMPT_EQUIPMENT_PREFERENCE_SUBMISSION',
  CalculateEquipmentPreferenceStatus = 'CALCULATE_EQUIPMENT_PREFERENCE_STATUS',
  LoadShippingAddress = 'LOAD_SHIPPING_ADDRESS',
  LoadShippingAddressSuccess = 'LOAD_SHIPPING_ADDRESS_SUCCESS',
  LoadShippingAddressFailure = 'LOAD_SHIPPING_ADDRESS_FAILURE',
  EditShippingAddress = 'EDIT_SHIPPING_ADDRESS',
  CancelEditShippingAddress = 'CANCEL_EDIT_SHIPPING_ADDRESS',
  ClearShippingAddress = 'CLEAR_SHIPPING_ADDRESS',
  UpdateShippingAddress = 'UPDATE_SHIPPING_ADDRESS',
  UpdateShippingAddressSuccess = 'UPDATE_SHIPPING_ADDRESS_SUCCESS',
  UpdateShippingAddressFailure = 'UPDATE_SHIPPING_ADDRESS_FAILURE',
  UpdateShippingAddressSuggestions = 'UPDATE_SHIPPING_ADDRESS_SUGGESTIONS',
  ChangeShippingAddress = 'CHANGE_SHIPPING_ADDRESS',
  ChangeShippingAddressCountry = 'CHANGE_SHIPPING_ADDRESS_COUNTRY',
  UpdateEquipmentPreference = 'UPDATE_EQUIPMENT_PREFERENCE',
  UpdateKeyboardLayoutSelection = 'UPDATE_KEYBOARD_LAYOUT_SELECTION',
  AddMessageBannerRef = 'ADD_MESSAGE_BANNER_REF',
  ScrollToMessageBanner = 'SCROLL_TO_MESSAGE_BANNER',
  RetainExistingHardware = 'RETAIN_EXISTING_HARDWARE',
  SelectITKit = 'SELECT_IT_KIT',
  OverrideAddressValidation = 'OVERRIDE_ADDRESS_VALIDATION',
  ChangeShippingAddressFromSuggestion = 'CHANGE_SHIPPING_ADDRESS_FROM_SUGGESTION',
  ValidateAndGetAddressSuggestions = 'VALIDATE_AND_GET_ADDRESS_SUGGESTIONS',

  //Assistive Technology actions
  SelectAssistiveTechnology = 'SELECT_ASSISTIVE_TECHNOLOGY',
  UnSelectAssistiveTechnology = 'UNSELECT_ASSISTIVE_TECHNOLOGY',
  OpenAssistiveTechnologyExpander = 'OPEN_ASSISTIVE_TECHNOLOGY',
}

export interface CalculateEquipmentPreferenceStatusAction extends Action {
  type: ShippingAddressActionTypes.CalculateEquipmentPreferenceStatus
  payload: any
}

export interface LoadShippingAddressAction extends Action {
  type: ShippingAddressActionTypes.LoadShippingAddress
}

export interface LoadShippingAddressSuccessAction extends Action {
  type: ShippingAddressActionTypes.LoadShippingAddressSuccess
  payload: ITEquipment
}

export interface LoadShippingAddressFailureAction extends Action {
  type: ShippingAddressActionTypes.LoadShippingAddressFailure
}

export interface EditShippingAddressAction extends Action {
  type: ShippingAddressActionTypes.EditShippingAddress
}

export interface CancelEditShippingAddressAction extends Action {
  type: ShippingAddressActionTypes.CancelEditShippingAddress
}

export interface ClearShippingAddressAction extends Action {
  type: ShippingAddressActionTypes.ClearShippingAddress
}

export interface UpdateShippingAddressAction extends Action {
  type: ShippingAddressActionTypes.UpdateShippingAddress
  payload: ITEquipment
}

export interface UpdateShippingAddressSuggestionsAction extends Action {
  type: ShippingAddressActionTypes.UpdateShippingAddressSuggestions
  payload: AddressValidationResult
}

export interface UpdateShippingAddressSuccessAction extends Action {
  type: ShippingAddressActionTypes.UpdateShippingAddressSuccess
  payload: ITEquipment
}

export interface UpdateShippingAddressFailureAction extends Action {
  type: ShippingAddressActionTypes.UpdateShippingAddressFailure
}

export interface ChangeShippingAddressAction extends Action {
  type: ShippingAddressActionTypes.ChangeShippingAddress
  payload: { [key: string]: string }
}

export interface ChangeShippingAddressCountryAction extends Action {
  type: ShippingAddressActionTypes.ChangeShippingAddressCountry
  payload: string
}

export interface UpdateEquipmentPreferenceAction extends Action {
  type: ShippingAddressActionTypes.UpdateEquipmentPreference
  payload: string
}

export interface UpdateKeyboardLayoutSelectionAction extends Action {
  type: ShippingAddressActionTypes.UpdateKeyboardLayoutSelection
  payload: string
}

export interface AddMessageBannerRefAction extends Action {
  type: ShippingAddressActionTypes.AddMessageBannerRef
  payload: RefObject<HTMLDivElement>
}

export interface ScrollToMessageBannerAction extends Action {
  type: ShippingAddressActionTypes.ScrollToMessageBanner
}

export interface AttemptEquipmentPreferenceSubmissionAction extends Action {
  type: ShippingAddressActionTypes.AttemptEquipmentPreferenceSubmission
}

export interface RetainExistingHardwareAction extends Action {
  type: ShippingAddressActionTypes.RetainExistingHardware
}

export interface SelectITKitAction extends Action {
  type: ShippingAddressActionTypes.SelectITKit
  payload: boolean
}

export interface OverrideAddressValidationAction extends Action {
  type: ShippingAddressActionTypes.OverrideAddressValidation
}
export interface ValidateAndGetAddressSuggestionsAction extends Action {
  type: ShippingAddressActionTypes.ValidateAndGetAddressSuggestions
  payload: ITEquipment
}

export interface ChangeShippingAddressFromSuggestionAction extends Action {
  type: ShippingAddressActionTypes.ChangeShippingAddressFromSuggestion
  payload: ITEquipment
}

export interface SelectAssistiveTechnologyAction extends Action {
  type: ShippingAddressActionTypes.SelectAssistiveTechnology
  payload: AssistiveTechnology
}

export interface UnSelectAssistiveTechnologyAction extends Action {
  type: ShippingAddressActionTypes.UnSelectAssistiveTechnology
  payload: AssistiveTechnology
}

export interface ToggleAssistiveTechnologyExpanderAction extends Action {
  type: ShippingAddressActionTypes.OpenAssistiveTechnologyExpander
  payload: boolean
}

export type ShippingAddressAction =  AttemptEquipmentPreferenceSubmissionAction
  | CalculateEquipmentPreferenceStatusAction
  | LoadShippingAddressAction
  | LoadShippingAddressSuccessAction
  | LoadShippingAddressFailureAction
  | EditShippingAddressAction
  | CancelEditShippingAddressAction
  | ClearShippingAddressAction
  | UpdateShippingAddressAction
  | UpdateShippingAddressSuggestionsAction
  | UpdateShippingAddressSuccessAction
  | UpdateShippingAddressFailureAction
  | UpdateEquipmentPreferenceAction
  | UpdateKeyboardLayoutSelectionAction
  | ChangeShippingAddressAction
  | ChangeShippingAddressCountryAction
  | AddMessageBannerRefAction
  | ScrollToMessageBannerAction
  | RetainExistingHardwareAction
  | SelectITKitAction
  | OverrideAddressValidationAction
  | ValidateAndGetAddressSuggestionsAction
  | ChangeShippingAddressFromSuggestionAction
  | SelectAssistiveTechnologyAction
  | UnSelectAssistiveTechnologyAction
  | ToggleAssistiveTechnologyExpanderAction

