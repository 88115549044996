export interface ExternalUrlMap {
  [key: string]: string;
}

export const EXTERNAL_URL_MAP: ExternalUrlMap = {
  USER_PROFILE: '/api/auth/me',
  USER_LOGIN: '/api/auth/login',
  USER_LOGOUT: '/api/auth/logout',
  COPY: '/api/copy',
  FAQ: '/api/faq/me',
  METRICS: '/api/metrics',
  PLATFORM:
    'https://issues.amazon.com/issues/create?assignedFolder=bea76e22-f340-47b8-83d3-c6754c8a20c7&title=' +
    'Platform+Issue&descriptionContentType=text%2Famz-markdown-sim&labels%5B0%5D%5Bid%5D=515d260b-d3fb-447' +
    'd-9f6a-b79d8d9dce57&extensions%5Btt%5D%5Bcategory%5D=',
  GENERAL:
    'https://issues.amazon.com/issues/create?assignedFolder=bea76e22-f340-47b8-83d3-c6754c8a20c7&title' +
    '=General+Program+Questions&descriptionContentType=text%2Famz-markdown-sim&labels%5B0%5D%5Bid%5D=ea2a8edb-8cce' +
    '-4ff0-9281-1bd1ac2a1e71&extensions%5Btt%5D%5Bcategory%5D=',
  COACH:
    'https://issues.amazon.com/issues/create?assignedFolder=bea76e22-f340-47b8-83d3-c6754c8a20c7&title' +
    '=Coach+Questions&descriptionContentType=text%2Famz-markdown-sim&labels%5B0%5D%5Bid%5D=882c52d4-70ba-4250-' +
    '8bd7-f18730d35c50&extensions%5Btt%5D%5Bcategory%5D=',
  BADGE_PHOTO_REVIEW_STATUS: '/api/badge/me',
  BADGE_PHOTO_UPLOAD_CHECK: '/api/badge/me',
  BGC: '/api/background-check/me',
  PENDING_BADGE_PHOTO: '/api/badge/me/photo',
  SHIPPING_ADDRESS: '/api/aws-it/me',
  SHIPPING_ADDRESS_VALIDATE: '/api/aws-it/validate-address',
  LOGIN_SUGGESTIONS: '/api/login-suggestions/me',
  LOGIN_RESERVATION: '/api/login-reservation/me',
  LOGIN_AVAILABILITY: '/api/login-availability/me',
  LOGIN_ELIGIBILITY: '/api/login-eligibility/me',
  GET_USER_RESERVED_LOGIN: '/api/user-reserved-login/me',
  GET_PIMA_USER_LOGIN: '/api/pima-data',
  DELETE_USER_RESERVED_LOGIN: '/api/remove-reserved-login/me',
  WORKAUTH: '/api/workauth/me',
  RELEASE: '/api/release',
  DASHBOARD_TASKS: '/api/dashboard/tasks',
  SUB_FOOTER_ITEMS: '/api/subFooterItems',
  NAVIGATION: '/api/navigation',
  HEARTBEAT: '/api/heartbeat',
  USER_LANGUAGE: '/api/user/me',
  RELEASED_LANGUAGES: '/api/release/languages',
  MYDOCS: '/api/documents/me',
  ID_VERIFICATION: '/api/id-verification/me',
  FDIE: '/api/fdie/me',
  RETURN_IT_EQUIPMENT: '/api/return-it-equipment/me',
  INTRODUCE_YOURSELF: '/api/introduce-yourself/me',
  INTRODUCE_YOURSELF_PHOTO: '/api/introduce-yourself/me/photo',
  INTRODUCE_YOURSELF_PHOTO_UPLOAD_NOTIFY: '/api/introduce-yourself/me/photo/upload-notify',
  IMMIGRATION: '/api/immigration/me',
  ONBOARDINGSITE_ORIENTATION: 'https://embark.corp.amazon.com/orientation',
  INSIDE_HR_AMAZON_DEV: 'https://inside.hr.amazon.dev/',
  DRUG_SCREEN_STATUS: '/api/drug-screen-status/me',
  IMMIGRATION_SERVICE_PROVIDER: '/api/immigration-service-provider/me',
  MEDICAL_CHECK: '/api/medical-check/me',
  PIC: '/api/pic/me',
  NOTICE: '/api/notice/me',
  LANDING: '/landing'
};
