export function shouldHideLogout(state) {
  //We hide logout button for internal user
  return !!state.user.isInternalUser;
}

export function isInternalUser(user) {
  return !!user.isInternalTransfer;
}

export function isCountryToCountryTransfer(user) {
  return !!user.isC2CInternalTransfer;
}

export function isCandidateInStudentPrograms(user) {
  return !!user.isCandidateInStudentPrograms;
}
