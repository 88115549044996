import { all, call, delay, fork, put, take, race, select, takeEvery } from 'redux-saga/effects'
import { BadgePhotoReviewStatusActionTypes, BadgePhotoReviewStatusApiResponse } from './types'
import { fetchBadgePhotoReviewStatusFulfilled, fetchBadgePhotoReviewStatusFailed } from './actions'
import { EXTERNAL_URL_MAP } from '../../routes/ExternalUrls'
import { get } from '../../utils/ajax'

const fetchBadgePhotoReview = async (): Promise<BadgePhotoReviewStatusApiResponse> => {
  const res = await get<BadgePhotoReviewStatusApiResponse>(EXTERNAL_URL_MAP.BADGE_PHOTO_REVIEW_STATUS)
  return res.data
}

const statusUpdatedDate = (state:any) => {
  return state.badgePhotoUploadCheck.reviewStatusUpdatedAt
}

function* handleFetchBadgePhotoReviewStatus(): Generator {
  try {
    const res: BadgePhotoReviewStatusApiResponse = (yield call(fetchBadgePhotoReview)) as BadgePhotoReviewStatusApiResponse
    yield put(fetchBadgePhotoReviewStatusFulfilled(res))
  } catch (err) {
    yield put(fetchBadgePhotoReviewStatusFailed())
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchFetchBadgePhotoReviewStatusRequest() {
  yield takeEvery(BadgePhotoReviewStatusActionTypes.FetchPhotoReviewStatus, handleFetchBadgePhotoReviewStatus)
}
  

// watch for BeginPollingPhotoReviewStatus.
// once that occurs, start the polling task (handleFetchBadgePhotoUploadCheck),
// but watch for StopPollingPhotoReviewStatus and terminate polling once its received.
function* watchFetchBadgeReviewCheckRequest() {
  yield take(BadgePhotoReviewStatusActionTypes.BeginPollingPhotoReviewStatus)
  yield race([call(handleFetchBadgeStatusCheck), take(BadgePhotoReviewStatusActionTypes.StopPollingPhotoReviewStatus)])
}

// every 10min = 10 * 60 * 1000 (unless we encounter an error),
// make a request to determine if the human auditors accepted or rejected the uploaded image.
// dispatch the response for the appropriate success/failure reducer to handle.
function* handleFetchBadgeStatusCheck(): Generator {
  while (true) {
    try {
      // Fetching reviewStatus at regular interval 100 ms.
      const reviewStatus = (yield call(fetchBadgePhotoReview)) as BadgePhotoReviewStatusApiResponse
      const res: BadgePhotoReviewStatusApiResponse = reviewStatus
      const previousStatusDate = yield select(statusUpdatedDate)
      if (previousStatusDate === undefined || previousStatusDate == null || previousStatusDate !== res.reviewStatusUpdatedAt) {
        yield put(fetchBadgePhotoReviewStatusFulfilled(res))
        if (res.status === 'APPROVED') { //FIXME update this to use types after hear from gabe 9/28
          yield put({ type: BadgePhotoReviewStatusActionTypes.StopPollingPhotoReviewStatus});
        }
      }
      // yield delay(30 * 1000); // every 30s for testing
      yield delay(10 * 60 * 1000); // every 10min = 10 * 60 * 1000
    } catch (err) {
      console.error(err)
      yield put(fetchBadgePhotoReviewStatusFailed())
      // Once the polling has encountered an error,
      // it should be stopped immediately
      yield put({ type: BadgePhotoReviewStatusActionTypes.StopPollingPhotoReviewStatus, err });
    }
  }
}

// Export our root saga.
export function* badgePhotoReviewStatusSaga() {
  yield all([fork(watchFetchBadgeReviewCheckRequest), fork(watchFetchBadgePhotoReviewStatusRequest)])
}
