import {
    BadgePhotoUploadCheckActionTypes,
    BadgePhotoUploadCheckActions,
    BadgePhotoUploadCheckState
} from './types'

const initialBadgePhotoUploadCheckState: BadgePhotoUploadCheckState = {
    icimsId: undefined,
    reviewStatusUpdatedAt: undefined,
    state: undefined,
    statusMessageCode: undefined,
    statusMessageStrings: undefined
}

export const badgePhotoUploadCheckReducer = (state = initialBadgePhotoUploadCheckState, action: BadgePhotoUploadCheckActions) => {
    switch (action.type) {
        case BadgePhotoUploadCheckActionTypes.BeginPollingBadgeUpload:
            return {...state, isPollingUpload: true, beganPolling: new Date()}

        case BadgePhotoUploadCheckActionTypes.FetchUploadCheckFulfilled:
            return {...action.payload, cleared: false}

        case BadgePhotoUploadCheckActionTypes.FetchUploadCheckFailed:
            return {...state, isPollingUpload: false}

        case BadgePhotoUploadCheckActionTypes.StopPollingBadgeUpload:
            return {...state, isPollingUpload: false}

        case BadgePhotoUploadCheckActionTypes.ClearUploadStatus:
            return {reviewStatusUpdatedAt: state.reviewStatusUpdatedAt, isPollingUpload: false, cleared: true}

        default:
            return state;
    }
}
