import { UserState, UserActionTypes, UserActions } from './types'
import { isInternalUser, isCountryToCountryTransfer, isCandidateInStudentPrograms } from '../../store/businessRules'

const initialUserState: UserState = {
    isInternalUser: false,
    isCountryToCountryTransfer: false,
    isAuthenticated: false,
    authenticationFailed: false,
    isLoading: false,
    authenticationCheckComplete: false,
    profile: undefined,
}

export const usersReducer = (state = initialUserState, action: UserActions) => {
    switch (action.type) {
        case UserActionTypes.FetchUser:
            return {
                ...state,
                isAuthenticated: false,
                authenticationFailed: false,
                isLoading: true,
                authenticationCheckComplete: false,
                profile: undefined,
            }
        case UserActionTypes.FetchUserFulfilled:
            return {
                ...state,
                isAuthenticated: true,
                authenticationFailed: false,
                isLoading: false,
                authenticationCheckComplete: true,
                profile: action.payload,
                isInternalUser: isInternalUser(action.payload),
                isCountryToCountryTransfer: isCountryToCountryTransfer(action.payload),
                isCandidateInStudentPrograms: isCandidateInStudentPrograms(action.payload),
            }
        case UserActionTypes.FetchUserFailed:
            return {
                ...state,
                isAuthenticated: false,
                authenticationFailed: true,
                isLoading: false,
                authenticationCheckComplete: true,
                profile: undefined
            }
        default:
            return state;
    }
}
