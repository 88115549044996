import { Action } from 'redux';

export enum NavActionTypes {
  FetchNav = 'NAV_REQUEST',
  FetchNavFulfilled = 'NAV_RESPONSE_COMPLETED',
  FetchNavFailed = 'NAV_REQUEST_FAILED'
}

export interface EligibleItem {
  slug: string
}

export enum EligibilityPolicies {
  allow = 'allow',
  deny = 'deny',
}

export interface EligibilityPolicy {
  defaultAction: EligibilityPolicies
}

export enum Region {
  CANCorp = 'CAN-corp',
  LUXCorp = 'LUX-corp',
  SGPCorp = 'SGP-corp',
  GBRCorp = 'GBR-corp',
  GBROps = 'GBR-ops',
}

interface HasTitle {
  title?: string
}
// interface HasRegion {
//   title?: string
// }

type HasTitleOrRegion = HasTitle & Record<Region, any>

interface BaseNavItem extends EligibleItem {
  type: string
  cmsType: string
  eligibilityPolicy: EligibilityPolicy
  url: string
}

export interface LocalizedNavItem extends BaseNavItem {
  title: string
}
export interface NavItem extends BaseNavItem {
  stringResources: Record<string, HasTitleOrRegion>
}

export type ExtendedNavItem = {
  items: NavItem[] & LocalizedNavItem[],
  stringResources: any
}

export interface NavPayload {
  items : LocalizedNavItem[],
  stringResources: any
}

export interface NavigationState extends NavPayload {
  loading: boolean
}

export enum NavigationEvent {
  LoadItems = 'NAVIGATION_LOAD_ITEMS',
  LoadItemsSuccess = 'NAVIGATION_LOAD_ITEMS_SUCCESS',
  LoadItemsFailure = 'NAVIGATION_LOAD_ITEMS_FAILURE',
}

interface LoadItemsAction extends Action {
  type: NavigationEvent.LoadItems
}

interface LoadItemsSuccessAction extends Action {
  type: NavigationEvent.LoadItemsSuccess
  payload: ExtendedNavItem
  lang: string
  region: Region
}

interface LoadItemsFailureAction extends Action {
  type: NavigationEvent.LoadItemsFailure
}

export type ItemAction = LoadItemsAction
  | LoadItemsSuccessAction
  | LoadItemsFailureAction
