import { post } from '../utils/ajax';
import { EXTERNAL_URL_MAP } from '../routes/ExternalUrls';
import { noop } from 'lodash';

import {
  prefixSegmentToPath
} from '../context/baseSegmentContext'

const headers = {
  contentType: 'application/json'
};

const isOffline = () => !window.navigator.onLine;

const isPageHidden = () => document.hidden

const postUtil = (type, prefixSegment) => context => {
  let metricsPath = EXTERNAL_URL_MAP.METRICS
  if(prefixSegment){
    metricsPath = prefixSegmentToPath({
      path: metricsPath,
      prefixSegment,
    })
  }
  return (isOffline() || isPageHidden()) ?
    noop() :
    post(
      metricsPath,
      {
        type,
        context
      },
      {
        headers
      }
    );
};

export default postUtil;
