var endpointMap = {
  cs: {
    "badge-upload-v1.index": "translation-cs.json",
    "bgc-p0.index": "translation-cs.json",
    "c2c-faq.index": "translation-cs.json",
    "catch-all.index": "translation-cs.json",
    "culture.index": "translation-cs.json",
    "dashboard.c2c": "translation-cs.json",
    "dashboard.index": "translation-cs.json",
    "drug-screen-status-v1.index": "translation-cs.json",
    "faq.index": "translation-cs.json",
    "fdie-v1.index": "translation-cs.json",
    "id-verification-v1.index": "translation-cs.json",
    "immigration-v1.index": "translation-cs.json",
    "introduce-yourself-v1.index": "translation-cs.json",
    "isp-v1.index": "translation-cs.json",
    "login-selection.index": "translation-cs.json",
    "medical-check-v1.index": "translation-cs.json",
    "mydocs-v1.c2c": "translation-cs.json",
    "mydocs-v1.index": "translation-cs.json",
    "nav.index": "translation-cs.json",
    "not-found.index": "translation-cs.json",
    "pdf.diversity-inclusion.index": "translation-cs.json",
    "pdf.innovative-culture.index": "translation-cs.json",
    "pdf.shareholder-letters.index": "translation-cs.json",
    "pic-v1.index": "translation-cs.json",
    "post-day-one.index": "translation-cs.json",
    "return-it-equipment-v1.index": "translation-cs.json",
    "shipping-address-v1.addressSuggestion": "translation-cs.json",
    "shipping-address-v1.assistiveTechnologies": "translation-cs.json",
    "shipping-address-v1.countries": "translation-cs.json",
    "shipping-address-v1.hardware": "translation-cs.json",
    "shipping-address-v1.index": "translation-cs.json",
    "shipping-address-v1.schema": "translation-cs.json",
    "shipping-address-v1.schemaAE": "translation-cs.json",
    "shipping-address-v1.schemaAU": "translation-cs.json",
    "shipping-address-v1.schemaCA": "translation-cs.json",
    "shipping-address-v1.schemaCR": "translation-cs.json",
    "shipping-address-v1.schemaDE": "translation-cs.json",
    "shipping-address-v1.schemaFR": "translation-cs.json",
    "shipping-address-v1.schemaGB": "translation-cs.json",
    "shipping-address-v1.schemaIN": "translation-cs.json",
    "shipping-address-v1.schemaIT": "translation-cs.json",
    "shipping-address-v1.schemaMX": "translation-cs.json",
    "shipping-address-v1.schemaSA": "translation-cs.json",
    "shipping-address-v1.schemaSG": "translation-cs.json",
    "shipping-address-v1.schemaUS": "translation-cs.json",
    "shipping-address-v1.states": "translation-cs.json",
    "shipping-address-v1.statesAU": "translation-cs.json",
    "shipping-address-v1.statesCA": "translation-cs.json",
    "shipping-address-v1.statesFR": "translation-cs.json",
    "shipping-address-v1.statesIN": "translation-cs.json",
    "shipping-address-v1.statesUS": "translation-cs.json",
    "shipping-address-v1.submissionOutcome": "translation-cs.json",
    "shipping-address-v1.validation": "translation-cs.json",
    "text.common.index": "translation-cs.json",
    "text.landing.index": "translation-cs.json",
    "text.page-footer.index": "translation-cs.json",
    "text.sub-footer.index": "translation-cs.json",
    "unauthorized.index": "translation-cs.json",
    "videos.amazon-14-leadership-principles-jeff-bezos.index": "translation-cs.json",
    "videos.amazon-ceo-jeff-bezos-it-is-always-day-one.index": "translation-cs.json",
    "videos.andy-jassy-ceo-aws.index": "translation-cs.json",
    "videos.aws-reinvent-2019-keynote-andy-jassy.index": "translation-cs.json",
    "videos.aws-reinvent-2020.index": "translation-cs.json",
    "videos.aws-reinvent-2021-compute.index": "translation-cs.json",
    "videos.aws-reinvent-2021-data-strategies.index": "translation-cs.json",
    "videos.aws-reinvent-2021-introduction.index": "translation-cs.json",
    "videos.aws-reinvent-2021-networking.index": "translation-cs.json",
    "videos.aws-reinvent-2021-transforming-industries.index": "translation-cs.json",
    "videos.aws-reinvent-2021.index": "translation-cs.json",
    "videos.aws-welcome-message-by-adam-selipsky.index": "translation-cs.json",
    "videos.early-look.index": "translation-cs.json",
    "videos.jeff-bezos-amazon-blue-origin.index": "translation-cs.json",
    "videos.shop-talk-jeff-wilke-fortune.index": "translation-cs.json",
    "videos.tenets-and-decisions.index": "translation-cs.json",
    "videos.virtuous-cycle.index": "translation-cs.json",
    "videos.welcome-to-aws.index": "translation-cs.json",
    "videos.what-is-aws.index": "translation-cs.json",
    "videos.what-is-cloud-computing.index": "translation-cs.json",
    "work-auth.USAcorp": "translation-cs.json",
    "work-auth.c2c": "translation-cs.json",
    "work-auth.default.c2c": "translation-cs.json",
    "work-auth.default.index": "translation-cs.json",
    "work-auth.generic.index": "translation-cs.json",
    "work-auth.index": "translation-cs.json",
    "work-auth.newHireDefault": "translation-cs.json",
    "work-auth.newHireGBR": "translation-cs.json",
    "work-auth.special.ARE-corp.index": "translation-cs.json",
    "work-auth.special.AUS-corp.index": "translation-cs.json",
    "work-auth.special.CAN-corp.c2c": "translation-cs.json",
    "work-auth.special.CAN-corp.index": "translation-cs.json",
    "work-auth.special.CHN-corp.index": "translation-cs.json",
    "work-auth.special.CZE-corp.index": "translation-cs.json",
    "work-auth.special.DEU-corp.c2c": "translation-cs.json",
    "work-auth.special.DEU-corp.index": "translation-cs.json",
    "work-auth.special.DEU-ops.index": "translation-cs.json",
    "work-auth.special.ESP-corp.index": "translation-cs.json",
    "work-auth.special.GBR-corp.c2c": "translation-cs.json",
    "work-auth.special.GBR-corp.index": "translation-cs.json",
    "work-auth.special.GBR-ops.index": "translation-cs.json",
    "work-auth.special.IND-corp.index": "translation-cs.json",
    "work-auth.special.JPN-corp.index": "translation-cs.json",
    "work-auth.special.KOR-corp.index": "translation-cs.json",
    "work-auth.special.LUX-corp.index": "translation-cs.json",
    "work-auth.special.PHL-corp.index": "translation-cs.json",
    "work-auth.special.POL-corp.index": "translation-cs.json",
    "work-auth.special.PRI-corp.index": "translation-cs.json",
    "work-auth.special.SAU-corp.index": "translation-cs.json",
    "work-auth.special.SGP-corp.index": "translation-cs.json",
    "work-auth.special.USA-corp.c2c": "translation-cs.json",
    "work-auth.special.USA-corp.index": "translation-cs.json"
  },
  "cs-CZ": {
    "badge-upload-v1.index": "translation-cs.json",
    "bgc-p0.index": "translation-cs.json",
    "c2c-faq.index": "translation-cs.json",
    "catch-all.index": "translation-cs.json",
    "culture.index": "translation-cs.json",
    "dashboard.c2c": "translation-cs.json",
    "dashboard.index": "translation-cs.json",
    "drug-screen-status-v1.index": "translation-cs.json",
    "faq.index": "translation-cs.json",
    "fdie-v1.index": "translation-cs.json",
    "id-verification-v1.index": "translation-cs.json",
    "immigration-v1.index": "translation-cs.json",
    "introduce-yourself-v1.index": "translation-cs.json",
    "isp-v1.index": "translation-cs.json",
    "login-selection.index": "translation-cs.json",
    "medical-check-v1.index": "translation-cs.json",
    "mydocs-v1.c2c": "translation-cs.json",
    "mydocs-v1.index": "translation-cs.json",
    "nav.index": "translation-cs.json",
    "not-found.index": "translation-cs.json",
    "pdf.diversity-inclusion.index": "translation-cs.json",
    "pdf.innovative-culture.index": "translation-cs.json",
    "pdf.shareholder-letters.index": "translation-cs.json",
    "pic-v1.index": "translation-cs.json",
    "post-day-one.index": "translation-cs.json",
    "return-it-equipment-v1.index": "translation-cs.json",
    "shipping-address-v1.addressSuggestion": "translation-cs.json",
    "shipping-address-v1.assistiveTechnologies": "translation-cs.json",
    "shipping-address-v1.countries": "translation-cs.json",
    "shipping-address-v1.hardware": "translation-cs.json",
    "shipping-address-v1.index": "translation-cs.json",
    "shipping-address-v1.schema": "translation-cs.json",
    "shipping-address-v1.schemaAE": "translation-cs.json",
    "shipping-address-v1.schemaAU": "translation-cs.json",
    "shipping-address-v1.schemaCA": "translation-cs.json",
    "shipping-address-v1.schemaCR": "translation-cs.json",
    "shipping-address-v1.schemaDE": "translation-cs.json",
    "shipping-address-v1.schemaFR": "translation-cs.json",
    "shipping-address-v1.schemaGB": "translation-cs.json",
    "shipping-address-v1.schemaIN": "translation-cs.json",
    "shipping-address-v1.schemaIT": "translation-cs.json",
    "shipping-address-v1.schemaMX": "translation-cs.json",
    "shipping-address-v1.schemaSA": "translation-cs.json",
    "shipping-address-v1.schemaSG": "translation-cs.json",
    "shipping-address-v1.schemaUS": "translation-cs.json",
    "shipping-address-v1.states": "translation-cs.json",
    "shipping-address-v1.statesAU": "translation-cs.json",
    "shipping-address-v1.statesCA": "translation-cs.json",
    "shipping-address-v1.statesFR": "translation-cs.json",
    "shipping-address-v1.statesIN": "translation-cs.json",
    "shipping-address-v1.statesUS": "translation-cs.json",
    "shipping-address-v1.submissionOutcome": "translation-cs.json",
    "shipping-address-v1.validation": "translation-cs.json",
    "text.common.index": "translation-cs.json",
    "text.landing.index": "translation-cs.json",
    "text.page-footer.index": "translation-cs.json",
    "text.sub-footer.index": "translation-cs.json",
    "unauthorized.index": "translation-cs.json",
    "videos.amazon-14-leadership-principles-jeff-bezos.index": "translation-cs.json",
    "videos.amazon-ceo-jeff-bezos-it-is-always-day-one.index": "translation-cs.json",
    "videos.andy-jassy-ceo-aws.index": "translation-cs.json",
    "videos.aws-reinvent-2019-keynote-andy-jassy.index": "translation-cs.json",
    "videos.aws-reinvent-2020.index": "translation-cs.json",
    "videos.aws-reinvent-2021-compute.index": "translation-cs.json",
    "videos.aws-reinvent-2021-data-strategies.index": "translation-cs.json",
    "videos.aws-reinvent-2021-introduction.index": "translation-cs.json",
    "videos.aws-reinvent-2021-networking.index": "translation-cs.json",
    "videos.aws-reinvent-2021-transforming-industries.index": "translation-cs.json",
    "videos.aws-reinvent-2021.index": "translation-cs.json",
    "videos.aws-welcome-message-by-adam-selipsky.index": "translation-cs.json",
    "videos.early-look.index": "translation-cs.json",
    "videos.jeff-bezos-amazon-blue-origin.index": "translation-cs.json",
    "videos.shop-talk-jeff-wilke-fortune.index": "translation-cs.json",
    "videos.tenets-and-decisions.index": "translation-cs.json",
    "videos.virtuous-cycle.index": "translation-cs.json",
    "videos.welcome-to-aws.index": "translation-cs.json",
    "videos.what-is-aws.index": "translation-cs.json",
    "videos.what-is-cloud-computing.index": "translation-cs.json",
    "work-auth.USAcorp": "translation-cs.json",
    "work-auth.c2c": "translation-cs.json",
    "work-auth.default.c2c": "translation-cs.json",
    "work-auth.default.index": "translation-cs.json",
    "work-auth.generic.index": "translation-cs.json",
    "work-auth.index": "translation-cs.json",
    "work-auth.newHireDefault": "translation-cs.json",
    "work-auth.newHireGBR": "translation-cs.json",
    "work-auth.special.ARE-corp.index": "translation-cs.json",
    "work-auth.special.AUS-corp.index": "translation-cs.json",
    "work-auth.special.CAN-corp.c2c": "translation-cs.json",
    "work-auth.special.CAN-corp.index": "translation-cs.json",
    "work-auth.special.CHN-corp.index": "translation-cs.json",
    "work-auth.special.CZE-corp.index": "translation-cs.json",
    "work-auth.special.DEU-corp.c2c": "translation-cs.json",
    "work-auth.special.DEU-corp.index": "translation-cs.json",
    "work-auth.special.DEU-ops.index": "translation-cs.json",
    "work-auth.special.ESP-corp.index": "translation-cs.json",
    "work-auth.special.GBR-corp.c2c": "translation-cs.json",
    "work-auth.special.GBR-corp.index": "translation-cs.json",
    "work-auth.special.GBR-ops.index": "translation-cs.json",
    "work-auth.special.IND-corp.index": "translation-cs.json",
    "work-auth.special.JPN-corp.index": "translation-cs.json",
    "work-auth.special.KOR-corp.index": "translation-cs.json",
    "work-auth.special.LUX-corp.index": "translation-cs.json",
    "work-auth.special.PHL-corp.index": "translation-cs.json",
    "work-auth.special.POL-corp.index": "translation-cs.json",
    "work-auth.special.PRI-corp.index": "translation-cs.json",
    "work-auth.special.SAU-corp.index": "translation-cs.json",
    "work-auth.special.SGP-corp.index": "translation-cs.json",
    "work-auth.special.USA-corp.c2c": "translation-cs.json",
    "work-auth.special.USA-corp.index": "translation-cs.json"
  },
  de: {
    "badge-upload-v1.index": "translation-de.json",
    "bgc-p0.index": "translation-de.json",
    "c2c-faq.index": "translation-de.json",
    "catch-all.index": "translation-de.json",
    "culture.index": "translation-de.json",
    "dashboard.c2c": "translation-de.json",
    "dashboard.index": "translation-de.json",
    "drug-screen-status-v1.index": "translation-de.json",
    "faq.index": "translation-de.json",
    "fdie-v1.index": "translation-de.json",
    "id-verification-v1.index": "translation-de.json",
    "immigration-v1.index": "translation-de.json",
    "introduce-yourself-v1.index": "translation-de.json",
    "isp-v1.index": "translation-de.json",
    "login-selection.index": "translation-de.json",
    "medical-check-v1.index": "translation-de.json",
    "mydocs-v1.c2c": "translation-de.json",
    "mydocs-v1.index": "translation-de.json",
    "nav.index": "translation-de.json",
    "not-found.index": "translation-de.json",
    "pdf.diversity-inclusion.index": "translation-de.json",
    "pdf.innovative-culture.index": "translation-de.json",
    "pdf.shareholder-letters.index": "translation-de.json",
    "pic-v1.index": "translation-de.json",
    "post-day-one.index": "translation-de.json",
    "return-it-equipment-v1.index": "translation-de.json",
    "shipping-address-v1.addressSuggestion": "translation-de.json",
    "shipping-address-v1.assistiveTechnologies": "translation-de.json",
    "shipping-address-v1.countries": "translation-de.json",
    "shipping-address-v1.hardware": "translation-de.json",
    "shipping-address-v1.index": "translation-de.json",
    "shipping-address-v1.schema": "translation-de.json",
    "shipping-address-v1.schemaAE": "translation-de.json",
    "shipping-address-v1.schemaAU": "translation-de.json",
    "shipping-address-v1.schemaCA": "translation-de.json",
    "shipping-address-v1.schemaCR": "translation-de.json",
    "shipping-address-v1.schemaDE": "translation-de.json",
    "shipping-address-v1.schemaFR": "translation-de.json",
    "shipping-address-v1.schemaGB": "translation-de.json",
    "shipping-address-v1.schemaIN": "translation-de.json",
    "shipping-address-v1.schemaIT": "translation-de.json",
    "shipping-address-v1.schemaMX": "translation-de.json",
    "shipping-address-v1.schemaSA": "translation-de.json",
    "shipping-address-v1.schemaSG": "translation-de.json",
    "shipping-address-v1.schemaUS": "translation-de.json",
    "shipping-address-v1.states": "translation-de.json",
    "shipping-address-v1.statesAU": "translation-de.json",
    "shipping-address-v1.statesCA": "translation-de.json",
    "shipping-address-v1.statesFR": "translation-de.json",
    "shipping-address-v1.statesIN": "translation-de.json",
    "shipping-address-v1.statesUS": "translation-de.json",
    "shipping-address-v1.submissionOutcome": "translation-de.json",
    "shipping-address-v1.validation": "translation-de.json",
    "text.common.index": "translation-de.json",
    "text.landing.index": "translation-de.json",
    "text.page-footer.index": "translation-de.json",
    "text.sub-footer.index": "translation-de.json",
    "unauthorized.index": "translation-de.json",
    "videos.amazon-14-leadership-principles-jeff-bezos.index": "translation-de.json",
    "videos.amazon-ceo-jeff-bezos-it-is-always-day-one.index": "translation-de.json",
    "videos.andy-jassy-ceo-aws.index": "translation-de.json",
    "videos.aws-reinvent-2019-keynote-andy-jassy.index": "translation-de.json",
    "videos.aws-reinvent-2020.index": "translation-de.json",
    "videos.aws-reinvent-2021-compute.index": "translation-de.json",
    "videos.aws-reinvent-2021-data-strategies.index": "translation-de.json",
    "videos.aws-reinvent-2021-introduction.index": "translation-de.json",
    "videos.aws-reinvent-2021-networking.index": "translation-de.json",
    "videos.aws-reinvent-2021-transforming-industries.index": "translation-de.json",
    "videos.aws-reinvent-2021.index": "translation-de.json",
    "videos.aws-welcome-message-by-adam-selipsky.index": "translation-de.json",
    "videos.early-look.index": "translation-de.json",
    "videos.jeff-bezos-amazon-blue-origin.index": "translation-de.json",
    "videos.shop-talk-jeff-wilke-fortune.index": "translation-de.json",
    "videos.tenets-and-decisions.index": "translation-de.json",
    "videos.virtuous-cycle.index": "translation-de.json",
    "videos.welcome-to-aws.index": "translation-de.json",
    "videos.what-is-aws.index": "translation-de.json",
    "videos.what-is-cloud-computing.index": "translation-de.json",
    "work-auth.USAcorp": "translation-de.json",
    "work-auth.c2c": "translation-de.json",
    "work-auth.default.c2c": "translation-de.json",
    "work-auth.default.index": "translation-de.json",
    "work-auth.generic.index": "translation-de.json",
    "work-auth.index": "translation-de.json",
    "work-auth.newHireDefault": "translation-de.json",
    "work-auth.newHireGBR": "translation-de.json",
    "work-auth.special.ARE-corp.index": "translation-de.json",
    "work-auth.special.AUS-corp.index": "translation-de.json",
    "work-auth.special.CAN-corp.c2c": "translation-de.json",
    "work-auth.special.CAN-corp.index": "translation-de.json",
    "work-auth.special.CHN-corp.index": "translation-de.json",
    "work-auth.special.CZE-corp.index": "translation-de.json",
    "work-auth.special.DEU-corp.c2c": "translation-de.json",
    "work-auth.special.DEU-corp.index": "translation-de.json",
    "work-auth.special.DEU-ops.index": "translation-de.json",
    "work-auth.special.ESP-corp.index": "translation-de.json",
    "work-auth.special.GBR-corp.c2c": "translation-de.json",
    "work-auth.special.GBR-corp.index": "translation-de.json",
    "work-auth.special.GBR-ops.index": "translation-de.json",
    "work-auth.special.IND-corp.index": "translation-de.json",
    "work-auth.special.JPN-corp.index": "translation-de.json",
    "work-auth.special.KOR-corp.index": "translation-de.json",
    "work-auth.special.LUX-corp.index": "translation-de.json",
    "work-auth.special.PHL-corp.index": "translation-de.json",
    "work-auth.special.POL-corp.index": "translation-de.json",
    "work-auth.special.PRI-corp.index": "translation-de.json",
    "work-auth.special.SAU-corp.index": "translation-de.json",
    "work-auth.special.SGP-corp.index": "translation-de.json",
    "work-auth.special.USA-corp.c2c": "translation-de.json",
    "work-auth.special.USA-corp.index": "translation-de.json"
  },
  "de-DE": {
    "badge-upload-v1.index": "translation-de.json",
    "bgc-p0.index": "translation-de.json",
    "c2c-faq.index": "translation-de.json",
    "catch-all.index": "translation-de.json",
    "culture.index": "translation-de.json",
    "dashboard.c2c": "translation-de.json",
    "dashboard.index": "translation-de.json",
    "drug-screen-status-v1.index": "translation-de.json",
    "faq.index": "translation-de.json",
    "fdie-v1.index": "translation-de.json",
    "id-verification-v1.index": "translation-de.json",
    "immigration-v1.index": "translation-de.json",
    "introduce-yourself-v1.index": "translation-de.json",
    "isp-v1.index": "translation-de.json",
    "login-selection.index": "translation-de.json",
    "medical-check-v1.index": "translation-de.json",
    "mydocs-v1.c2c": "translation-de.json",
    "mydocs-v1.index": "translation-de.json",
    "nav.index": "translation-de.json",
    "not-found.index": "translation-de.json",
    "pdf.diversity-inclusion.index": "translation-de.json",
    "pdf.innovative-culture.index": "translation-de.json",
    "pdf.shareholder-letters.index": "translation-de.json",
    "pic-v1.index": "translation-de.json",
    "post-day-one.index": "translation-de.json",
    "return-it-equipment-v1.index": "translation-de.json",
    "shipping-address-v1.addressSuggestion": "translation-de.json",
    "shipping-address-v1.assistiveTechnologies": "translation-de.json",
    "shipping-address-v1.countries": "translation-de.json",
    "shipping-address-v1.hardware": "translation-de.json",
    "shipping-address-v1.index": "translation-de.json",
    "shipping-address-v1.schema": "translation-de.json",
    "shipping-address-v1.schemaAE": "translation-de.json",
    "shipping-address-v1.schemaAU": "translation-de.json",
    "shipping-address-v1.schemaCA": "translation-de.json",
    "shipping-address-v1.schemaCR": "translation-de.json",
    "shipping-address-v1.schemaDE": "translation-de.json",
    "shipping-address-v1.schemaFR": "translation-de.json",
    "shipping-address-v1.schemaGB": "translation-de.json",
    "shipping-address-v1.schemaIN": "translation-de.json",
    "shipping-address-v1.schemaIT": "translation-de.json",
    "shipping-address-v1.schemaMX": "translation-de.json",
    "shipping-address-v1.schemaSA": "translation-de.json",
    "shipping-address-v1.schemaSG": "translation-de.json",
    "shipping-address-v1.schemaUS": "translation-de.json",
    "shipping-address-v1.states": "translation-de.json",
    "shipping-address-v1.statesAU": "translation-de.json",
    "shipping-address-v1.statesCA": "translation-de.json",
    "shipping-address-v1.statesFR": "translation-de.json",
    "shipping-address-v1.statesIN": "translation-de.json",
    "shipping-address-v1.statesUS": "translation-de.json",
    "shipping-address-v1.submissionOutcome": "translation-de.json",
    "shipping-address-v1.validation": "translation-de.json",
    "text.common.index": "translation-de.json",
    "text.landing.index": "translation-de.json",
    "text.page-footer.index": "translation-de.json",
    "text.sub-footer.index": "translation-de.json",
    "unauthorized.index": "translation-de.json",
    "videos.amazon-14-leadership-principles-jeff-bezos.index": "translation-de.json",
    "videos.amazon-ceo-jeff-bezos-it-is-always-day-one.index": "translation-de.json",
    "videos.andy-jassy-ceo-aws.index": "translation-de.json",
    "videos.aws-reinvent-2019-keynote-andy-jassy.index": "translation-de.json",
    "videos.aws-reinvent-2020.index": "translation-de.json",
    "videos.aws-reinvent-2021-compute.index": "translation-de.json",
    "videos.aws-reinvent-2021-data-strategies.index": "translation-de.json",
    "videos.aws-reinvent-2021-introduction.index": "translation-de.json",
    "videos.aws-reinvent-2021-networking.index": "translation-de.json",
    "videos.aws-reinvent-2021-transforming-industries.index": "translation-de.json",
    "videos.aws-reinvent-2021.index": "translation-de.json",
    "videos.aws-welcome-message-by-adam-selipsky.index": "translation-de.json",
    "videos.early-look.index": "translation-de.json",
    "videos.jeff-bezos-amazon-blue-origin.index": "translation-de.json",
    "videos.shop-talk-jeff-wilke-fortune.index": "translation-de.json",
    "videos.tenets-and-decisions.index": "translation-de.json",
    "videos.virtuous-cycle.index": "translation-de.json",
    "videos.welcome-to-aws.index": "translation-de.json",
    "videos.what-is-aws.index": "translation-de.json",
    "videos.what-is-cloud-computing.index": "translation-de.json",
    "work-auth.USAcorp": "translation-de.json",
    "work-auth.c2c": "translation-de.json",
    "work-auth.default.c2c": "translation-de.json",
    "work-auth.default.index": "translation-de.json",
    "work-auth.generic.index": "translation-de.json",
    "work-auth.index": "translation-de.json",
    "work-auth.newHireDefault": "translation-de.json",
    "work-auth.newHireGBR": "translation-de.json",
    "work-auth.special.ARE-corp.index": "translation-de.json",
    "work-auth.special.AUS-corp.index": "translation-de.json",
    "work-auth.special.CAN-corp.c2c": "translation-de.json",
    "work-auth.special.CAN-corp.index": "translation-de.json",
    "work-auth.special.CHN-corp.index": "translation-de.json",
    "work-auth.special.CZE-corp.index": "translation-de.json",
    "work-auth.special.DEU-corp.c2c": "translation-de.json",
    "work-auth.special.DEU-corp.index": "translation-de.json",
    "work-auth.special.DEU-ops.index": "translation-de.json",
    "work-auth.special.ESP-corp.index": "translation-de.json",
    "work-auth.special.GBR-corp.c2c": "translation-de.json",
    "work-auth.special.GBR-corp.index": "translation-de.json",
    "work-auth.special.GBR-ops.index": "translation-de.json",
    "work-auth.special.IND-corp.index": "translation-de.json",
    "work-auth.special.JPN-corp.index": "translation-de.json",
    "work-auth.special.KOR-corp.index": "translation-de.json",
    "work-auth.special.LUX-corp.index": "translation-de.json",
    "work-auth.special.PHL-corp.index": "translation-de.json",
    "work-auth.special.POL-corp.index": "translation-de.json",
    "work-auth.special.PRI-corp.index": "translation-de.json",
    "work-auth.special.SAU-corp.index": "translation-de.json",
    "work-auth.special.SGP-corp.index": "translation-de.json",
    "work-auth.special.USA-corp.c2c": "translation-de.json",
    "work-auth.special.USA-corp.index": "translation-de.json"
  },
  en: {
    "badge-upload-v1.index": "translation-en.json",
    "bgc-p0.index": "translation-en.json",
    "c2c-faq.index": "translation-en.json",
    "catch-all.index": "translation-en.json",
    "culture.index": "translation-en.json",
    "dashboard.c2c": "translation-en.json",
    "dashboard.index": "translation-en.json",
    "drug-screen-status-v1.index": "translation-en.json",
    "faq.index": "translation-en.json",
    "fdie-v1.index": "translation-en.json",
    "id-verification-v1.index": "translation-en.json",
    "immigration-v1.index": "translation-en.json",
    "introduce-yourself-v1.index": "translation-en.json",
    "isp-v1.index": "translation-en.json",
    "login-selection.index": "translation-en.json",
    "medical-check-v1.index": "translation-en.json",
    "mydocs-v1.c2c": "translation-en.json",
    "mydocs-v1.index": "translation-en.json",
    "nav.index": "translation-en.json",
    "not-found.index": "translation-en.json",
    "pdf.diversity-inclusion.index": "translation-en.json",
    "pdf.innovative-culture.index": "translation-en.json",
    "pdf.shareholder-letters.index": "translation-en.json",
    "pic-v1.index": "translation-en.json",
    "post-day-one.index": "translation-en.json",
    "return-it-equipment-v1.index": "translation-en.json",
    "shipping-address-v1.addressSuggestion": "translation-en.json",
    "shipping-address-v1.assistiveTechnologies": "translation-en.json",
    "shipping-address-v1.countries": "translation-en.json",
    "shipping-address-v1.hardware": "translation-en.json",
    "shipping-address-v1.index": "translation-en.json",
    "shipping-address-v1.schema": "translation-en.json",
    "shipping-address-v1.schemaAE": "translation-en.json",
    "shipping-address-v1.schemaAU": "translation-en.json",
    "shipping-address-v1.schemaCA": "translation-en.json",
    "shipping-address-v1.schemaCR": "translation-en.json",
    "shipping-address-v1.schemaDE": "translation-en.json",
    "shipping-address-v1.schemaFR": "translation-en.json",
    "shipping-address-v1.schemaGB": "translation-en.json",
    "shipping-address-v1.schemaIN": "translation-en.json",
    "shipping-address-v1.schemaIT": "translation-en.json",
    "shipping-address-v1.schemaMX": "translation-en.json",
    "shipping-address-v1.schemaSA": "translation-en.json",
    "shipping-address-v1.schemaSG": "translation-en.json",
    "shipping-address-v1.schemaUS": "translation-en.json",
    "shipping-address-v1.states": "translation-en.json",
    "shipping-address-v1.statesAU": "translation-en.json",
    "shipping-address-v1.statesCA": "translation-en.json",
    "shipping-address-v1.statesFR": "translation-en.json",
    "shipping-address-v1.statesIN": "translation-en.json",
    "shipping-address-v1.statesUS": "translation-en.json",
    "shipping-address-v1.submissionOutcome": "translation-en.json",
    "shipping-address-v1.validation": "translation-en.json",
    "text.common.index": "translation-en.json",
    "text.landing.index": "translation-en.json",
    "text.page-footer.index": "translation-en.json",
    "text.sub-footer.index": "translation-en.json",
    "unauthorized.index": "translation-en.json",
    "videos.amazon-14-leadership-principles-jeff-bezos.index": "translation-en.json",
    "videos.amazon-ceo-jeff-bezos-it-is-always-day-one.index": "translation-en.json",
    "videos.andy-jassy-ceo-aws.index": "translation-en.json",
    "videos.aws-reinvent-2019-keynote-andy-jassy.index": "translation-en.json",
    "videos.aws-reinvent-2020.index": "translation-en.json",
    "videos.aws-reinvent-2021-compute.index": "translation-en.json",
    "videos.aws-reinvent-2021-data-strategies.index": "translation-en.json",
    "videos.aws-reinvent-2021-introduction.index": "translation-en.json",
    "videos.aws-reinvent-2021-networking.index": "translation-en.json",
    "videos.aws-reinvent-2021-transforming-industries.index": "translation-en.json",
    "videos.aws-reinvent-2021.index": "translation-en.json",
    "videos.aws-welcome-message-by-adam-selipsky.index": "translation-en.json",
    "videos.early-look.index": "translation-en.json",
    "videos.jeff-bezos-amazon-blue-origin.index": "translation-en.json",
    "videos.shop-talk-jeff-wilke-fortune.index": "translation-en.json",
    "videos.tenets-and-decisions.index": "translation-en.json",
    "videos.virtuous-cycle.index": "translation-en.json",
    "videos.welcome-to-aws.index": "translation-en.json",
    "videos.what-is-aws.index": "translation-en.json",
    "videos.what-is-cloud-computing.index": "translation-en.json",
    "work-auth.USAcorp": "translation-en.json",
    "work-auth.c2c": "translation-en.json",
    "work-auth.default.c2c": "translation-en.json",
    "work-auth.default.index": "translation-en.json",
    "work-auth.generic.index": "translation-en.json",
    "work-auth.index": "translation-en.json",
    "work-auth.newHireDefault": "translation-en.json",
    "work-auth.newHireGBR": "translation-en.json",
    "work-auth.special.ARE-corp.index": "translation-en.json",
    "work-auth.special.AUS-corp.index": "translation-en.json",
    "work-auth.special.CAN-corp.c2c": "translation-en.json",
    "work-auth.special.CAN-corp.index": "translation-en.json",
    "work-auth.special.CHN-corp.index": "translation-en.json",
    "work-auth.special.CZE-corp.index": "translation-en.json",
    "work-auth.special.DEU-corp.c2c": "translation-en.json",
    "work-auth.special.DEU-corp.index": "translation-en.json",
    "work-auth.special.DEU-ops.index": "translation-en.json",
    "work-auth.special.ESP-corp.index": "translation-en.json",
    "work-auth.special.GBR-corp.c2c": "translation-en.json",
    "work-auth.special.GBR-corp.index": "translation-en.json",
    "work-auth.special.GBR-ops.index": "translation-en.json",
    "work-auth.special.IND-corp.index": "translation-en.json",
    "work-auth.special.JPN-corp.index": "translation-en.json",
    "work-auth.special.KOR-corp.index": "translation-en.json",
    "work-auth.special.LUX-corp.index": "translation-en.json",
    "work-auth.special.PHL-corp.index": "translation-en.json",
    "work-auth.special.POL-corp.index": "translation-en.json",
    "work-auth.special.PRI-corp.index": "translation-en.json",
    "work-auth.special.SAU-corp.index": "translation-en.json",
    "work-auth.special.SGP-corp.index": "translation-en.json",
    "work-auth.special.USA-corp.c2c": "translation-en.json",
    "work-auth.special.USA-corp.index": "translation-en.json"
  },
  "en-US": {
    "badge-upload-v1.index": "translation-en.json",
    "bgc-p0.index": "translation-en.json",
    "c2c-faq.index": "translation-en.json",
    "catch-all.index": "translation-en.json",
    "culture.index": "translation-en.json",
    "dashboard.c2c": "translation-en.json",
    "dashboard.index": "translation-en.json",
    "drug-screen-status-v1.index": "translation-en.json",
    "faq.index": "translation-en.json",
    "fdie-v1.index": "translation-en.json",
    "id-verification-v1.index": "translation-en.json",
    "immigration-v1.index": "translation-en.json",
    "introduce-yourself-v1.index": "translation-en.json",
    "isp-v1.index": "translation-en.json",
    "login-selection.index": "translation-en.json",
    "medical-check-v1.index": "translation-en.json",
    "mydocs-v1.c2c": "translation-en.json",
    "mydocs-v1.index": "translation-en.json",
    "nav.index": "translation-en.json",
    "not-found.index": "translation-en.json",
    "pdf.diversity-inclusion.index": "translation-en.json",
    "pdf.innovative-culture.index": "translation-en.json",
    "pdf.shareholder-letters.index": "translation-en.json",
    "pic-v1.index": "translation-en.json",
    "post-day-one.index": "translation-en.json",
    "return-it-equipment-v1.index": "translation-en.json",
    "shipping-address-v1.addressSuggestion": "translation-en.json",
    "shipping-address-v1.assistiveTechnologies": "translation-en.json",
    "shipping-address-v1.countries": "translation-en.json",
    "shipping-address-v1.hardware": "translation-en.json",
    "shipping-address-v1.index": "translation-en.json",
    "shipping-address-v1.schema": "translation-en.json",
    "shipping-address-v1.schemaAE": "translation-en.json",
    "shipping-address-v1.schemaAU": "translation-en.json",
    "shipping-address-v1.schemaCA": "translation-en.json",
    "shipping-address-v1.schemaCR": "translation-en.json",
    "shipping-address-v1.schemaDE": "translation-en.json",
    "shipping-address-v1.schemaFR": "translation-en.json",
    "shipping-address-v1.schemaGB": "translation-en.json",
    "shipping-address-v1.schemaIN": "translation-en.json",
    "shipping-address-v1.schemaIT": "translation-en.json",
    "shipping-address-v1.schemaMX": "translation-en.json",
    "shipping-address-v1.schemaSA": "translation-en.json",
    "shipping-address-v1.schemaSG": "translation-en.json",
    "shipping-address-v1.schemaUS": "translation-en.json",
    "shipping-address-v1.states": "translation-en.json",
    "shipping-address-v1.statesAU": "translation-en.json",
    "shipping-address-v1.statesCA": "translation-en.json",
    "shipping-address-v1.statesFR": "translation-en.json",
    "shipping-address-v1.statesIN": "translation-en.json",
    "shipping-address-v1.statesUS": "translation-en.json",
    "shipping-address-v1.submissionOutcome": "translation-en.json",
    "shipping-address-v1.validation": "translation-en.json",
    "text.common.index": "translation-en.json",
    "text.landing.index": "translation-en.json",
    "text.page-footer.index": "translation-en.json",
    "text.sub-footer.index": "translation-en.json",
    "unauthorized.index": "translation-en.json",
    "videos.amazon-14-leadership-principles-jeff-bezos.index": "translation-en.json",
    "videos.amazon-ceo-jeff-bezos-it-is-always-day-one.index": "translation-en.json",
    "videos.andy-jassy-ceo-aws.index": "translation-en.json",
    "videos.aws-reinvent-2019-keynote-andy-jassy.index": "translation-en.json",
    "videos.aws-reinvent-2020.index": "translation-en.json",
    "videos.aws-reinvent-2021-compute.index": "translation-en.json",
    "videos.aws-reinvent-2021-data-strategies.index": "translation-en.json",
    "videos.aws-reinvent-2021-introduction.index": "translation-en.json",
    "videos.aws-reinvent-2021-networking.index": "translation-en.json",
    "videos.aws-reinvent-2021-transforming-industries.index": "translation-en.json",
    "videos.aws-reinvent-2021.index": "translation-en.json",
    "videos.aws-welcome-message-by-adam-selipsky.index": "translation-en.json",
    "videos.early-look.index": "translation-en.json",
    "videos.jeff-bezos-amazon-blue-origin.index": "translation-en.json",
    "videos.shop-talk-jeff-wilke-fortune.index": "translation-en.json",
    "videos.tenets-and-decisions.index": "translation-en.json",
    "videos.virtuous-cycle.index": "translation-en.json",
    "videos.welcome-to-aws.index": "translation-en.json",
    "videos.what-is-aws.index": "translation-en.json",
    "videos.what-is-cloud-computing.index": "translation-en.json",
    "work-auth.USAcorp": "translation-en.json",
    "work-auth.c2c": "translation-en.json",
    "work-auth.default.c2c": "translation-en.json",
    "work-auth.default.index": "translation-en.json",
    "work-auth.generic.index": "translation-en.json",
    "work-auth.index": "translation-en.json",
    "work-auth.newHireDefault": "translation-en.json",
    "work-auth.newHireGBR": "translation-en.json",
    "work-auth.special.ARE-corp.index": "translation-en.json",
    "work-auth.special.AUS-corp.index": "translation-en.json",
    "work-auth.special.CAN-corp.c2c": "translation-en.json",
    "work-auth.special.CAN-corp.index": "translation-en.json",
    "work-auth.special.CHN-corp.index": "translation-en.json",
    "work-auth.special.CZE-corp.index": "translation-en.json",
    "work-auth.special.DEU-corp.c2c": "translation-en.json",
    "work-auth.special.DEU-corp.index": "translation-en.json",
    "work-auth.special.DEU-ops.index": "translation-en.json",
    "work-auth.special.ESP-corp.index": "translation-en.json",
    "work-auth.special.GBR-corp.c2c": "translation-en.json",
    "work-auth.special.GBR-corp.index": "translation-en.json",
    "work-auth.special.GBR-ops.index": "translation-en.json",
    "work-auth.special.IND-corp.index": "translation-en.json",
    "work-auth.special.JPN-corp.index": "translation-en.json",
    "work-auth.special.KOR-corp.index": "translation-en.json",
    "work-auth.special.LUX-corp.index": "translation-en.json",
    "work-auth.special.PHL-corp.index": "translation-en.json",
    "work-auth.special.POL-corp.index": "translation-en.json",
    "work-auth.special.PRI-corp.index": "translation-en.json",
    "work-auth.special.SAU-corp.index": "translation-en.json",
    "work-auth.special.SGP-corp.index": "translation-en.json",
    "work-auth.special.USA-corp.c2c": "translation-en.json",
    "work-auth.special.USA-corp.index": "translation-en.json"
  },
  es: {
    "badge-upload-v1.index": "translation-es.json",
    "bgc-p0.index": "translation-es.json",
    "c2c-faq.index": "translation-es.json",
    "catch-all.index": "translation-es.json",
    "culture.index": "translation-es.json",
    "dashboard.c2c": "translation-es.json",
    "dashboard.index": "translation-es.json",
    "drug-screen-status-v1.index": "translation-es.json",
    "faq.index": "translation-es.json",
    "fdie-v1.index": "translation-es.json",
    "id-verification-v1.index": "translation-es.json",
    "immigration-v1.index": "translation-es.json",
    "introduce-yourself-v1.index": "translation-es.json",
    "isp-v1.index": "translation-es.json",
    "login-selection.index": "translation-es.json",
    "medical-check-v1.index": "translation-es.json",
    "mydocs-v1.c2c": "translation-es.json",
    "mydocs-v1.index": "translation-es.json",
    "nav.index": "translation-es.json",
    "not-found.index": "translation-es.json",
    "pdf.diversity-inclusion.index": "translation-es.json",
    "pdf.innovative-culture.index": "translation-es.json",
    "pdf.shareholder-letters.index": "translation-es.json",
    "pic-v1.index": "translation-es.json",
    "post-day-one.index": "translation-es.json",
    "return-it-equipment-v1.index": "translation-es.json",
    "shipping-address-v1.addressSuggestion": "translation-es.json",
    "shipping-address-v1.assistiveTechnologies": "translation-es.json",
    "shipping-address-v1.countries": "translation-es.json",
    "shipping-address-v1.hardware": "translation-es.json",
    "shipping-address-v1.index": "translation-es.json",
    "shipping-address-v1.schema": "translation-es.json",
    "shipping-address-v1.schemaAE": "translation-es.json",
    "shipping-address-v1.schemaAU": "translation-es.json",
    "shipping-address-v1.schemaCA": "translation-es.json",
    "shipping-address-v1.schemaCR": "translation-es.json",
    "shipping-address-v1.schemaDE": "translation-es.json",
    "shipping-address-v1.schemaFR": "translation-es.json",
    "shipping-address-v1.schemaGB": "translation-es.json",
    "shipping-address-v1.schemaIN": "translation-es.json",
    "shipping-address-v1.schemaIT": "translation-es.json",
    "shipping-address-v1.schemaMX": "translation-es.json",
    "shipping-address-v1.schemaSA": "translation-es.json",
    "shipping-address-v1.schemaSG": "translation-es.json",
    "shipping-address-v1.schemaUS": "translation-es.json",
    "shipping-address-v1.states": "translation-es.json",
    "shipping-address-v1.statesAU": "translation-es.json",
    "shipping-address-v1.statesCA": "translation-es.json",
    "shipping-address-v1.statesFR": "translation-es.json",
    "shipping-address-v1.statesIN": "translation-es.json",
    "shipping-address-v1.statesUS": "translation-es.json",
    "shipping-address-v1.submissionOutcome": "translation-es.json",
    "shipping-address-v1.validation": "translation-es.json",
    "text.common.index": "translation-es.json",
    "text.landing.index": "translation-es.json",
    "text.page-footer.index": "translation-es.json",
    "text.sub-footer.index": "translation-es.json",
    "unauthorized.index": "translation-es.json",
    "videos.amazon-14-leadership-principles-jeff-bezos.index": "translation-es.json",
    "videos.amazon-ceo-jeff-bezos-it-is-always-day-one.index": "translation-es.json",
    "videos.andy-jassy-ceo-aws.index": "translation-es.json",
    "videos.aws-reinvent-2019-keynote-andy-jassy.index": "translation-es.json",
    "videos.aws-reinvent-2020.index": "translation-es.json",
    "videos.aws-reinvent-2021-compute.index": "translation-es.json",
    "videos.aws-reinvent-2021-data-strategies.index": "translation-es.json",
    "videos.aws-reinvent-2021-introduction.index": "translation-es.json",
    "videos.aws-reinvent-2021-networking.index": "translation-es.json",
    "videos.aws-reinvent-2021-transforming-industries.index": "translation-es.json",
    "videos.aws-reinvent-2021.index": "translation-es.json",
    "videos.aws-welcome-message-by-adam-selipsky.index": "translation-es.json",
    "videos.early-look.index": "translation-es.json",
    "videos.jeff-bezos-amazon-blue-origin.index": "translation-es.json",
    "videos.shop-talk-jeff-wilke-fortune.index": "translation-es.json",
    "videos.tenets-and-decisions.index": "translation-es.json",
    "videos.virtuous-cycle.index": "translation-es.json",
    "videos.welcome-to-aws.index": "translation-es.json",
    "videos.what-is-aws.index": "translation-es.json",
    "videos.what-is-cloud-computing.index": "translation-es.json",
    "work-auth.USAcorp": "translation-es.json",
    "work-auth.c2c": "translation-es.json",
    "work-auth.default.c2c": "translation-es.json",
    "work-auth.default.index": "translation-es.json",
    "work-auth.generic.index": "translation-es.json",
    "work-auth.index": "translation-es.json",
    "work-auth.newHireDefault": "translation-es.json",
    "work-auth.newHireGBR": "translation-es.json",
    "work-auth.special.ARE-corp.index": "translation-es.json",
    "work-auth.special.AUS-corp.index": "translation-es.json",
    "work-auth.special.CAN-corp.c2c": "translation-es.json",
    "work-auth.special.CAN-corp.index": "translation-es.json",
    "work-auth.special.CHN-corp.index": "translation-es.json",
    "work-auth.special.CZE-corp.index": "translation-es.json",
    "work-auth.special.DEU-corp.c2c": "translation-es.json",
    "work-auth.special.DEU-corp.index": "translation-es.json",
    "work-auth.special.DEU-ops.index": "translation-es.json",
    "work-auth.special.ESP-corp.index": "translation-es.json",
    "work-auth.special.GBR-corp.c2c": "translation-es.json",
    "work-auth.special.GBR-corp.index": "translation-es.json",
    "work-auth.special.GBR-ops.index": "translation-es.json",
    "work-auth.special.IND-corp.index": "translation-es.json",
    "work-auth.special.JPN-corp.index": "translation-es.json",
    "work-auth.special.KOR-corp.index": "translation-es.json",
    "work-auth.special.LUX-corp.index": "translation-es.json",
    "work-auth.special.PHL-corp.index": "translation-es.json",
    "work-auth.special.POL-corp.index": "translation-es.json",
    "work-auth.special.PRI-corp.index": "translation-es.json",
    "work-auth.special.SAU-corp.index": "translation-es.json",
    "work-auth.special.SGP-corp.index": "translation-es.json",
    "work-auth.special.USA-corp.c2c": "translation-es.json",
    "work-auth.special.USA-corp.index": "translation-es.json"
  },
  "es-ES": {
    "badge-upload-v1.index": "translation-es.json",
    "bgc-p0.index": "translation-es.json",
    "c2c-faq.index": "translation-es.json",
    "catch-all.index": "translation-es.json",
    "culture.index": "translation-es.json",
    "dashboard.c2c": "translation-es.json",
    "dashboard.index": "translation-es.json",
    "drug-screen-status-v1.index": "translation-es.json",
    "faq.index": "translation-es.json",
    "fdie-v1.index": "translation-es.json",
    "id-verification-v1.index": "translation-es.json",
    "immigration-v1.index": "translation-es.json",
    "introduce-yourself-v1.index": "translation-es.json",
    "isp-v1.index": "translation-es.json",
    "login-selection.index": "translation-es.json",
    "medical-check-v1.index": "translation-es.json",
    "mydocs-v1.c2c": "translation-es.json",
    "mydocs-v1.index": "translation-es.json",
    "nav.index": "translation-es.json",
    "not-found.index": "translation-es.json",
    "pdf.diversity-inclusion.index": "translation-es.json",
    "pdf.innovative-culture.index": "translation-es.json",
    "pdf.shareholder-letters.index": "translation-es.json",
    "pic-v1.index": "translation-es.json",
    "post-day-one.index": "translation-es.json",
    "return-it-equipment-v1.index": "translation-es.json",
    "shipping-address-v1.addressSuggestion": "translation-es.json",
    "shipping-address-v1.assistiveTechnologies": "translation-es.json",
    "shipping-address-v1.countries": "translation-es.json",
    "shipping-address-v1.hardware": "translation-es.json",
    "shipping-address-v1.index": "translation-es.json",
    "shipping-address-v1.schema": "translation-es.json",
    "shipping-address-v1.schemaAE": "translation-es.json",
    "shipping-address-v1.schemaAU": "translation-es.json",
    "shipping-address-v1.schemaCA": "translation-es.json",
    "shipping-address-v1.schemaCR": "translation-es.json",
    "shipping-address-v1.schemaDE": "translation-es.json",
    "shipping-address-v1.schemaFR": "translation-es.json",
    "shipping-address-v1.schemaGB": "translation-es.json",
    "shipping-address-v1.schemaIN": "translation-es.json",
    "shipping-address-v1.schemaIT": "translation-es.json",
    "shipping-address-v1.schemaMX": "translation-es.json",
    "shipping-address-v1.schemaSA": "translation-es.json",
    "shipping-address-v1.schemaSG": "translation-es.json",
    "shipping-address-v1.schemaUS": "translation-es.json",
    "shipping-address-v1.states": "translation-es.json",
    "shipping-address-v1.statesAU": "translation-es.json",
    "shipping-address-v1.statesCA": "translation-es.json",
    "shipping-address-v1.statesFR": "translation-es.json",
    "shipping-address-v1.statesIN": "translation-es.json",
    "shipping-address-v1.statesUS": "translation-es.json",
    "shipping-address-v1.submissionOutcome": "translation-es.json",
    "shipping-address-v1.validation": "translation-es.json",
    "text.common.index": "translation-es.json",
    "text.landing.index": "translation-es.json",
    "text.page-footer.index": "translation-es.json",
    "text.sub-footer.index": "translation-es.json",
    "unauthorized.index": "translation-es.json",
    "videos.amazon-14-leadership-principles-jeff-bezos.index": "translation-es.json",
    "videos.amazon-ceo-jeff-bezos-it-is-always-day-one.index": "translation-es.json",
    "videos.andy-jassy-ceo-aws.index": "translation-es.json",
    "videos.aws-reinvent-2019-keynote-andy-jassy.index": "translation-es.json",
    "videos.aws-reinvent-2020.index": "translation-es.json",
    "videos.aws-reinvent-2021-compute.index": "translation-es.json",
    "videos.aws-reinvent-2021-data-strategies.index": "translation-es.json",
    "videos.aws-reinvent-2021-introduction.index": "translation-es.json",
    "videos.aws-reinvent-2021-networking.index": "translation-es.json",
    "videos.aws-reinvent-2021-transforming-industries.index": "translation-es.json",
    "videos.aws-reinvent-2021.index": "translation-es.json",
    "videos.aws-welcome-message-by-adam-selipsky.index": "translation-es.json",
    "videos.early-look.index": "translation-es.json",
    "videos.jeff-bezos-amazon-blue-origin.index": "translation-es.json",
    "videos.shop-talk-jeff-wilke-fortune.index": "translation-es.json",
    "videos.tenets-and-decisions.index": "translation-es.json",
    "videos.virtuous-cycle.index": "translation-es.json",
    "videos.welcome-to-aws.index": "translation-es.json",
    "videos.what-is-aws.index": "translation-es.json",
    "videos.what-is-cloud-computing.index": "translation-es.json",
    "work-auth.USAcorp": "translation-es.json",
    "work-auth.c2c": "translation-es.json",
    "work-auth.default.c2c": "translation-es.json",
    "work-auth.default.index": "translation-es.json",
    "work-auth.generic.index": "translation-es.json",
    "work-auth.index": "translation-es.json",
    "work-auth.newHireDefault": "translation-es.json",
    "work-auth.newHireGBR": "translation-es.json",
    "work-auth.special.ARE-corp.index": "translation-es.json",
    "work-auth.special.AUS-corp.index": "translation-es.json",
    "work-auth.special.CAN-corp.c2c": "translation-es.json",
    "work-auth.special.CAN-corp.index": "translation-es.json",
    "work-auth.special.CHN-corp.index": "translation-es.json",
    "work-auth.special.CZE-corp.index": "translation-es.json",
    "work-auth.special.DEU-corp.c2c": "translation-es.json",
    "work-auth.special.DEU-corp.index": "translation-es.json",
    "work-auth.special.DEU-ops.index": "translation-es.json",
    "work-auth.special.ESP-corp.index": "translation-es.json",
    "work-auth.special.GBR-corp.c2c": "translation-es.json",
    "work-auth.special.GBR-corp.index": "translation-es.json",
    "work-auth.special.GBR-ops.index": "translation-es.json",
    "work-auth.special.IND-corp.index": "translation-es.json",
    "work-auth.special.JPN-corp.index": "translation-es.json",
    "work-auth.special.KOR-corp.index": "translation-es.json",
    "work-auth.special.LUX-corp.index": "translation-es.json",
    "work-auth.special.PHL-corp.index": "translation-es.json",
    "work-auth.special.POL-corp.index": "translation-es.json",
    "work-auth.special.PRI-corp.index": "translation-es.json",
    "work-auth.special.SAU-corp.index": "translation-es.json",
    "work-auth.special.SGP-corp.index": "translation-es.json",
    "work-auth.special.USA-corp.c2c": "translation-es.json",
    "work-auth.special.USA-corp.index": "translation-es.json"
  },
  fr: {
    "badge-upload-v1.index": "translation-fr.json",
    "bgc-p0.index": "translation-fr.json",
    "c2c-faq.index": "translation-fr.json",
    "catch-all.index": "translation-fr.json",
    "culture.index": "translation-fr.json",
    "dashboard.c2c": "translation-fr.json",
    "dashboard.index": "translation-fr.json",
    "drug-screen-status-v1.index": "translation-fr.json",
    "faq.index": "translation-fr.json",
    "fdie-v1.index": "translation-fr.json",
    "id-verification-v1.index": "translation-fr.json",
    "immigration-v1.index": "translation-fr.json",
    "introduce-yourself-v1.index": "translation-fr.json",
    "isp-v1.index": "translation-fr.json",
    "login-selection.index": "translation-fr.json",
    "medical-check-v1.index": "translation-fr.json",
    "mydocs-v1.c2c": "translation-fr.json",
    "mydocs-v1.index": "translation-fr.json",
    "nav.index": "translation-fr.json",
    "not-found.index": "translation-fr.json",
    "pdf.diversity-inclusion.index": "translation-fr.json",
    "pdf.innovative-culture.index": "translation-fr.json",
    "pdf.shareholder-letters.index": "translation-fr.json",
    "pic-v1.index": "translation-fr.json",
    "post-day-one.index": "translation-fr.json",
    "return-it-equipment-v1.index": "translation-fr.json",
    "shipping-address-v1.addressSuggestion": "translation-fr.json",
    "shipping-address-v1.assistiveTechnologies": "translation-fr.json",
    "shipping-address-v1.countries": "translation-fr.json",
    "shipping-address-v1.hardware": "translation-fr.json",
    "shipping-address-v1.index": "translation-fr.json",
    "shipping-address-v1.schema": "translation-fr.json",
    "shipping-address-v1.schemaAE": "translation-fr.json",
    "shipping-address-v1.schemaAU": "translation-fr.json",
    "shipping-address-v1.schemaCA": "translation-fr.json",
    "shipping-address-v1.schemaCR": "translation-fr.json",
    "shipping-address-v1.schemaDE": "translation-fr.json",
    "shipping-address-v1.schemaFR": "translation-fr.json",
    "shipping-address-v1.schemaGB": "translation-fr.json",
    "shipping-address-v1.schemaIN": "translation-fr.json",
    "shipping-address-v1.schemaIT": "translation-fr.json",
    "shipping-address-v1.schemaMX": "translation-fr.json",
    "shipping-address-v1.schemaSA": "translation-fr.json",
    "shipping-address-v1.schemaSG": "translation-fr.json",
    "shipping-address-v1.schemaUS": "translation-fr.json",
    "shipping-address-v1.states": "translation-fr.json",
    "shipping-address-v1.statesAU": "translation-fr.json",
    "shipping-address-v1.statesCA": "translation-fr.json",
    "shipping-address-v1.statesFR": "translation-fr.json",
    "shipping-address-v1.statesIN": "translation-fr.json",
    "shipping-address-v1.statesUS": "translation-fr.json",
    "shipping-address-v1.submissionOutcome": "translation-fr.json",
    "shipping-address-v1.validation": "translation-fr.json",
    "text.common.index": "translation-fr.json",
    "text.landing.index": "translation-fr.json",
    "text.page-footer.index": "translation-fr.json",
    "text.sub-footer.index": "translation-fr.json",
    "unauthorized.index": "translation-fr.json",
    "videos.amazon-14-leadership-principles-jeff-bezos.index": "translation-fr.json",
    "videos.amazon-ceo-jeff-bezos-it-is-always-day-one.index": "translation-fr.json",
    "videos.andy-jassy-ceo-aws.index": "translation-fr.json",
    "videos.aws-reinvent-2019-keynote-andy-jassy.index": "translation-fr.json",
    "videos.aws-reinvent-2020.index": "translation-fr.json",
    "videos.aws-reinvent-2021-compute.index": "translation-fr.json",
    "videos.aws-reinvent-2021-data-strategies.index": "translation-fr.json",
    "videos.aws-reinvent-2021-introduction.index": "translation-fr.json",
    "videos.aws-reinvent-2021-networking.index": "translation-fr.json",
    "videos.aws-reinvent-2021-transforming-industries.index": "translation-fr.json",
    "videos.aws-reinvent-2021.index": "translation-fr.json",
    "videos.aws-welcome-message-by-adam-selipsky.index": "translation-fr.json",
    "videos.early-look.index": "translation-fr.json",
    "videos.jeff-bezos-amazon-blue-origin.index": "translation-fr.json",
    "videos.shop-talk-jeff-wilke-fortune.index": "translation-fr.json",
    "videos.tenets-and-decisions.index": "translation-fr.json",
    "videos.virtuous-cycle.index": "translation-fr.json",
    "videos.welcome-to-aws.index": "translation-fr.json",
    "videos.what-is-aws.index": "translation-fr.json",
    "videos.what-is-cloud-computing.index": "translation-fr.json",
    "work-auth.USAcorp": "translation-fr.json",
    "work-auth.c2c": "translation-fr.json",
    "work-auth.default.c2c": "translation-fr.json",
    "work-auth.default.index": "translation-fr.json",
    "work-auth.generic.index": "translation-fr.json",
    "work-auth.index": "translation-fr.json",
    "work-auth.newHireDefault": "translation-fr.json",
    "work-auth.newHireGBR": "translation-fr.json",
    "work-auth.special.ARE-corp.index": "translation-fr.json",
    "work-auth.special.AUS-corp.index": "translation-fr.json",
    "work-auth.special.CAN-corp.c2c": "translation-fr.json",
    "work-auth.special.CAN-corp.index": "translation-fr.json",
    "work-auth.special.CHN-corp.index": "translation-fr.json",
    "work-auth.special.CZE-corp.index": "translation-fr.json",
    "work-auth.special.DEU-corp.c2c": "translation-fr.json",
    "work-auth.special.DEU-corp.index": "translation-fr.json",
    "work-auth.special.DEU-ops.index": "translation-fr.json",
    "work-auth.special.ESP-corp.index": "translation-fr.json",
    "work-auth.special.GBR-corp.c2c": "translation-fr.json",
    "work-auth.special.GBR-corp.index": "translation-fr.json",
    "work-auth.special.GBR-ops.index": "translation-fr.json",
    "work-auth.special.IND-corp.index": "translation-fr.json",
    "work-auth.special.JPN-corp.index": "translation-fr.json",
    "work-auth.special.KOR-corp.index": "translation-fr.json",
    "work-auth.special.LUX-corp.index": "translation-fr.json",
    "work-auth.special.PHL-corp.index": "translation-fr.json",
    "work-auth.special.POL-corp.index": "translation-fr.json",
    "work-auth.special.PRI-corp.index": "translation-fr.json",
    "work-auth.special.SAU-corp.index": "translation-fr.json",
    "work-auth.special.SGP-corp.index": "translation-fr.json",
    "work-auth.special.USA-corp.c2c": "translation-fr.json",
    "work-auth.special.USA-corp.index": "translation-fr.json"
  },
  "fr-FR": {
    "badge-upload-v1.index": "translation-fr.json",
    "bgc-p0.index": "translation-fr.json",
    "c2c-faq.index": "translation-fr.json",
    "catch-all.index": "translation-fr.json",
    "culture.index": "translation-fr.json",
    "dashboard.c2c": "translation-fr.json",
    "dashboard.index": "translation-fr.json",
    "drug-screen-status-v1.index": "translation-fr.json",
    "faq.index": "translation-fr.json",
    "fdie-v1.index": "translation-fr.json",
    "id-verification-v1.index": "translation-fr.json",
    "immigration-v1.index": "translation-fr.json",
    "introduce-yourself-v1.index": "translation-fr.json",
    "isp-v1.index": "translation-fr.json",
    "login-selection.index": "translation-fr.json",
    "medical-check-v1.index": "translation-fr.json",
    "mydocs-v1.c2c": "translation-fr.json",
    "mydocs-v1.index": "translation-fr.json",
    "nav.index": "translation-fr.json",
    "not-found.index": "translation-fr.json",
    "pdf.diversity-inclusion.index": "translation-fr.json",
    "pdf.innovative-culture.index": "translation-fr.json",
    "pdf.shareholder-letters.index": "translation-fr.json",
    "pic-v1.index": "translation-fr.json",
    "post-day-one.index": "translation-fr.json",
    "return-it-equipment-v1.index": "translation-fr.json",
    "shipping-address-v1.addressSuggestion": "translation-fr.json",
    "shipping-address-v1.assistiveTechnologies": "translation-fr.json",
    "shipping-address-v1.countries": "translation-fr.json",
    "shipping-address-v1.hardware": "translation-fr.json",
    "shipping-address-v1.index": "translation-fr.json",
    "shipping-address-v1.schema": "translation-fr.json",
    "shipping-address-v1.schemaAE": "translation-fr.json",
    "shipping-address-v1.schemaAU": "translation-fr.json",
    "shipping-address-v1.schemaCA": "translation-fr.json",
    "shipping-address-v1.schemaCR": "translation-fr.json",
    "shipping-address-v1.schemaDE": "translation-fr.json",
    "shipping-address-v1.schemaFR": "translation-fr.json",
    "shipping-address-v1.schemaGB": "translation-fr.json",
    "shipping-address-v1.schemaIN": "translation-fr.json",
    "shipping-address-v1.schemaIT": "translation-fr.json",
    "shipping-address-v1.schemaMX": "translation-fr.json",
    "shipping-address-v1.schemaSA": "translation-fr.json",
    "shipping-address-v1.schemaSG": "translation-fr.json",
    "shipping-address-v1.schemaUS": "translation-fr.json",
    "shipping-address-v1.states": "translation-fr.json",
    "shipping-address-v1.statesAU": "translation-fr.json",
    "shipping-address-v1.statesCA": "translation-fr.json",
    "shipping-address-v1.statesFR": "translation-fr.json",
    "shipping-address-v1.statesIN": "translation-fr.json",
    "shipping-address-v1.statesUS": "translation-fr.json",
    "shipping-address-v1.submissionOutcome": "translation-fr.json",
    "shipping-address-v1.validation": "translation-fr.json",
    "text.common.index": "translation-fr.json",
    "text.landing.index": "translation-fr.json",
    "text.page-footer.index": "translation-fr.json",
    "text.sub-footer.index": "translation-fr.json",
    "unauthorized.index": "translation-fr.json",
    "videos.amazon-14-leadership-principles-jeff-bezos.index": "translation-fr.json",
    "videos.amazon-ceo-jeff-bezos-it-is-always-day-one.index": "translation-fr.json",
    "videos.andy-jassy-ceo-aws.index": "translation-fr.json",
    "videos.aws-reinvent-2019-keynote-andy-jassy.index": "translation-fr.json",
    "videos.aws-reinvent-2020.index": "translation-fr.json",
    "videos.aws-reinvent-2021-compute.index": "translation-fr.json",
    "videos.aws-reinvent-2021-data-strategies.index": "translation-fr.json",
    "videos.aws-reinvent-2021-introduction.index": "translation-fr.json",
    "videos.aws-reinvent-2021-networking.index": "translation-fr.json",
    "videos.aws-reinvent-2021-transforming-industries.index": "translation-fr.json",
    "videos.aws-reinvent-2021.index": "translation-fr.json",
    "videos.aws-welcome-message-by-adam-selipsky.index": "translation-fr.json",
    "videos.early-look.index": "translation-fr.json",
    "videos.jeff-bezos-amazon-blue-origin.index": "translation-fr.json",
    "videos.shop-talk-jeff-wilke-fortune.index": "translation-fr.json",
    "videos.tenets-and-decisions.index": "translation-fr.json",
    "videos.virtuous-cycle.index": "translation-fr.json",
    "videos.welcome-to-aws.index": "translation-fr.json",
    "videos.what-is-aws.index": "translation-fr.json",
    "videos.what-is-cloud-computing.index": "translation-fr.json",
    "work-auth.USAcorp": "translation-fr.json",
    "work-auth.c2c": "translation-fr.json",
    "work-auth.default.c2c": "translation-fr.json",
    "work-auth.default.index": "translation-fr.json",
    "work-auth.generic.index": "translation-fr.json",
    "work-auth.index": "translation-fr.json",
    "work-auth.newHireDefault": "translation-fr.json",
    "work-auth.newHireGBR": "translation-fr.json",
    "work-auth.special.ARE-corp.index": "translation-fr.json",
    "work-auth.special.AUS-corp.index": "translation-fr.json",
    "work-auth.special.CAN-corp.c2c": "translation-fr.json",
    "work-auth.special.CAN-corp.index": "translation-fr.json",
    "work-auth.special.CHN-corp.index": "translation-fr.json",
    "work-auth.special.CZE-corp.index": "translation-fr.json",
    "work-auth.special.DEU-corp.c2c": "translation-fr.json",
    "work-auth.special.DEU-corp.index": "translation-fr.json",
    "work-auth.special.DEU-ops.index": "translation-fr.json",
    "work-auth.special.ESP-corp.index": "translation-fr.json",
    "work-auth.special.GBR-corp.c2c": "translation-fr.json",
    "work-auth.special.GBR-corp.index": "translation-fr.json",
    "work-auth.special.GBR-ops.index": "translation-fr.json",
    "work-auth.special.IND-corp.index": "translation-fr.json",
    "work-auth.special.JPN-corp.index": "translation-fr.json",
    "work-auth.special.KOR-corp.index": "translation-fr.json",
    "work-auth.special.LUX-corp.index": "translation-fr.json",
    "work-auth.special.PHL-corp.index": "translation-fr.json",
    "work-auth.special.POL-corp.index": "translation-fr.json",
    "work-auth.special.PRI-corp.index": "translation-fr.json",
    "work-auth.special.SAU-corp.index": "translation-fr.json",
    "work-auth.special.SGP-corp.index": "translation-fr.json",
    "work-auth.special.USA-corp.c2c": "translation-fr.json",
    "work-auth.special.USA-corp.index": "translation-fr.json"
  },
  id: {
    "badge-upload-v1.index": "translation-id.json",
    "bgc-p0.index": "translation-id.json",
    "c2c-faq.index": "translation-id.json",
    "catch-all.index": "translation-id.json",
    "culture.index": "translation-id.json",
    "dashboard.c2c": "translation-id.json",
    "dashboard.index": "translation-id.json",
    "drug-screen-status-v1.index": "translation-id.json",
    "faq.index": "translation-id.json",
    "fdie-v1.index": "translation-id.json",
    "id-verification-v1.index": "translation-id.json",
    "immigration-v1.index": "translation-id.json",
    "introduce-yourself-v1.index": "translation-id.json",
    "isp-v1.index": "translation-id.json",
    "login-selection.index": "translation-id.json",
    "medical-check-v1.index": "translation-id.json",
    "mydocs-v1.c2c": "translation-id.json",
    "mydocs-v1.index": "translation-id.json",
    "nav.index": "translation-id.json",
    "not-found.index": "translation-id.json",
    "pdf.diversity-inclusion.index": "translation-id.json",
    "pdf.innovative-culture.index": "translation-id.json",
    "pdf.shareholder-letters.index": "translation-id.json",
    "pic-v1.index": "translation-id.json",
    "post-day-one.index": "translation-id.json",
    "return-it-equipment-v1.index": "translation-id.json",
    "shipping-address-v1.addressSuggestion": "translation-id.json",
    "shipping-address-v1.assistiveTechnologies": "translation-id.json",
    "shipping-address-v1.countries": "translation-id.json",
    "shipping-address-v1.hardware": "translation-id.json",
    "shipping-address-v1.index": "translation-id.json",
    "shipping-address-v1.schema": "translation-id.json",
    "shipping-address-v1.schemaAE": "translation-id.json",
    "shipping-address-v1.schemaAU": "translation-id.json",
    "shipping-address-v1.schemaCA": "translation-id.json",
    "shipping-address-v1.schemaCR": "translation-id.json",
    "shipping-address-v1.schemaDE": "translation-id.json",
    "shipping-address-v1.schemaFR": "translation-id.json",
    "shipping-address-v1.schemaGB": "translation-id.json",
    "shipping-address-v1.schemaIN": "translation-id.json",
    "shipping-address-v1.schemaIT": "translation-id.json",
    "shipping-address-v1.schemaMX": "translation-id.json",
    "shipping-address-v1.schemaSA": "translation-id.json",
    "shipping-address-v1.schemaSG": "translation-id.json",
    "shipping-address-v1.schemaUS": "translation-id.json",
    "shipping-address-v1.states": "translation-id.json",
    "shipping-address-v1.statesAU": "translation-id.json",
    "shipping-address-v1.statesCA": "translation-id.json",
    "shipping-address-v1.statesFR": "translation-id.json",
    "shipping-address-v1.statesIN": "translation-id.json",
    "shipping-address-v1.statesUS": "translation-id.json",
    "shipping-address-v1.submissionOutcome": "translation-id.json",
    "shipping-address-v1.validation": "translation-id.json",
    "text.common.index": "translation-id.json",
    "text.landing.index": "translation-id.json",
    "text.page-footer.index": "translation-id.json",
    "text.sub-footer.index": "translation-id.json",
    "unauthorized.index": "translation-id.json",
    "videos.amazon-14-leadership-principles-jeff-bezos.index": "translation-id.json",
    "videos.amazon-ceo-jeff-bezos-it-is-always-day-one.index": "translation-id.json",
    "videos.andy-jassy-ceo-aws.index": "translation-id.json",
    "videos.aws-reinvent-2019-keynote-andy-jassy.index": "translation-id.json",
    "videos.aws-reinvent-2020.index": "translation-id.json",
    "videos.aws-reinvent-2021-compute.index": "translation-id.json",
    "videos.aws-reinvent-2021-data-strategies.index": "translation-id.json",
    "videos.aws-reinvent-2021-introduction.index": "translation-id.json",
    "videos.aws-reinvent-2021-networking.index": "translation-id.json",
    "videos.aws-reinvent-2021-transforming-industries.index": "translation-id.json",
    "videos.aws-reinvent-2021.index": "translation-id.json",
    "videos.aws-welcome-message-by-adam-selipsky.index": "translation-id.json",
    "videos.early-look.index": "translation-id.json",
    "videos.jeff-bezos-amazon-blue-origin.index": "translation-id.json",
    "videos.shop-talk-jeff-wilke-fortune.index": "translation-id.json",
    "videos.tenets-and-decisions.index": "translation-id.json",
    "videos.virtuous-cycle.index": "translation-id.json",
    "videos.welcome-to-aws.index": "translation-id.json",
    "videos.what-is-aws.index": "translation-id.json",
    "videos.what-is-cloud-computing.index": "translation-id.json",
    "work-auth.USAcorp": "translation-id.json",
    "work-auth.c2c": "translation-id.json",
    "work-auth.default.c2c": "translation-id.json",
    "work-auth.default.index": "translation-id.json",
    "work-auth.generic.index": "translation-id.json",
    "work-auth.index": "translation-id.json",
    "work-auth.newHireDefault": "translation-id.json",
    "work-auth.newHireGBR": "translation-id.json",
    "work-auth.special.ARE-corp.index": "translation-id.json",
    "work-auth.special.AUS-corp.index": "translation-id.json",
    "work-auth.special.CAN-corp.c2c": "translation-id.json",
    "work-auth.special.CAN-corp.index": "translation-id.json",
    "work-auth.special.CHN-corp.index": "translation-id.json",
    "work-auth.special.CZE-corp.index": "translation-id.json",
    "work-auth.special.DEU-corp.c2c": "translation-id.json",
    "work-auth.special.DEU-corp.index": "translation-id.json",
    "work-auth.special.DEU-ops.index": "translation-id.json",
    "work-auth.special.ESP-corp.index": "translation-id.json",
    "work-auth.special.GBR-corp.c2c": "translation-id.json",
    "work-auth.special.GBR-corp.index": "translation-id.json",
    "work-auth.special.GBR-ops.index": "translation-id.json",
    "work-auth.special.IND-corp.index": "translation-id.json",
    "work-auth.special.JPN-corp.index": "translation-id.json",
    "work-auth.special.KOR-corp.index": "translation-id.json",
    "work-auth.special.LUX-corp.index": "translation-id.json",
    "work-auth.special.PHL-corp.index": "translation-id.json",
    "work-auth.special.POL-corp.index": "translation-id.json",
    "work-auth.special.PRI-corp.index": "translation-id.json",
    "work-auth.special.SAU-corp.index": "translation-id.json",
    "work-auth.special.SGP-corp.index": "translation-id.json",
    "work-auth.special.USA-corp.c2c": "translation-id.json",
    "work-auth.special.USA-corp.index": "translation-id.json"
  },
  "id-ID": {
    "badge-upload-v1.index": "translation-id.json",
    "bgc-p0.index": "translation-id.json",
    "c2c-faq.index": "translation-id.json",
    "catch-all.index": "translation-id.json",
    "culture.index": "translation-id.json",
    "dashboard.c2c": "translation-id.json",
    "dashboard.index": "translation-id.json",
    "drug-screen-status-v1.index": "translation-id.json",
    "faq.index": "translation-id.json",
    "fdie-v1.index": "translation-id.json",
    "id-verification-v1.index": "translation-id.json",
    "immigration-v1.index": "translation-id.json",
    "introduce-yourself-v1.index": "translation-id.json",
    "isp-v1.index": "translation-id.json",
    "login-selection.index": "translation-id.json",
    "medical-check-v1.index": "translation-id.json",
    "mydocs-v1.c2c": "translation-id.json",
    "mydocs-v1.index": "translation-id.json",
    "nav.index": "translation-id.json",
    "not-found.index": "translation-id.json",
    "pdf.diversity-inclusion.index": "translation-id.json",
    "pdf.innovative-culture.index": "translation-id.json",
    "pdf.shareholder-letters.index": "translation-id.json",
    "pic-v1.index": "translation-id.json",
    "post-day-one.index": "translation-id.json",
    "return-it-equipment-v1.index": "translation-id.json",
    "shipping-address-v1.addressSuggestion": "translation-id.json",
    "shipping-address-v1.assistiveTechnologies": "translation-id.json",
    "shipping-address-v1.countries": "translation-id.json",
    "shipping-address-v1.hardware": "translation-id.json",
    "shipping-address-v1.index": "translation-id.json",
    "shipping-address-v1.schema": "translation-id.json",
    "shipping-address-v1.schemaAE": "translation-id.json",
    "shipping-address-v1.schemaAU": "translation-id.json",
    "shipping-address-v1.schemaCA": "translation-id.json",
    "shipping-address-v1.schemaCR": "translation-id.json",
    "shipping-address-v1.schemaDE": "translation-id.json",
    "shipping-address-v1.schemaFR": "translation-id.json",
    "shipping-address-v1.schemaGB": "translation-id.json",
    "shipping-address-v1.schemaIN": "translation-id.json",
    "shipping-address-v1.schemaIT": "translation-id.json",
    "shipping-address-v1.schemaMX": "translation-id.json",
    "shipping-address-v1.schemaSA": "translation-id.json",
    "shipping-address-v1.schemaSG": "translation-id.json",
    "shipping-address-v1.schemaUS": "translation-id.json",
    "shipping-address-v1.states": "translation-id.json",
    "shipping-address-v1.statesAU": "translation-id.json",
    "shipping-address-v1.statesCA": "translation-id.json",
    "shipping-address-v1.statesFR": "translation-id.json",
    "shipping-address-v1.statesIN": "translation-id.json",
    "shipping-address-v1.statesUS": "translation-id.json",
    "shipping-address-v1.submissionOutcome": "translation-id.json",
    "shipping-address-v1.validation": "translation-id.json",
    "text.common.index": "translation-id.json",
    "text.landing.index": "translation-id.json",
    "text.page-footer.index": "translation-id.json",
    "text.sub-footer.index": "translation-id.json",
    "unauthorized.index": "translation-id.json",
    "videos.amazon-14-leadership-principles-jeff-bezos.index": "translation-id.json",
    "videos.amazon-ceo-jeff-bezos-it-is-always-day-one.index": "translation-id.json",
    "videos.andy-jassy-ceo-aws.index": "translation-id.json",
    "videos.aws-reinvent-2019-keynote-andy-jassy.index": "translation-id.json",
    "videos.aws-reinvent-2020.index": "translation-id.json",
    "videos.aws-reinvent-2021-compute.index": "translation-id.json",
    "videos.aws-reinvent-2021-data-strategies.index": "translation-id.json",
    "videos.aws-reinvent-2021-introduction.index": "translation-id.json",
    "videos.aws-reinvent-2021-networking.index": "translation-id.json",
    "videos.aws-reinvent-2021-transforming-industries.index": "translation-id.json",
    "videos.aws-reinvent-2021.index": "translation-id.json",
    "videos.aws-welcome-message-by-adam-selipsky.index": "translation-id.json",
    "videos.early-look.index": "translation-id.json",
    "videos.jeff-bezos-amazon-blue-origin.index": "translation-id.json",
    "videos.shop-talk-jeff-wilke-fortune.index": "translation-id.json",
    "videos.tenets-and-decisions.index": "translation-id.json",
    "videos.virtuous-cycle.index": "translation-id.json",
    "videos.welcome-to-aws.index": "translation-id.json",
    "videos.what-is-aws.index": "translation-id.json",
    "videos.what-is-cloud-computing.index": "translation-id.json",
    "work-auth.USAcorp": "translation-id.json",
    "work-auth.c2c": "translation-id.json",
    "work-auth.default.c2c": "translation-id.json",
    "work-auth.default.index": "translation-id.json",
    "work-auth.generic.index": "translation-id.json",
    "work-auth.index": "translation-id.json",
    "work-auth.newHireDefault": "translation-id.json",
    "work-auth.newHireGBR": "translation-id.json",
    "work-auth.special.ARE-corp.index": "translation-id.json",
    "work-auth.special.AUS-corp.index": "translation-id.json",
    "work-auth.special.CAN-corp.c2c": "translation-id.json",
    "work-auth.special.CAN-corp.index": "translation-id.json",
    "work-auth.special.CHN-corp.index": "translation-id.json",
    "work-auth.special.CZE-corp.index": "translation-id.json",
    "work-auth.special.DEU-corp.c2c": "translation-id.json",
    "work-auth.special.DEU-corp.index": "translation-id.json",
    "work-auth.special.DEU-ops.index": "translation-id.json",
    "work-auth.special.ESP-corp.index": "translation-id.json",
    "work-auth.special.GBR-corp.c2c": "translation-id.json",
    "work-auth.special.GBR-corp.index": "translation-id.json",
    "work-auth.special.GBR-ops.index": "translation-id.json",
    "work-auth.special.IND-corp.index": "translation-id.json",
    "work-auth.special.JPN-corp.index": "translation-id.json",
    "work-auth.special.KOR-corp.index": "translation-id.json",
    "work-auth.special.LUX-corp.index": "translation-id.json",
    "work-auth.special.PHL-corp.index": "translation-id.json",
    "work-auth.special.POL-corp.index": "translation-id.json",
    "work-auth.special.PRI-corp.index": "translation-id.json",
    "work-auth.special.SAU-corp.index": "translation-id.json",
    "work-auth.special.SGP-corp.index": "translation-id.json",
    "work-auth.special.USA-corp.c2c": "translation-id.json",
    "work-auth.special.USA-corp.index": "translation-id.json"
  },
  it: {
    "badge-upload-v1.index": "translation-it.json",
    "bgc-p0.index": "translation-it.json",
    "c2c-faq.index": "translation-it.json",
    "catch-all.index": "translation-it.json",
    "culture.index": "translation-it.json",
    "dashboard.c2c": "translation-it.json",
    "dashboard.index": "translation-it.json",
    "drug-screen-status-v1.index": "translation-it.json",
    "faq.index": "translation-it.json",
    "fdie-v1.index": "translation-it.json",
    "id-verification-v1.index": "translation-it.json",
    "immigration-v1.index": "translation-it.json",
    "introduce-yourself-v1.index": "translation-it.json",
    "isp-v1.index": "translation-it.json",
    "login-selection.index": "translation-it.json",
    "medical-check-v1.index": "translation-it.json",
    "mydocs-v1.c2c": "translation-it.json",
    "mydocs-v1.index": "translation-it.json",
    "nav.index": "translation-it.json",
    "not-found.index": "translation-it.json",
    "pdf.diversity-inclusion.index": "translation-it.json",
    "pdf.innovative-culture.index": "translation-it.json",
    "pdf.shareholder-letters.index": "translation-it.json",
    "pic-v1.index": "translation-it.json",
    "post-day-one.index": "translation-it.json",
    "return-it-equipment-v1.index": "translation-it.json",
    "shipping-address-v1.addressSuggestion": "translation-it.json",
    "shipping-address-v1.assistiveTechnologies": "translation-it.json",
    "shipping-address-v1.countries": "translation-it.json",
    "shipping-address-v1.hardware": "translation-it.json",
    "shipping-address-v1.index": "translation-it.json",
    "shipping-address-v1.schema": "translation-it.json",
    "shipping-address-v1.schemaAE": "translation-it.json",
    "shipping-address-v1.schemaAU": "translation-it.json",
    "shipping-address-v1.schemaCA": "translation-it.json",
    "shipping-address-v1.schemaCR": "translation-it.json",
    "shipping-address-v1.schemaDE": "translation-it.json",
    "shipping-address-v1.schemaFR": "translation-it.json",
    "shipping-address-v1.schemaGB": "translation-it.json",
    "shipping-address-v1.schemaIN": "translation-it.json",
    "shipping-address-v1.schemaIT": "translation-it.json",
    "shipping-address-v1.schemaMX": "translation-it.json",
    "shipping-address-v1.schemaSA": "translation-it.json",
    "shipping-address-v1.schemaSG": "translation-it.json",
    "shipping-address-v1.schemaUS": "translation-it.json",
    "shipping-address-v1.states": "translation-it.json",
    "shipping-address-v1.statesAU": "translation-it.json",
    "shipping-address-v1.statesCA": "translation-it.json",
    "shipping-address-v1.statesFR": "translation-it.json",
    "shipping-address-v1.statesIN": "translation-it.json",
    "shipping-address-v1.statesUS": "translation-it.json",
    "shipping-address-v1.submissionOutcome": "translation-it.json",
    "shipping-address-v1.validation": "translation-it.json",
    "text.common.index": "translation-it.json",
    "text.landing.index": "translation-it.json",
    "text.page-footer.index": "translation-it.json",
    "text.sub-footer.index": "translation-it.json",
    "unauthorized.index": "translation-it.json",
    "videos.amazon-14-leadership-principles-jeff-bezos.index": "translation-it.json",
    "videos.amazon-ceo-jeff-bezos-it-is-always-day-one.index": "translation-it.json",
    "videos.andy-jassy-ceo-aws.index": "translation-it.json",
    "videos.aws-reinvent-2019-keynote-andy-jassy.index": "translation-it.json",
    "videos.aws-reinvent-2020.index": "translation-it.json",
    "videos.aws-reinvent-2021-compute.index": "translation-it.json",
    "videos.aws-reinvent-2021-data-strategies.index": "translation-it.json",
    "videos.aws-reinvent-2021-introduction.index": "translation-it.json",
    "videos.aws-reinvent-2021-networking.index": "translation-it.json",
    "videos.aws-reinvent-2021-transforming-industries.index": "translation-it.json",
    "videos.aws-reinvent-2021.index": "translation-it.json",
    "videos.aws-welcome-message-by-adam-selipsky.index": "translation-it.json",
    "videos.early-look.index": "translation-it.json",
    "videos.jeff-bezos-amazon-blue-origin.index": "translation-it.json",
    "videos.shop-talk-jeff-wilke-fortune.index": "translation-it.json",
    "videos.tenets-and-decisions.index": "translation-it.json",
    "videos.virtuous-cycle.index": "translation-it.json",
    "videos.welcome-to-aws.index": "translation-it.json",
    "videos.what-is-aws.index": "translation-it.json",
    "videos.what-is-cloud-computing.index": "translation-it.json",
    "work-auth.USAcorp": "translation-it.json",
    "work-auth.c2c": "translation-it.json",
    "work-auth.default.c2c": "translation-it.json",
    "work-auth.default.index": "translation-it.json",
    "work-auth.generic.index": "translation-it.json",
    "work-auth.index": "translation-it.json",
    "work-auth.newHireDefault": "translation-it.json",
    "work-auth.newHireGBR": "translation-it.json",
    "work-auth.special.ARE-corp.index": "translation-it.json",
    "work-auth.special.AUS-corp.index": "translation-it.json",
    "work-auth.special.CAN-corp.c2c": "translation-it.json",
    "work-auth.special.CAN-corp.index": "translation-it.json",
    "work-auth.special.CHN-corp.index": "translation-it.json",
    "work-auth.special.CZE-corp.index": "translation-it.json",
    "work-auth.special.DEU-corp.c2c": "translation-it.json",
    "work-auth.special.DEU-corp.index": "translation-it.json",
    "work-auth.special.DEU-ops.index": "translation-it.json",
    "work-auth.special.ESP-corp.index": "translation-it.json",
    "work-auth.special.GBR-corp.c2c": "translation-it.json",
    "work-auth.special.GBR-corp.index": "translation-it.json",
    "work-auth.special.GBR-ops.index": "translation-it.json",
    "work-auth.special.IND-corp.index": "translation-it.json",
    "work-auth.special.JPN-corp.index": "translation-it.json",
    "work-auth.special.KOR-corp.index": "translation-it.json",
    "work-auth.special.LUX-corp.index": "translation-it.json",
    "work-auth.special.PHL-corp.index": "translation-it.json",
    "work-auth.special.POL-corp.index": "translation-it.json",
    "work-auth.special.PRI-corp.index": "translation-it.json",
    "work-auth.special.SAU-corp.index": "translation-it.json",
    "work-auth.special.SGP-corp.index": "translation-it.json",
    "work-auth.special.USA-corp.c2c": "translation-it.json",
    "work-auth.special.USA-corp.index": "translation-it.json"
  },
  "it-IT": {
    "badge-upload-v1.index": "translation-it.json",
    "bgc-p0.index": "translation-it.json",
    "c2c-faq.index": "translation-it.json",
    "catch-all.index": "translation-it.json",
    "culture.index": "translation-it.json",
    "dashboard.c2c": "translation-it.json",
    "dashboard.index": "translation-it.json",
    "drug-screen-status-v1.index": "translation-it.json",
    "faq.index": "translation-it.json",
    "fdie-v1.index": "translation-it.json",
    "id-verification-v1.index": "translation-it.json",
    "immigration-v1.index": "translation-it.json",
    "introduce-yourself-v1.index": "translation-it.json",
    "isp-v1.index": "translation-it.json",
    "login-selection.index": "translation-it.json",
    "medical-check-v1.index": "translation-it.json",
    "mydocs-v1.c2c": "translation-it.json",
    "mydocs-v1.index": "translation-it.json",
    "nav.index": "translation-it.json",
    "not-found.index": "translation-it.json",
    "pdf.diversity-inclusion.index": "translation-it.json",
    "pdf.innovative-culture.index": "translation-it.json",
    "pdf.shareholder-letters.index": "translation-it.json",
    "pic-v1.index": "translation-it.json",
    "post-day-one.index": "translation-it.json",
    "return-it-equipment-v1.index": "translation-it.json",
    "shipping-address-v1.addressSuggestion": "translation-it.json",
    "shipping-address-v1.assistiveTechnologies": "translation-it.json",
    "shipping-address-v1.countries": "translation-it.json",
    "shipping-address-v1.hardware": "translation-it.json",
    "shipping-address-v1.index": "translation-it.json",
    "shipping-address-v1.schema": "translation-it.json",
    "shipping-address-v1.schemaAE": "translation-it.json",
    "shipping-address-v1.schemaAU": "translation-it.json",
    "shipping-address-v1.schemaCA": "translation-it.json",
    "shipping-address-v1.schemaCR": "translation-it.json",
    "shipping-address-v1.schemaDE": "translation-it.json",
    "shipping-address-v1.schemaFR": "translation-it.json",
    "shipping-address-v1.schemaGB": "translation-it.json",
    "shipping-address-v1.schemaIN": "translation-it.json",
    "shipping-address-v1.schemaIT": "translation-it.json",
    "shipping-address-v1.schemaMX": "translation-it.json",
    "shipping-address-v1.schemaSA": "translation-it.json",
    "shipping-address-v1.schemaSG": "translation-it.json",
    "shipping-address-v1.schemaUS": "translation-it.json",
    "shipping-address-v1.states": "translation-it.json",
    "shipping-address-v1.statesAU": "translation-it.json",
    "shipping-address-v1.statesCA": "translation-it.json",
    "shipping-address-v1.statesFR": "translation-it.json",
    "shipping-address-v1.statesIN": "translation-it.json",
    "shipping-address-v1.statesUS": "translation-it.json",
    "shipping-address-v1.submissionOutcome": "translation-it.json",
    "shipping-address-v1.validation": "translation-it.json",
    "text.common.index": "translation-it.json",
    "text.landing.index": "translation-it.json",
    "text.page-footer.index": "translation-it.json",
    "text.sub-footer.index": "translation-it.json",
    "unauthorized.index": "translation-it.json",
    "videos.amazon-14-leadership-principles-jeff-bezos.index": "translation-it.json",
    "videos.amazon-ceo-jeff-bezos-it-is-always-day-one.index": "translation-it.json",
    "videos.andy-jassy-ceo-aws.index": "translation-it.json",
    "videos.aws-reinvent-2019-keynote-andy-jassy.index": "translation-it.json",
    "videos.aws-reinvent-2020.index": "translation-it.json",
    "videos.aws-reinvent-2021-compute.index": "translation-it.json",
    "videos.aws-reinvent-2021-data-strategies.index": "translation-it.json",
    "videos.aws-reinvent-2021-introduction.index": "translation-it.json",
    "videos.aws-reinvent-2021-networking.index": "translation-it.json",
    "videos.aws-reinvent-2021-transforming-industries.index": "translation-it.json",
    "videos.aws-reinvent-2021.index": "translation-it.json",
    "videos.aws-welcome-message-by-adam-selipsky.index": "translation-it.json",
    "videos.early-look.index": "translation-it.json",
    "videos.jeff-bezos-amazon-blue-origin.index": "translation-it.json",
    "videos.shop-talk-jeff-wilke-fortune.index": "translation-it.json",
    "videos.tenets-and-decisions.index": "translation-it.json",
    "videos.virtuous-cycle.index": "translation-it.json",
    "videos.welcome-to-aws.index": "translation-it.json",
    "videos.what-is-aws.index": "translation-it.json",
    "videos.what-is-cloud-computing.index": "translation-it.json",
    "work-auth.USAcorp": "translation-it.json",
    "work-auth.c2c": "translation-it.json",
    "work-auth.default.c2c": "translation-it.json",
    "work-auth.default.index": "translation-it.json",
    "work-auth.generic.index": "translation-it.json",
    "work-auth.index": "translation-it.json",
    "work-auth.newHireDefault": "translation-it.json",
    "work-auth.newHireGBR": "translation-it.json",
    "work-auth.special.ARE-corp.index": "translation-it.json",
    "work-auth.special.AUS-corp.index": "translation-it.json",
    "work-auth.special.CAN-corp.c2c": "translation-it.json",
    "work-auth.special.CAN-corp.index": "translation-it.json",
    "work-auth.special.CHN-corp.index": "translation-it.json",
    "work-auth.special.CZE-corp.index": "translation-it.json",
    "work-auth.special.DEU-corp.c2c": "translation-it.json",
    "work-auth.special.DEU-corp.index": "translation-it.json",
    "work-auth.special.DEU-ops.index": "translation-it.json",
    "work-auth.special.ESP-corp.index": "translation-it.json",
    "work-auth.special.GBR-corp.c2c": "translation-it.json",
    "work-auth.special.GBR-corp.index": "translation-it.json",
    "work-auth.special.GBR-ops.index": "translation-it.json",
    "work-auth.special.IND-corp.index": "translation-it.json",
    "work-auth.special.JPN-corp.index": "translation-it.json",
    "work-auth.special.KOR-corp.index": "translation-it.json",
    "work-auth.special.LUX-corp.index": "translation-it.json",
    "work-auth.special.PHL-corp.index": "translation-it.json",
    "work-auth.special.POL-corp.index": "translation-it.json",
    "work-auth.special.PRI-corp.index": "translation-it.json",
    "work-auth.special.SAU-corp.index": "translation-it.json",
    "work-auth.special.SGP-corp.index": "translation-it.json",
    "work-auth.special.USA-corp.c2c": "translation-it.json",
    "work-auth.special.USA-corp.index": "translation-it.json"
  },
  ja: {
    "badge-upload-v1.index": "translation-ja.json",
    "bgc-p0.index": "translation-ja.json",
    "c2c-faq.index": "translation-ja.json",
    "catch-all.index": "translation-ja.json",
    "culture.index": "translation-ja.json",
    "dashboard.c2c": "translation-ja.json",
    "dashboard.index": "translation-ja.json",
    "drug-screen-status-v1.index": "translation-ja.json",
    "faq.index": "translation-ja.json",
    "fdie-v1.index": "translation-ja.json",
    "id-verification-v1.index": "translation-ja.json",
    "immigration-v1.index": "translation-ja.json",
    "introduce-yourself-v1.index": "translation-ja.json",
    "isp-v1.index": "translation-ja.json",
    "login-selection.index": "translation-ja.json",
    "medical-check-v1.index": "translation-ja.json",
    "mydocs-v1.c2c": "translation-ja.json",
    "mydocs-v1.index": "translation-ja.json",
    "nav.index": "translation-ja.json",
    "not-found.index": "translation-ja.json",
    "pdf.diversity-inclusion.index": "translation-ja.json",
    "pdf.innovative-culture.index": "translation-ja.json",
    "pdf.shareholder-letters.index": "translation-ja.json",
    "pic-v1.index": "translation-ja.json",
    "post-day-one.index": "translation-ja.json",
    "return-it-equipment-v1.index": "translation-ja.json",
    "shipping-address-v1.addressSuggestion": "translation-ja.json",
    "shipping-address-v1.assistiveTechnologies": "translation-ja.json",
    "shipping-address-v1.countries": "translation-ja.json",
    "shipping-address-v1.hardware": "translation-ja.json",
    "shipping-address-v1.index": "translation-ja.json",
    "shipping-address-v1.schema": "translation-ja.json",
    "shipping-address-v1.schemaAE": "translation-ja.json",
    "shipping-address-v1.schemaAU": "translation-ja.json",
    "shipping-address-v1.schemaCA": "translation-ja.json",
    "shipping-address-v1.schemaCR": "translation-ja.json",
    "shipping-address-v1.schemaDE": "translation-ja.json",
    "shipping-address-v1.schemaFR": "translation-ja.json",
    "shipping-address-v1.schemaGB": "translation-ja.json",
    "shipping-address-v1.schemaIN": "translation-ja.json",
    "shipping-address-v1.schemaIT": "translation-ja.json",
    "shipping-address-v1.schemaMX": "translation-ja.json",
    "shipping-address-v1.schemaSA": "translation-ja.json",
    "shipping-address-v1.schemaSG": "translation-ja.json",
    "shipping-address-v1.schemaUS": "translation-ja.json",
    "shipping-address-v1.states": "translation-ja.json",
    "shipping-address-v1.statesAU": "translation-ja.json",
    "shipping-address-v1.statesCA": "translation-ja.json",
    "shipping-address-v1.statesFR": "translation-ja.json",
    "shipping-address-v1.statesIN": "translation-ja.json",
    "shipping-address-v1.statesUS": "translation-ja.json",
    "shipping-address-v1.submissionOutcome": "translation-ja.json",
    "shipping-address-v1.validation": "translation-ja.json",
    "text.common.index": "translation-ja.json",
    "text.landing.index": "translation-ja.json",
    "text.page-footer.index": "translation-ja.json",
    "text.sub-footer.index": "translation-ja.json",
    "unauthorized.index": "translation-ja.json",
    "videos.amazon-14-leadership-principles-jeff-bezos.index": "translation-ja.json",
    "videos.amazon-ceo-jeff-bezos-it-is-always-day-one.index": "translation-ja.json",
    "videos.andy-jassy-ceo-aws.index": "translation-ja.json",
    "videos.aws-reinvent-2019-keynote-andy-jassy.index": "translation-ja.json",
    "videos.aws-reinvent-2020.index": "translation-ja.json",
    "videos.aws-reinvent-2021-compute.index": "translation-ja.json",
    "videos.aws-reinvent-2021-data-strategies.index": "translation-ja.json",
    "videos.aws-reinvent-2021-introduction.index": "translation-ja.json",
    "videos.aws-reinvent-2021-networking.index": "translation-ja.json",
    "videos.aws-reinvent-2021-transforming-industries.index": "translation-ja.json",
    "videos.aws-reinvent-2021.index": "translation-ja.json",
    "videos.aws-welcome-message-by-adam-selipsky.index": "translation-ja.json",
    "videos.early-look.index": "translation-ja.json",
    "videos.jeff-bezos-amazon-blue-origin.index": "translation-ja.json",
    "videos.shop-talk-jeff-wilke-fortune.index": "translation-ja.json",
    "videos.tenets-and-decisions.index": "translation-ja.json",
    "videos.virtuous-cycle.index": "translation-ja.json",
    "videos.welcome-to-aws.index": "translation-ja.json",
    "videos.what-is-aws.index": "translation-ja.json",
    "videos.what-is-cloud-computing.index": "translation-ja.json",
    "work-auth.USAcorp": "translation-ja.json",
    "work-auth.c2c": "translation-ja.json",
    "work-auth.default.c2c": "translation-ja.json",
    "work-auth.default.index": "translation-ja.json",
    "work-auth.generic.index": "translation-ja.json",
    "work-auth.index": "translation-ja.json",
    "work-auth.newHireDefault": "translation-ja.json",
    "work-auth.newHireGBR": "translation-ja.json",
    "work-auth.special.ARE-corp.index": "translation-ja.json",
    "work-auth.special.AUS-corp.index": "translation-ja.json",
    "work-auth.special.CAN-corp.c2c": "translation-ja.json",
    "work-auth.special.CAN-corp.index": "translation-ja.json",
    "work-auth.special.CHN-corp.index": "translation-ja.json",
    "work-auth.special.CZE-corp.index": "translation-ja.json",
    "work-auth.special.DEU-corp.c2c": "translation-ja.json",
    "work-auth.special.DEU-corp.index": "translation-ja.json",
    "work-auth.special.DEU-ops.index": "translation-ja.json",
    "work-auth.special.ESP-corp.index": "translation-ja.json",
    "work-auth.special.GBR-corp.c2c": "translation-ja.json",
    "work-auth.special.GBR-corp.index": "translation-ja.json",
    "work-auth.special.GBR-ops.index": "translation-ja.json",
    "work-auth.special.IND-corp.index": "translation-ja.json",
    "work-auth.special.JPN-corp.index": "translation-ja.json",
    "work-auth.special.KOR-corp.index": "translation-ja.json",
    "work-auth.special.LUX-corp.index": "translation-ja.json",
    "work-auth.special.PHL-corp.index": "translation-ja.json",
    "work-auth.special.POL-corp.index": "translation-ja.json",
    "work-auth.special.PRI-corp.index": "translation-ja.json",
    "work-auth.special.SAU-corp.index": "translation-ja.json",
    "work-auth.special.SGP-corp.index": "translation-ja.json",
    "work-auth.special.USA-corp.c2c": "translation-ja.json",
    "work-auth.special.USA-corp.index": "translation-ja.json"
  },
  "ja-JP": {
    "badge-upload-v1.index": "translation-ja.json",
    "bgc-p0.index": "translation-ja.json",
    "c2c-faq.index": "translation-ja.json",
    "catch-all.index": "translation-ja.json",
    "culture.index": "translation-ja.json",
    "dashboard.c2c": "translation-ja.json",
    "dashboard.index": "translation-ja.json",
    "drug-screen-status-v1.index": "translation-ja.json",
    "faq.index": "translation-ja.json",
    "fdie-v1.index": "translation-ja.json",
    "id-verification-v1.index": "translation-ja.json",
    "immigration-v1.index": "translation-ja.json",
    "introduce-yourself-v1.index": "translation-ja.json",
    "isp-v1.index": "translation-ja.json",
    "login-selection.index": "translation-ja.json",
    "medical-check-v1.index": "translation-ja.json",
    "mydocs-v1.c2c": "translation-ja.json",
    "mydocs-v1.index": "translation-ja.json",
    "nav.index": "translation-ja.json",
    "not-found.index": "translation-ja.json",
    "pdf.diversity-inclusion.index": "translation-ja.json",
    "pdf.innovative-culture.index": "translation-ja.json",
    "pdf.shareholder-letters.index": "translation-ja.json",
    "pic-v1.index": "translation-ja.json",
    "post-day-one.index": "translation-ja.json",
    "return-it-equipment-v1.index": "translation-ja.json",
    "shipping-address-v1.addressSuggestion": "translation-ja.json",
    "shipping-address-v1.assistiveTechnologies": "translation-ja.json",
    "shipping-address-v1.countries": "translation-ja.json",
    "shipping-address-v1.hardware": "translation-ja.json",
    "shipping-address-v1.index": "translation-ja.json",
    "shipping-address-v1.schema": "translation-ja.json",
    "shipping-address-v1.schemaAE": "translation-ja.json",
    "shipping-address-v1.schemaAU": "translation-ja.json",
    "shipping-address-v1.schemaCA": "translation-ja.json",
    "shipping-address-v1.schemaCR": "translation-ja.json",
    "shipping-address-v1.schemaDE": "translation-ja.json",
    "shipping-address-v1.schemaFR": "translation-ja.json",
    "shipping-address-v1.schemaGB": "translation-ja.json",
    "shipping-address-v1.schemaIN": "translation-ja.json",
    "shipping-address-v1.schemaIT": "translation-ja.json",
    "shipping-address-v1.schemaMX": "translation-ja.json",
    "shipping-address-v1.schemaSA": "translation-ja.json",
    "shipping-address-v1.schemaSG": "translation-ja.json",
    "shipping-address-v1.schemaUS": "translation-ja.json",
    "shipping-address-v1.states": "translation-ja.json",
    "shipping-address-v1.statesAU": "translation-ja.json",
    "shipping-address-v1.statesCA": "translation-ja.json",
    "shipping-address-v1.statesFR": "translation-ja.json",
    "shipping-address-v1.statesIN": "translation-ja.json",
    "shipping-address-v1.statesUS": "translation-ja.json",
    "shipping-address-v1.submissionOutcome": "translation-ja.json",
    "shipping-address-v1.validation": "translation-ja.json",
    "text.common.index": "translation-ja.json",
    "text.landing.index": "translation-ja.json",
    "text.page-footer.index": "translation-ja.json",
    "text.sub-footer.index": "translation-ja.json",
    "unauthorized.index": "translation-ja.json",
    "videos.amazon-14-leadership-principles-jeff-bezos.index": "translation-ja.json",
    "videos.amazon-ceo-jeff-bezos-it-is-always-day-one.index": "translation-ja.json",
    "videos.andy-jassy-ceo-aws.index": "translation-ja.json",
    "videos.aws-reinvent-2019-keynote-andy-jassy.index": "translation-ja.json",
    "videos.aws-reinvent-2020.index": "translation-ja.json",
    "videos.aws-reinvent-2021-compute.index": "translation-ja.json",
    "videos.aws-reinvent-2021-data-strategies.index": "translation-ja.json",
    "videos.aws-reinvent-2021-introduction.index": "translation-ja.json",
    "videos.aws-reinvent-2021-networking.index": "translation-ja.json",
    "videos.aws-reinvent-2021-transforming-industries.index": "translation-ja.json",
    "videos.aws-reinvent-2021.index": "translation-ja.json",
    "videos.aws-welcome-message-by-adam-selipsky.index": "translation-ja.json",
    "videos.early-look.index": "translation-ja.json",
    "videos.jeff-bezos-amazon-blue-origin.index": "translation-ja.json",
    "videos.shop-talk-jeff-wilke-fortune.index": "translation-ja.json",
    "videos.tenets-and-decisions.index": "translation-ja.json",
    "videos.virtuous-cycle.index": "translation-ja.json",
    "videos.welcome-to-aws.index": "translation-ja.json",
    "videos.what-is-aws.index": "translation-ja.json",
    "videos.what-is-cloud-computing.index": "translation-ja.json",
    "work-auth.USAcorp": "translation-ja.json",
    "work-auth.c2c": "translation-ja.json",
    "work-auth.default.c2c": "translation-ja.json",
    "work-auth.default.index": "translation-ja.json",
    "work-auth.generic.index": "translation-ja.json",
    "work-auth.index": "translation-ja.json",
    "work-auth.newHireDefault": "translation-ja.json",
    "work-auth.newHireGBR": "translation-ja.json",
    "work-auth.special.ARE-corp.index": "translation-ja.json",
    "work-auth.special.AUS-corp.index": "translation-ja.json",
    "work-auth.special.CAN-corp.c2c": "translation-ja.json",
    "work-auth.special.CAN-corp.index": "translation-ja.json",
    "work-auth.special.CHN-corp.index": "translation-ja.json",
    "work-auth.special.CZE-corp.index": "translation-ja.json",
    "work-auth.special.DEU-corp.c2c": "translation-ja.json",
    "work-auth.special.DEU-corp.index": "translation-ja.json",
    "work-auth.special.DEU-ops.index": "translation-ja.json",
    "work-auth.special.ESP-corp.index": "translation-ja.json",
    "work-auth.special.GBR-corp.c2c": "translation-ja.json",
    "work-auth.special.GBR-corp.index": "translation-ja.json",
    "work-auth.special.GBR-ops.index": "translation-ja.json",
    "work-auth.special.IND-corp.index": "translation-ja.json",
    "work-auth.special.JPN-corp.index": "translation-ja.json",
    "work-auth.special.KOR-corp.index": "translation-ja.json",
    "work-auth.special.LUX-corp.index": "translation-ja.json",
    "work-auth.special.PHL-corp.index": "translation-ja.json",
    "work-auth.special.POL-corp.index": "translation-ja.json",
    "work-auth.special.PRI-corp.index": "translation-ja.json",
    "work-auth.special.SAU-corp.index": "translation-ja.json",
    "work-auth.special.SGP-corp.index": "translation-ja.json",
    "work-auth.special.USA-corp.c2c": "translation-ja.json",
    "work-auth.special.USA-corp.index": "translation-ja.json"
  },
  nl: {
    "badge-upload-v1.index": "translation-nl.json",
    "bgc-p0.index": "translation-nl.json",
    "c2c-faq.index": "translation-nl.json",
    "catch-all.index": "translation-nl.json",
    "culture.index": "translation-nl.json",
    "dashboard.c2c": "translation-nl.json",
    "dashboard.index": "translation-nl.json",
    "drug-screen-status-v1.index": "translation-nl.json",
    "faq.index": "translation-nl.json",
    "fdie-v1.index": "translation-nl.json",
    "id-verification-v1.index": "translation-nl.json",
    "immigration-v1.index": "translation-nl.json",
    "introduce-yourself-v1.index": "translation-nl.json",
    "isp-v1.index": "translation-nl.json",
    "login-selection.index": "translation-nl.json",
    "medical-check-v1.index": "translation-nl.json",
    "mydocs-v1.c2c": "translation-nl.json",
    "mydocs-v1.index": "translation-nl.json",
    "nav.index": "translation-nl.json",
    "not-found.index": "translation-nl.json",
    "pdf.diversity-inclusion.index": "translation-nl.json",
    "pdf.innovative-culture.index": "translation-nl.json",
    "pdf.shareholder-letters.index": "translation-nl.json",
    "pic-v1.index": "translation-nl.json",
    "post-day-one.index": "translation-nl.json",
    "return-it-equipment-v1.index": "translation-nl.json",
    "shipping-address-v1.addressSuggestion": "translation-nl.json",
    "shipping-address-v1.assistiveTechnologies": "translation-nl.json",
    "shipping-address-v1.countries": "translation-nl.json",
    "shipping-address-v1.hardware": "translation-nl.json",
    "shipping-address-v1.index": "translation-nl.json",
    "shipping-address-v1.schema": "translation-nl.json",
    "shipping-address-v1.schemaAE": "translation-nl.json",
    "shipping-address-v1.schemaAU": "translation-nl.json",
    "shipping-address-v1.schemaCA": "translation-nl.json",
    "shipping-address-v1.schemaCR": "translation-nl.json",
    "shipping-address-v1.schemaDE": "translation-nl.json",
    "shipping-address-v1.schemaFR": "translation-nl.json",
    "shipping-address-v1.schemaGB": "translation-nl.json",
    "shipping-address-v1.schemaIN": "translation-nl.json",
    "shipping-address-v1.schemaIT": "translation-nl.json",
    "shipping-address-v1.schemaMX": "translation-nl.json",
    "shipping-address-v1.schemaSA": "translation-nl.json",
    "shipping-address-v1.schemaSG": "translation-nl.json",
    "shipping-address-v1.schemaUS": "translation-nl.json",
    "shipping-address-v1.states": "translation-nl.json",
    "shipping-address-v1.statesAU": "translation-nl.json",
    "shipping-address-v1.statesCA": "translation-nl.json",
    "shipping-address-v1.statesFR": "translation-nl.json",
    "shipping-address-v1.statesIN": "translation-nl.json",
    "shipping-address-v1.statesUS": "translation-nl.json",
    "shipping-address-v1.submissionOutcome": "translation-nl.json",
    "shipping-address-v1.validation": "translation-nl.json",
    "text.common.index": "translation-nl.json",
    "text.landing.index": "translation-nl.json",
    "text.page-footer.index": "translation-nl.json",
    "text.sub-footer.index": "translation-nl.json",
    "unauthorized.index": "translation-nl.json",
    "videos.amazon-14-leadership-principles-jeff-bezos.index": "translation-nl.json",
    "videos.amazon-ceo-jeff-bezos-it-is-always-day-one.index": "translation-nl.json",
    "videos.andy-jassy-ceo-aws.index": "translation-nl.json",
    "videos.aws-reinvent-2019-keynote-andy-jassy.index": "translation-nl.json",
    "videos.aws-reinvent-2020.index": "translation-nl.json",
    "videos.aws-reinvent-2021-compute.index": "translation-nl.json",
    "videos.aws-reinvent-2021-data-strategies.index": "translation-nl.json",
    "videos.aws-reinvent-2021-introduction.index": "translation-nl.json",
    "videos.aws-reinvent-2021-networking.index": "translation-nl.json",
    "videos.aws-reinvent-2021-transforming-industries.index": "translation-nl.json",
    "videos.aws-reinvent-2021.index": "translation-nl.json",
    "videos.aws-welcome-message-by-adam-selipsky.index": "translation-nl.json",
    "videos.early-look.index": "translation-nl.json",
    "videos.jeff-bezos-amazon-blue-origin.index": "translation-nl.json",
    "videos.shop-talk-jeff-wilke-fortune.index": "translation-nl.json",
    "videos.tenets-and-decisions.index": "translation-nl.json",
    "videos.virtuous-cycle.index": "translation-nl.json",
    "videos.welcome-to-aws.index": "translation-nl.json",
    "videos.what-is-aws.index": "translation-nl.json",
    "videos.what-is-cloud-computing.index": "translation-nl.json",
    "work-auth.USAcorp": "translation-nl.json",
    "work-auth.c2c": "translation-nl.json",
    "work-auth.default.c2c": "translation-nl.json",
    "work-auth.default.index": "translation-nl.json",
    "work-auth.generic.index": "translation-nl.json",
    "work-auth.index": "translation-nl.json",
    "work-auth.newHireDefault": "translation-nl.json",
    "work-auth.newHireGBR": "translation-nl.json",
    "work-auth.special.ARE-corp.index": "translation-nl.json",
    "work-auth.special.AUS-corp.index": "translation-nl.json",
    "work-auth.special.CAN-corp.c2c": "translation-nl.json",
    "work-auth.special.CAN-corp.index": "translation-nl.json",
    "work-auth.special.CHN-corp.index": "translation-nl.json",
    "work-auth.special.CZE-corp.index": "translation-nl.json",
    "work-auth.special.DEU-corp.c2c": "translation-nl.json",
    "work-auth.special.DEU-corp.index": "translation-nl.json",
    "work-auth.special.DEU-ops.index": "translation-nl.json",
    "work-auth.special.ESP-corp.index": "translation-nl.json",
    "work-auth.special.GBR-corp.c2c": "translation-nl.json",
    "work-auth.special.GBR-corp.index": "translation-nl.json",
    "work-auth.special.GBR-ops.index": "translation-nl.json",
    "work-auth.special.IND-corp.index": "translation-nl.json",
    "work-auth.special.JPN-corp.index": "translation-nl.json",
    "work-auth.special.KOR-corp.index": "translation-nl.json",
    "work-auth.special.LUX-corp.index": "translation-nl.json",
    "work-auth.special.PHL-corp.index": "translation-nl.json",
    "work-auth.special.POL-corp.index": "translation-nl.json",
    "work-auth.special.PRI-corp.index": "translation-nl.json",
    "work-auth.special.SAU-corp.index": "translation-nl.json",
    "work-auth.special.SGP-corp.index": "translation-nl.json",
    "work-auth.special.USA-corp.c2c": "translation-nl.json",
    "work-auth.special.USA-corp.index": "translation-nl.json"
  },
  "nl-NL": {
    "badge-upload-v1.index": "translation-nl.json",
    "bgc-p0.index": "translation-nl.json",
    "c2c-faq.index": "translation-nl.json",
    "catch-all.index": "translation-nl.json",
    "culture.index": "translation-nl.json",
    "dashboard.c2c": "translation-nl.json",
    "dashboard.index": "translation-nl.json",
    "drug-screen-status-v1.index": "translation-nl.json",
    "faq.index": "translation-nl.json",
    "fdie-v1.index": "translation-nl.json",
    "id-verification-v1.index": "translation-nl.json",
    "immigration-v1.index": "translation-nl.json",
    "introduce-yourself-v1.index": "translation-nl.json",
    "isp-v1.index": "translation-nl.json",
    "login-selection.index": "translation-nl.json",
    "medical-check-v1.index": "translation-nl.json",
    "mydocs-v1.c2c": "translation-nl.json",
    "mydocs-v1.index": "translation-nl.json",
    "nav.index": "translation-nl.json",
    "not-found.index": "translation-nl.json",
    "pdf.diversity-inclusion.index": "translation-nl.json",
    "pdf.innovative-culture.index": "translation-nl.json",
    "pdf.shareholder-letters.index": "translation-nl.json",
    "pic-v1.index": "translation-nl.json",
    "post-day-one.index": "translation-nl.json",
    "return-it-equipment-v1.index": "translation-nl.json",
    "shipping-address-v1.addressSuggestion": "translation-nl.json",
    "shipping-address-v1.assistiveTechnologies": "translation-nl.json",
    "shipping-address-v1.countries": "translation-nl.json",
    "shipping-address-v1.hardware": "translation-nl.json",
    "shipping-address-v1.index": "translation-nl.json",
    "shipping-address-v1.schema": "translation-nl.json",
    "shipping-address-v1.schemaAE": "translation-nl.json",
    "shipping-address-v1.schemaAU": "translation-nl.json",
    "shipping-address-v1.schemaCA": "translation-nl.json",
    "shipping-address-v1.schemaCR": "translation-nl.json",
    "shipping-address-v1.schemaDE": "translation-nl.json",
    "shipping-address-v1.schemaFR": "translation-nl.json",
    "shipping-address-v1.schemaGB": "translation-nl.json",
    "shipping-address-v1.schemaIN": "translation-nl.json",
    "shipping-address-v1.schemaIT": "translation-nl.json",
    "shipping-address-v1.schemaMX": "translation-nl.json",
    "shipping-address-v1.schemaSA": "translation-nl.json",
    "shipping-address-v1.schemaSG": "translation-nl.json",
    "shipping-address-v1.schemaUS": "translation-nl.json",
    "shipping-address-v1.states": "translation-nl.json",
    "shipping-address-v1.statesAU": "translation-nl.json",
    "shipping-address-v1.statesCA": "translation-nl.json",
    "shipping-address-v1.statesFR": "translation-nl.json",
    "shipping-address-v1.statesIN": "translation-nl.json",
    "shipping-address-v1.statesUS": "translation-nl.json",
    "shipping-address-v1.submissionOutcome": "translation-nl.json",
    "shipping-address-v1.validation": "translation-nl.json",
    "text.common.index": "translation-nl.json",
    "text.landing.index": "translation-nl.json",
    "text.page-footer.index": "translation-nl.json",
    "text.sub-footer.index": "translation-nl.json",
    "unauthorized.index": "translation-nl.json",
    "videos.amazon-14-leadership-principles-jeff-bezos.index": "translation-nl.json",
    "videos.amazon-ceo-jeff-bezos-it-is-always-day-one.index": "translation-nl.json",
    "videos.andy-jassy-ceo-aws.index": "translation-nl.json",
    "videos.aws-reinvent-2019-keynote-andy-jassy.index": "translation-nl.json",
    "videos.aws-reinvent-2020.index": "translation-nl.json",
    "videos.aws-reinvent-2021-compute.index": "translation-nl.json",
    "videos.aws-reinvent-2021-data-strategies.index": "translation-nl.json",
    "videos.aws-reinvent-2021-introduction.index": "translation-nl.json",
    "videos.aws-reinvent-2021-networking.index": "translation-nl.json",
    "videos.aws-reinvent-2021-transforming-industries.index": "translation-nl.json",
    "videos.aws-reinvent-2021.index": "translation-nl.json",
    "videos.aws-welcome-message-by-adam-selipsky.index": "translation-nl.json",
    "videos.early-look.index": "translation-nl.json",
    "videos.jeff-bezos-amazon-blue-origin.index": "translation-nl.json",
    "videos.shop-talk-jeff-wilke-fortune.index": "translation-nl.json",
    "videos.tenets-and-decisions.index": "translation-nl.json",
    "videos.virtuous-cycle.index": "translation-nl.json",
    "videos.welcome-to-aws.index": "translation-nl.json",
    "videos.what-is-aws.index": "translation-nl.json",
    "videos.what-is-cloud-computing.index": "translation-nl.json",
    "work-auth.USAcorp": "translation-nl.json",
    "work-auth.c2c": "translation-nl.json",
    "work-auth.default.c2c": "translation-nl.json",
    "work-auth.default.index": "translation-nl.json",
    "work-auth.generic.index": "translation-nl.json",
    "work-auth.index": "translation-nl.json",
    "work-auth.newHireDefault": "translation-nl.json",
    "work-auth.newHireGBR": "translation-nl.json",
    "work-auth.special.ARE-corp.index": "translation-nl.json",
    "work-auth.special.AUS-corp.index": "translation-nl.json",
    "work-auth.special.CAN-corp.c2c": "translation-nl.json",
    "work-auth.special.CAN-corp.index": "translation-nl.json",
    "work-auth.special.CHN-corp.index": "translation-nl.json",
    "work-auth.special.CZE-corp.index": "translation-nl.json",
    "work-auth.special.DEU-corp.c2c": "translation-nl.json",
    "work-auth.special.DEU-corp.index": "translation-nl.json",
    "work-auth.special.DEU-ops.index": "translation-nl.json",
    "work-auth.special.ESP-corp.index": "translation-nl.json",
    "work-auth.special.GBR-corp.c2c": "translation-nl.json",
    "work-auth.special.GBR-corp.index": "translation-nl.json",
    "work-auth.special.GBR-ops.index": "translation-nl.json",
    "work-auth.special.IND-corp.index": "translation-nl.json",
    "work-auth.special.JPN-corp.index": "translation-nl.json",
    "work-auth.special.KOR-corp.index": "translation-nl.json",
    "work-auth.special.LUX-corp.index": "translation-nl.json",
    "work-auth.special.PHL-corp.index": "translation-nl.json",
    "work-auth.special.POL-corp.index": "translation-nl.json",
    "work-auth.special.PRI-corp.index": "translation-nl.json",
    "work-auth.special.SAU-corp.index": "translation-nl.json",
    "work-auth.special.SGP-corp.index": "translation-nl.json",
    "work-auth.special.USA-corp.c2c": "translation-nl.json",
    "work-auth.special.USA-corp.index": "translation-nl.json"
  },
  pl: {
    "badge-upload-v1.index": "translation-pl.json",
    "bgc-p0.index": "translation-pl.json",
    "c2c-faq.index": "translation-pl.json",
    "catch-all.index": "translation-pl.json",
    "culture.index": "translation-pl.json",
    "dashboard.c2c": "translation-pl.json",
    "dashboard.index": "translation-pl.json",
    "drug-screen-status-v1.index": "translation-pl.json",
    "faq.index": "translation-pl.json",
    "fdie-v1.index": "translation-pl.json",
    "id-verification-v1.index": "translation-pl.json",
    "immigration-v1.index": "translation-pl.json",
    "introduce-yourself-v1.index": "translation-pl.json",
    "isp-v1.index": "translation-pl.json",
    "login-selection.index": "translation-pl.json",
    "medical-check-v1.index": "translation-pl.json",
    "mydocs-v1.c2c": "translation-pl.json",
    "mydocs-v1.index": "translation-pl.json",
    "nav.index": "translation-pl.json",
    "not-found.index": "translation-pl.json",
    "pdf.diversity-inclusion.index": "translation-pl.json",
    "pdf.innovative-culture.index": "translation-pl.json",
    "pdf.shareholder-letters.index": "translation-pl.json",
    "pic-v1.index": "translation-pl.json",
    "post-day-one.index": "translation-pl.json",
    "return-it-equipment-v1.index": "translation-pl.json",
    "shipping-address-v1.addressSuggestion": "translation-pl.json",
    "shipping-address-v1.assistiveTechnologies": "translation-pl.json",
    "shipping-address-v1.countries": "translation-pl.json",
    "shipping-address-v1.hardware": "translation-pl.json",
    "shipping-address-v1.index": "translation-pl.json",
    "shipping-address-v1.schema": "translation-pl.json",
    "shipping-address-v1.schemaAE": "translation-pl.json",
    "shipping-address-v1.schemaAU": "translation-pl.json",
    "shipping-address-v1.schemaCA": "translation-pl.json",
    "shipping-address-v1.schemaCR": "translation-pl.json",
    "shipping-address-v1.schemaDE": "translation-pl.json",
    "shipping-address-v1.schemaFR": "translation-pl.json",
    "shipping-address-v1.schemaGB": "translation-pl.json",
    "shipping-address-v1.schemaIN": "translation-pl.json",
    "shipping-address-v1.schemaIT": "translation-pl.json",
    "shipping-address-v1.schemaMX": "translation-pl.json",
    "shipping-address-v1.schemaSA": "translation-pl.json",
    "shipping-address-v1.schemaSG": "translation-pl.json",
    "shipping-address-v1.schemaUS": "translation-pl.json",
    "shipping-address-v1.states": "translation-pl.json",
    "shipping-address-v1.statesAU": "translation-pl.json",
    "shipping-address-v1.statesCA": "translation-pl.json",
    "shipping-address-v1.statesFR": "translation-pl.json",
    "shipping-address-v1.statesIN": "translation-pl.json",
    "shipping-address-v1.statesUS": "translation-pl.json",
    "shipping-address-v1.submissionOutcome": "translation-pl.json",
    "shipping-address-v1.validation": "translation-pl.json",
    "text.common.index": "translation-pl.json",
    "text.landing.index": "translation-pl.json",
    "text.page-footer.index": "translation-pl.json",
    "text.sub-footer.index": "translation-pl.json",
    "unauthorized.index": "translation-pl.json",
    "videos.amazon-14-leadership-principles-jeff-bezos.index": "translation-pl.json",
    "videos.amazon-ceo-jeff-bezos-it-is-always-day-one.index": "translation-pl.json",
    "videos.andy-jassy-ceo-aws.index": "translation-pl.json",
    "videos.aws-reinvent-2019-keynote-andy-jassy.index": "translation-pl.json",
    "videos.aws-reinvent-2020.index": "translation-pl.json",
    "videos.aws-reinvent-2021-compute.index": "translation-pl.json",
    "videos.aws-reinvent-2021-data-strategies.index": "translation-pl.json",
    "videos.aws-reinvent-2021-introduction.index": "translation-pl.json",
    "videos.aws-reinvent-2021-networking.index": "translation-pl.json",
    "videos.aws-reinvent-2021-transforming-industries.index": "translation-pl.json",
    "videos.aws-reinvent-2021.index": "translation-pl.json",
    "videos.aws-welcome-message-by-adam-selipsky.index": "translation-pl.json",
    "videos.early-look.index": "translation-pl.json",
    "videos.jeff-bezos-amazon-blue-origin.index": "translation-pl.json",
    "videos.shop-talk-jeff-wilke-fortune.index": "translation-pl.json",
    "videos.tenets-and-decisions.index": "translation-pl.json",
    "videos.virtuous-cycle.index": "translation-pl.json",
    "videos.welcome-to-aws.index": "translation-pl.json",
    "videos.what-is-aws.index": "translation-pl.json",
    "videos.what-is-cloud-computing.index": "translation-pl.json",
    "work-auth.USAcorp": "translation-pl.json",
    "work-auth.c2c": "translation-pl.json",
    "work-auth.default.c2c": "translation-pl.json",
    "work-auth.default.index": "translation-pl.json",
    "work-auth.generic.index": "translation-pl.json",
    "work-auth.index": "translation-pl.json",
    "work-auth.newHireDefault": "translation-pl.json",
    "work-auth.newHireGBR": "translation-pl.json",
    "work-auth.special.ARE-corp.index": "translation-pl.json",
    "work-auth.special.AUS-corp.index": "translation-pl.json",
    "work-auth.special.CAN-corp.c2c": "translation-pl.json",
    "work-auth.special.CAN-corp.index": "translation-pl.json",
    "work-auth.special.CHN-corp.index": "translation-pl.json",
    "work-auth.special.CZE-corp.index": "translation-pl.json",
    "work-auth.special.DEU-corp.c2c": "translation-pl.json",
    "work-auth.special.DEU-corp.index": "translation-pl.json",
    "work-auth.special.DEU-ops.index": "translation-pl.json",
    "work-auth.special.ESP-corp.index": "translation-pl.json",
    "work-auth.special.GBR-corp.c2c": "translation-pl.json",
    "work-auth.special.GBR-corp.index": "translation-pl.json",
    "work-auth.special.GBR-ops.index": "translation-pl.json",
    "work-auth.special.IND-corp.index": "translation-pl.json",
    "work-auth.special.JPN-corp.index": "translation-pl.json",
    "work-auth.special.KOR-corp.index": "translation-pl.json",
    "work-auth.special.LUX-corp.index": "translation-pl.json",
    "work-auth.special.PHL-corp.index": "translation-pl.json",
    "work-auth.special.POL-corp.index": "translation-pl.json",
    "work-auth.special.PRI-corp.index": "translation-pl.json",
    "work-auth.special.SAU-corp.index": "translation-pl.json",
    "work-auth.special.SGP-corp.index": "translation-pl.json",
    "work-auth.special.USA-corp.c2c": "translation-pl.json",
    "work-auth.special.USA-corp.index": "translation-pl.json"
  },
  "pl-PL": {
    "badge-upload-v1.index": "translation-pl.json",
    "bgc-p0.index": "translation-pl.json",
    "c2c-faq.index": "translation-pl.json",
    "catch-all.index": "translation-pl.json",
    "culture.index": "translation-pl.json",
    "dashboard.c2c": "translation-pl.json",
    "dashboard.index": "translation-pl.json",
    "drug-screen-status-v1.index": "translation-pl.json",
    "faq.index": "translation-pl.json",
    "fdie-v1.index": "translation-pl.json",
    "id-verification-v1.index": "translation-pl.json",
    "immigration-v1.index": "translation-pl.json",
    "introduce-yourself-v1.index": "translation-pl.json",
    "isp-v1.index": "translation-pl.json",
    "login-selection.index": "translation-pl.json",
    "medical-check-v1.index": "translation-pl.json",
    "mydocs-v1.c2c": "translation-pl.json",
    "mydocs-v1.index": "translation-pl.json",
    "nav.index": "translation-pl.json",
    "not-found.index": "translation-pl.json",
    "pdf.diversity-inclusion.index": "translation-pl.json",
    "pdf.innovative-culture.index": "translation-pl.json",
    "pdf.shareholder-letters.index": "translation-pl.json",
    "pic-v1.index": "translation-pl.json",
    "post-day-one.index": "translation-pl.json",
    "return-it-equipment-v1.index": "translation-pl.json",
    "shipping-address-v1.addressSuggestion": "translation-pl.json",
    "shipping-address-v1.assistiveTechnologies": "translation-pl.json",
    "shipping-address-v1.countries": "translation-pl.json",
    "shipping-address-v1.hardware": "translation-pl.json",
    "shipping-address-v1.index": "translation-pl.json",
    "shipping-address-v1.schema": "translation-pl.json",
    "shipping-address-v1.schemaAE": "translation-pl.json",
    "shipping-address-v1.schemaAU": "translation-pl.json",
    "shipping-address-v1.schemaCA": "translation-pl.json",
    "shipping-address-v1.schemaCR": "translation-pl.json",
    "shipping-address-v1.schemaDE": "translation-pl.json",
    "shipping-address-v1.schemaFR": "translation-pl.json",
    "shipping-address-v1.schemaGB": "translation-pl.json",
    "shipping-address-v1.schemaIN": "translation-pl.json",
    "shipping-address-v1.schemaIT": "translation-pl.json",
    "shipping-address-v1.schemaMX": "translation-pl.json",
    "shipping-address-v1.schemaSA": "translation-pl.json",
    "shipping-address-v1.schemaSG": "translation-pl.json",
    "shipping-address-v1.schemaUS": "translation-pl.json",
    "shipping-address-v1.states": "translation-pl.json",
    "shipping-address-v1.statesAU": "translation-pl.json",
    "shipping-address-v1.statesCA": "translation-pl.json",
    "shipping-address-v1.statesFR": "translation-pl.json",
    "shipping-address-v1.statesIN": "translation-pl.json",
    "shipping-address-v1.statesUS": "translation-pl.json",
    "shipping-address-v1.submissionOutcome": "translation-pl.json",
    "shipping-address-v1.validation": "translation-pl.json",
    "text.common.index": "translation-pl.json",
    "text.landing.index": "translation-pl.json",
    "text.page-footer.index": "translation-pl.json",
    "text.sub-footer.index": "translation-pl.json",
    "unauthorized.index": "translation-pl.json",
    "videos.amazon-14-leadership-principles-jeff-bezos.index": "translation-pl.json",
    "videos.amazon-ceo-jeff-bezos-it-is-always-day-one.index": "translation-pl.json",
    "videos.andy-jassy-ceo-aws.index": "translation-pl.json",
    "videos.aws-reinvent-2019-keynote-andy-jassy.index": "translation-pl.json",
    "videos.aws-reinvent-2020.index": "translation-pl.json",
    "videos.aws-reinvent-2021-compute.index": "translation-pl.json",
    "videos.aws-reinvent-2021-data-strategies.index": "translation-pl.json",
    "videos.aws-reinvent-2021-introduction.index": "translation-pl.json",
    "videos.aws-reinvent-2021-networking.index": "translation-pl.json",
    "videos.aws-reinvent-2021-transforming-industries.index": "translation-pl.json",
    "videos.aws-reinvent-2021.index": "translation-pl.json",
    "videos.aws-welcome-message-by-adam-selipsky.index": "translation-pl.json",
    "videos.early-look.index": "translation-pl.json",
    "videos.jeff-bezos-amazon-blue-origin.index": "translation-pl.json",
    "videos.shop-talk-jeff-wilke-fortune.index": "translation-pl.json",
    "videos.tenets-and-decisions.index": "translation-pl.json",
    "videos.virtuous-cycle.index": "translation-pl.json",
    "videos.welcome-to-aws.index": "translation-pl.json",
    "videos.what-is-aws.index": "translation-pl.json",
    "videos.what-is-cloud-computing.index": "translation-pl.json",
    "work-auth.USAcorp": "translation-pl.json",
    "work-auth.c2c": "translation-pl.json",
    "work-auth.default.c2c": "translation-pl.json",
    "work-auth.default.index": "translation-pl.json",
    "work-auth.generic.index": "translation-pl.json",
    "work-auth.index": "translation-pl.json",
    "work-auth.newHireDefault": "translation-pl.json",
    "work-auth.newHireGBR": "translation-pl.json",
    "work-auth.special.ARE-corp.index": "translation-pl.json",
    "work-auth.special.AUS-corp.index": "translation-pl.json",
    "work-auth.special.CAN-corp.c2c": "translation-pl.json",
    "work-auth.special.CAN-corp.index": "translation-pl.json",
    "work-auth.special.CHN-corp.index": "translation-pl.json",
    "work-auth.special.CZE-corp.index": "translation-pl.json",
    "work-auth.special.DEU-corp.c2c": "translation-pl.json",
    "work-auth.special.DEU-corp.index": "translation-pl.json",
    "work-auth.special.DEU-ops.index": "translation-pl.json",
    "work-auth.special.ESP-corp.index": "translation-pl.json",
    "work-auth.special.GBR-corp.c2c": "translation-pl.json",
    "work-auth.special.GBR-corp.index": "translation-pl.json",
    "work-auth.special.GBR-ops.index": "translation-pl.json",
    "work-auth.special.IND-corp.index": "translation-pl.json",
    "work-auth.special.JPN-corp.index": "translation-pl.json",
    "work-auth.special.KOR-corp.index": "translation-pl.json",
    "work-auth.special.LUX-corp.index": "translation-pl.json",
    "work-auth.special.PHL-corp.index": "translation-pl.json",
    "work-auth.special.POL-corp.index": "translation-pl.json",
    "work-auth.special.PRI-corp.index": "translation-pl.json",
    "work-auth.special.SAU-corp.index": "translation-pl.json",
    "work-auth.special.SGP-corp.index": "translation-pl.json",
    "work-auth.special.USA-corp.c2c": "translation-pl.json",
    "work-auth.special.USA-corp.index": "translation-pl.json"
  },
  pt: {
    "badge-upload-v1.index": "translation-pt.json",
    "bgc-p0.index": "translation-pt.json",
    "c2c-faq.index": "translation-pt.json",
    "catch-all.index": "translation-pt.json",
    "culture.index": "translation-pt.json",
    "dashboard.c2c": "translation-pt.json",
    "dashboard.index": "translation-pt.json",
    "drug-screen-status-v1.index": "translation-pt.json",
    "faq.index": "translation-pt.json",
    "fdie-v1.index": "translation-pt.json",
    "id-verification-v1.index": "translation-pt.json",
    "immigration-v1.index": "translation-pt.json",
    "introduce-yourself-v1.index": "translation-pt.json",
    "isp-v1.index": "translation-pt.json",
    "login-selection.index": "translation-pt.json",
    "medical-check-v1.index": "translation-pt.json",
    "mydocs-v1.c2c": "translation-pt.json",
    "mydocs-v1.index": "translation-pt.json",
    "nav.index": "translation-pt.json",
    "not-found.index": "translation-pt.json",
    "pdf.diversity-inclusion.index": "translation-pt.json",
    "pdf.innovative-culture.index": "translation-pt.json",
    "pdf.shareholder-letters.index": "translation-pt.json",
    "pic-v1.index": "translation-pt.json",
    "post-day-one.index": "translation-pt.json",
    "return-it-equipment-v1.index": "translation-pt.json",
    "shipping-address-v1.addressSuggestion": "translation-pt.json",
    "shipping-address-v1.assistiveTechnologies": "translation-pt.json",
    "shipping-address-v1.countries": "translation-pt.json",
    "shipping-address-v1.hardware": "translation-pt.json",
    "shipping-address-v1.index": "translation-pt.json",
    "shipping-address-v1.schema": "translation-pt.json",
    "shipping-address-v1.schemaAE": "translation-pt.json",
    "shipping-address-v1.schemaAU": "translation-pt.json",
    "shipping-address-v1.schemaCA": "translation-pt.json",
    "shipping-address-v1.schemaCR": "translation-pt.json",
    "shipping-address-v1.schemaDE": "translation-pt.json",
    "shipping-address-v1.schemaFR": "translation-pt.json",
    "shipping-address-v1.schemaGB": "translation-pt.json",
    "shipping-address-v1.schemaIN": "translation-pt.json",
    "shipping-address-v1.schemaIT": "translation-pt.json",
    "shipping-address-v1.schemaMX": "translation-pt.json",
    "shipping-address-v1.schemaSA": "translation-pt.json",
    "shipping-address-v1.schemaSG": "translation-pt.json",
    "shipping-address-v1.schemaUS": "translation-pt.json",
    "shipping-address-v1.states": "translation-pt.json",
    "shipping-address-v1.statesAU": "translation-pt.json",
    "shipping-address-v1.statesCA": "translation-pt.json",
    "shipping-address-v1.statesFR": "translation-pt.json",
    "shipping-address-v1.statesIN": "translation-pt.json",
    "shipping-address-v1.statesUS": "translation-pt.json",
    "shipping-address-v1.submissionOutcome": "translation-pt.json",
    "shipping-address-v1.validation": "translation-pt.json",
    "text.common.index": "translation-pt.json",
    "text.landing.index": "translation-pt.json",
    "text.page-footer.index": "translation-pt.json",
    "text.sub-footer.index": "translation-pt.json",
    "unauthorized.index": "translation-pt.json",
    "videos.amazon-14-leadership-principles-jeff-bezos.index": "translation-pt.json",
    "videos.amazon-ceo-jeff-bezos-it-is-always-day-one.index": "translation-pt.json",
    "videos.andy-jassy-ceo-aws.index": "translation-pt.json",
    "videos.aws-reinvent-2019-keynote-andy-jassy.index": "translation-pt.json",
    "videos.aws-reinvent-2020.index": "translation-pt.json",
    "videos.aws-reinvent-2021-compute.index": "translation-pt.json",
    "videos.aws-reinvent-2021-data-strategies.index": "translation-pt.json",
    "videos.aws-reinvent-2021-introduction.index": "translation-pt.json",
    "videos.aws-reinvent-2021-networking.index": "translation-pt.json",
    "videos.aws-reinvent-2021-transforming-industries.index": "translation-pt.json",
    "videos.aws-reinvent-2021.index": "translation-pt.json",
    "videos.aws-welcome-message-by-adam-selipsky.index": "translation-pt.json",
    "videos.early-look.index": "translation-pt.json",
    "videos.jeff-bezos-amazon-blue-origin.index": "translation-pt.json",
    "videos.shop-talk-jeff-wilke-fortune.index": "translation-pt.json",
    "videos.tenets-and-decisions.index": "translation-pt.json",
    "videos.virtuous-cycle.index": "translation-pt.json",
    "videos.welcome-to-aws.index": "translation-pt.json",
    "videos.what-is-aws.index": "translation-pt.json",
    "videos.what-is-cloud-computing.index": "translation-pt.json",
    "work-auth.USAcorp": "translation-pt.json",
    "work-auth.c2c": "translation-pt.json",
    "work-auth.default.c2c": "translation-pt.json",
    "work-auth.default.index": "translation-pt.json",
    "work-auth.generic.index": "translation-pt.json",
    "work-auth.index": "translation-pt.json",
    "work-auth.newHireDefault": "translation-pt.json",
    "work-auth.newHireGBR": "translation-pt.json",
    "work-auth.special.ARE-corp.index": "translation-pt.json",
    "work-auth.special.AUS-corp.index": "translation-pt.json",
    "work-auth.special.CAN-corp.c2c": "translation-pt.json",
    "work-auth.special.CAN-corp.index": "translation-pt.json",
    "work-auth.special.CHN-corp.index": "translation-pt.json",
    "work-auth.special.CZE-corp.index": "translation-pt.json",
    "work-auth.special.DEU-corp.c2c": "translation-pt.json",
    "work-auth.special.DEU-corp.index": "translation-pt.json",
    "work-auth.special.DEU-ops.index": "translation-pt.json",
    "work-auth.special.ESP-corp.index": "translation-pt.json",
    "work-auth.special.GBR-corp.c2c": "translation-pt.json",
    "work-auth.special.GBR-corp.index": "translation-pt.json",
    "work-auth.special.GBR-ops.index": "translation-pt.json",
    "work-auth.special.IND-corp.index": "translation-pt.json",
    "work-auth.special.JPN-corp.index": "translation-pt.json",
    "work-auth.special.KOR-corp.index": "translation-pt.json",
    "work-auth.special.LUX-corp.index": "translation-pt.json",
    "work-auth.special.PHL-corp.index": "translation-pt.json",
    "work-auth.special.POL-corp.index": "translation-pt.json",
    "work-auth.special.PRI-corp.index": "translation-pt.json",
    "work-auth.special.SAU-corp.index": "translation-pt.json",
    "work-auth.special.SGP-corp.index": "translation-pt.json",
    "work-auth.special.USA-corp.c2c": "translation-pt.json",
    "work-auth.special.USA-corp.index": "translation-pt.json"
  },
  "pt-BR": {
    "badge-upload-v1.index": "translation-pt.json",
    "bgc-p0.index": "translation-pt.json",
    "c2c-faq.index": "translation-pt.json",
    "catch-all.index": "translation-pt.json",
    "culture.index": "translation-pt.json",
    "dashboard.c2c": "translation-pt.json",
    "dashboard.index": "translation-pt.json",
    "drug-screen-status-v1.index": "translation-pt.json",
    "faq.index": "translation-pt.json",
    "fdie-v1.index": "translation-pt.json",
    "id-verification-v1.index": "translation-pt.json",
    "immigration-v1.index": "translation-pt.json",
    "introduce-yourself-v1.index": "translation-pt.json",
    "isp-v1.index": "translation-pt.json",
    "login-selection.index": "translation-pt.json",
    "medical-check-v1.index": "translation-pt.json",
    "mydocs-v1.c2c": "translation-pt.json",
    "mydocs-v1.index": "translation-pt.json",
    "nav.index": "translation-pt.json",
    "not-found.index": "translation-pt.json",
    "pdf.diversity-inclusion.index": "translation-pt.json",
    "pdf.innovative-culture.index": "translation-pt.json",
    "pdf.shareholder-letters.index": "translation-pt.json",
    "pic-v1.index": "translation-pt.json",
    "post-day-one.index": "translation-pt.json",
    "return-it-equipment-v1.index": "translation-pt.json",
    "shipping-address-v1.addressSuggestion": "translation-pt.json",
    "shipping-address-v1.assistiveTechnologies": "translation-pt.json",
    "shipping-address-v1.countries": "translation-pt.json",
    "shipping-address-v1.hardware": "translation-pt.json",
    "shipping-address-v1.index": "translation-pt.json",
    "shipping-address-v1.schema": "translation-pt.json",
    "shipping-address-v1.schemaAE": "translation-pt.json",
    "shipping-address-v1.schemaAU": "translation-pt.json",
    "shipping-address-v1.schemaCA": "translation-pt.json",
    "shipping-address-v1.schemaCR": "translation-pt.json",
    "shipping-address-v1.schemaDE": "translation-pt.json",
    "shipping-address-v1.schemaFR": "translation-pt.json",
    "shipping-address-v1.schemaGB": "translation-pt.json",
    "shipping-address-v1.schemaIN": "translation-pt.json",
    "shipping-address-v1.schemaIT": "translation-pt.json",
    "shipping-address-v1.schemaMX": "translation-pt.json",
    "shipping-address-v1.schemaSA": "translation-pt.json",
    "shipping-address-v1.schemaSG": "translation-pt.json",
    "shipping-address-v1.schemaUS": "translation-pt.json",
    "shipping-address-v1.states": "translation-pt.json",
    "shipping-address-v1.statesAU": "translation-pt.json",
    "shipping-address-v1.statesCA": "translation-pt.json",
    "shipping-address-v1.statesFR": "translation-pt.json",
    "shipping-address-v1.statesIN": "translation-pt.json",
    "shipping-address-v1.statesUS": "translation-pt.json",
    "shipping-address-v1.submissionOutcome": "translation-pt.json",
    "shipping-address-v1.validation": "translation-pt.json",
    "text.common.index": "translation-pt.json",
    "text.landing.index": "translation-pt.json",
    "text.page-footer.index": "translation-pt.json",
    "text.sub-footer.index": "translation-pt.json",
    "unauthorized.index": "translation-pt.json",
    "videos.amazon-14-leadership-principles-jeff-bezos.index": "translation-pt.json",
    "videos.amazon-ceo-jeff-bezos-it-is-always-day-one.index": "translation-pt.json",
    "videos.andy-jassy-ceo-aws.index": "translation-pt.json",
    "videos.aws-reinvent-2019-keynote-andy-jassy.index": "translation-pt.json",
    "videos.aws-reinvent-2020.index": "translation-pt.json",
    "videos.aws-reinvent-2021-compute.index": "translation-pt.json",
    "videos.aws-reinvent-2021-data-strategies.index": "translation-pt.json",
    "videos.aws-reinvent-2021-introduction.index": "translation-pt.json",
    "videos.aws-reinvent-2021-networking.index": "translation-pt.json",
    "videos.aws-reinvent-2021-transforming-industries.index": "translation-pt.json",
    "videos.aws-reinvent-2021.index": "translation-pt.json",
    "videos.aws-welcome-message-by-adam-selipsky.index": "translation-pt.json",
    "videos.early-look.index": "translation-pt.json",
    "videos.jeff-bezos-amazon-blue-origin.index": "translation-pt.json",
    "videos.shop-talk-jeff-wilke-fortune.index": "translation-pt.json",
    "videos.tenets-and-decisions.index": "translation-pt.json",
    "videos.virtuous-cycle.index": "translation-pt.json",
    "videos.welcome-to-aws.index": "translation-pt.json",
    "videos.what-is-aws.index": "translation-pt.json",
    "videos.what-is-cloud-computing.index": "translation-pt.json",
    "work-auth.USAcorp": "translation-pt.json",
    "work-auth.c2c": "translation-pt.json",
    "work-auth.default.c2c": "translation-pt.json",
    "work-auth.default.index": "translation-pt.json",
    "work-auth.generic.index": "translation-pt.json",
    "work-auth.index": "translation-pt.json",
    "work-auth.newHireDefault": "translation-pt.json",
    "work-auth.newHireGBR": "translation-pt.json",
    "work-auth.special.ARE-corp.index": "translation-pt.json",
    "work-auth.special.AUS-corp.index": "translation-pt.json",
    "work-auth.special.CAN-corp.c2c": "translation-pt.json",
    "work-auth.special.CAN-corp.index": "translation-pt.json",
    "work-auth.special.CHN-corp.index": "translation-pt.json",
    "work-auth.special.CZE-corp.index": "translation-pt.json",
    "work-auth.special.DEU-corp.c2c": "translation-pt.json",
    "work-auth.special.DEU-corp.index": "translation-pt.json",
    "work-auth.special.DEU-ops.index": "translation-pt.json",
    "work-auth.special.ESP-corp.index": "translation-pt.json",
    "work-auth.special.GBR-corp.c2c": "translation-pt.json",
    "work-auth.special.GBR-corp.index": "translation-pt.json",
    "work-auth.special.GBR-ops.index": "translation-pt.json",
    "work-auth.special.IND-corp.index": "translation-pt.json",
    "work-auth.special.JPN-corp.index": "translation-pt.json",
    "work-auth.special.KOR-corp.index": "translation-pt.json",
    "work-auth.special.LUX-corp.index": "translation-pt.json",
    "work-auth.special.PHL-corp.index": "translation-pt.json",
    "work-auth.special.POL-corp.index": "translation-pt.json",
    "work-auth.special.PRI-corp.index": "translation-pt.json",
    "work-auth.special.SAU-corp.index": "translation-pt.json",
    "work-auth.special.SGP-corp.index": "translation-pt.json",
    "work-auth.special.USA-corp.c2c": "translation-pt.json",
    "work-auth.special.USA-corp.index": "translation-pt.json"
  },
  ro: {
    "badge-upload-v1.index": "translation-ro.json",
    "bgc-p0.index": "translation-ro.json",
    "c2c-faq.index": "translation-ro.json",
    "catch-all.index": "translation-ro.json",
    "culture.index": "translation-ro.json",
    "dashboard.c2c": "translation-ro.json",
    "dashboard.index": "translation-ro.json",
    "drug-screen-status-v1.index": "translation-ro.json",
    "faq.index": "translation-ro.json",
    "fdie-v1.index": "translation-ro.json",
    "id-verification-v1.index": "translation-ro.json",
    "immigration-v1.index": "translation-ro.json",
    "introduce-yourself-v1.index": "translation-ro.json",
    "isp-v1.index": "translation-ro.json",
    "login-selection.index": "translation-ro.json",
    "medical-check-v1.index": "translation-ro.json",
    "mydocs-v1.c2c": "translation-ro.json",
    "mydocs-v1.index": "translation-ro.json",
    "nav.index": "translation-ro.json",
    "not-found.index": "translation-ro.json",
    "pdf.diversity-inclusion.index": "translation-ro.json",
    "pdf.innovative-culture.index": "translation-ro.json",
    "pdf.shareholder-letters.index": "translation-ro.json",
    "pic-v1.index": "translation-ro.json",
    "post-day-one.index": "translation-ro.json",
    "return-it-equipment-v1.index": "translation-ro.json",
    "shipping-address-v1.addressSuggestion": "translation-ro.json",
    "shipping-address-v1.assistiveTechnologies": "translation-ro.json",
    "shipping-address-v1.countries": "translation-ro.json",
    "shipping-address-v1.hardware": "translation-ro.json",
    "shipping-address-v1.index": "translation-ro.json",
    "shipping-address-v1.schema": "translation-ro.json",
    "shipping-address-v1.schemaAE": "translation-ro.json",
    "shipping-address-v1.schemaAU": "translation-ro.json",
    "shipping-address-v1.schemaCA": "translation-ro.json",
    "shipping-address-v1.schemaCR": "translation-ro.json",
    "shipping-address-v1.schemaDE": "translation-ro.json",
    "shipping-address-v1.schemaFR": "translation-ro.json",
    "shipping-address-v1.schemaGB": "translation-ro.json",
    "shipping-address-v1.schemaIN": "translation-ro.json",
    "shipping-address-v1.schemaIT": "translation-ro.json",
    "shipping-address-v1.schemaMX": "translation-ro.json",
    "shipping-address-v1.schemaSA": "translation-ro.json",
    "shipping-address-v1.schemaSG": "translation-ro.json",
    "shipping-address-v1.schemaUS": "translation-ro.json",
    "shipping-address-v1.states": "translation-ro.json",
    "shipping-address-v1.statesAU": "translation-ro.json",
    "shipping-address-v1.statesCA": "translation-ro.json",
    "shipping-address-v1.statesFR": "translation-ro.json",
    "shipping-address-v1.statesIN": "translation-ro.json",
    "shipping-address-v1.statesUS": "translation-ro.json",
    "shipping-address-v1.submissionOutcome": "translation-ro.json",
    "shipping-address-v1.validation": "translation-ro.json",
    "text.common.index": "translation-ro.json",
    "text.landing.index": "translation-ro.json",
    "text.page-footer.index": "translation-ro.json",
    "text.sub-footer.index": "translation-ro.json",
    "unauthorized.index": "translation-ro.json",
    "videos.amazon-14-leadership-principles-jeff-bezos.index": "translation-ro.json",
    "videos.amazon-ceo-jeff-bezos-it-is-always-day-one.index": "translation-ro.json",
    "videos.andy-jassy-ceo-aws.index": "translation-ro.json",
    "videos.aws-reinvent-2019-keynote-andy-jassy.index": "translation-ro.json",
    "videos.aws-reinvent-2020.index": "translation-ro.json",
    "videos.aws-reinvent-2021-compute.index": "translation-ro.json",
    "videos.aws-reinvent-2021-data-strategies.index": "translation-ro.json",
    "videos.aws-reinvent-2021-introduction.index": "translation-ro.json",
    "videos.aws-reinvent-2021-networking.index": "translation-ro.json",
    "videos.aws-reinvent-2021-transforming-industries.index": "translation-ro.json",
    "videos.aws-reinvent-2021.index": "translation-ro.json",
    "videos.aws-welcome-message-by-adam-selipsky.index": "translation-ro.json",
    "videos.early-look.index": "translation-ro.json",
    "videos.jeff-bezos-amazon-blue-origin.index": "translation-ro.json",
    "videos.shop-talk-jeff-wilke-fortune.index": "translation-ro.json",
    "videos.tenets-and-decisions.index": "translation-ro.json",
    "videos.virtuous-cycle.index": "translation-ro.json",
    "videos.welcome-to-aws.index": "translation-ro.json",
    "videos.what-is-aws.index": "translation-ro.json",
    "videos.what-is-cloud-computing.index": "translation-ro.json",
    "work-auth.USAcorp": "translation-ro.json",
    "work-auth.c2c": "translation-ro.json",
    "work-auth.default.c2c": "translation-ro.json",
    "work-auth.default.index": "translation-ro.json",
    "work-auth.generic.index": "translation-ro.json",
    "work-auth.index": "translation-ro.json",
    "work-auth.newHireDefault": "translation-ro.json",
    "work-auth.newHireGBR": "translation-ro.json",
    "work-auth.special.ARE-corp.index": "translation-ro.json",
    "work-auth.special.AUS-corp.index": "translation-ro.json",
    "work-auth.special.CAN-corp.c2c": "translation-ro.json",
    "work-auth.special.CAN-corp.index": "translation-ro.json",
    "work-auth.special.CHN-corp.index": "translation-ro.json",
    "work-auth.special.CZE-corp.index": "translation-ro.json",
    "work-auth.special.DEU-corp.c2c": "translation-ro.json",
    "work-auth.special.DEU-corp.index": "translation-ro.json",
    "work-auth.special.DEU-ops.index": "translation-ro.json",
    "work-auth.special.ESP-corp.index": "translation-ro.json",
    "work-auth.special.GBR-corp.c2c": "translation-ro.json",
    "work-auth.special.GBR-corp.index": "translation-ro.json",
    "work-auth.special.GBR-ops.index": "translation-ro.json",
    "work-auth.special.IND-corp.index": "translation-ro.json",
    "work-auth.special.JPN-corp.index": "translation-ro.json",
    "work-auth.special.KOR-corp.index": "translation-ro.json",
    "work-auth.special.LUX-corp.index": "translation-ro.json",
    "work-auth.special.PHL-corp.index": "translation-ro.json",
    "work-auth.special.POL-corp.index": "translation-ro.json",
    "work-auth.special.PRI-corp.index": "translation-ro.json",
    "work-auth.special.SAU-corp.index": "translation-ro.json",
    "work-auth.special.SGP-corp.index": "translation-ro.json",
    "work-auth.special.USA-corp.c2c": "translation-ro.json",
    "work-auth.special.USA-corp.index": "translation-ro.json"
  },
  "ro-RO": {
    "badge-upload-v1.index": "translation-ro.json",
    "bgc-p0.index": "translation-ro.json",
    "c2c-faq.index": "translation-ro.json",
    "catch-all.index": "translation-ro.json",
    "culture.index": "translation-ro.json",
    "dashboard.c2c": "translation-ro.json",
    "dashboard.index": "translation-ro.json",
    "drug-screen-status-v1.index": "translation-ro.json",
    "faq.index": "translation-ro.json",
    "fdie-v1.index": "translation-ro.json",
    "id-verification-v1.index": "translation-ro.json",
    "immigration-v1.index": "translation-ro.json",
    "introduce-yourself-v1.index": "translation-ro.json",
    "isp-v1.index": "translation-ro.json",
    "login-selection.index": "translation-ro.json",
    "medical-check-v1.index": "translation-ro.json",
    "mydocs-v1.c2c": "translation-ro.json",
    "mydocs-v1.index": "translation-ro.json",
    "nav.index": "translation-ro.json",
    "not-found.index": "translation-ro.json",
    "pdf.diversity-inclusion.index": "translation-ro.json",
    "pdf.innovative-culture.index": "translation-ro.json",
    "pdf.shareholder-letters.index": "translation-ro.json",
    "pic-v1.index": "translation-ro.json",
    "post-day-one.index": "translation-ro.json",
    "return-it-equipment-v1.index": "translation-ro.json",
    "shipping-address-v1.addressSuggestion": "translation-ro.json",
    "shipping-address-v1.assistiveTechnologies": "translation-ro.json",
    "shipping-address-v1.countries": "translation-ro.json",
    "shipping-address-v1.hardware": "translation-ro.json",
    "shipping-address-v1.index": "translation-ro.json",
    "shipping-address-v1.schema": "translation-ro.json",
    "shipping-address-v1.schemaAE": "translation-ro.json",
    "shipping-address-v1.schemaAU": "translation-ro.json",
    "shipping-address-v1.schemaCA": "translation-ro.json",
    "shipping-address-v1.schemaCR": "translation-ro.json",
    "shipping-address-v1.schemaDE": "translation-ro.json",
    "shipping-address-v1.schemaFR": "translation-ro.json",
    "shipping-address-v1.schemaGB": "translation-ro.json",
    "shipping-address-v1.schemaIN": "translation-ro.json",
    "shipping-address-v1.schemaIT": "translation-ro.json",
    "shipping-address-v1.schemaMX": "translation-ro.json",
    "shipping-address-v1.schemaSA": "translation-ro.json",
    "shipping-address-v1.schemaSG": "translation-ro.json",
    "shipping-address-v1.schemaUS": "translation-ro.json",
    "shipping-address-v1.states": "translation-ro.json",
    "shipping-address-v1.statesAU": "translation-ro.json",
    "shipping-address-v1.statesCA": "translation-ro.json",
    "shipping-address-v1.statesFR": "translation-ro.json",
    "shipping-address-v1.statesIN": "translation-ro.json",
    "shipping-address-v1.statesUS": "translation-ro.json",
    "shipping-address-v1.submissionOutcome": "translation-ro.json",
    "shipping-address-v1.validation": "translation-ro.json",
    "text.common.index": "translation-ro.json",
    "text.landing.index": "translation-ro.json",
    "text.page-footer.index": "translation-ro.json",
    "text.sub-footer.index": "translation-ro.json",
    "unauthorized.index": "translation-ro.json",
    "videos.amazon-14-leadership-principles-jeff-bezos.index": "translation-ro.json",
    "videos.amazon-ceo-jeff-bezos-it-is-always-day-one.index": "translation-ro.json",
    "videos.andy-jassy-ceo-aws.index": "translation-ro.json",
    "videos.aws-reinvent-2019-keynote-andy-jassy.index": "translation-ro.json",
    "videos.aws-reinvent-2020.index": "translation-ro.json",
    "videos.aws-reinvent-2021-compute.index": "translation-ro.json",
    "videos.aws-reinvent-2021-data-strategies.index": "translation-ro.json",
    "videos.aws-reinvent-2021-introduction.index": "translation-ro.json",
    "videos.aws-reinvent-2021-networking.index": "translation-ro.json",
    "videos.aws-reinvent-2021-transforming-industries.index": "translation-ro.json",
    "videos.aws-reinvent-2021.index": "translation-ro.json",
    "videos.aws-welcome-message-by-adam-selipsky.index": "translation-ro.json",
    "videos.early-look.index": "translation-ro.json",
    "videos.jeff-bezos-amazon-blue-origin.index": "translation-ro.json",
    "videos.shop-talk-jeff-wilke-fortune.index": "translation-ro.json",
    "videos.tenets-and-decisions.index": "translation-ro.json",
    "videos.virtuous-cycle.index": "translation-ro.json",
    "videos.welcome-to-aws.index": "translation-ro.json",
    "videos.what-is-aws.index": "translation-ro.json",
    "videos.what-is-cloud-computing.index": "translation-ro.json",
    "work-auth.USAcorp": "translation-ro.json",
    "work-auth.c2c": "translation-ro.json",
    "work-auth.default.c2c": "translation-ro.json",
    "work-auth.default.index": "translation-ro.json",
    "work-auth.generic.index": "translation-ro.json",
    "work-auth.index": "translation-ro.json",
    "work-auth.newHireDefault": "translation-ro.json",
    "work-auth.newHireGBR": "translation-ro.json",
    "work-auth.special.ARE-corp.index": "translation-ro.json",
    "work-auth.special.AUS-corp.index": "translation-ro.json",
    "work-auth.special.CAN-corp.c2c": "translation-ro.json",
    "work-auth.special.CAN-corp.index": "translation-ro.json",
    "work-auth.special.CHN-corp.index": "translation-ro.json",
    "work-auth.special.CZE-corp.index": "translation-ro.json",
    "work-auth.special.DEU-corp.c2c": "translation-ro.json",
    "work-auth.special.DEU-corp.index": "translation-ro.json",
    "work-auth.special.DEU-ops.index": "translation-ro.json",
    "work-auth.special.ESP-corp.index": "translation-ro.json",
    "work-auth.special.GBR-corp.c2c": "translation-ro.json",
    "work-auth.special.GBR-corp.index": "translation-ro.json",
    "work-auth.special.GBR-ops.index": "translation-ro.json",
    "work-auth.special.IND-corp.index": "translation-ro.json",
    "work-auth.special.JPN-corp.index": "translation-ro.json",
    "work-auth.special.KOR-corp.index": "translation-ro.json",
    "work-auth.special.LUX-corp.index": "translation-ro.json",
    "work-auth.special.PHL-corp.index": "translation-ro.json",
    "work-auth.special.POL-corp.index": "translation-ro.json",
    "work-auth.special.PRI-corp.index": "translation-ro.json",
    "work-auth.special.SAU-corp.index": "translation-ro.json",
    "work-auth.special.SGP-corp.index": "translation-ro.json",
    "work-auth.special.USA-corp.c2c": "translation-ro.json",
    "work-auth.special.USA-corp.index": "translation-ro.json"
  },
  sk: {
    "badge-upload-v1.index": "translation-sk.json",
    "bgc-p0.index": "translation-sk.json",
    "c2c-faq.index": "translation-sk.json",
    "catch-all.index": "translation-sk.json",
    "culture.index": "translation-sk.json",
    "dashboard.c2c": "translation-sk.json",
    "dashboard.index": "translation-sk.json",
    "drug-screen-status-v1.index": "translation-sk.json",
    "faq.index": "translation-sk.json",
    "fdie-v1.index": "translation-sk.json",
    "id-verification-v1.index": "translation-sk.json",
    "immigration-v1.index": "translation-sk.json",
    "introduce-yourself-v1.index": "translation-sk.json",
    "isp-v1.index": "translation-sk.json",
    "login-selection.index": "translation-sk.json",
    "medical-check-v1.index": "translation-sk.json",
    "mydocs-v1.c2c": "translation-sk.json",
    "mydocs-v1.index": "translation-sk.json",
    "nav.index": "translation-sk.json",
    "not-found.index": "translation-sk.json",
    "pdf.diversity-inclusion.index": "translation-sk.json",
    "pdf.innovative-culture.index": "translation-sk.json",
    "pdf.shareholder-letters.index": "translation-sk.json",
    "pic-v1.index": "translation-sk.json",
    "post-day-one.index": "translation-sk.json",
    "return-it-equipment-v1.index": "translation-sk.json",
    "shipping-address-v1.addressSuggestion": "translation-sk.json",
    "shipping-address-v1.assistiveTechnologies": "translation-sk.json",
    "shipping-address-v1.countries": "translation-sk.json",
    "shipping-address-v1.hardware": "translation-sk.json",
    "shipping-address-v1.index": "translation-sk.json",
    "shipping-address-v1.schema": "translation-sk.json",
    "shipping-address-v1.schemaAE": "translation-sk.json",
    "shipping-address-v1.schemaAU": "translation-sk.json",
    "shipping-address-v1.schemaCA": "translation-sk.json",
    "shipping-address-v1.schemaCR": "translation-sk.json",
    "shipping-address-v1.schemaDE": "translation-sk.json",
    "shipping-address-v1.schemaFR": "translation-sk.json",
    "shipping-address-v1.schemaGB": "translation-sk.json",
    "shipping-address-v1.schemaIN": "translation-sk.json",
    "shipping-address-v1.schemaIT": "translation-sk.json",
    "shipping-address-v1.schemaMX": "translation-sk.json",
    "shipping-address-v1.schemaSA": "translation-sk.json",
    "shipping-address-v1.schemaSG": "translation-sk.json",
    "shipping-address-v1.schemaUS": "translation-sk.json",
    "shipping-address-v1.states": "translation-sk.json",
    "shipping-address-v1.statesAU": "translation-sk.json",
    "shipping-address-v1.statesCA": "translation-sk.json",
    "shipping-address-v1.statesFR": "translation-sk.json",
    "shipping-address-v1.statesIN": "translation-sk.json",
    "shipping-address-v1.statesUS": "translation-sk.json",
    "shipping-address-v1.submissionOutcome": "translation-sk.json",
    "shipping-address-v1.validation": "translation-sk.json",
    "text.common.index": "translation-sk.json",
    "text.landing.index": "translation-sk.json",
    "text.page-footer.index": "translation-sk.json",
    "text.sub-footer.index": "translation-sk.json",
    "unauthorized.index": "translation-sk.json",
    "videos.amazon-14-leadership-principles-jeff-bezos.index": "translation-sk.json",
    "videos.amazon-ceo-jeff-bezos-it-is-always-day-one.index": "translation-sk.json",
    "videos.andy-jassy-ceo-aws.index": "translation-sk.json",
    "videos.aws-reinvent-2019-keynote-andy-jassy.index": "translation-sk.json",
    "videos.aws-reinvent-2020.index": "translation-sk.json",
    "videos.aws-reinvent-2021-compute.index": "translation-sk.json",
    "videos.aws-reinvent-2021-data-strategies.index": "translation-sk.json",
    "videos.aws-reinvent-2021-introduction.index": "translation-sk.json",
    "videos.aws-reinvent-2021-networking.index": "translation-sk.json",
    "videos.aws-reinvent-2021-transforming-industries.index": "translation-sk.json",
    "videos.aws-reinvent-2021.index": "translation-sk.json",
    "videos.aws-welcome-message-by-adam-selipsky.index": "translation-sk.json",
    "videos.early-look.index": "translation-sk.json",
    "videos.jeff-bezos-amazon-blue-origin.index": "translation-sk.json",
    "videos.shop-talk-jeff-wilke-fortune.index": "translation-sk.json",
    "videos.tenets-and-decisions.index": "translation-sk.json",
    "videos.virtuous-cycle.index": "translation-sk.json",
    "videos.welcome-to-aws.index": "translation-sk.json",
    "videos.what-is-aws.index": "translation-sk.json",
    "videos.what-is-cloud-computing.index": "translation-sk.json",
    "work-auth.USAcorp": "translation-sk.json",
    "work-auth.c2c": "translation-sk.json",
    "work-auth.default.c2c": "translation-sk.json",
    "work-auth.default.index": "translation-sk.json",
    "work-auth.generic.index": "translation-sk.json",
    "work-auth.index": "translation-sk.json",
    "work-auth.newHireDefault": "translation-sk.json",
    "work-auth.newHireGBR": "translation-sk.json",
    "work-auth.special.ARE-corp.index": "translation-sk.json",
    "work-auth.special.AUS-corp.index": "translation-sk.json",
    "work-auth.special.CAN-corp.c2c": "translation-sk.json",
    "work-auth.special.CAN-corp.index": "translation-sk.json",
    "work-auth.special.CHN-corp.index": "translation-sk.json",
    "work-auth.special.CZE-corp.index": "translation-sk.json",
    "work-auth.special.DEU-corp.c2c": "translation-sk.json",
    "work-auth.special.DEU-corp.index": "translation-sk.json",
    "work-auth.special.DEU-ops.index": "translation-sk.json",
    "work-auth.special.ESP-corp.index": "translation-sk.json",
    "work-auth.special.GBR-corp.c2c": "translation-sk.json",
    "work-auth.special.GBR-corp.index": "translation-sk.json",
    "work-auth.special.GBR-ops.index": "translation-sk.json",
    "work-auth.special.IND-corp.index": "translation-sk.json",
    "work-auth.special.JPN-corp.index": "translation-sk.json",
    "work-auth.special.KOR-corp.index": "translation-sk.json",
    "work-auth.special.LUX-corp.index": "translation-sk.json",
    "work-auth.special.PHL-corp.index": "translation-sk.json",
    "work-auth.special.POL-corp.index": "translation-sk.json",
    "work-auth.special.PRI-corp.index": "translation-sk.json",
    "work-auth.special.SAU-corp.index": "translation-sk.json",
    "work-auth.special.SGP-corp.index": "translation-sk.json",
    "work-auth.special.USA-corp.c2c": "translation-sk.json",
    "work-auth.special.USA-corp.index": "translation-sk.json"
  },
  "sk-SK": {
    "badge-upload-v1.index": "translation-sk.json",
    "bgc-p0.index": "translation-sk.json",
    "c2c-faq.index": "translation-sk.json",
    "catch-all.index": "translation-sk.json",
    "culture.index": "translation-sk.json",
    "dashboard.c2c": "translation-sk.json",
    "dashboard.index": "translation-sk.json",
    "drug-screen-status-v1.index": "translation-sk.json",
    "faq.index": "translation-sk.json",
    "fdie-v1.index": "translation-sk.json",
    "id-verification-v1.index": "translation-sk.json",
    "immigration-v1.index": "translation-sk.json",
    "introduce-yourself-v1.index": "translation-sk.json",
    "isp-v1.index": "translation-sk.json",
    "login-selection.index": "translation-sk.json",
    "medical-check-v1.index": "translation-sk.json",
    "mydocs-v1.c2c": "translation-sk.json",
    "mydocs-v1.index": "translation-sk.json",
    "nav.index": "translation-sk.json",
    "not-found.index": "translation-sk.json",
    "pdf.diversity-inclusion.index": "translation-sk.json",
    "pdf.innovative-culture.index": "translation-sk.json",
    "pdf.shareholder-letters.index": "translation-sk.json",
    "pic-v1.index": "translation-sk.json",
    "post-day-one.index": "translation-sk.json",
    "return-it-equipment-v1.index": "translation-sk.json",
    "shipping-address-v1.addressSuggestion": "translation-sk.json",
    "shipping-address-v1.assistiveTechnologies": "translation-sk.json",
    "shipping-address-v1.countries": "translation-sk.json",
    "shipping-address-v1.hardware": "translation-sk.json",
    "shipping-address-v1.index": "translation-sk.json",
    "shipping-address-v1.schema": "translation-sk.json",
    "shipping-address-v1.schemaAE": "translation-sk.json",
    "shipping-address-v1.schemaAU": "translation-sk.json",
    "shipping-address-v1.schemaCA": "translation-sk.json",
    "shipping-address-v1.schemaCR": "translation-sk.json",
    "shipping-address-v1.schemaDE": "translation-sk.json",
    "shipping-address-v1.schemaFR": "translation-sk.json",
    "shipping-address-v1.schemaGB": "translation-sk.json",
    "shipping-address-v1.schemaIN": "translation-sk.json",
    "shipping-address-v1.schemaIT": "translation-sk.json",
    "shipping-address-v1.schemaMX": "translation-sk.json",
    "shipping-address-v1.schemaSA": "translation-sk.json",
    "shipping-address-v1.schemaSG": "translation-sk.json",
    "shipping-address-v1.schemaUS": "translation-sk.json",
    "shipping-address-v1.states": "translation-sk.json",
    "shipping-address-v1.statesAU": "translation-sk.json",
    "shipping-address-v1.statesCA": "translation-sk.json",
    "shipping-address-v1.statesFR": "translation-sk.json",
    "shipping-address-v1.statesIN": "translation-sk.json",
    "shipping-address-v1.statesUS": "translation-sk.json",
    "shipping-address-v1.submissionOutcome": "translation-sk.json",
    "shipping-address-v1.validation": "translation-sk.json",
    "text.common.index": "translation-sk.json",
    "text.landing.index": "translation-sk.json",
    "text.page-footer.index": "translation-sk.json",
    "text.sub-footer.index": "translation-sk.json",
    "unauthorized.index": "translation-sk.json",
    "videos.amazon-14-leadership-principles-jeff-bezos.index": "translation-sk.json",
    "videos.amazon-ceo-jeff-bezos-it-is-always-day-one.index": "translation-sk.json",
    "videos.andy-jassy-ceo-aws.index": "translation-sk.json",
    "videos.aws-reinvent-2019-keynote-andy-jassy.index": "translation-sk.json",
    "videos.aws-reinvent-2020.index": "translation-sk.json",
    "videos.aws-reinvent-2021-compute.index": "translation-sk.json",
    "videos.aws-reinvent-2021-data-strategies.index": "translation-sk.json",
    "videos.aws-reinvent-2021-introduction.index": "translation-sk.json",
    "videos.aws-reinvent-2021-networking.index": "translation-sk.json",
    "videos.aws-reinvent-2021-transforming-industries.index": "translation-sk.json",
    "videos.aws-reinvent-2021.index": "translation-sk.json",
    "videos.aws-welcome-message-by-adam-selipsky.index": "translation-sk.json",
    "videos.early-look.index": "translation-sk.json",
    "videos.jeff-bezos-amazon-blue-origin.index": "translation-sk.json",
    "videos.shop-talk-jeff-wilke-fortune.index": "translation-sk.json",
    "videos.tenets-and-decisions.index": "translation-sk.json",
    "videos.virtuous-cycle.index": "translation-sk.json",
    "videos.welcome-to-aws.index": "translation-sk.json",
    "videos.what-is-aws.index": "translation-sk.json",
    "videos.what-is-cloud-computing.index": "translation-sk.json",
    "work-auth.USAcorp": "translation-sk.json",
    "work-auth.c2c": "translation-sk.json",
    "work-auth.default.c2c": "translation-sk.json",
    "work-auth.default.index": "translation-sk.json",
    "work-auth.generic.index": "translation-sk.json",
    "work-auth.index": "translation-sk.json",
    "work-auth.newHireDefault": "translation-sk.json",
    "work-auth.newHireGBR": "translation-sk.json",
    "work-auth.special.ARE-corp.index": "translation-sk.json",
    "work-auth.special.AUS-corp.index": "translation-sk.json",
    "work-auth.special.CAN-corp.c2c": "translation-sk.json",
    "work-auth.special.CAN-corp.index": "translation-sk.json",
    "work-auth.special.CHN-corp.index": "translation-sk.json",
    "work-auth.special.CZE-corp.index": "translation-sk.json",
    "work-auth.special.DEU-corp.c2c": "translation-sk.json",
    "work-auth.special.DEU-corp.index": "translation-sk.json",
    "work-auth.special.DEU-ops.index": "translation-sk.json",
    "work-auth.special.ESP-corp.index": "translation-sk.json",
    "work-auth.special.GBR-corp.c2c": "translation-sk.json",
    "work-auth.special.GBR-corp.index": "translation-sk.json",
    "work-auth.special.GBR-ops.index": "translation-sk.json",
    "work-auth.special.IND-corp.index": "translation-sk.json",
    "work-auth.special.JPN-corp.index": "translation-sk.json",
    "work-auth.special.KOR-corp.index": "translation-sk.json",
    "work-auth.special.LUX-corp.index": "translation-sk.json",
    "work-auth.special.PHL-corp.index": "translation-sk.json",
    "work-auth.special.POL-corp.index": "translation-sk.json",
    "work-auth.special.PRI-corp.index": "translation-sk.json",
    "work-auth.special.SAU-corp.index": "translation-sk.json",
    "work-auth.special.SGP-corp.index": "translation-sk.json",
    "work-auth.special.USA-corp.c2c": "translation-sk.json",
    "work-auth.special.USA-corp.index": "translation-sk.json"
  },
  tr: {
    "badge-upload-v1.index": "translation-tr.json",
    "bgc-p0.index": "translation-tr.json",
    "c2c-faq.index": "translation-tr.json",
    "catch-all.index": "translation-tr.json",
    "culture.index": "translation-tr.json",
    "dashboard.c2c": "translation-tr.json",
    "dashboard.index": "translation-tr.json",
    "drug-screen-status-v1.index": "translation-tr.json",
    "faq.index": "translation-tr.json",
    "fdie-v1.index": "translation-tr.json",
    "id-verification-v1.index": "translation-tr.json",
    "immigration-v1.index": "translation-tr.json",
    "introduce-yourself-v1.index": "translation-tr.json",
    "isp-v1.index": "translation-tr.json",
    "login-selection.index": "translation-tr.json",
    "medical-check-v1.index": "translation-tr.json",
    "mydocs-v1.c2c": "translation-tr.json",
    "mydocs-v1.index": "translation-tr.json",
    "nav.index": "translation-tr.json",
    "not-found.index": "translation-tr.json",
    "pdf.diversity-inclusion.index": "translation-tr.json",
    "pdf.innovative-culture.index": "translation-tr.json",
    "pdf.shareholder-letters.index": "translation-tr.json",
    "pic-v1.index": "translation-tr.json",
    "post-day-one.index": "translation-tr.json",
    "return-it-equipment-v1.index": "translation-tr.json",
    "shipping-address-v1.addressSuggestion": "translation-tr.json",
    "shipping-address-v1.assistiveTechnologies": "translation-tr.json",
    "shipping-address-v1.countries": "translation-tr.json",
    "shipping-address-v1.hardware": "translation-tr.json",
    "shipping-address-v1.index": "translation-tr.json",
    "shipping-address-v1.schema": "translation-tr.json",
    "shipping-address-v1.schemaAE": "translation-tr.json",
    "shipping-address-v1.schemaAU": "translation-tr.json",
    "shipping-address-v1.schemaCA": "translation-tr.json",
    "shipping-address-v1.schemaCR": "translation-tr.json",
    "shipping-address-v1.schemaDE": "translation-tr.json",
    "shipping-address-v1.schemaFR": "translation-tr.json",
    "shipping-address-v1.schemaGB": "translation-tr.json",
    "shipping-address-v1.schemaIN": "translation-tr.json",
    "shipping-address-v1.schemaIT": "translation-tr.json",
    "shipping-address-v1.schemaMX": "translation-tr.json",
    "shipping-address-v1.schemaSA": "translation-tr.json",
    "shipping-address-v1.schemaSG": "translation-tr.json",
    "shipping-address-v1.schemaUS": "translation-tr.json",
    "shipping-address-v1.states": "translation-tr.json",
    "shipping-address-v1.statesAU": "translation-tr.json",
    "shipping-address-v1.statesCA": "translation-tr.json",
    "shipping-address-v1.statesFR": "translation-tr.json",
    "shipping-address-v1.statesIN": "translation-tr.json",
    "shipping-address-v1.statesUS": "translation-tr.json",
    "shipping-address-v1.submissionOutcome": "translation-tr.json",
    "shipping-address-v1.validation": "translation-tr.json",
    "text.common.index": "translation-tr.json",
    "text.landing.index": "translation-tr.json",
    "text.page-footer.index": "translation-tr.json",
    "text.sub-footer.index": "translation-tr.json",
    "unauthorized.index": "translation-tr.json",
    "videos.amazon-14-leadership-principles-jeff-bezos.index": "translation-tr.json",
    "videos.amazon-ceo-jeff-bezos-it-is-always-day-one.index": "translation-tr.json",
    "videos.andy-jassy-ceo-aws.index": "translation-tr.json",
    "videos.aws-reinvent-2019-keynote-andy-jassy.index": "translation-tr.json",
    "videos.aws-reinvent-2020.index": "translation-tr.json",
    "videos.aws-reinvent-2021-compute.index": "translation-tr.json",
    "videos.aws-reinvent-2021-data-strategies.index": "translation-tr.json",
    "videos.aws-reinvent-2021-introduction.index": "translation-tr.json",
    "videos.aws-reinvent-2021-networking.index": "translation-tr.json",
    "videos.aws-reinvent-2021-transforming-industries.index": "translation-tr.json",
    "videos.aws-reinvent-2021.index": "translation-tr.json",
    "videos.aws-welcome-message-by-adam-selipsky.index": "translation-tr.json",
    "videos.early-look.index": "translation-tr.json",
    "videos.jeff-bezos-amazon-blue-origin.index": "translation-tr.json",
    "videos.shop-talk-jeff-wilke-fortune.index": "translation-tr.json",
    "videos.tenets-and-decisions.index": "translation-tr.json",
    "videos.virtuous-cycle.index": "translation-tr.json",
    "videos.welcome-to-aws.index": "translation-tr.json",
    "videos.what-is-aws.index": "translation-tr.json",
    "videos.what-is-cloud-computing.index": "translation-tr.json",
    "work-auth.USAcorp": "translation-tr.json",
    "work-auth.c2c": "translation-tr.json",
    "work-auth.default.c2c": "translation-tr.json",
    "work-auth.default.index": "translation-tr.json",
    "work-auth.generic.index": "translation-tr.json",
    "work-auth.index": "translation-tr.json",
    "work-auth.newHireDefault": "translation-tr.json",
    "work-auth.newHireGBR": "translation-tr.json",
    "work-auth.special.ARE-corp.index": "translation-tr.json",
    "work-auth.special.AUS-corp.index": "translation-tr.json",
    "work-auth.special.CAN-corp.c2c": "translation-tr.json",
    "work-auth.special.CAN-corp.index": "translation-tr.json",
    "work-auth.special.CHN-corp.index": "translation-tr.json",
    "work-auth.special.CZE-corp.index": "translation-tr.json",
    "work-auth.special.DEU-corp.c2c": "translation-tr.json",
    "work-auth.special.DEU-corp.index": "translation-tr.json",
    "work-auth.special.DEU-ops.index": "translation-tr.json",
    "work-auth.special.ESP-corp.index": "translation-tr.json",
    "work-auth.special.GBR-corp.c2c": "translation-tr.json",
    "work-auth.special.GBR-corp.index": "translation-tr.json",
    "work-auth.special.GBR-ops.index": "translation-tr.json",
    "work-auth.special.IND-corp.index": "translation-tr.json",
    "work-auth.special.JPN-corp.index": "translation-tr.json",
    "work-auth.special.KOR-corp.index": "translation-tr.json",
    "work-auth.special.LUX-corp.index": "translation-tr.json",
    "work-auth.special.PHL-corp.index": "translation-tr.json",
    "work-auth.special.POL-corp.index": "translation-tr.json",
    "work-auth.special.PRI-corp.index": "translation-tr.json",
    "work-auth.special.SAU-corp.index": "translation-tr.json",
    "work-auth.special.SGP-corp.index": "translation-tr.json",
    "work-auth.special.USA-corp.c2c": "translation-tr.json",
    "work-auth.special.USA-corp.index": "translation-tr.json"
  },
  "tr-TR": {
    "badge-upload-v1.index": "translation-tr.json",
    "bgc-p0.index": "translation-tr.json",
    "c2c-faq.index": "translation-tr.json",
    "catch-all.index": "translation-tr.json",
    "culture.index": "translation-tr.json",
    "dashboard.c2c": "translation-tr.json",
    "dashboard.index": "translation-tr.json",
    "drug-screen-status-v1.index": "translation-tr.json",
    "faq.index": "translation-tr.json",
    "fdie-v1.index": "translation-tr.json",
    "id-verification-v1.index": "translation-tr.json",
    "immigration-v1.index": "translation-tr.json",
    "introduce-yourself-v1.index": "translation-tr.json",
    "isp-v1.index": "translation-tr.json",
    "login-selection.index": "translation-tr.json",
    "medical-check-v1.index": "translation-tr.json",
    "mydocs-v1.c2c": "translation-tr.json",
    "mydocs-v1.index": "translation-tr.json",
    "nav.index": "translation-tr.json",
    "not-found.index": "translation-tr.json",
    "pdf.diversity-inclusion.index": "translation-tr.json",
    "pdf.innovative-culture.index": "translation-tr.json",
    "pdf.shareholder-letters.index": "translation-tr.json",
    "pic-v1.index": "translation-tr.json",
    "post-day-one.index": "translation-tr.json",
    "return-it-equipment-v1.index": "translation-tr.json",
    "shipping-address-v1.addressSuggestion": "translation-tr.json",
    "shipping-address-v1.assistiveTechnologies": "translation-tr.json",
    "shipping-address-v1.countries": "translation-tr.json",
    "shipping-address-v1.hardware": "translation-tr.json",
    "shipping-address-v1.index": "translation-tr.json",
    "shipping-address-v1.schema": "translation-tr.json",
    "shipping-address-v1.schemaAE": "translation-tr.json",
    "shipping-address-v1.schemaAU": "translation-tr.json",
    "shipping-address-v1.schemaCA": "translation-tr.json",
    "shipping-address-v1.schemaCR": "translation-tr.json",
    "shipping-address-v1.schemaDE": "translation-tr.json",
    "shipping-address-v1.schemaFR": "translation-tr.json",
    "shipping-address-v1.schemaGB": "translation-tr.json",
    "shipping-address-v1.schemaIN": "translation-tr.json",
    "shipping-address-v1.schemaIT": "translation-tr.json",
    "shipping-address-v1.schemaMX": "translation-tr.json",
    "shipping-address-v1.schemaSA": "translation-tr.json",
    "shipping-address-v1.schemaSG": "translation-tr.json",
    "shipping-address-v1.schemaUS": "translation-tr.json",
    "shipping-address-v1.states": "translation-tr.json",
    "shipping-address-v1.statesAU": "translation-tr.json",
    "shipping-address-v1.statesCA": "translation-tr.json",
    "shipping-address-v1.statesFR": "translation-tr.json",
    "shipping-address-v1.statesIN": "translation-tr.json",
    "shipping-address-v1.statesUS": "translation-tr.json",
    "shipping-address-v1.submissionOutcome": "translation-tr.json",
    "shipping-address-v1.validation": "translation-tr.json",
    "text.common.index": "translation-tr.json",
    "text.landing.index": "translation-tr.json",
    "text.page-footer.index": "translation-tr.json",
    "text.sub-footer.index": "translation-tr.json",
    "unauthorized.index": "translation-tr.json",
    "videos.amazon-14-leadership-principles-jeff-bezos.index": "translation-tr.json",
    "videos.amazon-ceo-jeff-bezos-it-is-always-day-one.index": "translation-tr.json",
    "videos.andy-jassy-ceo-aws.index": "translation-tr.json",
    "videos.aws-reinvent-2019-keynote-andy-jassy.index": "translation-tr.json",
    "videos.aws-reinvent-2020.index": "translation-tr.json",
    "videos.aws-reinvent-2021-compute.index": "translation-tr.json",
    "videos.aws-reinvent-2021-data-strategies.index": "translation-tr.json",
    "videos.aws-reinvent-2021-introduction.index": "translation-tr.json",
    "videos.aws-reinvent-2021-networking.index": "translation-tr.json",
    "videos.aws-reinvent-2021-transforming-industries.index": "translation-tr.json",
    "videos.aws-reinvent-2021.index": "translation-tr.json",
    "videos.aws-welcome-message-by-adam-selipsky.index": "translation-tr.json",
    "videos.early-look.index": "translation-tr.json",
    "videos.jeff-bezos-amazon-blue-origin.index": "translation-tr.json",
    "videos.shop-talk-jeff-wilke-fortune.index": "translation-tr.json",
    "videos.tenets-and-decisions.index": "translation-tr.json",
    "videos.virtuous-cycle.index": "translation-tr.json",
    "videos.welcome-to-aws.index": "translation-tr.json",
    "videos.what-is-aws.index": "translation-tr.json",
    "videos.what-is-cloud-computing.index": "translation-tr.json",
    "work-auth.USAcorp": "translation-tr.json",
    "work-auth.c2c": "translation-tr.json",
    "work-auth.default.c2c": "translation-tr.json",
    "work-auth.default.index": "translation-tr.json",
    "work-auth.generic.index": "translation-tr.json",
    "work-auth.index": "translation-tr.json",
    "work-auth.newHireDefault": "translation-tr.json",
    "work-auth.newHireGBR": "translation-tr.json",
    "work-auth.special.ARE-corp.index": "translation-tr.json",
    "work-auth.special.AUS-corp.index": "translation-tr.json",
    "work-auth.special.CAN-corp.c2c": "translation-tr.json",
    "work-auth.special.CAN-corp.index": "translation-tr.json",
    "work-auth.special.CHN-corp.index": "translation-tr.json",
    "work-auth.special.CZE-corp.index": "translation-tr.json",
    "work-auth.special.DEU-corp.c2c": "translation-tr.json",
    "work-auth.special.DEU-corp.index": "translation-tr.json",
    "work-auth.special.DEU-ops.index": "translation-tr.json",
    "work-auth.special.ESP-corp.index": "translation-tr.json",
    "work-auth.special.GBR-corp.c2c": "translation-tr.json",
    "work-auth.special.GBR-corp.index": "translation-tr.json",
    "work-auth.special.GBR-ops.index": "translation-tr.json",
    "work-auth.special.IND-corp.index": "translation-tr.json",
    "work-auth.special.JPN-corp.index": "translation-tr.json",
    "work-auth.special.KOR-corp.index": "translation-tr.json",
    "work-auth.special.LUX-corp.index": "translation-tr.json",
    "work-auth.special.PHL-corp.index": "translation-tr.json",
    "work-auth.special.POL-corp.index": "translation-tr.json",
    "work-auth.special.PRI-corp.index": "translation-tr.json",
    "work-auth.special.SAU-corp.index": "translation-tr.json",
    "work-auth.special.SGP-corp.index": "translation-tr.json",
    "work-auth.special.USA-corp.c2c": "translation-tr.json",
    "work-auth.special.USA-corp.index": "translation-tr.json"
  },
  und: {
    "badge-upload-v1.index": "translation.json",
    "bgc-p0.index": "translation.json",
    "c2c-faq.index": "translation.json",
    "catch-all.index": "translation.json",
    "culture.index": "translation.json",
    "dashboard.c2c": "translation.json",
    "dashboard.index": "translation.json",
    "drug-screen-status-v1.index": "translation.json",
    "faq.index": "translation.json",
    "fdie-v1.index": "translation.json",
    "id-verification-v1.index": "translation.json",
    "immigration-v1.index": "translation.json",
    "introduce-yourself-v1.index": "translation.json",
    "isp-v1.index": "translation.json",
    "login-selection.index": "translation.json",
    "medical-check-v1.index": "translation.json",
    "mydocs-v1.c2c": "translation.json",
    "mydocs-v1.index": "translation.json",
    "nav.index": "translation.json",
    "not-found.index": "translation.json",
    "pdf.diversity-inclusion.index": "translation.json",
    "pdf.innovative-culture.index": "translation.json",
    "pdf.shareholder-letters.index": "translation.json",
    "pic-v1.index": "translation.json",
    "post-day-one.index": "translation.json",
    "return-it-equipment-v1.index": "translation.json",
    "shipping-address-v1.addressSuggestion": "translation.json",
    "shipping-address-v1.assistiveTechnologies": "translation.json",
    "shipping-address-v1.countries": "translation.json",
    "shipping-address-v1.hardware": "translation.json",
    "shipping-address-v1.index": "translation.json",
    "shipping-address-v1.schema": "translation.json",
    "shipping-address-v1.schemaAE": "translation.json",
    "shipping-address-v1.schemaAU": "translation.json",
    "shipping-address-v1.schemaCA": "translation.json",
    "shipping-address-v1.schemaCR": "translation.json",
    "shipping-address-v1.schemaDE": "translation.json",
    "shipping-address-v1.schemaFR": "translation.json",
    "shipping-address-v1.schemaGB": "translation.json",
    "shipping-address-v1.schemaIN": "translation.json",
    "shipping-address-v1.schemaIT": "translation.json",
    "shipping-address-v1.schemaMX": "translation.json",
    "shipping-address-v1.schemaSA": "translation.json",
    "shipping-address-v1.schemaSG": "translation.json",
    "shipping-address-v1.schemaUS": "translation.json",
    "shipping-address-v1.states": "translation.json",
    "shipping-address-v1.statesAU": "translation.json",
    "shipping-address-v1.statesCA": "translation.json",
    "shipping-address-v1.statesFR": "translation.json",
    "shipping-address-v1.statesIN": "translation.json",
    "shipping-address-v1.statesUS": "translation.json",
    "shipping-address-v1.submissionOutcome": "translation.json",
    "shipping-address-v1.validation": "translation.json",
    "tests.index": "translation.json",
    "text.common.index": "translation.json",
    "text.landing.index": "translation.json",
    "text.page-footer.index": "translation.json",
    "text.sub-footer.index": "translation.json",
    "unauthorized.index": "translation.json",
    "videos.amazon-14-leadership-principles-jeff-bezos.index": "translation.json",
    "videos.amazon-ceo-jeff-bezos-it-is-always-day-one.index": "translation.json",
    "videos.andy-jassy-ceo-aws.index": "translation.json",
    "videos.aws-reinvent-2019-keynote-andy-jassy.index": "translation.json",
    "videos.aws-reinvent-2020.index": "translation.json",
    "videos.aws-reinvent-2021-compute.index": "translation.json",
    "videos.aws-reinvent-2021-data-strategies.index": "translation.json",
    "videos.aws-reinvent-2021-introduction.index": "translation.json",
    "videos.aws-reinvent-2021-networking.index": "translation.json",
    "videos.aws-reinvent-2021-transforming-industries.index": "translation.json",
    "videos.aws-reinvent-2021.index": "translation.json",
    "videos.aws-welcome-message-by-adam-selipsky.index": "translation.json",
    "videos.early-look.index": "translation.json",
    "videos.jeff-bezos-amazon-blue-origin.index": "translation.json",
    "videos.shop-talk-jeff-wilke-fortune.index": "translation.json",
    "videos.tenets-and-decisions.index": "translation.json",
    "videos.virtuous-cycle.index": "translation.json",
    "videos.welcome-to-aws.index": "translation.json",
    "videos.what-is-aws.index": "translation.json",
    "videos.what-is-cloud-computing.index": "translation.json",
    "work-auth.USAcorp": "translation.json",
    "work-auth.c2c": "translation.json",
    "work-auth.default.c2c": "translation.json",
    "work-auth.default.index": "translation.json",
    "work-auth.generic.index": "translation.json",
    "work-auth.index": "translation.json",
    "work-auth.newHireDefault": "translation.json",
    "work-auth.newHireGBR": "translation.json",
    "work-auth.special.ARE-corp.index": "translation.json",
    "work-auth.special.AUS-corp.index": "translation.json",
    "work-auth.special.CAN-corp.c2c": "translation.json",
    "work-auth.special.CAN-corp.index": "translation.json",
    "work-auth.special.CHN-corp.index": "translation.json",
    "work-auth.special.CZE-corp.index": "translation.json",
    "work-auth.special.DEU-corp.c2c": "translation.json",
    "work-auth.special.DEU-corp.index": "translation.json",
    "work-auth.special.DEU-ops.index": "translation.json",
    "work-auth.special.ESP-corp.index": "translation.json",
    "work-auth.special.GBR-corp.c2c": "translation.json",
    "work-auth.special.GBR-corp.index": "translation.json",
    "work-auth.special.GBR-ops.index": "translation.json",
    "work-auth.special.IND-corp.index": "translation.json",
    "work-auth.special.JPN-corp.index": "translation.json",
    "work-auth.special.KOR-corp.index": "translation.json",
    "work-auth.special.LUX-corp.index": "translation.json",
    "work-auth.special.PHL-corp.index": "translation.json",
    "work-auth.special.POL-corp.index": "translation.json",
    "work-auth.special.PRI-corp.index": "translation.json",
    "work-auth.special.SAU-corp.index": "translation.json",
    "work-auth.special.SGP-corp.index": "translation.json",
    "work-auth.special.USA-corp.c2c": "translation.json",
    "work-auth.special.USA-corp.index": "translation.json"
  },
  zh: {
    "badge-upload-v1.index": "translation-zh.json",
    "bgc-p0.index": "translation-zh.json",
    "c2c-faq.index": "translation-zh.json",
    "catch-all.index": "translation-zh.json",
    "culture.index": "translation-zh.json",
    "dashboard.c2c": "translation-zh.json",
    "dashboard.index": "translation-zh.json",
    "drug-screen-status-v1.index": "translation-zh.json",
    "faq.index": "translation-zh.json",
    "fdie-v1.index": "translation-zh.json",
    "id-verification-v1.index": "translation-zh.json",
    "immigration-v1.index": "translation-zh.json",
    "introduce-yourself-v1.index": "translation-zh.json",
    "isp-v1.index": "translation-zh.json",
    "login-selection.index": "translation-zh.json",
    "medical-check-v1.index": "translation-zh.json",
    "mydocs-v1.c2c": "translation-zh.json",
    "mydocs-v1.index": "translation-zh.json",
    "nav.index": "translation-zh.json",
    "not-found.index": "translation-zh.json",
    "pdf.diversity-inclusion.index": "translation-zh.json",
    "pdf.innovative-culture.index": "translation-zh.json",
    "pdf.shareholder-letters.index": "translation-zh.json",
    "pic-v1.index": "translation-zh.json",
    "post-day-one.index": "translation-zh.json",
    "return-it-equipment-v1.index": "translation-zh.json",
    "shipping-address-v1.addressSuggestion": "translation-zh.json",
    "shipping-address-v1.assistiveTechnologies": "translation-zh.json",
    "shipping-address-v1.countries": "translation-zh.json",
    "shipping-address-v1.hardware": "translation-zh.json",
    "shipping-address-v1.index": "translation-zh.json",
    "shipping-address-v1.schema": "translation-zh.json",
    "shipping-address-v1.schemaAE": "translation-zh.json",
    "shipping-address-v1.schemaAU": "translation-zh.json",
    "shipping-address-v1.schemaCA": "translation-zh.json",
    "shipping-address-v1.schemaCR": "translation-zh.json",
    "shipping-address-v1.schemaDE": "translation-zh.json",
    "shipping-address-v1.schemaFR": "translation-zh.json",
    "shipping-address-v1.schemaGB": "translation-zh.json",
    "shipping-address-v1.schemaIN": "translation-zh.json",
    "shipping-address-v1.schemaIT": "translation-zh.json",
    "shipping-address-v1.schemaMX": "translation-zh.json",
    "shipping-address-v1.schemaSA": "translation-zh.json",
    "shipping-address-v1.schemaSG": "translation-zh.json",
    "shipping-address-v1.schemaUS": "translation-zh.json",
    "shipping-address-v1.states": "translation-zh.json",
    "shipping-address-v1.statesAU": "translation-zh.json",
    "shipping-address-v1.statesCA": "translation-zh.json",
    "shipping-address-v1.statesFR": "translation-zh.json",
    "shipping-address-v1.statesIN": "translation-zh.json",
    "shipping-address-v1.statesUS": "translation-zh.json",
    "shipping-address-v1.submissionOutcome": "translation-zh.json",
    "shipping-address-v1.validation": "translation-zh.json",
    "text.common.index": "translation-zh.json",
    "text.landing.index": "translation-zh.json",
    "text.page-footer.index": "translation-zh.json",
    "text.sub-footer.index": "translation-zh.json",
    "unauthorized.index": "translation-zh.json",
    "videos.amazon-14-leadership-principles-jeff-bezos.index": "translation-zh.json",
    "videos.amazon-ceo-jeff-bezos-it-is-always-day-one.index": "translation-zh.json",
    "videos.andy-jassy-ceo-aws.index": "translation-zh.json",
    "videos.aws-reinvent-2019-keynote-andy-jassy.index": "translation-zh.json",
    "videos.aws-reinvent-2020.index": "translation-zh.json",
    "videos.aws-reinvent-2021-compute.index": "translation-zh.json",
    "videos.aws-reinvent-2021-data-strategies.index": "translation-zh.json",
    "videos.aws-reinvent-2021-introduction.index": "translation-zh.json",
    "videos.aws-reinvent-2021-networking.index": "translation-zh.json",
    "videos.aws-reinvent-2021-transforming-industries.index": "translation-zh.json",
    "videos.aws-reinvent-2021.index": "translation-zh.json",
    "videos.aws-welcome-message-by-adam-selipsky.index": "translation-zh.json",
    "videos.early-look.index": "translation-zh.json",
    "videos.jeff-bezos-amazon-blue-origin.index": "translation-zh.json",
    "videos.shop-talk-jeff-wilke-fortune.index": "translation-zh.json",
    "videos.tenets-and-decisions.index": "translation-zh.json",
    "videos.virtuous-cycle.index": "translation-zh.json",
    "videos.welcome-to-aws.index": "translation-zh.json",
    "videos.what-is-aws.index": "translation-zh.json",
    "videos.what-is-cloud-computing.index": "translation-zh.json",
    "work-auth.USAcorp": "translation-zh.json",
    "work-auth.c2c": "translation-zh.json",
    "work-auth.default.c2c": "translation-zh.json",
    "work-auth.default.index": "translation-zh.json",
    "work-auth.generic.index": "translation-zh.json",
    "work-auth.index": "translation-zh.json",
    "work-auth.newHireDefault": "translation-zh.json",
    "work-auth.newHireGBR": "translation-zh.json",
    "work-auth.special.ARE-corp.index": "translation-zh.json",
    "work-auth.special.AUS-corp.index": "translation-zh.json",
    "work-auth.special.CAN-corp.c2c": "translation-zh.json",
    "work-auth.special.CAN-corp.index": "translation-zh.json",
    "work-auth.special.CHN-corp.index": "translation-zh.json",
    "work-auth.special.CZE-corp.index": "translation-zh.json",
    "work-auth.special.DEU-corp.c2c": "translation-zh.json",
    "work-auth.special.DEU-corp.index": "translation-zh.json",
    "work-auth.special.DEU-ops.index": "translation-zh.json",
    "work-auth.special.ESP-corp.index": "translation-zh.json",
    "work-auth.special.GBR-corp.c2c": "translation-zh.json",
    "work-auth.special.GBR-corp.index": "translation-zh.json",
    "work-auth.special.GBR-ops.index": "translation-zh.json",
    "work-auth.special.IND-corp.index": "translation-zh.json",
    "work-auth.special.JPN-corp.index": "translation-zh.json",
    "work-auth.special.KOR-corp.index": "translation-zh.json",
    "work-auth.special.LUX-corp.index": "translation-zh.json",
    "work-auth.special.PHL-corp.index": "translation-zh.json",
    "work-auth.special.POL-corp.index": "translation-zh.json",
    "work-auth.special.PRI-corp.index": "translation-zh.json",
    "work-auth.special.SAU-corp.index": "translation-zh.json",
    "work-auth.special.SGP-corp.index": "translation-zh.json",
    "work-auth.special.USA-corp.c2c": "translation-zh.json",
    "work-auth.special.USA-corp.index": "translation-zh.json"
  },
  "zh-CN": {
    "badge-upload-v1.index": "translation-zh.json",
    "bgc-p0.index": "translation-zh.json",
    "c2c-faq.index": "translation-zh.json",
    "catch-all.index": "translation-zh.json",
    "culture.index": "translation-zh.json",
    "dashboard.c2c": "translation-zh.json",
    "dashboard.index": "translation-zh.json",
    "drug-screen-status-v1.index": "translation-zh.json",
    "faq.index": "translation-zh.json",
    "fdie-v1.index": "translation-zh.json",
    "id-verification-v1.index": "translation-zh.json",
    "immigration-v1.index": "translation-zh.json",
    "introduce-yourself-v1.index": "translation-zh.json",
    "isp-v1.index": "translation-zh.json",
    "login-selection.index": "translation-zh.json",
    "medical-check-v1.index": "translation-zh.json",
    "mydocs-v1.c2c": "translation-zh.json",
    "mydocs-v1.index": "translation-zh.json",
    "nav.index": "translation-zh.json",
    "not-found.index": "translation-zh.json",
    "pdf.diversity-inclusion.index": "translation-zh.json",
    "pdf.innovative-culture.index": "translation-zh.json",
    "pdf.shareholder-letters.index": "translation-zh.json",
    "pic-v1.index": "translation-zh.json",
    "post-day-one.index": "translation-zh.json",
    "return-it-equipment-v1.index": "translation-zh.json",
    "shipping-address-v1.addressSuggestion": "translation-zh.json",
    "shipping-address-v1.assistiveTechnologies": "translation-zh.json",
    "shipping-address-v1.countries": "translation-zh.json",
    "shipping-address-v1.hardware": "translation-zh.json",
    "shipping-address-v1.index": "translation-zh.json",
    "shipping-address-v1.schema": "translation-zh.json",
    "shipping-address-v1.schemaAE": "translation-zh.json",
    "shipping-address-v1.schemaAU": "translation-zh.json",
    "shipping-address-v1.schemaCA": "translation-zh.json",
    "shipping-address-v1.schemaCR": "translation-zh.json",
    "shipping-address-v1.schemaDE": "translation-zh.json",
    "shipping-address-v1.schemaFR": "translation-zh.json",
    "shipping-address-v1.schemaGB": "translation-zh.json",
    "shipping-address-v1.schemaIN": "translation-zh.json",
    "shipping-address-v1.schemaIT": "translation-zh.json",
    "shipping-address-v1.schemaMX": "translation-zh.json",
    "shipping-address-v1.schemaSA": "translation-zh.json",
    "shipping-address-v1.schemaSG": "translation-zh.json",
    "shipping-address-v1.schemaUS": "translation-zh.json",
    "shipping-address-v1.states": "translation-zh.json",
    "shipping-address-v1.statesAU": "translation-zh.json",
    "shipping-address-v1.statesCA": "translation-zh.json",
    "shipping-address-v1.statesFR": "translation-zh.json",
    "shipping-address-v1.statesIN": "translation-zh.json",
    "shipping-address-v1.statesUS": "translation-zh.json",
    "shipping-address-v1.submissionOutcome": "translation-zh.json",
    "shipping-address-v1.validation": "translation-zh.json",
    "text.common.index": "translation-zh.json",
    "text.landing.index": "translation-zh.json",
    "text.page-footer.index": "translation-zh.json",
    "text.sub-footer.index": "translation-zh.json",
    "unauthorized.index": "translation-zh.json",
    "videos.amazon-14-leadership-principles-jeff-bezos.index": "translation-zh.json",
    "videos.amazon-ceo-jeff-bezos-it-is-always-day-one.index": "translation-zh.json",
    "videos.andy-jassy-ceo-aws.index": "translation-zh.json",
    "videos.aws-reinvent-2019-keynote-andy-jassy.index": "translation-zh.json",
    "videos.aws-reinvent-2020.index": "translation-zh.json",
    "videos.aws-reinvent-2021-compute.index": "translation-zh.json",
    "videos.aws-reinvent-2021-data-strategies.index": "translation-zh.json",
    "videos.aws-reinvent-2021-introduction.index": "translation-zh.json",
    "videos.aws-reinvent-2021-networking.index": "translation-zh.json",
    "videos.aws-reinvent-2021-transforming-industries.index": "translation-zh.json",
    "videos.aws-reinvent-2021.index": "translation-zh.json",
    "videos.aws-welcome-message-by-adam-selipsky.index": "translation-zh.json",
    "videos.early-look.index": "translation-zh.json",
    "videos.jeff-bezos-amazon-blue-origin.index": "translation-zh.json",
    "videos.shop-talk-jeff-wilke-fortune.index": "translation-zh.json",
    "videos.tenets-and-decisions.index": "translation-zh.json",
    "videos.virtuous-cycle.index": "translation-zh.json",
    "videos.welcome-to-aws.index": "translation-zh.json",
    "videos.what-is-aws.index": "translation-zh.json",
    "videos.what-is-cloud-computing.index": "translation-zh.json",
    "work-auth.USAcorp": "translation-zh.json",
    "work-auth.c2c": "translation-zh.json",
    "work-auth.default.c2c": "translation-zh.json",
    "work-auth.default.index": "translation-zh.json",
    "work-auth.generic.index": "translation-zh.json",
    "work-auth.index": "translation-zh.json",
    "work-auth.newHireDefault": "translation-zh.json",
    "work-auth.newHireGBR": "translation-zh.json",
    "work-auth.special.ARE-corp.index": "translation-zh.json",
    "work-auth.special.AUS-corp.index": "translation-zh.json",
    "work-auth.special.CAN-corp.c2c": "translation-zh.json",
    "work-auth.special.CAN-corp.index": "translation-zh.json",
    "work-auth.special.CHN-corp.index": "translation-zh.json",
    "work-auth.special.CZE-corp.index": "translation-zh.json",
    "work-auth.special.DEU-corp.c2c": "translation-zh.json",
    "work-auth.special.DEU-corp.index": "translation-zh.json",
    "work-auth.special.DEU-ops.index": "translation-zh.json",
    "work-auth.special.ESP-corp.index": "translation-zh.json",
    "work-auth.special.GBR-corp.c2c": "translation-zh.json",
    "work-auth.special.GBR-corp.index": "translation-zh.json",
    "work-auth.special.GBR-ops.index": "translation-zh.json",
    "work-auth.special.IND-corp.index": "translation-zh.json",
    "work-auth.special.JPN-corp.index": "translation-zh.json",
    "work-auth.special.KOR-corp.index": "translation-zh.json",
    "work-auth.special.LUX-corp.index": "translation-zh.json",
    "work-auth.special.PHL-corp.index": "translation-zh.json",
    "work-auth.special.POL-corp.index": "translation-zh.json",
    "work-auth.special.PRI-corp.index": "translation-zh.json",
    "work-auth.special.SAU-corp.index": "translation-zh.json",
    "work-auth.special.SGP-corp.index": "translation-zh.json",
    "work-auth.special.USA-corp.c2c": "translation-zh.json",
    "work-auth.special.USA-corp.index": "translation-zh.json"
  }
};
var arbManifest = {
  endpointMap: endpointMap
};
export { arbManifest as default, endpointMap };