import {
    WorkAuthApplicationState,
    WorkAuthAction,
    WorkAuthActionTypes,
    SubmissionStatus,
} from './types'

const initialState: WorkAuthApplicationState = {
    status: '',
};

export const workAuthReducer = (state = initialState, action: WorkAuthAction) => {
    switch (action.type) {
        case WorkAuthActionTypes.GetWorkAuthStatusSuccess: {
            const payload = action.payload
            const { status, myDocsPortalSsoUrl } = payload

            return {
                ...state,
                status,
                myDocsPortalSsoUrl,
                submissionStatus: SubmissionStatus.SubmissionSuccess
            }
        }
        case WorkAuthActionTypes.UpdateWorkAuthStatus: {
            return {
                ...state,
                submissionStatus: SubmissionStatus.Submitting
            };
        }
        case WorkAuthActionTypes.UpdateWorkAuthStatusSuccess: {
            const payload = action.payload;
            const { status } = payload
            return {
                ...state,
                status,
                submissionStatus: SubmissionStatus.SubmissionSuccess
            };
        }
        case WorkAuthActionTypes.UpdateWorkAuthStatusFailure: {
            return {
                ...state,
                submissionStatus: SubmissionStatus.SubmissionFailure
            }
        }
        case WorkAuthActionTypes.SaveWorkAuthCopyInjections: {
            return {
                ...state,
                injections: action.payload
            }
        }
        default:
            return state;
    }
}
