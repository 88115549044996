// TO BE REMOVED. KEEPING THIS FOR THIS CR
export enum LoginSelectionActionTypes {
  GetInitialState = 'GET_INITIAL_STATE',
  GetInitialStateSuccess = 'GET_INITIAL_STATE_SUCCESS',
  GetInitialStateError = 'GET_INITIAL_STATE_FAIL',
  GetLoginSuggestions = 'GET_LOGIN_SUGGESTIONS',
  LoginSuggestionsSuccess = 'GET_LOGIN_SELECTIONS_SUCCESS',
  LoginSuggestionsError = 'GET_LOGIN_SELECTIONS_ERROR',
  GetLoginAvailability = 'GET_LOGIN_AVAILABILITY',
  LoginAvailabilitySuccess = 'GET_LOGIN_AVAILABILITY_SUCCESS',
  LoginAvailabilityError = 'GET_LOGIN_AVAILABILITY_ERROR',
  GetUserReservedLogin = 'GET_USER_RESERVED_LOGIN',
  GetUserReservedLoginSuccess = 'GET_USER_RESERVED_LOGIN_SUCCESS',
  GetUserReservedLoginError = 'GET_USER_RESERVED_LOGIN_ERROR',
  ReserveUserLogin = 'RESERVE_USER_LOGIN',
  DeleteUserLogin = 'DELETE_USER_LOGIN',
  DeleteUserLoginSuccess = 'DELETE_USER_LOGIN_SUCCESS',
  ReserveUserLoginSuccess = 'RESERVE_USER_LOGIN_SUCCESS',
}
export interface InitialStateSuccessResponse {
  suggestedLogins: []
  login: string
  eligibilityData: {
    eligibilityTableLogin?: string
    userLoginReservationStatus: string
  }
}

export enum UserLoginReservationStatus {
  LOCKED = 'LOCKED',
  AUTOGENERATED_AND_LOCKED = 'AUTOGENERATED_AND_LOCKED',
}

export interface LoginSuggestionsSuccessResponse {
  suggestedLogins: []
}

export interface UserReservedLoginSuccessResponse {
  login: ''
}

export interface ReserveUserLoginSuccessResponse {
  personId: ''
  login: ''
}

export interface ReserveUserLoginErrorResponse {
  errorMessage: ''
}

export interface DeleteUserLoginSuccessResponse {
  isLoginDeleted: boolean
}

export interface LoginAvailabilitySuccessResponse {
  loginAvailable: boolean
}

export interface PimaLoginSuccessResponse {
  pimalogin: string
}

export enum STATUS_CODES {
  SUCCESS =  200,
  NOT_AVAILABLE = 404,
  DELETE_SUCCESS = 204
}

// TODO: Might have to modify it for the other use cases
export interface LoginSuggestionsState {
  suggestedLogins: []
  isLoginAvailable: boolean
  userReservedLogin: string
  choseSelfSelect: boolean
  selectedLoginOption?: string
}

export interface LoginAvailabilityState {
  isLoginAvailable: boolean
  isReserveLoginError: boolean
  isLoginAvailabilityError: boolean
  isLoginAvailabilityComplete: boolean
  isLoginAvailabilityProgress: boolean
}