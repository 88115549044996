export const config = {
  exampleBadgeImage: '/api/content/badge/badge-upload-v1/photo_representation.png',
  examplePhotoTooltipGood: 'acceptable',
  examplePhotoTooltipBad: 'unacceptable',
  examplePhotosGood1: '/api/content/badge/badge-upload-v1/acceptable_1_2x.png',
  examplePhotosGood2: '/api/content/badge/badge-upload-v1/acceptable_2_2x.png',
  examplePhotosGood3: '/api/content/badge/badge-upload-v1/acceptable_3_2x.png',
  examplePhotosGood4: '/api/content/badge/badge-upload-v1/acceptable_4_2x.png',
  examplePhotosBad1: '/api/content/badge/badge-upload-v1/unacceptable_1_2x.png',
  examplePhotosBad2: '/api/content/badge/badge-upload-v1/unacceptable_2_2x.png',
  examplePhotosBad3: '/api/content/badge/badge-upload-v1/unacceptable_3_2x.png',
  examplePhotosBad4: '/api/content/badge/badge-upload-v1/unacceptable_4_2x.png',
  examplePhotosBad5: '/api/content/badge/badge-upload-v1/unacceptable_5_2x.png',
  examplePhotosBad6: '/api/content/badge/badge-upload-v1/unacceptable_6_2x.png',
  examplePhotosBad7: '/api/content/badge/badge-upload-v1/unacceptable_7_2x.png',
  minSize: 51200,
  maxSize: 5242880,
};
