import { Action } from 'redux'

export interface PendingBadgePhotoApiResponse {
    pendingBadgePhoto: Blob
}

export enum PendingBadgePhotoActionTypes {
    FetchPendingBadgePhoto = 'FETCH_PENDING_BADGE_PHOTO',
    FetchPendingBadgePhotoFulfilled = 'FETCH_PENDING_BADGE_PHOTO_FULFILLED',
    FetchPendingBadgePhotoFailed = 'FETCH_PENDING_BADGE_PHOTO_FAILED'
}

export type PendingBadgePhotoActions = PendingBadgePhotoAction | PendingBadgePhotoFulfilledAction | PendingBadgePhotoFailedAction

export interface PendingBadgePhotoAction extends Action {
    type: PendingBadgePhotoActionTypes.FetchPendingBadgePhoto
}

export interface PendingBadgePhotoFulfilledAction extends Action {
    type: PendingBadgePhotoActionTypes.FetchPendingBadgePhotoFulfilled
    payload: PendingBadgePhotoApiResponse
}

export interface PendingBadgePhotoFailedAction extends Action {
    type: PendingBadgePhotoActionTypes.FetchPendingBadgePhotoFailed
    payload: ''
}

export interface PendingBadgePhotoState {
    pendingBadgePhoto?: string
}