import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'
import { DashboardEvent, Task } from './types'
import { get } from '../../utils/ajax'

import { EXTERNAL_URL_MAP } from '../../routes/ExternalUrls'

import { loadTasksSuccessAction, loadTasksFailureAction } from './actions'

import { ApplicationState } from '../types'

import {
  prefixSegmentToPath
} from '../../context/baseSegmentContext'

const loadTasks = async (prefixSegment: string) : Promise<Task[]> => {
  const tasksUrl = prefixSegmentToPath({
    path: EXTERNAL_URL_MAP.DASHBOARD_TASKS,
    prefixSegment 
  })
  const response = await get<Task[]>(tasksUrl)
  return response.data
}

function* handleLoadTaskRequest(): Generator {
  try {
    const prefixSegment = (yield select((state: ApplicationState) => state.environment.baseSegmentPrefix)) as string
    const res: Task[] = (yield(call(loadTasks, prefixSegment))) as Task[]
    yield(put(loadTasksSuccessAction(res)))
  } catch(err) {
    yield(put(loadTasksFailureAction()))
  }
}

function* watchLoadTasksRequests(){
  yield takeEvery(
    DashboardEvent.LoadTasks,
    handleLoadTaskRequest
  )
}

export function* dashboardSaga() {
  yield all([
    fork(watchLoadTasksRequests)
  ])
}
