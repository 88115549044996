import { useEffect } from 'react';
import { UserState } from '../../store/user/types';
import { initializeRUM, getAwsRumClient } from '../rumClient';

import {
  HostApp
} from '../../types/hostApp'

export function shouldUseRum(hostApp: HostApp): boolean {
  switch(hostApp){
    case HostApp.Fallback:
      return true
    default:
      return false
  }
}

const useRUM = (user: UserState, hostApp: HostApp = HostApp.Fallback) => {

  if(!shouldUseRum(hostApp)) return

  initializeRUM();

  useEffect(() => {
    if (user && user.profile && user.profile.icimsId) {
      const rumClient = getAwsRumClient();

      rumClient?.addSessionAttributes({ icimsId: user.profile.icimsId });
    }
  }, [user]);
};

export default useRUM;
