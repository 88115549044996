import { parse, format, parseISO } from 'date-fns';
import { fr, sk, pt, id, tr, zhCN, cs, it, es, ja, pl, ro, de, frCA, enUS } from 'date-fns/locale';
import { DEFAULT_LANGUAGE_TAG } from '../i18n-constants';

/*
Supported locales are here:
https://github.com/date-fns/date-fns/tree/master/src/locale
Date format by country:
https://en.wikipedia.org/wiki/Date_format_by_country
*/
const localeMap = {
  sk_SK: { formatString: 'PPP', locale: sk },
  pt_BR: { formatString: 'd MMMM yyyy', locale: pt },
  id_ID: { formatString: 'PPP', locale: id },
  tr_TR: { formatString: 'PPP', locale: tr },
  zh_CN: { formatString: 'PPP', locale: zhCN },
  cs_CZ: { formatString: 'PPP', locale: cs },
  it_IT: { formatString: 'd MMMM yyyy', locale: it },
  es_ES: { formatString: 'd MMMM yyyy', locale: es },
  ja_JP: { formatString: 'PPP', locale: ja },
  pl_PL: { formatString: "d MMMM yyyy 'r.'", locale: pl },
  ro_RO: { formatString: 'd MMMM yyyy', locale: ro },
  de_DE: { formatString: 'd MMMM yyyy', locale: de },
  fr_CA: { formatString: 'd MMMM yyyy', locale: frCA },
  fr_FR: { formatString: 'd MMMM yyyy', locale: fr },
  en_US: { formatString: 'MMMM d, yyyy', locale: enUS },
};

export function parseInput(input) {
  return parse(input, 'yyyy-MM-dd', new Date());
}

export function formatOutput(parsedDate, language = DEFAULT_LANGUAGE_TAG) {
  const { formatString, locale } = localeMap[language] || localeMap[DEFAULT_LANGUAGE_TAG];
  return format(parsedDate, formatString, { locale });
}

export function formatOutputLong(parsedDateTime, language = DEFAULT_LANGUAGE_TAG) {
  // Always use this format for now
  const formatString = 'd MMMM yyyy h:mm aaa OOOO';
  const locale = (localeMap[language] || localeMap[DEFAULT_LANGUAGE_TAG]).locale;
  return format(parsedDateTime, formatString, { locale });
}

/**
 * Return date in specified locale, unspecified defaults to en_US
 * Please add other locale specific date formats to localeMap
 * @param {string} input -  '2021-10-02'
 * @param {string} language - 'fr_CA'
 */
export const humanizeDate = (input, language) => {
  const parsedDate = parseInput(input);
  return formatOutput(parsedDate, language);
};

/**
 * Return date in specified locale, unspecified defaults to en_US
 * Please add other locale specific date formats to localeMap
 * @param {string} input -  '2021-12-06T17:30:50.743Z'
 *             See:  https://date-fns.org/v2.27.0/docs/parseISO
 * @param {string} language - 'fr_CA'
 */
export const humanizeDateTime = (input, language) => {
  const parsedDateTime = parseISO(input);
  return formatOutputLong(parsedDateTime, language);
};
