import { Action } from 'redux'

export enum IdVerificationBannerCode {
    REJECTED_EXPIRED = 'REJECTED_EXPIRED',
    REJECTED_MISSING_DATA = 'REJECTED_MISSING_DATA',
    REJECTED_OTHER = 'REJECTED_OTHER',
    SUCCESS = 'SUCCESS',
    SUBMITTED = 'SUBMITTED',
    EXPIRED = 'EXPIRED',
    OVERDUE = 'OVERDUE',
    NOT_AVAILABLE = 'NOT_AVAILABLE',
}

export interface IdVerificationStateResponse {
    status: number,
    banner: IdVerificationBannerCode,
    dueDate: string,
    wizardUrl?: string,
    isAutomatedRivEnabled?: boolean,
}

export interface IdVerificationStateRequest {
    status: number,
    banner: IdVerificationBannerCode,
    dueDate: string,
}

export interface IdVerificationApplicationState {
    status?: number,
    banner?: IdVerificationBannerCode,
    dueDate?: string,
    wizardUrl?: string,
    isAutomatedRivEnabled?: boolean,
}

export enum IdVerificationActionTypes {
    GetIdVerificationStatus = 'GET_ID_VERIFICATION_STATUS',
    GetIdVerificationStatusSuccess = 'GET_ID_VERIFICATION_STATUS_SUCCESS',
    GetIdVerificationStatusFailure = 'GET_ID_VERIFICATION_STATUS_FAILURE',
}

export interface GetIdVerificationStatusAction extends Action {
    type: IdVerificationActionTypes.GetIdVerificationStatus
}

export interface GetIdVerificationStatusSuccessAction extends Action {
    type: IdVerificationActionTypes.GetIdVerificationStatusSuccess
    payload: IdVerificationStateResponse
}

export interface GetIdVerificationStatusFailureAction extends Action {
    type: IdVerificationActionTypes.GetIdVerificationStatusFailure
}

export type IdVerificationAction =
    GetIdVerificationStatusAction
    | GetIdVerificationStatusSuccessAction
    | GetIdVerificationStatusFailureAction
