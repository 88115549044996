import axios from 'axios';
import { flow, noop } from 'lodash';
import { EXTERNAL_URL_MAP } from '../routes/ExternalUrls';
import { Maybe } from 'monet';

// authMandatedUrls :: Array UrlPath
const authMandatedUrls = [
  EXTERNAL_URL_MAP.METRICS,
  EXTERNAL_URL_MAP.COPY,
  EXTERNAL_URL_MAP.HEARTBEAT
];

// liftToMaybe :: a -> Maybe a
const liftToMaybe = Maybe.fromUndefined;

// extractUrlPath :: Maybe String -> Maybe UrlPath
const extractUrlPath = maybeUrl => maybeUrl.bind(url => liftToMaybe((new URL(url)).pathname));

// requireReAuthentication :: Maybe UrlPath -> Boolean
const requireReAuthentication = maybePath => authMandatedUrls.indexOf(maybePath.orJust('')) !== -1;

// goToLanding :: Eff()
const logUserOut = () => window.location.pathname = EXTERNAL_URL_MAP.USER_LOGOUT;

// shouldReauthenticate :: Maybe String -> Boolean
const shouldReauthenticate = flow(
  extractUrlPath,
  requireReAuthentication
);

// isUnauthorizedRequest :: Maybe Int -> Boolean
export const isUnauthorizedRequest = maybeResponseStatus => {
  return (maybeResponseStatus.orJust(0) === 401) 
}

// shouldLogUserOut :: Maybe Int -> Maybe UrlPath -> Boolean
const shouldLogUserOut = maybeResponseStatus => maybeResponseUrl => isUnauthorizedRequest(maybeResponseStatus) && shouldReauthenticate(maybeResponseUrl);

axios.defaults.withCredentials = true;
axios.interceptors.response.use(
  response => response,
  error => {
     const maybeError = Maybe.fromUndefined(error);
     const maybeResponseURL = maybeError
        .bind(error => liftToMaybe(error.request))
        .bind(request => liftToMaybe(request.responseURL));
     const maybeStatus = maybeError
        .bind(error => liftToMaybe(error.response))
        .bind(response => liftToMaybe(response.status));

     shouldLogUserOut(maybeStatus)(maybeResponseURL) ? logUserOut() : noop();
  }
);

const post = axios.post;

const get = axios.get;

const httpPut = axios.put;

// This axios client is used by React Query hooks. It doesn't have any interceptors attached to it.
const client = axios.create();

export { client, get, post, httpPut };
