import {
  BgcAction,
  BgcActionType,
  BgcApplicationState
} from './types'

const initialBgcState: BgcApplicationState = {
  isLoadingInitialState: false,
}

export const bgcReducer = (state = initialBgcState, action: BgcAction): BgcApplicationState => {
  switch(action.type){
    case BgcActionType.InitialLoadRequest: {
      return {
        ...state,
        isLoadingInitialState: true
      }
    }
    case BgcActionType.InitialLoadRequestSuccess: {
      return {
        ...state,
        ...action.payload,
        isLoadingInitialState: false
      }
    }
    case BgcActionType.InitialLoadRequestFailure: {
      return {
        ...state,
        isLoadingInitialState: false
      }
    }
    default: {
      return state
    }
  }
}
