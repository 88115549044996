import { Action } from 'redux'

export interface BadgePhotoReviewStatusApiResponse {
    icimsId?: string
    reviewStatusUpdatedAt?: string
    status?: string
    statusMessageCode?: any
    statusMessageStrings?: any
}

export enum BadgePhotoReviewStatusActionTypes {
    FetchPhotoReviewStatus = 'BEGIN_POLLING_BADGE_PHOTO_REVIEW_STATUS',
    BeginPollingPhotoReviewStatus = 'FETCH_BADGE_PHOTO_REVIEW_STATUS_NOW',
    FetchReviewStatusFulfilled = 'FETCH_BADGE_PHOTO_REVIEW_STATUS_FULFILLED',
    FetchReviewStatusFailed = 'FETCH_BADGE_PHOTO_REVIEW_STATUS_FAILED',
    StopPollingPhotoReviewStatus = 'STOP_POLLING_BADGE_PHOTO_REVIEW_STATUS'
}

export type BadgePhotoReviewStatusActions = FetchPhotoReviewStatusAction | BeginPollingReviewStatusAction | FetchReviewStatusFulfilledAction | FetchReviewStatusFailedAction

export interface FetchPhotoReviewStatusAction extends Action {
    type: BadgePhotoReviewStatusActionTypes.FetchPhotoReviewStatus
}

export interface BeginPollingReviewStatusAction extends Action {
    type: BadgePhotoReviewStatusActionTypes.BeginPollingPhotoReviewStatus
}

export interface FetchReviewStatusFulfilledAction extends Action {
    type: BadgePhotoReviewStatusActionTypes.FetchReviewStatusFulfilled
    payload: BadgePhotoReviewStatusApiResponse
}

export interface FetchReviewStatusFailedAction extends Action {
    type: BadgePhotoReviewStatusActionTypes.FetchReviewStatusFailed
}

export interface StopPollingReviewStatusAction extends Action {
    type: BadgePhotoReviewStatusActionTypes.StopPollingPhotoReviewStatus
}

export interface BadgePhotoReviewStatusState {
    icimsId?: string
    reviewStatusUpdatedAt?: string
    status?: string
    statusMessageCode?: any
    statusMessageStrings?: any
}