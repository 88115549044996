import { all, fork, takeLatest, call } from "@redux-saga/core/effects";
import { PageScrollActionTypes } from "./types";

const scrollToHash = () => {
    const hash = window.location.hash;
    if (hash && document.getElementById(hash.substr(1))) {
        const el = document.getElementById(hash.substr(1));
        if (el) {
            el.scrollIntoView({ behavior: 'smooth' });
        }
    }
}

function* handleScrollToHash() {
    yield call(scrollToHash)
}

function* watchScrollToHash() {
    yield takeLatest(PageScrollActionTypes.ScrollToHash, handleScrollToHash);
}

export function* pageScrollSaga() {
    yield all([fork(watchScrollToHash)]);
}