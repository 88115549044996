import { LoginSuggestionsState, LoginAvailabilityState } from './types';
import { LoginSelectionActionTypes, LoginSelectionActions } from './actionTypes';

const initialLoginSelectionState: LoginSuggestionsState = {
  suggestedLogins: [],
  isLoginAvailable: false,
  choseSelfSelect: false,
  userReservedLogin: '',
};

const defaultLoginAvailabilityCompleteState: LoginAvailabilityState = {
  isLoginAvailable: false,
  isReserveLoginError: false,
  isLoginAvailabilityError: false,
  isLoginAvailabilityComplete: true,
  isLoginAvailabilityProgress: false
}

const defaultGetLoginSuggestionsCompleteState = {
  isSuggestedLoginFetchComplete: true,
  isSuggestedLoginProgress: false,
  suggestedLoginError: false
}

const defaultDeleteUserLoginState = {
  isReserveLoginError: false,
  isLoginDeleted: true
}

export const loginSuggestionsReducer = (state = initialLoginSelectionState, action: LoginSelectionActions ) => {
  switch (action.type) {
    case LoginSelectionActionTypes.GetInitialState: {
      return { ...state, isUserReservedLoginFetchComplete: false };
    }
    case LoginSelectionActionTypes.GetInitialStateSuccess: {
      const { suggestedLogins, login, eligibilityData } = action.payload;
      return { ...state, isUserReservedLoginFetchComplete: true, initialStateError: false, isSuggestedLoginFetchComplete: true, userReservedLogin: login, eligibilityData, suggestedLogins };
    }
    case LoginSelectionActionTypes.GetInitialStateError:
      return { ...state, isUserReservedLoginFetchComplete: true, initialStateError: true};

    case LoginSelectionActionTypes.GetLoginSuggestions:
      return { ...state, isSuggestedLoginFetchComplete: false, isSuggestedLoginProgress: true, isReserveLoginError: false };
    case LoginSelectionActionTypes.LoginSuggestionsSuccess:
      const { suggestedLogins } = action.payload;
      return { ...state, ...defaultGetLoginSuggestionsCompleteState, suggestedLogins };
    case LoginSelectionActionTypes.LoginSuggestionsError:
      return { ...state, ...defaultGetLoginSuggestionsCompleteState, suggestedLoginError: true};

    case LoginSelectionActionTypes.SelectLoginOption:
      return {
        ...state,
        userReservedLogin: action.payload,
      }
    case LoginSelectionActionTypes.ChangedSelfSelect:
      return {
        ...state,
        choseSelfSelect: action.payload,
      }
        
    case LoginSelectionActionTypes.GetLoginAvailability:
      return { ...state, isLoginAvailabilityProgress: true, isLoginAvailabilityComplete: false };
    case LoginSelectionActionTypes.LoginAvailabilitySuccess:
      const { loginAvailable: isLoginAvailable } = action.payload;
      return { ...state, ...defaultLoginAvailabilityCompleteState, isLoginAvailable  };
    case LoginSelectionActionTypes.LoginAvailabilityError:
      return { ...state, ...defaultLoginAvailabilityCompleteState, isLoginAvailabilityError: true };

    case LoginSelectionActionTypes.ReserveUserLogin:
      return { ...state, isUpdateLoginInProgress: true, isReserveLoginError: false};
    case LoginSelectionActionTypes.ReserveUserLoginSuccess:
      const { login: userReservedLogin } = action.payload;
      return { ...state, isLoginDeleted: false, isUpdateLoginInProgress: false, isReserveLoginError: false, userReservedLogin};
    case LoginSelectionActionTypes.ReserveUserLoginError:
      const { errorMessage: loginReservationErrorMessage } = action.payload;
      return { ...state, isUpdateLoginInProgress: false, isReserveLoginError: true, loginReservationErrorMessage};

    case LoginSelectionActionTypes.DeleteUserLogin:
        return { ...state, isUpdateLoginInProgress: true, isReserveLoginError: false};
    case LoginSelectionActionTypes.DeleteUserLoginSuccess:
      return { ...state, ...defaultDeleteUserLoginState};
    case LoginSelectionActionTypes.DeleteUserLoginError:
      return { ...state, isLoginDeleted: false, isReserveLoginError: true, isUpdateLoginInProgress: false};

    case LoginSelectionActionTypes.GetPimaUserLoginSuccess:
      const { pimalogin } = action.payload;
      return { ...state, pimalogin };

    default:
      return state;
  }
};
