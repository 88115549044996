import { pickBy, pick } from 'lodash';
import { PageScrollActionTypes, PageScrollActions, PageScrollState } from './types';

//
// PageScrollState Attributes
//
// @scrollLocked: Manage the scrollLock part of the application state; Activates 
//   whenever the page scrolling needs to be locked. For eg, when a modal opens 
//   up, we lock the Page scroll; When it closes we unlock it.
//
// @scrollTop: The top of the scroll window of cards in pixels.
// 
// @scrollToCard: Set this to trigger a smooth scroll to a card.
//
// @visibleCard: The index of the currently visible card.
//
// @visibleCardSlug: The card slug of the currently visible card
//
const initialState: PageScrollState = {
  scrollTop: undefined,
  scrollLocked: false,
  scrollToCard: undefined,
  visibleCard: undefined,
  visibleCardSlug: undefined
}

export const pageScrollReducer = (state = initialState, action: PageScrollActions) => {
  switch (action.type) {
    case PageScrollActionTypes.SetProps:
      return {
        ...state,
        ...pickBy(
          pick(action.payload, ['scrollTop', 'visibleCard', 'visibleCardSlug']),
          x => x !== undefined
        )
      }
    case PageScrollActionTypes.ScrollToCard:
      return {
        ...state,
        scrollToCard: action.payload
      }
    case PageScrollActionTypes.Lock:
      return {
        ...state,
        scrollLocked: true
      }
    case PageScrollActionTypes.Unlock:
      return {
        ...state,
        scrollLocked: false
      }
    case PageScrollActionTypes.ScrollToHash:
      return {
        ...state,
        scrollToHash: true
      }
    default:
      return state;
  }
};
