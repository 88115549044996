import { createStandardAction } from 'typesafe-actions'

import {
  BgcActionType,
  BgcUserData
} from './types'

export const getBgcPageLoadData = createStandardAction(BgcActionType.InitialLoadRequest)()

export const bindBgcPageLoadDataToState = createStandardAction(BgcActionType.InitialLoadRequestSuccess)<BgcUserData>()

export const trackBgcPageLoadDataRequestFailure = createStandardAction(BgcActionType.InitialLoadRequestFailure)()
