import React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { StencilProvider } from '@amzn/stencil-react-components/esm/context';
import { QueryClientProvider } from 'react-query';
// import { ReactQueryDevtools } from 'react-query/devtools';
import { queryClient } from './query-client';
import { mbmOptions } from './copy/mbmOptions';
import { MbmProvider } from '@amzn/react-arb-tools';

import App from './containers/App';
import './assets/App/root.scss';
import defaultTheme from '@amzn/stencil-react-theme-default';

import { store } from './i18n';

// Temporary polyfill for Intl.Locale. Awaiting fix from Panther.
// SIM: https://issues.amazon.com/issues/V837602526
import { shouldPolyfill } from '@formatjs/intl-locale/should-polyfill';

import {
  HostApp,
  HostAppContext
} from './types/hostApp'

import {
  getHostAppEmpId,
  getHostAppMountDataAttribute,
  mapHostToPrefix,
  PreboardingHostAppContext
} from './context/baseSegmentContext'

import {
  getHostLanguage,
} from './context/hostLanguage';

async function polyfillIntlLocale() {
  if (shouldPolyfill()) {
    await import('@formatjs/intl-locale/polyfill');
  }
}
polyfillIntlLocale();

const mountRoot = document.getElementById('atoz-preboarding-app-root')

// PIC will need the following hostApp, since they will need to change
// their behavior based on which app is hosting them.
// This will also drive the behavior of Pre-boarding portal allowing it to distinguish
// pre-transition and atoz ux.
const hostApp: HostApp = getHostAppMountDataAttribute(mountRoot)

// We will be prefixing this ahead of all relative paths for page routes and api calls.
const baseSegmentPrefix: string = mapHostToPrefix(hostApp)

// Getting the host language locale
const hostAppLanguage = getHostLanguage(mountRoot)

// Getting the logged in user employeeId using data attributes of mountRoot
const employeeId = getHostAppEmpId(mountRoot)

const hostAppContext: HostAppContext = {
  employeeId,
  hostApp,
  baseSegmentPrefix,
  hostAppLanguage,
}

function EmbarkLaunchpadApp() {
  return (
    <PreboardingHostAppContext.Provider value={hostAppContext}>
      <MbmProvider {...mbmOptions}>
        <StencilProvider
          theme={{
            ...defaultTheme,
              color: {
                ...defaultTheme.color,
          },
          }}
        >
          <QueryClientProvider client={queryClient}>
            <Provider store={store}>
              <App />
            </Provider>
            {/* <ReactQueryDevtools /> */}
          </QueryClientProvider>
        </StencilProvider>
      </MbmProvider>
    </PreboardingHostAppContext.Provider>
  )
}

ReactDOM.render(<EmbarkLaunchpadApp />, mountRoot)
