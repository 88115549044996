import { all, call, delay, fork, put, race, take, takeEvery, select } from 'redux-saga/effects'
import { BadgePhotoUploadCheckActionTypes, BadgePhotoUploadCheckApiResponse } from './types'
import { fetchBadgePhotoUploadCheckFulfilled, fetchBadgePhotoUploadCheckFailed } from './actions'
import { EXTERNAL_URL_MAP } from '../../routes/ExternalUrls'
import { get } from '../../utils/ajax'
import { NavActionTypes } from '../nav/types'
// import { parseInput, formatOutput } from '../../components/Welcome/util'

const fetchBadgePhotoReview = async (): Promise<BadgePhotoUploadCheckApiResponse> => {
  const res = await get<BadgePhotoUploadCheckApiResponse>(EXTERNAL_URL_MAP.BADGE_PHOTO_UPLOAD_CHECK)
  return res.data
}

const statusUpdatedDate = (state:any): string => {
  return state.badgePhotoUploadCheck.reviewStatusUpdatedAt
}

const uploadDateTime = (state:any): string => {
  return state.badgePhotoUploadCheck.beganPolling
}

function* watchAllFetchBadgePhotoUploadCheckRequest() {
  yield takeEvery(BadgePhotoUploadCheckActionTypes.BeginPollingBadgeUpload, watchFetchBadgePhotoUploadCheckRequest)
}
// watch for BeginPollingBadgeUpload.
// once that occurs, start the polling task (handleFetchBadgePhotoUploadCheck),
// but watch for StopPollingBadgeUpload and terminate polling once its received.
function* watchFetchBadgePhotoUploadCheckRequest() {
  // yield take(BadgePhotoUploadCheckActionTypes.BeginPollingBadgeUpload)
  yield race([call(handleFetchBadgePhotoUploadCheck), take(BadgePhotoUploadCheckActionTypes.StopPollingBadgeUpload)])
}

// every 10ms (unless we encounter an error),
// make a request to determine if the sanity checks accepted or rejected the uploaded image.
// dispatch the response for the appropriate success/failure reducer to handle.
function* handleFetchBadgePhotoUploadCheck(): Generator {
  while (true) {
    try {
      // Fetching badgeStatus at regular interval 100 ms.
      const badgeStatus = (yield call(fetchBadgePhotoReview)) as BadgePhotoUploadCheckApiResponse
      const res: BadgePhotoUploadCheckApiResponse = badgeStatus
      const previousStatusDate = <string> (yield select(statusUpdatedDate))
      const uploadTime = <string> (yield select(uploadDateTime))
      const responseDateTimeObj = res.reviewStatusUpdatedAt ? new Date(res.reviewStatusUpdatedAt+'+00:00') : null
      const uploadDateTimeObj = uploadTime ? new Date(uploadTime) : null
      if (res.status !== 'BEGIN' && responseDateTimeObj!==null && uploadDateTimeObj !== null && responseDateTimeObj > uploadDateTimeObj) {
        yield all([
          put(fetchBadgePhotoUploadCheckFulfilled(res)),
          put({ type: NavActionTypes.FetchNav, meta: { refetch: true } })
        ])
        yield put({ type: BadgePhotoUploadCheckActionTypes.StopPollingBadgeUpload})
      }
      yield delay(100); // every 100 ms
      // yield delay(30*1000); // every 30s for testing
    } catch (err) {
      console.error(err)
      yield put(fetchBadgePhotoUploadCheckFailed())
      // Once the polling has encountered an error,
      // it should be stopped immediately
      yield put({ type: BadgePhotoUploadCheckActionTypes.StopPollingBadgeUpload, err });
    }
  }
}

// Export our root saga.
export function* badgePhotoUploadCheckSaga() {
  yield all([fork(watchAllFetchBadgePhotoUploadCheckRequest)])
}
