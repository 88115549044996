// Identifies the host app which embeds Pre-boarding application.
export enum HostApp {
  AtoZESSP,
  AtoZMobile,
  Fallback
}

export const BasePrefix = {
  ATOZ_ESSP: 'preboarding',
  ATOZ_MOBILE: 'preboarding',
  WEB_PORTAL: '', // default
}

export interface HostAppContext {
  employeeId: string
  hostApp: HostApp
  baseSegmentPrefix: string
  hostAppLanguage: string
}
