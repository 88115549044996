// Scroll to an element with an optional offset
export const scrollIntoViewWithOffset = (el: HTMLElement, offset: number = 0) => {
  window.scrollTo({
    top: el.getBoundingClientRect().top - document.body.getBoundingClientRect().top - offset,
  });
};

// scrollIntoViewWithOffset but using the height of the header as the offset
export const scrollIntoViewWithHeaderOffset = (el: HTMLElement) => {
  const header = document.querySelector<HTMLElement>('.nav-bar[role="navigation"]');

  let offset;

  if (header) {
    offset = header.offsetHeight;
  }

  scrollIntoViewWithOffset(el, offset);
};
