import { NavigationState, ItemAction, NavigationEvent } from './types'

const initialNavState: NavigationState = {
  loading: false,
  items: [],
  stringResources: null
}

const navReducer = (state = initialNavState, action: ItemAction): NavigationState => {
  switch (action.type) {
    case NavigationEvent.LoadItems:
      return {
        ...initialNavState,
        loading: true
      }
    case NavigationEvent.LoadItemsSuccess:
      return {
        items: action?.payload?.items,
        stringResources: action?.payload?.stringResources,
        loading: false
      }
    case NavigationEvent.LoadItemsFailure:
      return {
        ...initialNavState,
        loading: false
      }
    default:
      return state;
  }
};

export default navReducer