import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { EXTERNAL_URL_MAP } from '../../routes/ExternalUrls';
import { fetchFaqFulfilled, fetchFaqFailed } from './actions'
import { get } from '../../utils/ajax';
import { FaqActionTypes, FaqResponse } from './types'

async function fetchFaq(): Promise<FaqResponse> {
  const requestUrl = EXTERNAL_URL_MAP.FAQ
  const res = await get(requestUrl);
  return res.data;
}

function* manageFaq(): Generator {
  try{
    const res: FaqResponse = (yield call(fetchFaq)) as FaqResponse
    yield put(fetchFaqFulfilled(res))
  }
  catch (err) {
    yield put(fetchFaqFailed())
  }
}

function* watchFaqRequest() {
  yield takeLatest(FaqActionTypes.FetchFaq, manageFaq);
}

export function* faqSaga() {
  yield all([fork(watchFaqRequest)])
}

