import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects'
import { PendingBadgePhotoActionTypes, PendingBadgePhotoApiResponse } from './types'
import { fetchPendingBadgePhotoFulfilled, fetchPendingBadgePhotoFailed } from './actions'
import { EXTERNAL_URL_MAP } from '../../routes/ExternalUrls'
import { get } from '../../utils/ajax'
import { config } from '../../components/BadgeUpload/config'

const fetchPendingBadgePhoto = async (): Promise<PendingBadgePhotoApiResponse> => {
  const res = await get<PendingBadgePhotoApiResponse>(EXTERNAL_URL_MAP.PENDING_BADGE_PHOTO, {responseType: 'blob'})
  return res.data
}

function* handlePendingBadgePhoto(): Generator {
  try {
    const res: PendingBadgePhotoApiResponse = (yield call(fetchPendingBadgePhoto)) as PendingBadgePhotoApiResponse
    yield put(fetchPendingBadgePhotoFulfilled(res))
  } catch (err) {
    const defaultImageUrl = (yield config.exampleBadgeImage) as string
    yield put(fetchPendingBadgePhotoFailed(defaultImageUrl))
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchFetchPendingBadgePhotoRequest() {
  yield takeEvery(PendingBadgePhotoActionTypes.FetchPendingBadgePhoto, handlePendingBadgePhoto)
}

// Export our root saga.
// We can also use `fork()` here to split our saga into multiple watchers.
export function* pendingBadgePhotoSaga() {
  yield all([fork(watchFetchPendingBadgePhotoRequest)])
}
