import { FaqResponse, FaqActions, FaqActionTypes } from './types';

const initialFaqs: FaqResponse = {
  items: []
};

export function faqReducer(state = initialFaqs, action: FaqActions){
  switch(action.type){
    case FaqActionTypes.FetchFaqFulfilled:
      return action.payload;
    default:
      return state;
  }
}
