import { LanguagesState, ItemAction, LanguagesEvent, DEFAULT_LANG, potentialLanguages } from './types'
import { UserActionTypes} from "../user/types"
const initialLangState: LanguagesState = {
  loading: false,
  languages: [DEFAULT_LANG],
  languageOfPreference: DEFAULT_LANG,
  setting: false,
}

const langReducer = (state = initialLangState, action:ItemAction):LanguagesState => {
  switch(action.type){
    case LanguagesEvent.LoadItems:
      return {
        ...state,
        loading: true
      }
    case LanguagesEvent.LoadItemsSuccess:
      return {
        ...state,
        languages: action.payload.languages,
        loading: false
      }
    case LanguagesEvent.LoadItemsFailure:
      return {
        ...state,
        loading: false
      }
    case LanguagesEvent.SetUserLanguage:
      return {
          ...state,
          setting: true,
          languageOfPreference: action.payload,
      }
    // placeholder https://sim.amazon.com/issues/P49274723
    case LanguagesEvent.SetUserLanguageSuccess:
      return {
        ...state,
        setting: false,
      }
    // placeholder https://sim.amazon.com/issues/P49274723
    case LanguagesEvent.SetUserLanguageFailure:
      console.error("Failed to set user's preferred language")
      return {
        ...state,
        setting: false,
      }
    case UserActionTypes.FetchUserFulfilled:
      const lang = action.payload?.languageOfPreference ?? DEFAULT_LANG.tag
      return {
        ...state,
        setting: false,
        languageOfPreference: potentialLanguages[lang]
      }
    default:
      return state;
  }
};

export default langReducer
