/* eslint-disable no-case-declarations */
import { MetricActionType } from '../types';

export default function (state = [], action) {
  switch (action.type) {
    case MetricActionType.Impression:
      const [lastEntry, ...rest] = state.reverse();
      const { payload } = action;
      const ts = new Date().toISOString();
      if (lastEntry === undefined) {
        return [
          {
            sectionName: payload,
            entryTime: ts,
          },
        ];
      }
      return [
        ...rest.reverse(),
        {
          sectionName: lastEntry.sectionName,
          entryTime: lastEntry.entryTime,
          exitTime: ts,
        },
        {
          sectionName: payload,
          entryTime: ts,
        },
      ];
    default:
      return state;
  }
}
