import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'
import { UserActionTypes, UserProfileApiResponse } from './types'
import { fetchUserFulfilled, fetchUserFailed } from './actions'
import { EXTERNAL_URL_MAP } from '../../routes/ExternalUrls'
import { get, httpPut } from '../../utils/ajax'

import { trackPerformanceMetrics } from '../../utils/lcp'

import {
  prefixSegmentToPath
} from '../../context/baseSegmentContext'

import { ApplicationState } from '../types'

import {
  EnvironmentState
} from '../environment/types'
import {HostApp} from '../../types/hostApp'

const setLanguage = async (languageOfPreference: string): Promise<UserProfileApiResponse> => {
  const res = await httpPut<UserProfileApiResponse>(EXTERNAL_URL_MAP.USER_LANGUAGE, {
    languageOfPreference
  })
  return res.data
}

const fetchUserProfile = async (prefixSegment: string): Promise<UserProfileApiResponse> => {
  const userProfileUrl = prefixSegmentToPath({
    path: EXTERNAL_URL_MAP.USER_PROFILE,
    prefixSegment 
  })
  const res = await get<UserProfileApiResponse>(userProfileUrl)
  return res.data
}

export function* handleFetchUserOld(): Generator {
  try {
    const prefixSegment = (yield select((state: ApplicationState) => state.environment.baseSegmentPrefix)) as string
    const res: UserProfileApiResponse = (yield call(fetchUserProfile, prefixSegment)) as UserProfileApiResponse
    yield put(fetchUserFulfilled(res))
    trackPerformanceMetrics()
    if (res && res.languageOfPreference) {
      document.documentElement.lang = res.languageOfPreference.replace('_', '-')
    }
  } catch (err) {
    yield put(fetchUserFailed())
  }
}

function setDocumentLanguage(hostApp: HostApp, res: UserProfileApiResponse): void {
  if (hostApp === HostApp.Fallback && res && res.languageOfPreference) {
    document.documentElement.lang = res.languageOfPreference.replace('_', '-')
  }
}

function* contextualUserResponseHandling(hostApp: HostApp, hostAppLanguage: string, res: UserProfileApiResponse): Generator {
  if(hostApp === HostApp.Fallback || hostAppLanguage === ''){
    yield put(fetchUserFulfilled(res))
  } else {
    yield put(fetchUserFulfilled({...res, languageOfPreference: hostAppLanguage}))
    if(res.languageOfPreference !== hostAppLanguage){
      yield call(setLanguage, hostAppLanguage)
    }
  }
}

export function* handleFetchUserNew(): Generator {
  try {
    const {
      hostApp,
      baseSegmentPrefix,
      hostAppLanguage,
    } = (yield select((state: ApplicationState) => state.environment)) as EnvironmentState

    let res: UserProfileApiResponse = (yield call(fetchUserProfile, baseSegmentPrefix)) as UserProfileApiResponse
    yield contextualUserResponseHandling(hostApp, hostAppLanguage, res)
    setDocumentLanguage(hostApp, res)
    trackPerformanceMetrics()
  } catch (err) {
    yield put(fetchUserFailed())
  }
}

//export const handleFetchUser = handleFetchUserOld
export const handleFetchUser = handleFetchUserNew
// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchFetchUserRequest() {
  yield takeEvery(UserActionTypes.FetchUser, handleFetchUser)
}

// Export our root saga.
// We can also use `fork()` here to split our saga into multiple watchers.
export function* userSaga() {
  yield all([fork(watchFetchUserRequest)])
}
