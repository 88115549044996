import { all, fork, call, put, select, takeLatest } from 'redux-saga/effects';
import { EXTERNAL_URL_MAP } from '../../routes/ExternalUrls';
import { get } from '../../utils/ajax';
import { NavActionTypes, NavigationEvent } from './types';

import {
  prefixSegmentToPath
} from '../../context/baseSegmentContext'

async function fetchNav(prefixSegment) {
  const requestUrl = prefixSegmentToPath({
    path: EXTERNAL_URL_MAP.NAVIGATION,
    prefixSegment 
  })
  const res = await get(requestUrl);
  return res.data;
}

// const navItems = (state:any) => {
const navItems = (state) => {
  return state.navigation.items;
};

const language = (state) => state.languages.languageOfPreference;

const workAuthRegion = (state) => state.user.profile.workAuthContentGroup;

function* manageNav(action) {
  const { meta } = action
  let cachedNavItems = yield select(navItems);
  let userLang = yield select(language);
  let userRegion = yield select(workAuthRegion);

  if (cachedNavItems.length === 0 || (meta && meta.refetch)) {
    const prefixSegment = yield select(state => state.environment.baseSegmentPrefix)
    const payload = yield call(fetchNav, prefixSegment)
    yield put({
      type: NavigationEvent.LoadItemsSuccess,
      payload,
      lang: userLang,
      region: userRegion,
    });
  }
}

function* watchNavRequest() {
  yield takeLatest(NavActionTypes.FetchNav, manageNav);
}

export function* navSaga() {
  yield all([fork(watchNavRequest)]);
}
