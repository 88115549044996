import { PillStatus } from '../../components/LaunchpadPill'
import { checkMyDocsEligibility } from '../../utils/myDocsEligibility'
import { DashboardEvent, DashboardTaskState, TaskAction, Task } from './types'

const initialTaskState: DashboardTaskState = {
  loading: false,
  tasks: []
}

const prioritized = (tasks: Task[]): Task[] => {
  const sorted = [...tasks].filter(checkMyDocsEligibility)
  sorted.sort((...[{status: statusA, 'type': typeA}, {status: statusB, 'type': typeB}]) => {
    if (statusA === PillStatus.DUE_NOW && typeA === 'ImmigrationServiceProvider') {
      return -1;
    }
    if (statusB === PillStatus.DUE_NOW && typeB === 'ImmigrationServiceProvider') {
      return 1;
    }
    // if they're the same status, they're equal
    if (statusA === statusB) {
      return 0
    }
    // if a task is overdue, it's before another
    switch (statusA) {
      case PillStatus.OVERDUE:
        return -1
      // if a task is due now it's after the other if the other is overdue,
      // if a task is workauth, it's first among due now
      // otherwise it's before the other
      case PillStatus.DUE_NOW:
        return statusB === PillStatus.OVERDUE || typeB === 'WorkAuth' ? 1 : -1
      // if a task is in_progress, put it after tasks that are overdue and duenow
      case PillStatus.IN_PROGRESS:
        return statusB === PillStatus.OVERDUE || PillStatus.DUE_NOW ? 1 : -1
      // if a task is done it should be after everything except unavailable 
      case PillStatus.DONE:
        return statusB === PillStatus.UNAVAILABLE ? -1 : 1
      default:
        return 0
    }
  })
  return sorted
}

const taskReducer = (state: DashboardTaskState  = initialTaskState, action: TaskAction): DashboardTaskState => {
  switch(action.type){
    case DashboardEvent.LoadTasks: {
      return {
        ...state,
        loading: true
      }
    }
    case DashboardEvent.LoadTasksSuccess: {
      const { payload } = action;
      return {
        loading: false,
        tasks: prioritized(payload)
      }
    }
    case DashboardEvent.LoadTasksFailure: {
      return {
        ...state,
        loading: false
      }
    }
    default:
      return state
  }
};

export default taskReducer;
