import {
    BadgePhotoReviewStatusActionTypes,
    BadgePhotoReviewStatusActions,
    BadgePhotoReviewStatusState
} from './types'

const initialBadgePhotoReviewStatusState: BadgePhotoReviewStatusState = {
    icimsId: undefined,
    reviewStatusUpdatedAt: undefined,
    status: undefined,
    statusMessageCode: undefined,
    statusMessageStrings: undefined
}

export const badgePhotoReviewStatusReducer = (state = initialBadgePhotoReviewStatusState, action: BadgePhotoReviewStatusActions) => {
    switch (action.type) {
        case BadgePhotoReviewStatusActionTypes.BeginPollingPhotoReviewStatus:
            return state

        case BadgePhotoReviewStatusActionTypes.FetchReviewStatusFulfilled:
            return {...action.payload}

        case BadgePhotoReviewStatusActionTypes.FetchReviewStatusFailed:
            return state

        default:
            return state;
    }
}
