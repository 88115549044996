import React from 'react';
import { prefixSegmentImplementationToggle } from '../releaseToggles';
import {
  BasePrefix,
  HostApp,
  HostAppContext
} from '../types/hostApp'

// Identifies the host app which embeds Pre-boarding application.
export const PreboardingHostAppContext = React.createContext<HostAppContext>({
  employeeId: '',
  hostApp: HostApp.Fallback,
  baseSegmentPrefix: BasePrefix.WEB_PORTAL,
  hostAppLanguage: ''
});

interface PrefixSegmentToPathProps {
  backendEndpoint?: string
  path: string
  prefixSegment: string
  releaseToggle?: boolean
}

export const prefixSegmentToPath = (prefixSegmentToPathProps: PrefixSegmentToPathProps) => {
  const { backendEndpoint, path, prefixSegment, releaseToggle = prefixSegmentImplementationToggle } = prefixSegmentToPathProps
  if (!releaseToggle) {
    return path
  }

  try {
    const parsedUrl = new URL(path)
    const urlProtocol = parsedUrl.protocol
    // Checking to see if the path passed in is a full url.
    // If it is a url with an http based protocol, we simply return it.
    // This is because we often specifiy links to partner team service urls
    // with protocols, and don't want to change them.
    if(urlProtocol === 'http:' || urlProtocol === 'https:'){
      return path
    }
  } catch(e){
  }

  // checking to see if the url was already updated with prefixSegment
  const prefixSegmentMatcher = new RegExp(`^/${prefixSegment}`)
  if (prefixSegmentMatcher.test(path)) {
    return path
  }

  // finally if the url was a path/route, make the base path change to it. There are two
  // variations to this - as a relative path or an absolute path to the backend.
  // We distinguish this based on the presence or absence of the `backendEndpoint`.
  //
  // 1. If `backendEndpoint` is passed in, we assume this is url pointing to the backend apigw.
  // Usually, we would do so for making an api call. We always use the https protocol.
  //
  // 2. If `backendEndpoint` is not passed in, we assume that this is a contextual relative path

  if(backendEndpoint) {
    return `${backendEndpoint}/${prefixSegment}${path}`
  }
  return `/${prefixSegment}${path}`
}

// Maps a HostApp to prefix
export const mapHostToPrefix = (hostApp: HostApp | string): string => {
  switch(hostApp){
    case HostApp.AtoZESSP:
      return BasePrefix.ATOZ_MOBILE
    case HostApp.AtoZMobile:
      return BasePrefix.ATOZ_MOBILE
    default:
      return BasePrefix.WEB_PORTAL
  }
}

// Gets HostApp from a data-attribute, given an element id.
// `id` defaults to `atoz-preboarding-app-root`
export const getHostAppMountDataAttribute = (elem: HTMLElement | null): HostApp => {

  if(elem === null) return HostApp.Fallback

  const hostApp = elem.dataset.hostapp

  switch(hostApp){
    case 'atozweb':
      return HostApp.AtoZESSP
    case 'atozmobile':
      return HostApp.AtoZMobile
    default:
      return HostApp.Fallback
  }
}

// Gets employeeId of the logged in employee from the host app
export const getHostAppEmpId = (elem: HTMLElement | null): string => {
  if(elem === null) return ''
  return (elem.dataset.employeeid || '')
}

export const atozBackendHostName = process.env.ATOZ_HOST_BACKEND
