import { createStandardAction } from 'typesafe-actions';
import {
  LoginSuggestionsSuccessResponse,
  LoginAvailabilitySuccessResponse,
  InitialStateSuccessResponse,
  ReserveUserLoginSuccessResponse,
  ReserveUserLoginErrorResponse,
  DeleteUserLoginSuccessResponse,
  PimaLoginSuccessResponse
} from './types';
import { LoginSelectionActionTypes } from './actionTypes';

export const initialPageStateSuccess = createStandardAction(LoginSelectionActionTypes.GetInitialStateSuccess)<InitialStateSuccessResponse>();
export const initialPageStateError = createStandardAction(LoginSelectionActionTypes.GetInitialStateError)();

export const loginSuggestionsSuccess = createStandardAction(LoginSelectionActionTypes.LoginSuggestionsSuccess)<LoginSuggestionsSuccessResponse>()
export const loginSuggestionsError = createStandardAction(LoginSelectionActionTypes.LoginSuggestionsError)()

export const loginAvailabilitySuccess = createStandardAction(LoginSelectionActionTypes.LoginAvailabilitySuccess)<LoginAvailabilitySuccessResponse>()
export const loginAvailabilityError = createStandardAction(LoginSelectionActionTypes.LoginAvailabilityError)()

export const deleteUserLoginSuccess = createStandardAction(LoginSelectionActionTypes.DeleteUserLoginSuccess)<DeleteUserLoginSuccessResponse>()
export const deleteUserLoginError = createStandardAction(LoginSelectionActionTypes.DeleteUserLoginError)()

export const reserveUserLoginSuccess = createStandardAction(LoginSelectionActionTypes.ReserveUserLoginSuccess)<ReserveUserLoginSuccessResponse>()
export const reserveUserLoginError = createStandardAction(LoginSelectionActionTypes.ReserveUserLoginError)<ReserveUserLoginErrorResponse>()

export const getPimaLoginSuccess = createStandardAction(LoginSelectionActionTypes.GetPimaUserLoginSuccess)<PimaLoginSuccessResponse>()
