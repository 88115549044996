/* eslint-disable no-case-declarations */

import { MetricActionType } from '../types';
import { identity } from 'lodash';

function applyForId(id, key, arry, fn = identity) {
  return arry.map((elem) => {
    if (elem[key] !== id) return elem;
    return fn(elem);
  });
}

export default function (state = [], action) {
  switch (action.type) {
    case MetricActionType.VideoAction:
      const { videoName, duration } = action.payload;
      const videoInState = !!state.find((elem) => elem.videoName === videoName);
      if (!videoInState) {
        return [
          ...state,
          {
            videoName,
            maxDuration: duration,
          },
        ];
      }
      return applyForId(videoName, 'videoName', state, ({ videoName, maxDuration = 0 }) => {
        return {
          videoName,
          maxDuration: maxDuration > duration ? maxDuration : duration,
        };
      });
    default:
      return state;
  }
}
