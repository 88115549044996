import { Action } from 'redux'

export const SubmissionStatus = {
    Unsubmitted: 0,
    Submitting: 1,
    SubmissionSuccess: 2,
    SubmissionFailure: 3,
};

export interface WorkAuthStateResponse {
    status: string
    myDocsPortalSsoUrl?: string
}

export interface WorkAuthStateRequest {
    status: string
}

export enum WorkAuthActionTypes {
    GetWorkAuthStatus = 'GET_WORKAUTH_STATUS',
    GetWorkAuthStatusSuccess = 'GET_WORKAUTH_STATUS_SUCCESS',
    GetWorkAuthStatusFailure = 'GET_WORKAUTH_STATUS_FAILURE',
    UpdateWorkAuthStatus = 'UPDATE_WORKAUTH_STATUS',
    UpdateWorkAuthStatusSuccess = 'UPDATE_WORKAUTH_STATUS_SUCCESS',
    UpdateWorkAuthStatusFailure = 'UPDATE_WORKAUTH_STATUS_FAILURE',
    SaveWorkAuthCopyInjections = 'WORK_AUTH/SAVE_COPY_INJECTIONS',
}

interface StringInjection {
    type: "string"
}

interface LinkInjection {
    url: string
}

interface AnchorInjection extends LinkInjection {
    type: "link"
}

interface EmailInjection extends LinkInjection {
    type: "email"
}

type Injection = StringInjection
    | AnchorInjection
    | EmailInjection

export type InjectionConfig = {[key: string]: Injection}

export interface GetWorkAuthStatusAction extends Action {
    type: WorkAuthActionTypes.GetWorkAuthStatus
}

export interface GetWorkAuthStatusSuccessAction extends Action {
    type: WorkAuthActionTypes.GetWorkAuthStatusSuccess
    payload: WorkAuthStateResponse
}

export interface GetWorkAuthStatusFailureAction extends Action {
    type: WorkAuthActionTypes.GetWorkAuthStatusFailure
}

export interface UpdateWorkAuthStatusAction extends Action {
    type: WorkAuthActionTypes.UpdateWorkAuthStatus
    payload: WorkAuthStateRequest
}

export interface UpdateWorkAuthStatusSuccessAction extends Action {
    type: WorkAuthActionTypes.UpdateWorkAuthStatusSuccess
    payload: WorkAuthStateRequest
}

export interface UpdateWorkAuthStatusFailureAction extends Action {
    type: WorkAuthActionTypes.UpdateWorkAuthStatusFailure
}

export interface SaveWorkAuthCopyInjectionsAction extends Action {
    type: WorkAuthActionTypes.SaveWorkAuthCopyInjections
    payload: InjectionConfig
}

export type WorkAuthAction =
    GetWorkAuthStatusAction
    | GetWorkAuthStatusSuccessAction
    | GetWorkAuthStatusFailureAction
    | UpdateWorkAuthStatusAction
    | UpdateWorkAuthStatusSuccessAction
    | UpdateWorkAuthStatusFailureAction
    | SaveWorkAuthCopyInjectionsAction

export interface WorkAuthApplicationState {
    defaultLayout?: any
    injections?: InjectionConfig
    statusUpdatedAt?: string
    status?: string
    submissionStatus?: number
    myDocsPortalSsoUrl?: string
}
