import { Store, compose, createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { ApplicationState } from './types'
import { rootSaga } from './sagas'
import { rootReducer } from './reducer'

declare global {
  interface Window {
    __UI_BASE_PATHNAME: string;
  }
}

export function configureStore(): Store<ApplicationState> {
  const composeEnhancers =
    typeof window === 'object' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose

  const sagaMiddleware = createSagaMiddleware({
    onError: (err) => {
      /*
       * We need to figure how to handle client side errors for the case
       * when the user is still unauthenticated, which happens in the
       * landing page. Such authenticated requests force a logout for
       * the user, which forces them to land on the landing page at the
       * end. As you see - it closes the loop triggering a vicious cycle in
       * which the user's page loads keep on cycling back to landing paga.
       *
      postUtil('app.error')({
        error: err,
        timeStamp: (new Date()).toUTCString()
      });
      */
    }
  })

  const uiBasePathname = window.__UI_BASE_PATHNAME || '/'

  const history = createBrowserHistory({
    basename: uiBasePathname
  })

  if (!window.location.pathname.startsWith(uiBasePathname)) {
    history.push('/')
  }

  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware(history))) // does order matter here?
  )

  sagaMiddleware.run(rootSaga)
  return store
}
