import { DashboardEvent, TaskAction } from './dashboard/types'

const dashboardNavigationReducer= (state = true, action: TaskAction) => {
  switch(action.type){
    case DashboardEvent.LoadTasks:
      return true;
    case DashboardEvent.GoBackToDashboard:
      return false;
    default:
      return state;
  }
};

export { dashboardNavigationReducer };
