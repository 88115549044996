import { Action } from 'redux';

export interface SubFooterItem {
  type: string
  payload: {
    icimsid?: string
    target?: string
    email?: string
    name?: string
    url?: string
  }
}

export enum EmailTarget {
  recruiter = 'recruiter',
  manager = 'manager',
  onboardingSupport = 'onboardingSupport',
}

export enum SubFooterItemTypes {
  EMAIL = "email",
  FAQ = "faq",
  MYHR = "myHR"
}

export interface SubFooterState {
  loading: boolean;
  subFooterItems: SubFooterItem[];
}

export interface SubFooterApplicationState {
  subFooterState: SubFooterState;
}

export enum SubFooterEvent {  
  LoadSubFooterItems = 'LOAD_SUB_FOOTER_ITEMS',
  LoadSubFooterItemsSuccess = 'LOAD_SUB_FOOTER_ITEMS_SUCCESS',
  LoadSubFooterItemsFailure = 'LOAD_SUB_FOOTER_ITEMS_FAILURE',
  LoadSubFooterItemsContent = 'LOAD_SUB_FOOTER_ITEMS_CONTENT'
}
interface LoadSubFooterItemsAction extends Action {
  type: SubFooterEvent.LoadSubFooterItems
}

interface LoadSubFooterItemsSuccessAction extends Action {
  type: SubFooterEvent.LoadSubFooterItemsSuccess
  payload: SubFooterItem[]
}

interface LoadSubFooterItemsFailureAction extends Action {
  type: SubFooterEvent.LoadSubFooterItemsFailure
}

interface LoadSubFooterItemsContentAction extends Action {
  type: SubFooterEvent.LoadSubFooterItemsContent
}

export type SubFooterAction = LoadSubFooterItemsAction
  | LoadSubFooterItemsSuccessAction
  | LoadSubFooterItemsFailureAction 
  | LoadSubFooterItemsContentAction

