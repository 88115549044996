import { Action } from 'redux'
import { HostAppContext } from '../../types/hostApp'

export enum EnvActionTypes {
  FetchEnv = 'ENV_REQUEST',
  FetchEnvFulfilled = 'ENV_RESPONSE_COMPLETED',
  FetchEnvFailed = 'ENV_REQUEST_FAILED'
}

export enum EnvStages {
  prod = 'prod',
  gamma = 'gamma',
  beta = 'beta',
}

interface EnvCanStage {
  prod?: boolean
  gamma?: boolean
  beta?: boolean
}

export interface EnvState {
  loading: boolean
  canStage?: EnvCanStage
}

export type EnvironmentState = HostAppContext

export enum EnvEvent {
  LoadEnv = 'ENV_LOAD',
  LoadEnvSuccess = 'ENV_LOAD_SUCCESS',
  LoadEnvFailure = 'ENV_LOAD_FAILURE',
}

interface LoadEnvAction extends Action {
  type: EnvEvent.LoadEnv
}

interface LoadEnvSuccessAction extends Action {
  type: EnvEvent.LoadEnvSuccess
  payload: EnvCanStage
}

interface LoadEnvFailureAction extends Action {
  type: EnvEvent.LoadEnvFailure
}

export type LoadEnvActions = LoadEnvAction
  | LoadEnvSuccessAction
  | LoadEnvFailureAction

export enum EnvActionType {
  PersistHostApp = 'PREBOARDING_PERSIST_HOST'
}

interface PersistHostAppAction extends Action {
  type: EnvActionType.PersistHostApp
  payload: HostAppContext
}

export type EnvAction = PersistHostAppAction
