import React, { FunctionComponent } from 'react';
import Pill, { PillProps } from '../Pill';
import { ColorPalette, DefaultPillText } from '../../constants';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store/types';
import { getStringResources } from '../../utils/intl';

interface PillConfig {
  bgColor: string;
  color: string;
  text: string;
}

export interface pillCopyMessages {
  due: string;
  overdue: string;
  complete: string;
  inReview: string;
}

export interface PillStatusMap {
  HIDE: number;
  UNFETCHED: number;
  NO_ACTION: number;
  UNAVAILABLE: number;
  DUE_NOW: number;
  DONE: number;
  OVERDUE: number;
  IN_PROGRESS: number;
}

interface SemanticPillProps extends PillProps {
  status: number;
  copy?: pillCopyMessages;
  configMap?: (status: number) => PillConfig;
  textMap?: (status: number) => string;
}

export const PillStatus = {
  HIDE: -3,
  UNFETCHED: -2,
  UNAVAILABLE: -1,
  DUE_NOW: 0,
  DONE: 1,
  OVERDUE: 2,
  IN_PROGRESS: 3,
  NO_ACTION: 4,
} as PillStatusMap;

const LaunchpadPill: FunctionComponent<SemanticPillProps> = (props) => {
  const configMapper = props.configMap || getPillConfig;
  const config = configMapper(props.status, props.copy);

  return props.status == PillStatus.DUE_NOW || props.status == PillStatus.UNAVAILABLE ? null : (
    <Pill {...props} color={config.color} bgColor={config.bgColor}>
      {props.children || props.textMap?.(props.status) || config.text}
    </Pill>
  );
};

export const getPillConfig = (status: number, copy?: pillCopyMessages) => {
  let retVal = {
    color: `${ColorPalette.Black}`,
    bgColor: `${ColorPalette.PillDefault}`,
    text: '',
  };
  switch (status) {
    case PillStatus.DUE_NOW:
      retVal = {
        color: `${ColorPalette.Black}`,
        bgColor: `${ColorPalette.PillYellow}`,
        text: copy?.due ?? DefaultPillText.due,
      };
      break;
    case PillStatus.IN_PROGRESS:
      retVal = {
        color: `${ColorPalette.White}`,
        bgColor: `${ColorPalette.PillBlue}`,
        text: copy?.inReview ?? DefaultPillText.inReview,
      };
      break;
    case PillStatus.DONE:
      retVal = {
        color: `${ColorPalette.White}`,
        bgColor: `${ColorPalette.PillGreen}`,
        text: copy?.complete ?? DefaultPillText.complete,
      };
      break;
    case PillStatus.OVERDUE:
      retVal = {
        color: `${ColorPalette.White}`,
        bgColor: `${ColorPalette.PillRed}`,
        text: copy?.overdue ?? DefaultPillText.overdue,
      };
      break;
    default:
      retVal = {
        color: `${ColorPalette.Black}`,
        bgColor: `${ColorPalette.PillDefault}`,
        text: '',
      };
  }
  return retVal;
};

const mapStateToProps = (state: ApplicationState) => {
  const language = state.languages.languageOfPreference.tag;
  const commonItem = state?.copy?.items?.find((item: any) => item.slug === 'common');
  return {
    copy: getStringResources(commonItem?.stringResources, language)?.pills,
  };
};

export default connect(mapStateToProps)(LaunchpadPill);
