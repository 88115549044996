import { all, fork, call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import { EXTERNAL_URL_MAP } from '../../routes/ExternalUrls';
import { get, httpPut } from '../../utils/ajax';
import { LangActionTypes, LanguagesEvent, SetUserLanguageAction, LanguagesPayload, SetUserLanguageSuccessAction } from './types';
import { Language } from '../languages/types'
import { UserProfileApiResponse } from '../user/types'
import { updateWorkAuthCopyAfterPreferredLanguageChange } from '../../store/copy/workAuthCopySagas'
import { handleFetchUser } from '../../store/user/sagas'
import { languages } from '../../config/languages'

function* manageLang() {
  yield put({
    type: LanguagesEvent.LoadItemsSuccess,
    payload: {
      languages: languages
    },
  });
}

function* watchLangRequest() {
  yield takeLatest(LangActionTypes.FetchLang, manageLang);
}

const setLanguage = async (language: Language): Promise<UserProfileApiResponse> => {
  const res = await httpPut<UserProfileApiResponse>(EXTERNAL_URL_MAP.USER_LANGUAGE, {
    languageOfPreference: language.tag
  })
  document.documentElement.lang = language.tag
  return res.data
}

function* handleSetLanguage(action: SetUserLanguageAction): Generator {
  try {
    const result: UserProfileApiResponse = (yield call(setLanguage, action.payload)) as UserProfileApiResponse
    yield put({type:LanguagesEvent.SetUserLanguageSuccess, result})
  } catch (err) {
    yield put({type:LanguagesEvent.SetUserLanguageFailure})
    console.error('error sending language preference', err)
  }
}

function* handleSetLanguageSuccess(action: SetUserLanguageSuccessAction): Generator {
  try {
    yield all([call(handleFetchUser), call(updateWorkAuthCopyAfterPreferredLanguageChange, action.result)])
    yield put({type:LanguagesEvent.UpdatedWorkAuthCopySuccess})
  } catch (err) {
    yield put({type:LanguagesEvent.UpdatedWorkAuthCopyFailure})
    console.error('error sending language preference', err)
  }
}

function* watchSetLanguageRequest() {
  yield takeEvery(LanguagesEvent.SetUserLanguage, handleSetLanguage)
  yield takeEvery(LanguagesEvent.SetUserLanguageSuccess, handleSetLanguageSuccess)
}

export function* langSaga() {
  yield all([fork(watchLangRequest),  fork(watchSetLanguageRequest)]);
}
