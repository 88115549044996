import { useState, useEffect } from 'react';
import { EXTERNAL_URL_MAP } from '../routes/ExternalUrls';
import { get } from './ajax';
import { AxiosResponse } from 'axios';

enum ENVIRONMENTS {
  LOCAL = 'local',
  BETA = 'beta',
  GAMMA = 'gamma',
  PROD = 'prod'
}

export const StagingEnvs:Record<ENVIRONMENTS, string> = {
  local: '',
  beta: 'beta',
  gamma: 'gamma',
  prod: 'prod'
};

export const stagesToLocal = [
  StagingEnvs.local
];

export const stagesToBeta = [
  ...stagesToLocal,
  StagingEnvs.beta
];

export const stagesToGamma = [
  ...stagesToBeta,
  StagingEnvs.gamma
];

const isInStoryBook = () => window.location.host === 'localhost:6006'

export const fetchEnvs = (envs: ENVIRONMENTS[]):Promise<boolean> => {
  const stages = envs.join(',');
  const url = `${EXTERNAL_URL_MAP.RELEASE}?stages=${stages}`;
  return get(url)
      .then((response:AxiosResponse<any>) => response.data)
}

export const fetchAllEnvs = async () => {
  const results:Map<ENVIRONMENTS, boolean> = new Map()
  results.set(ENVIRONMENTS.LOCAL, true)
  const betaResult = await fetchEnvs([ENVIRONMENTS.BETA])
  results.set(ENVIRONMENTS.BETA, betaResult)
  if (betaResult === true) {
    const gammaResult = await fetchEnvs([ENVIRONMENTS.GAMMA])
    results.set(ENVIRONMENTS.GAMMA, gammaResult)
  }
  return results  
}

/*
 * Use this hook within your component for a release toggle.
 * The way it works is very simple:-
 * 1. Pass in the stages that you want your component to be rendered. For instance,
 *    If you want to release only till beta, use `stagesToBeta`. If you then want to release to gamma,
 *    pass in `stagesToGamma`. For more custom arrangements, build an array out of `StagingEnvs`.
 * 2. This will return a state variable (denyRelease) which will indicate whether to deny rendering or not to.
 * 3. If denyRelease is true, simply render a null from the functional component. If it's false, render whatever you want to.
 */

export const useReleaseToggle = (envs = []) => {
  if (isInStoryBook()) {  // FIXME: perhaps this is not the best way to fix this problem , consider removing,
                          // especially now with `environment` introduced to store...
    return true
  }
  
  const [allowRelease, setReleaseStatus] = useState(false);
  useEffect(() => {
    fetchEnvs(envs)
      .then(shouldRelease => setReleaseStatus(shouldRelease));
  },[]);
  return !allowRelease;
};
