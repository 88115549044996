import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'
import { ShippingAddressActionTypes, ShippingAddress, AddressValidationResult, AddressValidationResultCode } from './types'
import {
  scrollToMessageBanner,
  loadShippingAddressSuccess,
  loadShippingAddressFailure,
  updateShippingAddressSuccess,
  updateShippingAddressFailure,
  updateShippingAddressSuggestions
} from './actions'
import {
  SelectAssistiveTechnologyAction,
  ToggleAssistiveTechnologyExpanderAction,
  UnSelectAssistiveTechnologyAction,
  UpdateShippingAddressAction,
  ValidateAndGetAddressSuggestionsAction
} from './types';
import { EXTERNAL_URL_MAP } from '../../routes/ExternalUrls'
import { get, post, httpPut } from '../../utils/ajax'
import postUtil from '../../metrics/postUtil';
import { NavActionTypes } from '../nav/types';
import { trackATSelection, trackATToggles, trackATUnselection } from './metrics'
import { scrollIntoViewWithHeaderOffset } from '../../utils/scrollIntoViewWithOffset'

const loadShippingAddress = async (): Promise<ShippingAddress> => {
  const res = await get<ShippingAddress>(EXTERNAL_URL_MAP.SHIPPING_ADDRESS)
  return res.data
}

const updateShippingAddress = async (shippingAddress: ShippingAddress): Promise<AddressValidationResult> => {
  const res = await httpPut<AddressValidationResult>(EXTERNAL_URL_MAP.SHIPPING_ADDRESS, shippingAddress)
  return res.data
}

const validateShippingAddress = async (shippingAddress: ShippingAddress): Promise<AddressValidationResult> => {
  const res = await post<AddressValidationResult>(EXTERNAL_URL_MAP.SHIPPING_ADDRESS_VALIDATE, shippingAddress)
  return res.data
}

function* handleLoadShippingAddress(): Generator {
  try {
    const res: ShippingAddress = (yield call(loadShippingAddress)) as ShippingAddress
    yield put(loadShippingAddressSuccess(res))
  } catch (err) {
    yield put(loadShippingAddressFailure())
  }
}

function* handleUpdateShippingAddress(action: UpdateShippingAddressAction){
  try {
    const res: ShippingAddress = (yield call(updateShippingAddress, action.payload)) as ShippingAddress
    postUtil('equipmentPreference.save.success')({
      timeStamp: (new Date()).toISOString()
    })
    yield all([
      put(updateShippingAddressSuccess(res)),
      put({ type: NavActionTypes.FetchNav, meta: { refetch: true } })
    ])
  } catch (err) {
    postUtil('equipmentPreference.save.failure')({
      timeStamp: (new Date()).toISOString()
    })
    yield put(updateShippingAddressFailure())
  } finally {
    yield put(scrollToMessageBanner())
  }
}

/**
 * This performs address validation
 * if resultCode is INVALID or VALID_WITH_CHANGES it will dispatch updateShippingAddressSuggestions
 * if resultCode is VALID or the service could not be performed, it will submit the form without validation
 * @param action 
 */
function* handleValidateShippingAddress(action: ValidateAndGetAddressSuggestionsAction) {

  let persistShippingAddress = false;

  try {
    const res: AddressValidationResult = (yield call(validateShippingAddress, action.payload)) as AddressValidationResult
    const {resultCode = '' } = res 
    if (resultCode === AddressValidationResultCode.INVALID 
      || resultCode === AddressValidationResultCode.VALID_WITH_CHANGES 
      || resultCode === AddressValidationResultCode.PO_BOX) {
      yield put(updateShippingAddressSuggestions(res))
    } else {
      persistShippingAddress = true
    }
  } catch (err) {
    persistShippingAddress = true
  }

  try {
    if (persistShippingAddress) {
      const updateRes: ShippingAddress = (yield call(updateShippingAddress, action.payload)) as ShippingAddress
      postUtil('equipmentPreference.save.success')({
        timeStamp: (new Date()).toISOString()
      })
      yield all([
        put(updateShippingAddressSuccess(updateRes)),
        put({ type: NavActionTypes.FetchNav, meta: { refetch: true } })
      ])
    }
  } catch (err) {
    postUtil('equipmentPreference.validate.failure')({
      timeStamp: (new Date()).toISOString()
    })
    yield put(updateShippingAddressFailure())
  } finally {
    yield put(scrollToMessageBanner())
  }
}

function* handleScrollToMessageBannerAction(){
  yield select(state => {
    const  messageBannerRef = state.shippingAddressApplicationState.messageBannerRef;
    const current = messageBannerRef && messageBannerRef.current
    if(current){
      scrollIntoViewWithHeaderOffset(current)
      current.focus()
    }
  })
}

export function* handleATSelections(action: SelectAssistiveTechnologyAction): Generator {
  const assistiveTechnology = action.payload
  yield call(trackATSelection, assistiveTechnology)
}

export function* handleATToggles(action: ToggleAssistiveTechnologyExpanderAction): Generator{
  const shouldATBeActive = action.payload
  yield call(trackATToggles, {shouldATBeActive})
}

export function* handleATUnselections(action: UnSelectAssistiveTechnologyAction): Generator {
  const assistiveTechnology = action.payload
  yield call(trackATUnselection, assistiveTechnology)
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchLoadShippingAddressRequest() {
  yield takeEvery(ShippingAddressActionTypes.LoadShippingAddress, handleLoadShippingAddress)
}

function* watchUpdateShippingAddressRequest() {
  yield takeEvery(ShippingAddressActionTypes.UpdateShippingAddress, handleUpdateShippingAddress)
  yield takeEvery(ShippingAddressActionTypes.ScrollToMessageBanner, handleScrollToMessageBannerAction)
}

function* watchValidateUpdateShippingAddressRequest() {
  yield takeEvery(ShippingAddressActionTypes.ValidateAndGetAddressSuggestions, handleValidateShippingAddress)
}

function* watchATSelections() {
  yield takeEvery(ShippingAddressActionTypes.SelectAssistiveTechnology, handleATSelections)
}

function* watchATToggles(){
  yield takeEvery(ShippingAddressActionTypes.OpenAssistiveTechnologyExpander, handleATToggles)
}

function* watchATUnselections() {
  yield takeEvery(ShippingAddressActionTypes.UnSelectAssistiveTechnology, handleATUnselections)
}

// Export our root saga.
// We can also use `fork()` here to split our saga into multiple watchers.
export function* shippingAddressSaga() {
  yield all([
    fork(watchLoadShippingAddressRequest),
    fork(watchUpdateShippingAddressRequest),
    fork(watchValidateUpdateShippingAddressRequest),
    fork(watchATSelections),
    fork(watchATToggles),
    fork(watchATUnselections),
  ])
}
