import { createGlobalStyle } from 'styled-components';

const Globalstyle = createGlobalStyle`
// html {
//   @media(max-height: 667px){
//     font-size: 13px;
//   }
// }

.breakable-strong {
  word-break: break-word;
}
`;

export const ScrollFixStyle = createGlobalStyle`
html > body:not([data-backdrop]) {
  overflow-y: scroll !important;
  position: static;
  top: auto;
}
html > body > #atoz-preboarding-app-root {
  max-height: none !important;
}
a {
  color: #1768C9;
  text-decoration: none;
}
`;

export default Globalstyle;
