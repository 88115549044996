import { PendingBadgePhotoActionTypes, PendingBadgePhotoActions } from './types'

const initialPendingBadgePhotoState = ''

export const pendingBadgePhotoReducer = (state = initialPendingBadgePhotoState, action: PendingBadgePhotoActions): string => {
    switch (action.type) {
        case PendingBadgePhotoActionTypes.FetchPendingBadgePhoto:
            return ''

        case PendingBadgePhotoActionTypes.FetchPendingBadgePhotoFulfilled:
        //@ts-ignore
            return URL.createObjectURL(action.payload)

        case PendingBadgePhotoActionTypes.FetchPendingBadgePhotoFailed:
            return action.payload as string

        default:
            return state;
    }
}
