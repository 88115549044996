import React, {FunctionComponent} from 'react'
import styled from 'styled-components'
import { ColorPalette, StencilTypography, TextSizes } from '../../constants'
import type {} from 'styled-components/cssprop'
import { ScreenReaderOnly } from '@amzn/stencil-react-components/esm/screen-reader-only';

export interface PillProps {
  color?: string
  bgColor?: string
  borderColor?: string
  margin?: string
  size?: TextSizes
  'aria-hidden'?: boolean
  'vertical-align'?: boolean
  ariaLabelPrefix?: string
  borderRadius?: string
}

interface StyleProps {
  pillColor: string
  pillBgColor: string
  borderColor: string
  fontSize: number
  margin: string
  'vertical-align'?: boolean
  'aria-label'?: string
  borderRadius?: string
}

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: ${(props:StyleProps) => props.borderRadius};
  color: ${(props:StyleProps) => props.pillColor};
  background-color: ${(props:StyleProps) => props.pillBgColor};
  border: 1px solid ${(props:StyleProps) => props.borderColor};
  padding: 0 0.75rem 0.125rem;
  font-size: 0.6875rem;
  line-height: 1.0625rem;
  min-height: 1.0625rem;
  max-height: 1.0625rem;
  margin: ${(props:StyleProps) => props.margin};
  white-space: nowrap;
`;

const Pill: FunctionComponent<PillProps> = (props) => {
  const {
    color,
    bgColor,
    borderColor = 'transparent',
    margin = '0',
    children,
    size = TextSizes.tiny,
    borderRadius = '0.75rem',
  } = props
  const pillColor = `${ColorPalette.Black}`
  const pillBgColor = `${ColorPalette.PillDefault}`
  const ariaHide = props['aria-hidden'] ?? true
  const verticalAlign = props['vertical-align'] ?? 'text-bottom'

  const intermediate = StencilTypography[size ]
  const fontSize = intermediate.fontSize

  const restProps: StyleProps = {
    pillColor: color || pillColor,
    pillBgColor: bgColor || pillBgColor,
    borderColor,
    margin,
    fontSize,
    borderRadius,
  };

  return (
    //@ts-ignore
    <Container vertical-align={verticalAlign} aria-hidden={ariaHide} {...restProps}>
      { 
        !ariaHide && 'ariaLabelPrefix' in props &&
        <ScreenReaderOnly>{props.ariaLabelPrefix}</ScreenReaderOnly>
      }
      {children}
    </Container>
  );
};

export default Pill;
