import { Action } from 'redux'

// The relevant action types invoked by the video player.
// For each action, we do something in the reducer.
export enum VideoActionTypes {
  CurrentlyPlaying = 'VIDEO_ACTION_CURRENTLY_PLAYING',
  Play = 'VIDEO_ACTION_PLAY',
  Pause = 'VIDEO_ACTION_PAUSE',
  Finish = 'VIDEO_ACTION_FINISH'
}

// The Video Metadata type.
// For now we require only currentTime for each video to be listed, so that
// we can restore the video to the same time, on page resizing.
//
// In future, if we need to know more attributes about the video, then we can
// add more fields to this type, without having to change many procedure signatures.
export interface VideoMeta {
  currentTime: number | undefined;
}

// The Video state type in the application state.
// This will encapsulate all the state within videos.
// @field playing: The slug of the currently playing video. All other videos will be paused.
// @field meta: Metadata for all videos, indexed by their slug names.
export interface VideoState {
  playing: string | null;
  meta?: { [key: string] : VideoMeta };
}

// The payload type for video actions.
// @field id : The slug name for video
// @field currentTime: The current time at which the video is playing (in seconds).
interface VideoPayload {
  id: string;
  currentTime: number | undefined;
}

// A generic video action. This will carry a payload of type `VideoPayload`.
export interface VideoAction extends Action {
  payload: VideoPayload;
}

// An action to indicate a play operation request on a video.
export interface PlayVideoAction extends VideoAction {
  type: VideoActionTypes.Play;
}

// An action to indicate the video is currently playing.
// The payload carries a `currentTime` timestamp with it, indicating where the video is playing at.
export interface CurrentlyPlayingVideoAction extends VideoAction {
  type: VideoActionTypes.CurrentlyPlaying;
}

// An action to indicate a pause operation request on a video.
export interface PauseVideoAction extends VideoAction {
  type: VideoActionTypes.Pause
}

// An action indicating that the video has finished.
export interface FinishVideoAction extends VideoAction {
  type: VideoActionTypes.Finish
}

// A sum type of all VideoActions. This creates a set of allowable types in the video reducer.
export type VideoActions = PlayVideoAction | PauseVideoAction | FinishVideoAction | CurrentlyPlayingVideoAction;
