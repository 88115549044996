import { get } from 'lodash'
import React, { useContext } from 'react'
import { Route } from 'react-router-dom'
import { Location } from 'history'
import { EXTERNAL_URL_MAP } from './ExternalUrls'
import { UserState } from '../store/user/types'

export interface PrivateRouteProps {
    component: React.ComponentType<any>
    componentProps?: ComponentProps
    user: UserState
    location?: Location
    to?: string
    path: string
    exact: boolean
}

export interface ComponentProps {
  [key: string]: any
}

import {
  HostAppContext,
} from '../types/hostApp'

import {
  PreboardingHostAppContext,
} from '../context/baseSegmentContext'

import {
  apiUserLoginRule
} from '../context/apiRules'

function loginOnFailedAuthentication(user: UserState){
  // if the user is not authenticated (has a valid session cookie), then
  // redirect to the login router URL so they can log in
  if (user.authenticationFailed) {
    const nextUrl = `${get(location, 'pathname', '/')}${location.hash}`
    const loginUrl = `${EXTERNAL_URL_MAP.USER_LOGIN}?next=${encodeURIComponent(nextUrl)}`
    window.location.href = loginUrl
    // never gets here - browser is redirected
  }
}

export const PrivateRoute: React.SFC<PrivateRouteProps> = ({ component: Component, componentProps, user, ...rest }) => {
  const hostAppContext = useContext<HostAppContext>(PreboardingHostAppContext)
  const shouldCheckAuthentication = apiUserLoginRule(hostAppContext.hostApp)

  if (!user.authenticationCheckComplete) {
    return null;
  }

  if(shouldCheckAuthentication){
    loginOnFailedAuthentication(user)
  }

  return (
    <Route
      {...rest}
      render={(props) => (user.isAuthenticated ? <Component {...componentProps}/> : null)}
    />
  )
}
