import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { EXTERNAL_URL_MAP } from '../../routes/ExternalUrls';
import { get } from '../../utils/ajax';
import { CopyActionTypes } from './types';
import {
  manageWorkAuthCopy
} from './workAuthCopySagas'

const copyFailed = (err) => ({
  message: 'failed to load copy',
  error: err,
});

async function fetchCopy(planId) {
  let requestUrl;
  if (planId) {
    requestUrl = `${EXTERNAL_URL_MAP.COPY}?plan=${planId}`;
  } else {
    requestUrl = EXTERNAL_URL_MAP.COPY;
  }
  try {
    const res = await get(requestUrl);
    return res.data;
  } catch (copyError) {
    return copyFailed(copyError);
  }
}

function* manageCopyResponseSuccess(payload){
  yield put({
    type: CopyActionTypes.FetchCopyFulfilled,
    payload,
  });
}

function* manageCopyResponseError(payload){
  yield put({
    type: CopyActionTypes.FetchCopyFailed,
    payload,
  });
}

function* manageCopy(action) {
  const payload = yield call(fetchCopy, action.payload);
  if ('error' in payload) {
    yield manageCopyResponseError(payload)
  } else {
    yield manageCopyResponseSuccess(payload)

    // Module specific generators go next
    yield manageWorkAuthCopy(payload)
  }
}

function* watchCopyRequest() {
  yield takeLatest(CopyActionTypes.FetchCopy, manageCopy);
}

export function* copySaga() {
  yield all([fork(watchCopyRequest)]);
}
