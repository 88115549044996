import { EnvState, EnvEvent, LoadEnvActions, EnvironmentState, EnvAction, EnvActionType } from './types'

import { BasePrefix, HostApp } from '../../types/hostApp'

const initialEnvState: EnvState = {
  loading: false,
}

const initialEnvironmentState: EnvironmentState = {
  employeeId: '',
  hostApp: HostApp.Fallback,
  baseSegmentPrefix: BasePrefix.WEB_PORTAL,
  hostAppLanguage: '',
}

export const oldReducer = (state = initialEnvState, action:LoadEnvActions):EnvState => {
  switch(action.type){
    case EnvEvent.LoadEnv:
      return {
        ...initialEnvState,
        loading: true
      }
    case EnvEvent.LoadEnvSuccess:
      return {
        ...initialEnvState,
        loading: false,
        canStage: action.payload
      }
    case EnvEvent.LoadEnvFailure:
      return {
        ...initialEnvState,
        loading: false
      }
    default:
      return state;
  }
};

// This is a new reducer for the environment application state.
// It's main responsibility is to hold the environment related information, which
// can include information such as the name of the hosting application and other
// related metadata such as prefix segment associated with it.
// It's collaborators are redux sagas which make calls to external apis.
// They will be able to retrieve the hostApp and prefixSegment using the reducer.
export const newReducer = (state = initialEnvironmentState, action:EnvAction):EnvironmentState => {
  switch(action.type){
    case EnvActionType.PersistHostApp:
      return action.payload
    default:
      return state
  }
}

export const envReducer = newReducer

export default envReducer
