import { eventChannel, END, EventChannel } from 'redux-saga'
import { all, call, fork, put, select, take, takeEvery, throttle } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'
import {
  unauthorizedAction,
  heartBeatAction
} from './actions'

import { ApplicationState } from '../types'
import { UserState } from '../user/types'

import { HeartbeatActionType } from './types'

import { get } from '../../utils/ajax'
import { EXTERNAL_URL_MAP } from '../../routes/ExternalUrls'

import {
  prefixSegmentToPath
} from '../../context/baseSegmentContext'

/*
 * Used when heartBeatPollingInterval is set to 1 minute.
 */
export const heartbeatPollingInterval = 30*1000

/*
 * This expression simply returns the result of making a get call
 * to the Heartbeat API
 */
export function getHeartBeat(prefixSegment: string): Promise<any> {
  const heartbeatUrl = prefixSegmentToPath({
    path: EXTERNAL_URL_MAP.HEARTBEAT,
    prefixSegment 
  })
  return get(heartbeatUrl)
}

/*
 * Log user out if the client receives and unauthorized signal from
 * the heartbeat api.
 */
function* logoutIfUnauthorized(){
  const user: UserState = yield select((state: ApplicationState) => state.user)
  if(user.profile) {
    window.location.href = '/api/auth/logout'
  }
}

/*
 * This makes a get call to the heartbeat endpoint if the useragent's navigator is online.
 * If online, this returns the promise for that get call.
 * What to do with that promise is beyond the scope of this method
 */
export function* heartBeat(){
  if(window.navigator.onLine && !window.document.hidden){
    const prefixSegment = (yield select((state: ApplicationState) => state.environment.baseSegmentPrefix)) as string
    const resp: AxiosResponse = (yield call(getHeartBeat, prefixSegment)) as AxiosResponse
    yield (onPulse(resp))
  }
}

/*
 * This generator emits a Pulse when invoked
 * And we emit a pulse, when we receive a response from the server which indicates everything is alright.
 * I.e everthing's fine: The session's good; The access tokens are ok. Take a breather.
 */
export function* onPulse(resp: AxiosResponse){
  const { status } = resp
  switch(status){
    case 401:
      yield put(unauthorizedAction())
      return
    default:
      yield put(heartBeatAction())
      return
  }
}
  
/*
 * Watcher for `heartBeat` on HeartbeatActionType.Ok action
 * This throttles heartbeat calls to every minute.
 */
function* watchHeartBeats(){
  yield throttle(heartbeatPollingInterval, HeartbeatActionType.Ok, heartBeat)
}

/*
 * Watcher for unathorized user handler
 * Right now, the policy is to log them out, if they are unauthorized
 */
function* watchForUnauthorizedUser(){
  yield takeEvery(HeartbeatActionType.Unauthorized, logoutIfUnauthorized)
}

type VisibilityEmitter = (input: boolean | END) => void 

export function createDocumentVisibilityEmitter(emitter: VisibilityEmitter){
  return function(){
    if(!document.hidden && location.pathname !== EXTERNAL_URL_MAP.LANDING){
      emitter(true)
    } else {
      emitter(false)
    }
  }
}

export function createDocumentVisibilityChannel(){
  return eventChannel((emitter: VisibilityEmitter) => {
    const documentVisibilityEmitter = createDocumentVisibilityEmitter(emitter)
    document.addEventListener('visibilitychange', documentVisibilityEmitter)
    return () => {
      document.removeEventListener('visibilitychange', documentVisibilityEmitter)
    }
  })
}

export function* heartbeatRestartSaga(){
  let didUserNavigateBackFromAnotherTab: boolean
  const documentVisibilityChan: EventChannel<boolean> = yield call(createDocumentVisibilityChannel)
  while(true){
    didUserNavigateBackFromAnotherTab = yield take(documentVisibilityChan)
    if(didUserNavigateBackFromAnotherTab){
      const prefixSegment = (yield select((state: ApplicationState) => state.environment.baseSegmentPrefix)) as string
      yield call(getHeartBeat, prefixSegment)
      yield put(heartBeatAction())
    }
  }
}

/*
 * The main saga for this module
 */
export function* heartBeatSaga(){
  yield all([
    fork(watchHeartBeats),
    fork(watchForUnauthorizedUser),
    fork(heartbeatRestartSaga)
  ])
}
