export const languages = [
  { tag: 'en_US', name: 'EN - English', shortName: 'En', mobileName: 'English' },
  { tag: 'fr_FR', name: 'FR - Français', shortName: 'Fr', mobileName: 'Français' },
  { tag: 'de_DE', name: 'DE - Deutsch', shortName: 'De', mobileName: 'Deutsch' },
  { tag: 'pl_PL', name: 'PL - Polski', shortName: 'Pl', mobileName: 'Polski' },
  { tag: 'ja_JP', name: 'JA - 日本語', shortName: 'Ja', mobileName: '日本語' },
  { tag: 'it_IT', name: 'IT - Italiano', shortName: 'It', mobileName: 'Italiano' },
  { tag: 'es_ES', name: 'ES - Español', shortName: 'Es', mobileName: 'Español' },
  { tag: 'cs_CZ', name: 'CS - Čeština', shortName: 'Cs', mobileName: 'Čeština' },
  { tag: 'zh_CN', name: 'ZH - 中文 (简体)', shortName: 'Zh', mobileName: '中文 (简体)' },
  { tag: 'sk_SK', name: 'SK - Slovenčina', shortName: 'Sk', mobileName: 'Slovenčina' },
  { tag: 'ro_RO', name: 'RO - ​Română', shortName: 'Ro', mobileName: '​Română' },
  { tag: 'tr_TR', name: 'TR - Türkçe', shortName: 'Tr', mobileName: 'Türkçe' },
  { tag: 'id_ID', name: 'ID - bahasa Indonesia', shortName: 'Id', mobileName: 'bahasa Indonesia' },
  { tag: 'pt_BR', name: 'PT - Portuguese', shortName: 'Pt', mobileName: 'Portuguese' },
]